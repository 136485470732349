import React, { Component } from 'react';
import SignUp from 'pages/sign-up/SignUp';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { registerUser } from 'services/user/userService.js';
import { emailMessage, errorMessage } from 'components/design-components/toast/ToastrMsg';
import passwordPolicies from 'componentsWithData/utils/passwordPolicies.js';
import { logEvent } from 'services/analytics/gaService.js';

export default class SignUpWithData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordVisible: false,
      isLoading: false,
      errorMessage: '',
    };
  }

  onSubmit = ({ email, password, firstName='', lastName='', location }) => {
    const { history } = this.props;

    this.setState({
      isLoading: true,
    });

    email = email.toLowerCase();
    firstName = firstName.trim();

    registerUser({ email, password, firstName, lastName, location })
      .then(result => {
        this.setState({
          isLoading: false,
        });
        emailMessage('Confirmation email sent');

        logEvent({
          category: 'Sign Up',
          action: 'success',
          label: 'Signup Success',
        });
        history.push(routeEnums.HOME);
      })
      .catch(error => {
        errorMessage('Error creating account');

        logEvent({
          category: 'Sign Up',
          action: 'fail',
          label: 'Signup Fail',
        });

        this.setState({
          isLoading: false,
          errorMessage: error.message || 'Register error',
        });
      });
  };

  onPasswordVisibleChange = evt => {
    this.setState((state, props) => ({
      passwordVisible: !state.passwordVisible,
    }));
  };

  render() {
    const { passwordVisible, isLoading, errorMessage } = this.state;
    const { location } = this.props;
    const searchString = location.search || '';
    const email = searchString.split('inviteEmail=')[1];

    const signUpProps = {
      onSubmit: this.onSubmit,
      onPasswordVisibleChange: this.onPasswordVisibleChange,
      passwordVisible: passwordVisible,
      termsLink: routeEnums.TERMS_OF_SERVICE,
      privacyLink: routeEnums.PRIVACY_POLICY,
      errorText: errorMessage,
      isLoading: isLoading,
      passwordInstructions: passwordPolicies,
    };

    if (email) {
      signUpProps.initialValues = { email };
    }

    return <SignUp {...signUpProps} />;
  }
}
