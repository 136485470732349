import React from 'react';

const SvgLeftArrow = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h18v18H0z" />
      <path
        fill="#1AB38E"
        d="M5.117 8.649a.677.677 0 0 0 0 .76L7.68 12.75c.194.266.621.342.893.114a.646.646 0 0 0 .272-.494.721.721 0 0 0-.117-.38L6.942 9.636h5.398c.35 0 .66-.266.66-.645 0-.38-.272-.646-.66-.646H6.864L8.65 5.99c.195-.266.156-.684-.155-.874-.272-.19-.699-.152-.893.152l-2.485 3.38z"
      />
    </g>
  </svg>
);

export default SvgLeftArrow;
