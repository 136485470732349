import React, { Component } from 'react';
import lodash from 'lodash';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import ViewGroup from 'pages/groups/ViewGroup';
import getSingleGroup from 'services/graphQL/queries/groups/getSingleGroup';
import removeAllGroupProfiles from 'services/graphQL/mutations/groups/removeAllGroupProfiles';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { replaceRouteParams } from 'componentsWithData/router/routeService.js';
import removeGroupProfile from 'services/graphQL/mutations/groups/removeGroupProfile';
import updateGroupProfile from 'services/graphQL/mutations/groups/updateGroupProfile';
import rejectGroup from 'services/graphQL/mutations/groups/rejectGroup';

class ViewGroupWithData extends Component {
  state = {
    isLoading: false,
    group: {},
    groupProfiles: [],
    groupInvites: [],
    editMemberErrorText: '',
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { match } = this.props;
    const groupId = match.params.groupId;

    this.setState({
      isLoading: true,
    });
    getSingleGroup(groupId)
      .then(data => {
        const groupData = lodash.get(data, 'data.group');
        const groupProfiles = lodash.get(data, 'data.group.groupProfiles') || [];
        const groupInvites = lodash.get(data, 'data.group.groupInvites') || [];

        this.setState({
          isLoading: false,
          group: groupData,
          groupProfiles,
          groupInvites
        })
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
      })
  }

  goToGroups = () => {
    const { history } = this.props;

    history.push(routeEnums.GROUPS);
  }

  onEditGroup = groupId => {
    const { history } = this.props;

    history.push(replaceRouteParams(routeEnums.EDIT_GROUP, { groupId }));
  };

  onRemoveAllMembers = (onClose, setLoading) => {
    const { match } = this.props;
    const groupId = match.params.groupId;
    const { groupProfiles } = this.state;
    const { userId } = this.props;
    const profiles = groupProfiles.filter(profile => profile.profile.id === userId);

    setLoading(true);
    removeAllGroupProfiles({groupId})
      .then(data => {
        const success = lodash.get(data, 'data.removeAllGroupProfiles.success');
        if(success) {
          onClose();
          setLoading(false);
          this.setState({
            groupProfiles: profiles,
            groupInvites: []
          });
          successMessage('Removed successfuly!');
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      })
  }

  onRemoveProfile = (groupId, profileId, handleClose, setLoading, leave) => {
    const { groupProfiles } = this.state;
    const profiles = groupProfiles.filter(profile => profile.profile.id !== profileId);
    const groupData = {
      groupId,
      profileId
    };
    setLoading(true);
    removeGroupProfile(groupData)
      .then(data => {
        const success = lodash.get(data, 'data.removeGroupProfile.success');
        handleClose();
        setLoading(false);
        if(success) {
          this.setState({
            groupProfiles: profiles,
          });
          successMessage(leave ? 'Leaved successfuly!' : 'Removed successfuly!');
          if(leave) {
            this.goToGroups();
          }
        }
      })
      .catch(err => {
        setLoading(false);
      });
  };

  onUpdateGroupProfile = (profileId, firstName, lasttName, handleClose, setLoading) => {
    const { match } = this.props;
    const groupId = match.params.groupId;
    const profileData = {
      groupId,
      profileId: profileId,
      givenName: firstName,
      familyName: lasttName
    };
    setLoading(true);
    updateGroupProfile(profileData)
      .then(data => {
        handleClose();
        setLoading(false);
        const success = lodash.get(data, 'data.updateGroupProfile.success');
        const errors = lodash.get(data, 'data.updateGroupProfile.errors');
        if(success) {
          successMessage('Member info changed!');
        } else {
          errorMessage('Member info not changed!');
          this.setState({
            editMemberErrorText: errors[0],
          });
        }

      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        errorMessage('Member info not changed!');
        this.setState({
          editMemberErrorText: errors[0],
        });
      })
  }

  onDeleteInvite = (inviteId, setLoading, leave) => {
    const { groupInvites } = this.state;
    const invites = groupInvites.filter(invite => invite.id !== inviteId);

    setLoading(true);
    rejectGroup({inviteId})
      .then(data => {
        const success = lodash.get(data, 'data.rejectGroupInvitation.success');
        setLoading(false);
        if(success) {
          this.setState({
            groupInvites: invites
          });
          successMessage('Removed successfuly!');
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  render() {
    const { isLoading, group, group: { s3Key }, groupProfiles, groupInvites, editMemberErrorText } = this.state;
    const { userId } = this.props;
    const viewGroupProps = {
      userId,
      group,
      groupProfiles,
      groupInvites,
      editMemberErrorText,
      goToGroups: this.goToGroups,
      onRemoveAllMembers: this.onRemoveAllMembers,
      onEditGroup: this.onEditGroup,
      groupsLink: routeEnums.GROUPS,
      imgSrc: s3Key && getS3ImageUrl(s3BucketTypes.GROUP, s3Key),
      onRemoveProfile: this.onRemoveProfile,
      onDeleteInvite: this.onDeleteInvite,
      onUpdateGroupProfile: this.onUpdateGroupProfile,
    }
    if (isLoading) {
      return <Loader />;
    }

    return <ViewGroup {...viewGroupProps} />;
  }
}

export default ViewGroupWithData;
