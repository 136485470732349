import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { BodyContainer, Button, FlexCenter, H1, Image, P } from 'components/design-components';
import uniqid from 'uniqid';
import { media, theme } from 'components/design-components/config.js';

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1;
    ${media.desktop`text-align: center`}
  }
  ${media.tablet`display: block;`}
`;

const DownloadButtonsContainer = styled(DownloadContainer)`
  margin-top: 90px;
`;

const DownloadButton = styled(Button)`
  font-weight: 700;
  padding: 14px 0;
  width: 230px;
  font-size: 20px;
  background-color: ${theme.colors.equell_teal};
  color: ${theme.colors.true_white};
  ${props =>
    props.secondary &&
    css`
      background-color: ${theme.colors.grey};
    `}
`;
const DownloadDescription = styled.div`
  font-size: 14px;
`;
const DownloadTitle = styled(H1)`
  font-size: 45px;
  font-weight: bold;
`;
const DownloadButtons = ({ links }) =>
  links.map(({ title, secondary, description }) => (
    <div key={uniqid()}>
      <DownloadButton secondary={secondary}>{title}</DownloadButton>
      <DownloadDescription>{description}</DownloadDescription>
    </div>
  ));
const DownloadAndInstall = ({ title, description, imageUrl, downloadLinks }) => (
  <BodyContainer>
    <DownloadContainer>
      <div>
        <DownloadTitle>{title}</DownloadTitle>
        {description && <P>{description}</P>}
        <DownloadButtonsContainer>
          <DownloadButtons links={downloadLinks} />
        </DownloadButtonsContainer>
      </div>
      <FlexCenter>
        <Image src={imageUrl} style={{ width: '88%' }} />
      </FlexCenter>
    </DownloadContainer>
  </BodyContainer>
);

DownloadAndInstall.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  description: PropTypes.string,
  downloadLinks: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      secondary: PropTypes.bool,
    })
  ),
};

DownloadAndInstall.defaultProps = {
  description: '',
  downloadLinks: [],
};
export default DownloadAndInstall;
