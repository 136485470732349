import React from 'react';

const SvgQuestionIconNotifcation = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={15} cy={15} r={15} fill="#EADED8" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M13.75 22h2.5v-2.5h-2.5V22zM15 7c-2.763 0-5 2.237-5 5h2.5c0-1.375 1.125-2.5 2.5-2.5s2.5 1.125 2.5 2.5c0 2.5-3.75 2.188-3.75 6.25h2.5c0-2.813 3.75-3.125 3.75-6.25 0-2.763-2.237-5-5-5z"
      />
    </g>
  </svg>
);

export default SvgQuestionIconNotifcation;
