import React, { Component } from 'react';
import EditProfile from 'pages/my-account/EditProfile.jsx';
import getProfileData from 'services/graphQL/queries/userSettings/editProfileData.js';
import updateProfile from 'services/graphQL/mutations/userSettings/updateProfile.js';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import lodash from 'lodash';
import { changePassword } from 'services/user/userService.js';
import EventEmitter, { EventsEnum } from 'services/events/eventsService.js';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { uploadFile } from 'services/s3/apiGatewayClient.js';
import moment from 'moment';
import passwordPolicies from 'componentsWithData/utils/passwordPolicies.js';
import { logEvent } from 'services/analytics/gaService.js';
import cognitoErrorEnum from 'services/aws-cognito/enums/cognitoErrorEnum.js';

const fileSizeLimit = 200000;

export default class EditProfileWithData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      fetchError: '',
      passwordChangeError: '',
      isSubmitting: false,
      disableSubmit: false,
      isImageLoading: false,
      imageKey: null,
      isPasswordSubmitting: false,
      submitError: '',
      profileData: {},
      changePasswordModalOpen: false,
      error: null,
    };
  }

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchData() {
    getProfileData()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const me = lodash.get(data, 'data.me');

        this.setState({
          isLoading: false,
          profileData: me,
          imageKey: me.s3Key,
        });
      })
      .catch(err => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          fetchError: err.message,
          isLoading: false,
        });
      });
  }

  saveProfile = ({ firstName, lastName }) => {
    const { imageKey } = this.state;

    this.setState({
      isSubmitting: true,
    });

    const currentTimezone = new Date().getTimezoneOffset();
    const profileData = {
      givenName: firstName,
      familyName: lastName,
      utcOffsetMinutes: currentTimezone,
    };

    if (imageKey) {
      profileData.s3Key = imageKey;
    }

    updateProfile(profileData)
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          isSubmitting: false,
        });

        const success = lodash.get(data, 'data.updateProfile.success');
        const errors = lodash.get(data, 'data.updateProfile.errors') || [];

        if (success) {
          successMessage('Settings saved!');

          this.fetchData();
          EventEmitter.emit(EventsEnum.FETCH_USER_DATA, false);
        } else {
          errorMessage('Error saving settings!');

          this.setState({
            submitError: errors[0],
          });
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        errorMessage('Error saving settings!');

        this.setState({
          isSubmitting: false,
          submitError: error.message,
        });
      });
  };

  getPasswordErrorMessage(error) {
    if (error.code === cognitoErrorEnum.InvalidParameterException) {
      return 'New password is not valid';
    } else if (error.code === cognitoErrorEnum.InvalidPasswordException) {
      return 'Old password is not correct';
    } else if (error.code === cognitoErrorEnum.NotAuthorizedException) {
      return 'Old password is not correct';
    } else if (error.code === cognitoErrorEnum.LimitExceededException) {
      return "You've reached the retry limit";
    }

    return 'Error changing password';
  }

  updatePassword = ({ oldPassword, newPassword }) => {
    this.setState({
      isPasswordSubmitting: true,
    });

    changePassword({ oldPassword, newPassword })
      .then(data => {
        successMessage('Password changed!');

        this.setState({
          isPasswordSubmitting: false,
        });
        this.togglePasswordModal();
      })
      .catch(error => {
        errorMessage('Error changing password!');

        this.setState({
          passwordChangeError: this.getPasswordErrorMessage(error),
          isPasswordSubmitting: false,
        });
      });
  };

  togglePasswordModal = () => {
    this.setState((state, props) => ({
      changePasswordModalOpen: !state.changePasswordModalOpen,
    }));
  };

  fileSizeError = () => {
    errorMessage('File must be max 200kB');
  };

  onFileSelect = base64String => {
    const { profileData } = this.state;
    this.setState({
      disableSubmit: true,
      isImageLoading: true,
    });

    uploadFile(base64String, s3BucketTypes.PROFILE)
      .then(uploadId => {
        if (!this.__isComponentMounted) {
          return;
        }
        this.setState({
          disableSubmit: false,
          isImageLoading: false,
          imageKey: uploadId,
        });

        logEvent({
          category: 'Profile image',
          action: 'changed',
          label: `Profile image changed, UserID:${profileData.id}`,
        });

        successMessage('Image uploaded, click Update to add to your profile');
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          disableSubmit: false,
          isImageLoading: false,
        });
        errorMessage('Error uploading file');
      });
  };

  render() {
    const {
      isLoading,
      isSubmitting,
      isImageLoading,
      imageKey,
      isPasswordSubmitting,
      profileData,
      error,
      passwordChangeError,
      changePasswordModalOpen,
      disableSubmit,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    const teamItems = profileData.teams || [];
    const profileProps = {
      title: `Profile`,
      onUpdate: this.saveProfile,
      changePasswordLink: '#',
      userEmail: profileData.email || '',
      imgSrc: imageKey ? getS3ImageUrl(s3BucketTypes.PROFILE, imageKey) : null,
      profileLoading: isSubmitting,
      passwordLoading: isPasswordSubmitting,
      passwordUpdateError: passwordChangeError,
      fields: {
        firstName: profileData.givenName || '',
        lastName: profileData.familyName || '',
      },
      onUpdatePassword: this.updatePassword,
      passwordInstructions: passwordPolicies,
      itemList: teamItems.map(teamItem => ({
        id: teamItem.id,
        name: teamItem.name,
        members: teamItem.teamProfiles ? teamItem.teamProfiles.length : 0,
        joined: moment(profileData.created * 1000).format('D MMMM YYYY'),
      })),
      opened: changePasswordModalOpen,
      handleModalOpen: this.togglePasswordModal,
      handleModalClose: this.togglePasswordModal,
      disableSubmit,
      isImageLoading,
      fileSizeLimit: fileSizeLimit,
      fileSizeError: this.fileSizeError,
      onFileSelect: this.onFileSelect,
    };

    return <EditProfile {...profileProps} />;
  }
}
