import ReactGA from 'react-ga';
import gaSettings from './gaSettings.js';

function initializeGaClient() {
  const obj = {};
  if (process.env.NODE_ENV === 'development') {
    obj.debug = false;
  }
  ReactGA.initialize(gaSettings.clientId, obj);
}

function logPageView(pageUrl) {
  ReactGA.pageview(pageUrl);
}

function logEvent({ category, action, label, value }) {
  ReactGA.event({ category, action, label, value });
}

export { initializeGaClient, logPageView, logEvent };
