import React from 'react';
import AchievementsList from './components/AchievementsList';
import { H4 } from 'components/design-components';

const Achievements = ({ achievements, onClickAward }) =>
  achievements.length ? (
    <AchievementsList achievements={achievements} onClickAward={onClickAward} />
  ) : (
    <H4>No achievements</H4>
  );

Achievements.propTypes = AchievementsList.propTypes;

export default Achievements;
