import s3Settings from './s3Settings.js';

function getS3ImageUrl(type, key) {
  if (!key) {
    return null;
  } else {
    return `https://s3.amazonaws.com/${s3Settings.imagesS3Bucket}/${type}/${key}`;
  }
}

function getS3RewardImages(type, key) {
  if (!/^(f|ht)tps?:\/\//i.test(key)) {
    return `https://s3.amazonaws.com/${s3Settings.imagesS3Bucket}/${type}/${key}`;
  }
  return key;
}

export { getS3ImageUrl, getS3RewardImages };
