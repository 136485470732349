import React from 'react';
import {
  Button,
  Col,
  DatePickerInput,
  DropdownInput,
  ErrorText,
  ImageUpload,
  Input,
  Link,
  Row,
} from 'components/design-components';
import { PointValueEnum, RewardDurationEnum } from '../../../enums';
import { media, theme } from 'components/design-components/config';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

const UploadInput = styled.div`
  min-width: 355px;
`;

const UploadInfo = styled.div`
  font-size: ${theme.fontSize.smallNormal};
  padding-top: 15px;
  padding-left: 5px;
  width: 300px;
`;

const UploadContainer = styled.div`
  height: 200px;
`;

const InputContainer = styled.div`
  width: 725px;
  height: auto;
  margin: 5px 0 5px 25px;
  ${media.tablet`
    width: 100%
    margin: 10px 2px;
  `}
`;

const SubmitContainer = styled(Row)`
  margin-top: 30px;
`;

const Container = styled.div`
  display: flex;
  ${media.tablet`display: block;`}
`;

const StyledButton = styled(Button)`
  margin: 0;
`;

const StyledInput = styled.div`
  margin-bottom: 20px;
`;

const RewardFormContent = ({
  titleMaxLength,
  descriptionMaxLength,
  errorText,
  cancelLink,
  submitting,
  pristine,
  disabledSubmit,
  isLoading,
  fileSizeLimit,
  fileSizeError,
  onFileSelect,
  isImageLoading,
  imageUrl,
  onImageRemove,
}) => (
  <Container>
    <UploadInput>
      <UploadContainer>
        <Field name="imageUrl">
          {({ input, meta }) => (
            <ImageUpload
              label="Reward Image"
              input={input}
              meta={meta}
              fileSizeLimit={fileSizeLimit}
              fileSizeError={fileSizeError}
              onFileSelect={onFileSelect}
              isLoading={isImageLoading}
              imageUrl={imageUrl}
              onImageRemove={onImageRemove}
            />
          )}
        </Field>
      </UploadContainer>
      <UploadInfo>Reward images should be 350w and 226h and max. file size of 200 KB</UploadInfo>
    </UploadInput>
    <InputContainer>
      <StyledInput>
        <Input maxLength={titleMaxLength} name="rewardTitle" label="Reward Title" />
      </StyledInput>
      <Input
        maxLength={descriptionMaxLength}
        isTextarea
        name="rewardDescription"
        label="Reward Description"
      />

      <Row>
        <Col width={2}>
          <DatePickerInput label="Start date" name="startDate" />
        </Col>
        <Col width={1}>
          <DropdownInput label="Duration" name="duration" items={RewardDurationEnum} />
        </Col>
      </Row>
      <Row>
        <Col width={1}>
          <DropdownInput label="Point value" name="pointValue" items={PointValueEnum} />
        </Col>
        <Col width={2} verticalCenter horizontalCenter>
          <ErrorText>{errorText}</ErrorText>
        </Col>
      </Row>
      <SubmitContainer>
        <Col width={2} />
        <Col horizontalCenter verticalCenter>
          <Link underlined to={cancelLink}>
            Cancel
          </Link>
        </Col>
        <Col>
          <StyledButton
            loading={isLoading}
            disabled={submitting || pristine || disabledSubmit}
            isSubmit
            fullwidth
          >
            Save Reward
          </StyledButton>
        </Col>
      </SubmitContainer>
    </InputContainer>
  </Container>
);

RewardFormContent.propTypes = {
  titleMaxLength: PropTypes.number,
  descriptionMaxLength: PropTypes.number,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  cancelLink: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  disabledSubmit: PropTypes.bool,
  fileSizeLimit: PropTypes.number,
  fileSizeError: PropTypes.func,
  onFileSelect: PropTypes.func,
  onImageRemove: PropTypes.func,
  isImageLoading: PropTypes.bool,
};

RewardFormContent.defaultProps = {
  pristine: false,
  submitting: false,
  isLoading: false,
  errorText: '',
  titleMaxLength: 50,
  descriptionMaxLength: 100,
  fileSizeLimit: null,
};

export default React.memo(RewardFormContent);
