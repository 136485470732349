import React from 'react';
import PropTypes from 'prop-types';
import { InputField, Label } from 'components/design-components/form/styled';
import FormGroup from 'components/design-components/form/FormGroup';
import styled from 'styled-components';

const StyledFormGroup = styled(FormGroup)`
  & + div > div {
    background: none;
  }
  & + div > div:hover {
    background: none;
  }
`;

const SearchInput = ({ children, error, name, ...rest }) => {
  return (
    <StyledFormGroup>
      {children}
      <InputField type="text" id={name} name={name} autoComplete="nope" placeholder=" " error={error} {...rest} />
      <Label htmlFor={name}>Location</Label>
    </StyledFormGroup>
  );
};

SearchInput.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  meta: PropTypes.object,
};

SearchInput.defaultProps = {
  value: '',
  error: false,
  meta: {},
};

export default React.memo(SearchInput);
