import React from 'react';

const SvgMeterThumbnailEmpty = props => (
  <svg viewBox="0 0 41.49 26.6" {...props} width="1em" height="1em">
    <g data-name="Layer 2">
      <path
        fill="none"
        stroke="#ebebeb"
        strokeLinecap="round"
        strokeWidth={12}
        d="M35.49 20.6A14.75 14.75 0 0 0 6 20.6"
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default SvgMeterThumbnailEmpty;
