import React from 'react';
import PropTypes from 'prop-types';
import { BodyContainer, Button, DefaultTable, Link } from 'components/design-components';
import MembersTable from './components/MembersTable';

const tableColumns = ['Name', 'Email address', 'Points', 'Creation date', '', ''];
const listOfInfo = [];

const Members = ({ addMembersLink, members, ...rest }) => (
  <BodyContainer
    title={'Members'}
    titleCount={members.length}
    after={
      <Link to={addMembersLink}>
        <Button className="float-right"> + Add members</Button>
      </Link>
    }
  >
    {members.length > 0 ? (
      <MembersTable tableColumns={tableColumns} members={members} {...rest} />
    ) : (
      <DefaultTable listOfInfo={listOfInfo} listOfColumns={tableColumns} />
    )}
  </BodyContainer>
);

Members.propTypes = {
  addMembersLink: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      avatarUrl: PropTypes.shape.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      creationDate: PropTypes.instanceOf(Date),
    })
  ),
  onEditSave: PropTypes.func.isRequired,
  onRemoveAllMembers: PropTypes.func.isRequired,
  onRemoveMember: PropTypes.func.isRequired,
  onEditValidate: PropTypes.func,
  removeAllMembersErrorText: PropTypes.string,
  removeMemberErrorText: PropTypes.string,
  editMemberErrorText: PropTypes.string,
};

Members.defaultProps = {
  members: [],
};

export default Members;
