import React, { Component } from 'react';
import About from 'pages/about/About.jsx';

const aboutProps = {
  header: {
    text1: 'Founded in 2018',
    text2: 'About Equell',
    text3: 'Digital Balance in Life',
  },
  sections: [
    {
      title: 'Connectivity addiction has reached epidemic proportions ',
      description:
        'To provide you with a truly disconnected experience, Equell is accessible both on your phone and your computer. Equall desktop app and Equell Web are computer based extensions of the Equell account on your phone. The messages you send and receive are fully synced between your phone and your computer, and you can see all messages on both devices. Any action you take on the phone will apply to Equell on your computer and vice versa.',
      imageUrl: 'https://i.imgur.com/jW6jmTz.png',
      imageAlt: 'test',
    },
    {
      title: 'Connectivity addiction has reached epidemic proportions ',
      description:
        'To provide you with a truly disconnected experience, Equell is accessible both on your phone and your computer. Equall desktop app and Equell Web are computer based extensions of the Equell account on your phone. The messages you send and receive are fully synced between your phone and your computer, and you can see all messages on both devices. Any action you take on the phone will apply to Equell on your computer and vice versa.',
      imageUrl: 'https://i.imgur.com/jW6jmTz.png',
      imageAlt: 'test',
    },
  ],
  footer: {
    title: 'Connectivity addiction has reached epidemic proportions ',
    description:
      'To provide you with a truly disconnected experience, Equell is accessible both on your phone and your computer. Equall desktop app and Equell Web are computer based extensions of the Equell account on your phone. The messages you send and receive are fully synced between your phone and your computer, and you can see all messages on both devices. Any action you take on the phone will apply to Equell on your computer and vice versa.',
  },
};

export default class AboutWithData extends Component {
  render() {
    return <About {...aboutProps} />;
  }
}
