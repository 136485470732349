import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ImageResponsive = ({ alt, src, ...props }) => (
  <img
    alt={alt}
    src={src}
    className={classNames('img-responsive', 'w-100', props.className)}
    {...props}
  />
);

ImageResponsive.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default ImageResponsive;
