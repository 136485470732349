import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactRangeSlider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

import { theme } from '../config';

const SliderRemake = styled(ReactRangeSlider)`
  && {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: 4px;
    ${theme.borderRadius};
    background: ${props => (!props.disabled ? theme.colors.switch_off : theme.colors.light_grey)};
  }
  && .rangeslider {
    &__handle {
      box-shadow: none;
      outline: none;
    }

    &__handle:hover,
    &__handle:active {
      ${props => (!props.disabled ? theme.boxShadow : 'none')}
    }

    &__handle::after {
      content: none;
    }

    &__fill {
      ${props => (!props.disabled ? `background-color:${theme.colors.equell_teal}` : 'height: 0')};
    }
  }
`;

const SliderContainer = styled.div`
  width: 100%;
`;

const Slider = ({ handleChange, value, minValue, maxValue, disabled }) => {
  return (
    <SliderContainer>
      <SliderRemake
        disabled={disabled}
        min={minValue}
        max={maxValue}
        value={value}
        tooltip={false}
        onChange={handleChange}
        orientation="horizontal"
      />
    </SliderContainer>
  );
};

Slider.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

Slider.defaultProps = {
  disabled: false,
};

export default React.memo(Slider);
