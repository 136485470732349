import React, { useState } from 'react';
import lodash from 'lodash';
import {
  TableRowStyled,
  MemberImg,
  MemberName,
  MemberInfo,
  MemberEmail,
  MemberPoints,
  MemberRole,
  MemberIcon,
  MemberAction,
  RemoveMember
} from '../styled';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { MEMBER_ROLE } from 'enums/MemberRoleEnum';
import { Account } from 'components/design-components/icons';
import EditMember from './EditMember';
import RemoveMemberModal from './RemoveMemberModal';
import LeaveMemberModal from './LeaveMemberModal';

const TableRow = ({
  groupId,
  member,
  userId,
  adminMember,
  onRemoveProfile,
  onDeleteInvite,
  onUpdateGroupProfile,
  editMemberErrorText
}) => {
  const [openedEditModal, setEditModal] = useState(false);
  const [openedRemoveModal, setRemoveModal] = useState(false);
  const [openedLeaveModal, setLeaveModal] = useState(false);
  const adminId = lodash.get(adminMember, 'profile.id') || null;
  const memberId = lodash.get(member, 'profile.id');
  let firstName = member.givenName;
  if (!firstName) {
    firstName = lodash.get(member, 'profile.givenName') || 'Guest' ;
  }
  let lastName = member.familyName;
  if(!lastName) {
    lastName = lodash.get(member, 'profile.familyName') || '' ;
  }
  const [memberFirstName, setFirstName] = useState(firstName);
  const [memberLasttName, setLastName] = useState(lastName);
  const fullName = `${memberFirstName} ${memberLasttName}`.trim();
  const email = lodash.get(member, 'profile.email') || member && member.email;
  const points = lodash.get(member, 'profile.activity.availablePoints') || '-';
  const imageKey = lodash.get(member, 'profile.s3Key');
  const imgSrc = imageKey ? getS3ImageUrl(s3BucketTypes.PROFILE, imageKey) : null;
  let role;
  switch (member.isAdmin || member.accepted) {
  case true:
    role = MEMBER_ROLE.OWNER;
    break;
  case null:
    role = MEMBER_ROLE.INVITED;
    break;
  default:
    role = MEMBER_ROLE.MEMBER;
  }
  const removeProfile = (setLoading) => {
    onRemoveProfile(groupId, memberId, closeRemoveModal, setLoading);
  };
  const onRemoveMember = () => {
    setRemoveModal(true);
  };
  const closeEditModal = () => {
    setEditModal(false);
  };
  const onEditMember = () => {
    setEditModal(true);
  };
  const closeRemoveModal = () => {
    setRemoveModal(false);
  };
  const deleteInvite = (setLoading) => {
    onDeleteInvite(member.id, setLoading);
  };
  const onChangeName = (firstName, lastName) => {
    setFirstName(firstName);
    setLastName(lastName);
  };
  const leaveModal = () => {
    setLeaveModal(true);
  };
  const closeLeaveModal = () => {
    setLeaveModal(false);
  };
  const leaveGroup = setLoading => {
    const leave = true;
    onRemoveProfile(groupId, memberId, closeLeaveModal, setLoading, leave);
  };

  return (
    <TableRowStyled>
      <MemberInfo>
        {
          imgSrc ? <MemberImg src={imgSrc} /> : <MemberIcon><Account/></MemberIcon>
        }
        <MemberName title={fullName}>{fullName}</MemberName>
      </MemberInfo>
      <MemberEmail>
        <span title={email}>{email}</span>
        <MemberRole hiddenDesktop>{role}</MemberRole>
      </MemberEmail>
      <MemberPoints>{points}</MemberPoints>
      <MemberRole hiddenMobile>{role}</MemberRole>
      {
        userId === memberId && !member.isAdmin &&
        <MemberAction hiddenMobile>
          <a href="javascript:;" onClick={leaveModal}>Leave group</a>
        </MemberAction>
      }
      {
        adminId === null || adminId !== memberId &&
        <MemberAction>
          {/* <a href="javascript:;" onClick={onEditMember}>Edit</a> */}
          <RemoveMember onClick={onRemoveMember}>
            <i className="mdi mdi-close" />
          </RemoveMember>
        </MemberAction>
      }
      <EditMember
        errorText={editMemberErrorText}
        user={member.profile || {}}
        opened={openedEditModal}
        onClose={closeEditModal}
        onSave={onUpdateGroupProfile}
        firstName={firstName}
        lastName={lastName}
        onChangeName={onChangeName}
      />
      <RemoveMemberModal
        onClose={closeRemoveModal}
        opened={openedRemoveModal}
        onSave={memberId ? removeProfile : deleteInvite}
        firstName={firstName}
        lastName={lastName}
      />
      <LeaveMemberModal
        onClose={closeLeaveModal}
        opened={openedLeaveModal}
        onSave={leaveGroup}
        firstName={firstName}
        lastName={lastName}
      />
    </TableRowStyled>
  );
};

export default React.memo(TableRow);
