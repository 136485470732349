import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { LeftArrow, RightArrow } from 'components/design-components/icons';
import { media, theme } from 'components/design-components/config';
import MeterAnimationLogic from './MeterAnimationLogic';
import Notch from './Notch';
import * as moment from 'moment';
import { ClockFormat, ClockNumber } from './styledComponents';

const MeterStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const DayContainer = styled.div`
  width: 140px;
  display: flex;
  position: absolute;
  left: 50%;
  bottom: -7%;
  transform: translateX(-50%);
  ${media.tablet(css`
    top: 100%;
    right: 36%;
  `)};
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSize.normal};
  svg {
    cursor: pointer;
  }
`;

const HoursContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 64%;
  transform: translateX(-50%);
  ${media.tablet(css`
    left: 42%;
    top: 70%;
    transform: translateY(-50%);
  `)};
  width: 91px;
  text-align: center;
`;

const DayPoints = styled.div`
  font-size: 12px;
  padding-top: 4px;
`;

const DisplayDay = styled.div`
  width: 95px;
  text-align: center;
  color: ${theme.colors.dark_grey};
`;

const StyledRightArrowGreen = styled.div`
  font-size: 18px;
  visibility: ${props => !props.showForwardArrow && 'hidden'};
`;

const StyledLeftArrowGreen = styled.div`
  font-size: 18px;
  visibility: ${props => !props.showBackArrow && 'hidden'};
`;

const MeterAnimation = ({
  daySeconds,
  previousDaySeconds,
  dayPoints,
  prevDayPoints,
  changeDayBack,
  changeDayForward,
  displayDay,
  showBackArrow,
  showForwardArrow,
}) => {
  const durationHours = Math.floor(moment.duration(daySeconds, 'seconds').asHours());
  const durationMinutes = Math.round(moment.duration(daySeconds, 'seconds').asMinutes() % 60);
  const prevDurationHours = Math.floor(moment.duration(previousDaySeconds, 'seconds').asHours());
  const prevDisplayMinutes = Math.round(
    moment.duration(previousDaySeconds, 'seconds').asMinutes() % 60
  );

  let displayMinutes = '' + durationMinutes;
  if (durationMinutes < 10) {
    displayMinutes = '0' + durationMinutes;
  }

  return (
    <MeterStyled>
      <MeterAnimationLogic hours={durationHours} minutes={durationMinutes} />
      <Notch
        durationHours={prevDurationHours}
        displayMinutes={prevDisplayMinutes}
        dayPoints={prevDayPoints}
      />
      <HoursContainer>
        <ClockFormat>
          <ClockNumber>{durationHours}</ClockNumber>h{' '}
          <ClockNumber style={{ paddingLeft: '3px' }}>{displayMinutes}</ClockNumber>m
        </ClockFormat>
        <DayPoints>{dayPoints.toLocaleString()} POINTS</DayPoints>
      </HoursContainer>
      <DayContainer>
        <StyledLeftArrowGreen showBackArrow={showBackArrow}>
          <LeftArrow onClick={changeDayBack} />
        </StyledLeftArrowGreen>
        <DisplayDay>{displayDay}</DisplayDay>
        <StyledRightArrowGreen showForwardArrow={showForwardArrow}>
          <RightArrow onClick={changeDayForward} />
        </StyledRightArrowGreen>
      </DayContainer>
    </MeterStyled>
  );
};

MeterAnimation.propTypes = {
  daySeconds: PropTypes.number,
  previousDaySeconds: PropTypes.number,
  dayPoints: PropTypes.number,
  prevDayPoints: PropTypes.number,
  changeDayBack: PropTypes.func.isRequired,
  changeDayForward: PropTypes.func.isRequired,
  displayDay: PropTypes.string.isRequired,
  showBackArrow: PropTypes.bool,
  showForwardArrow: PropTypes.bool,
};

MeterAnimation.defaultProps = {
  hours: 0,
  daySeconds: 0,
  previousDaySeconds: 0,
  minutes: 0,
  dayPoints: 0,
  prevDayPoints: 0,
  displayDay: 'Today',
  showBackArrow: false,
  showForwardArrow: false,
};

export default React.memo(MeterAnimation);
