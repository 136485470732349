import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';
import { MainContainer } from 'components/design-components';
import moment from 'moment';
import { DashboardHeaderMonths, Meter } from 'pages/activity-dashboard/components/dashboard-header';

const Container = styled.div`
  width: 100%;
  box-shadow: 0 10px 20px -10px ${theme.colors.warm_shadow};
  background-color: ${theme.colors.true_white};
  ${theme.noSelect}
`;

const StyledMainContainer = styled(MainContainer)`
  min-height: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const DashboardHeader = ({
  redeemRewardsLink,
  totalPoints,
  onChangeWeekBack,
  onChangeWeekForward,
  onChangeDayBack,
  onChangeDayForward,
  singleDayActivity,
  previousDayActivity,
  currentDate,
  currentWeekDate,
  activity,
  userFirstInteraction,
  onClickDayMonth,
}) => (
  <Container>
    <StyledMainContainer>
      <Meter
        redeemRewardsLink={redeemRewardsLink}
        totalPoints={totalPoints}
        onChangeDayBack={onChangeDayBack}
        onChangeDayForward={onChangeDayForward}
        singleDayActivity={singleDayActivity}
        previousDayActivity={previousDayActivity}
        currentDateFromProps={currentDate}
        userFirstInteraction={userFirstInteraction}
      />
      <DashboardHeaderMonths
        activity={activity}
        currentWeekDate={currentWeekDate}
        clickBack={onChangeWeekBack}
        clickForward={onChangeWeekForward}
        userFirstInteraction={userFirstInteraction}
        onClickDayMonth={onClickDayMonth}
        currentDate={currentDate}
      />
    </StyledMainContainer>
  </Container>
);

DashboardHeader.propTypes = {
  activity: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
        .isRequired,
      end: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
        .isRequired,
      points: PropTypes.number.isRequired,
    })
  ),
  singleDayActivity: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
        .isRequired,
      end: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
        .isRequired,
      points: PropTypes.number.isRequired,
    })
  ),
  previousDayActivity: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
        .isRequired,
      end: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
        .isRequired,
      points: PropTypes.number.isRequired,
    })
  ),
  redeemRewardsLink: PropTypes.string.isRequired,
  totalPoints: PropTypes.number,
  onChangeWeekBack: PropTypes.func.isRequired,
  onChangeWeekForward: PropTypes.func.isRequired,
  onChangeDayBack: PropTypes.func.isRequired,
  onChangeDayForward: PropTypes.func.isRequired,
  onClickDayMonth: PropTypes.func.isRequired,
  currentDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
    .isRequired,
  currentWeekDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
    .isRequired,
  userFirstInteraction: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
  ]).isRequired,
};

DashboardHeader.defaultProps = {
  totalPoints: 0,
  activity: [],
  singleDayActivity: [],
};

export default React.memo(DashboardHeader);
