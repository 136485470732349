export default {
  myProfile: 'My profile',
  editProfile: 'Edit profile',
  activity: 'Activity',
  rewards: 'Rewards',
  rewardHistory: 'Reward history',
  devices: 'Devices',
  groups: 'Groups',
  createGroup: 'Create group',
  editGroup: 'Edit group',
  notifications: 'Notifications',
};
