import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Align, HelperText, Link } from 'components/design-components/index';
import { theme } from 'components/design-components/config';

const StyledHelperText = styled(HelperText)`
  color: ${theme.colors.grey};
  font-weight: 400;

  a {
    font-weight: 400;
    text-decoration: underline;
  }
`;

const Links = ({ forgotPasswordLink, mobileApp, chromeExtension }) => (
  <Align left style={{ marginTop: 26 }}>
    <StyledHelperText>
      <Link underlined to={forgotPasswordLink} fontSize={14}>
        Forgot your password?
      </Link>
    </StyledHelperText>
    <StyledHelperText>
      Ready to use equell? Get the{' '}
      <Link
        reactLink={false}
        href={mobileApp}
        rel="noopener noreferrer"
        target="_blank"
        fontSize={14}
        underlined
      >
        Mobile App
      </Link>{' '}
      {/* and{' '}
      <Link
        reactLink={false}
        href={chromeExtension}
        rel="noopener noreferrer"
        target="_blank"
        fontSize={14}
        underlined
      >
        Chrome Extension
      </Link>{' '} */}
    </StyledHelperText>
  </Align>
);
Links.propTypes = {
  forgotPasswordLink: PropTypes.string.isRequired,
  mobileApp: PropTypes.string.isRequired,
  chromeExtension: PropTypes.string.isRequired,
};

export default Links;
