import React from 'react';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';
import { DOWNLOAD_CHROME, DOWNLOAD_MOBILE } from './enums';

const StyledLink = styled.a`
  cursor: pointer;
  width: fit-content;
  &:hover {
    color: ${theme.colors.equell_teal};
    text-decoration: none;
  }
`;

export default [
  <StyledLink href={DOWNLOAD_MOBILE} rel="noopener noreferrer" target="_blank">
    Get Mobile App
  </StyledLink>,
];
