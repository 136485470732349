import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query myAccountData {
    me {
      id
      teams {
        id
        rewardRedemptions {
          id
          created
          pointsUsed
          status
          reward {
            id
            name
          }
          profile {
            givenName
            familyName
          }
        }
      }
    }
  }
`;

export default function getClaimedRewards() {
  return executeQuery(query, {});
}
