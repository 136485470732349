import React from 'react';

const SvgMeterThumbnail3H = props => (
  <svg width="1em" height="1em" viewBox="0 0 43 27" {...props}>
    <defs>
      <linearGradient id="a" x1="100%" x2="5.911%" y1="84.089%" y2="57.129%">
        <stop offset="0%" stopColor="#3FDDB6" />
        <stop offset="100%" stopColor="#1AB38E" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#F2E1D9"
        strokeLinecap="round"
        strokeWidth={12}
        d="M36.358 20.683c0-8.062-6.6-14.598-14.743-14.598S6.87 12.621 6.87 20.683"
      />
      <g transform="translate(6.642 6)">
        <path
          stroke="url(#a)"
          strokeLinecap="round"
          strokeWidth={8}
          d="M6.545 2.465a14.868 14.868 0 0 0-3.727 3.529A14.43 14.43 0 0 0 1.226 8.72 14.21 14.21 0 0 0 0 14.51"
        />
        <circle cx={6.651} cy={1.651} r={1.651} fill="#FDFCFB" />
      </g>
    </g>
  </svg>
);

export default SvgMeterThumbnail3H;
