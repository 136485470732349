import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation RemoveAllGroupProfiles($input: RemoveAlGroupProfilesInput!) {
    removeAllGroupProfiles(input: $input) {
      success
      errors
    }
  }
`;

export default function removeAllGroupProfiles({groupId}) {
  return executeMutation(mutation, { input: { groupId } });
}

