import PropTypes from 'prop-types';
import styled from 'styled-components';

const FluidContainer = styled.div`
  width: 100%;
  padding: 72px 82px 0;
`;

FluidContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FluidContainer;
