import { API, graphqlOperation } from 'aws-amplify';

function executeQuery(query, variables) {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(query, variables))
      .then(resolve)
      .catch(data => {
        if (data.errors && data.errors.length) {
          reject(data.errors[0]);
        } else {
          reject(new Error('Internal server error'));
        }
      });
  });
}

function executeMutation(mutation, variables) {
  return new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(mutation, variables))
      .then(resolve)
      .catch(data => {
        if (data.errors && data.errors.length) {
          reject(data.errors[0]);
        } else {
          reject(new Error('Internal server error'));
        }
      });
  });
}

export { executeQuery, executeMutation };
