import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { theme } from '../config';

const Wrapper = styled.div`
  background: ${theme.colors.true_white};
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  padding: 0 25px;
  ${theme.boxShadow};
  ${props => props.isOpen && 'z-index:10'}
`;

const Container = styled.div`
  padding: 20px 0;
  ${props => props.isOpen && `border-bottom: 1px solid ${theme.colors.light_grey};`};
  cursor: pointer;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: ${props => (props.isTop ? 'space-between' : 'flex-end')};
  align-items: center;
`;

const HeaderTitle = styled.div`
  ${theme.fontFamily};
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  transition: ${theme.transition};
  ${props =>
    props.isTop &&
    css`
      top: 0;
      font-size: 12px;
      color: ${theme.colors.equell_teal};
    `};
`;

const HeaderArrow = styled.i`
  font-size: 25px;
  color: ${theme.colors.dark_grey};
`;

const Content = styled.div`
  padding: 15px 25px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 10;
  background: ${theme.colors.true_white};
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  box-shadow: 0 8px 10px 0px rgba(109, 50, 50, 0.24);
`;

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      labelState: null,
    };
    this.value = props.value;
  }

  componentDidUpdate() {
    const { isOpen } = this.state;
    const { value } = this.props;
    setTimeout(() => {
      if (isOpen) {
        if (value !== this.value && value !== null) {
          this.setState({
            isOpen: false,
          });
        }
        window.addEventListener('click', this.handleClickOutside);
      } else {
        window.removeEventListener('click', this.handleClickOutside);
      }
      this.value = value;
    }, 100);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = () => {
    this.setState({
      isOpen: false,
    });
  };

  toggleOpen = () => {
    this.setState(prevState => {
      const { show } = this.props;
      if (show) {
        return {
          isOpen: !prevState.isOpen,
        };
      }
    });
  };

  stopClickChild(e) {
    e.stopPropagation();
  }

  render() {
    const { label, value, children } = this.props;
    const { isOpen } = this.state;
    return (
      <Wrapper isOpen={isOpen}>
        <Container isOpen={isOpen} onClick={this.toggleOpen}>
          <Header isTop={value}>
            <HeaderTitle isTop={value}>{label}</HeaderTitle>
            {value && value}
            {!isOpen && <HeaderArrow className="mdi mdi-chevron-down" />}
            {isOpen && <HeaderArrow className="mdi mdi-chevron-up" />}
          </Header>
        </Container>
        {isOpen && <Content onClick={this.stopClickChild}>{children}</Content>}
      </Wrapper>
    );
  }
}

DropDown.propTypes = {
  children: PropTypes.node.isRequired,
};

DropDown.defaultProps = {
  padding: '15px 25px 15px 25px',
  fontSize: 16,
  iconSize: 25,
  show: true,
};

export default React.memo(DropDown);
