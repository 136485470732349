import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from 'components/design-components/avatar/Avatar';

const Container = styled.div`
  display: inline-flex;
  padding-left: 20px;
`;

const ListOfAvatars = ({ listOfImg, limit }) => {
  function renderImages() {
    if (!listOfImg.length) {
      return <Avatar>0</Avatar>;
    }
    return listOfImg.slice(0).reduce((reducer, item, index, arr) => {
      if (index > limit) {
        arr.slice(1);
        return reducer;
      } else if (index < limit) {
        reducer.push(<Avatar key={index} url={item} />);
      } else {
        reducer.push(<Avatar key={index}>+{listOfImg.length - limit}</Avatar>);
      }
      return reducer;
    }, []);
  }
  return <Container>{renderImages()}</Container>;
};

ListOfAvatars.propTypes = {
  listOfImg: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  limit: PropTypes.number,
};

ListOfAvatars.defaultProps = {
  listOfImg: [],
  limit: 5,
};

export default React.memo(ListOfAvatars);
