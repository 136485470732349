import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      success
      errors
      team {
        id
        name
        type
        code
        slug
      }
    }
  }
`;

export default function createTeam(input) {
  return executeMutation(mutation, { input });
}
