import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media, theme } from '../config';
import classNames from 'classnames';

const ModalTitle = styled.div`
  ${theme.fontFamily};
  font-size: ${props => `${props.modalTitleFontSize}px`};
  font-weight: bold;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 37px;
  padding: 0 0 20px;
  border-bottom: 1px solid ${theme.colors.light_grey};

  ${props =>
    props.noBorder &&
    css`
      border-bottom: 0;
      margin-bottom: 26px;
    `}
  ${props =>
    props.subTitle &&
    css`
      margin-bottom: 0;
      padding: 0;
    `}
`;

const ModalSubTitle = styled.div`
  font-size: 16px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
`;

const ModalContent = styled.div`
  padding-bottom: 30px;
  text-align: center;
  font-size: 16px;
  line-height: 1.75;
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  & > * {
    flex: 1;
    ${props =>
    props.fullscreen &&
      css`
        &:not(:last-child) {
          margin-right: 100px;
        }
      `}
  }
`;

const ModalContainer = styled.div`
  width: 500px;

  padding: 30px 45px;
  border-radius: 4px;
  ${props =>
    !props.fullscreen &&
    css`
      width: 448px;
      box-shadow: 0 0 46px 0 rgba(109, 50, 50, 0.24), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: ${theme.colors.true_white};
    `};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${media.phone`width: 98%`}
`;

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  ${props => props.fullscreen && 'background-color: rgba(253, 252, 251, 0.9)'}
`;

const Modal = ({
  footer,
  title,
  subTitle,
  opened,
  onClose,
  children,
  fullscreen,
  modalTitleFontSize,
}) => {
  const [modalContainer] = useState(document.createElement('div'));
  useEffect(() => {
    if (opened) {
      document.body.appendChild(modalContainer);
      document.body.style.overflowY = 'hidden';
    } else {
      if (document.body.contains(modalContainer)) document.body.removeChild(modalContainer);
      document.body.style.overflowY = '';
    }
  }, [opened]);
  const content = (
    <ModalWrapper className={classNames({ opened })}>
      <ModalBackground fullscreen={fullscreen} onClick={onClose} />
      <ModalContainer fullscreen={fullscreen}>
        {title && (
          <ModalTitle
            modalTitleFontSize={modalTitleFontSize}
            subTitle={subTitle}
            noBorder={fullscreen}
          >
            {title}
          </ModalTitle>
        )}
        {subTitle && <ModalSubTitle noBorder={fullscreen}>{subTitle}</ModalSubTitle>}
        <ModalContent>{children}</ModalContent>
        {footer && <ModalFooter fullscreen={fullscreen}>{footer}</ModalFooter>}
      </ModalContainer>
    </ModalWrapper>
  );
  return ReactDOM.createPortal(content, modalContainer);
};

Modal.propTypes = {
  opened: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  subTitle: PropTypes.string,
  footer: PropTypes.node,
  header: PropTypes.node,
  fullscreen: PropTypes.bool,
  modalTitleFontSize: PropTypes.number,
};

Modal.defaultProps = {
  title: null,
  header: null,
  footer: null,
  fullscreen: false,
  modalTitleFontSize: 20,
};

export default React.memo(Modal);
