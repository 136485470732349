import styled, { css } from 'styled-components';
import { theme } from 'components/design-components/config';

export const Label = styled.label`
  position: absolute;
  ${theme.fontFamily};
  font-size: ${theme.fontSize.normal};
  cursor: text;
  line-height: 1.25;
  left: 13px;
  top: 24px;
  margin: 0;
  transition: ${theme.transition};
  color: ${theme.colors.grey};
`;

const InputCss = css`
  border-radius: 4px;
  font-size: ${theme.fontSize.normal};
  line-height: 2;
  box-shadow: 0 0 10px 0 ${theme.colors.warm_shadow};
  -webkit-box-shadow: 0 0 10px 0 ${theme.colors.warm_shadow};
  -webkit-appearance: none;
  background-color: ${theme.colors.true_white};
  outline: none;
  width: 100%;
  padding: 24px 20px 12px 13px;
  border: 1px solid transparent;

  :focus ~ span,
  :not(:placeholder-shown) ~ span {
    transform: translateY(0);
    top: 10px;
    font-size: ${theme.fontSize.xsmallNormal};
  }

  &[type='password'],
  &.with-icon {
    padding-right: 60px;
  }
  &[type='password'] {
    color: ${theme.colors.grey};
  }
`;

export const InputField = styled.input`
  ${InputCss};
  :focus ~ label,
  :not(:placeholder-shown) ~ label {
    transform: translateY(0);
    top: 10px;
    font-size: ${theme.fontSize.xsmallNormal};
    color: ${props => (!props.error ? theme.colors.equell_teal : theme.colors.error_red)};
  }
  ${props =>
    props.error &&
    css`
      box-shadow: 0 0 45px 0 ${theme.colors.warm_shadow};
      border-color: ${theme.colors.error_red};
    `}
`;

export const TextareaField = styled.textarea`
  ${InputCss};
  :focus ~ label,
  :not(:placeholder-shown) ~ label {
    transform: translateY(0);
    top: 10px;
    font-size: ${theme.fontSize.xsmallNormal};
    color: ${props => (!props.error ? theme.colors.equell_teal : theme.colors.error_red)};
  }
`;

export const ToggleIcons = styled.span`
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 3%;
  transform: translate(-50%, -50%);
`;
