import React, { Component } from 'react';
import ClaimedRewards from 'pages/admin-dashboard/ClaimedRewards.jsx';
import getClaimedRewards from 'services/graphQL/queries/admin/reward/claimedRewards.js';
import lodash from 'lodash';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import moment from 'moment';
import DelivaryStatusEnum from 'enums/DeliveryStatusEnum.js';

export default class ClaimedRewardsWithData extends Component {
  state = {
    rewardsData: {},
    isLoading: true,
    fetchError: '',
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchData() {
    this.setState({
      isLoading: true,
    });

    getClaimedRewards()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const team = lodash.get(data, 'data.me.teams.0');

        this.setState({
          rewardsData: {
            claimedRewards: team.rewardRedemptions,
          },
          isLoading: false,
        });
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          fetchError: error.message,
          isLoading: false,
        });
      });
  }

  getRecordUserName(record) {
    return `${lodash.get(record, 'profile.givenName')} ${lodash.get(record, 'profile.familyName')}`;
  }

  render() {
    const { rewardsData, isLoading, fetchError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (fetchError) {
      return <div>{fetchError}</div>;
    }

    const claimedRewards = rewardsData.claimedRewards || [];

    const rewardsProps = {
      rewards: claimedRewards.map(record => ({
        userName: this.getRecordUserName(record),
        date: moment(record.created * 1000).toDate(),
        title: lodash.get(record, 'reward.name'),
        points: record.pointsUsed,
        authorize: DelivaryStatusEnum[record.status] === DelivaryStatusEnum.AUTHORIZED,
        status: DelivaryStatusEnum[record.status],
      })),
    };

    return <ClaimedRewards {...rewardsProps} />;
  }
}
