import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from './DatePicker';
import DropDown from '../form/DropDown';
import ErrorField from '../form/ErrorField';
import { Field } from 'react-final-form';
import moment from 'moment';
import FormGroup from '../form/FormGroup';

const TIME_FORMAT = 'MMM D, YYYY';

class DatePickerInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateString: null,
    };
  }
  formatDateToString(date) {
    if (!date) return;

    const { from, to } = date || {};
    if (from && to) {
      const fromDate = moment(fromDate).format(TIME_FORMAT);
      const toDate = moment(toDate).format(TIME_FORMAT);
      return `${fromDate} - ${toDate}`;
    } else {
      return moment(date).format(TIME_FORMAT);
    }
  }

  render() {
    const { name, label, ...props } = this.props;
    return (
      <Field name={name}>
        {({ input, meta }) => (
          <FormGroup>
            <DropDown label={label} value={this.formatDateToString(input.value)}>
              <DatePicker
                {...props}
                singleDay={day => {
                  input.onChange({ target: { value: day } });
                }}
                day={input.value === '' ? null : input.value}
              />
            </DropDown>
            {meta.error && <ErrorField>{meta.error}</ErrorField>}
          </FormGroup>
        )}
      </Field>
    );
  }
}

DatePickerInput.propTypes = {
  name: PropTypes.string.isRequired,
};
DatePickerInput.defaultProps = {
  label: '',
};
export default React.memo(DatePickerInput);
