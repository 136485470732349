import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Image = styled.img`
  width: 100%;
  height: auto;
  ${props =>
    !props.isAvatar &&
    css`
      object-fit: fill;
      margin: 4px;
      border-radius: 8px;
    `};

  ${props => props.withShadow && 'box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);'}
`;

Image.propTypes = {
  withRatio: PropTypes.bool,
  withShadow: PropTypes.bool,
  isAvatar: PropTypes.bool,
};
Image.defaultProps = {
  withRation: false,
  withShadow: false,
};

export default Image;
