import React from 'react';
import PropTypes from 'prop-types';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import LocationSearchInputToggle from 'components/design-components/form/LocationSearch/LocationSearchInputToggle';
import ResultItem from 'components/design-components/form/LocationSearch/ResultItem';

const MY_API_KEY = 'AIzaSyAHiioaKlbiufdQnmjVgepOxIJ4KNo0NFw';

class LocationSearchInput extends React.Component {
  state = {
    search: '',
    value: '',
  };

  handleInputChange = e => {
    this.setState({ search: e.target.value, value: e.target.value });
  };

  handleSelectSuggest = geocodedPrediction => {
    this.setState({ search: '', value: geocodedPrediction.formatted_address }, () => {
      const { setLocation } = this.props;
      setLocation(geocodedPrediction.place_id);
    });
  };

  customRender(prediction) {
    if (prediction) {
      const label = prediction.description || 'No results found';
      return <ResultItem label={label} />;
    } else {
      return <ResultItem label="No results found" />;
    }
  }

  mergeInputChange = () => {
    const { onChange } = this.props;
    if (onChange) {
      const mergedOnChange = e => {
        this.handleInputChange(e);
        onChange(e);
      };

      return { ...this.props, onChange: mergedOnChange };
    }
    return { ...this.props, onChange: this.handleInputChange };
  };

  render() {
    const { search, value } = this.state;
    const { error, name, value: omitedValue, mobileStyles, ...rest } = this.mergeInputChange();
    return (
      <ReactGoogleMapLoader
        params={{
          key: MY_API_KEY,
          libraries: 'places,geocode',
        }}
        render={googleMaps =>
          googleMaps && (
            <ReactGooglePlacesSuggest
              googleMaps={googleMaps}
              autocompletionRequest={{
                input: search,
                types: ['(cities)'],
                // componentRestrictions: { country: 'us' },
                // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
              }}
              onSelectSuggest={this.handleSelectSuggest}
              customRender={this.customRender}
            >
              <LocationSearchInputToggle mobileStyles={mobileStyles} name={name} value={value} {...rest} error={error} />
            </ReactGooglePlacesSuggest>
          )
        }
      />
    );
  }
}

LocationSearchInput.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  name: PropTypes.string,
};

export default LocationSearchInput;
