import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RewardCard from './RewardCard';
import RewardInformation from './RewardInformation';

const Container = styled.div`
  padding-top: 58px;
  display: flex;
`;

const RewardContainer = ({
  imgSrc,
  maxProgress,
  points,
  redeemPoints,
  title,
  valueProgress,
  onRedeemReward,
  rewardId,
  description,
  condition,
  footer,
  isLoading,
  cropImage
}) => {
  return (
    <Container>
      <RewardCard
        rewardId={rewardId}
        title={title}
        imgSrc={imgSrc}
        points={points}
        maxProgress={maxProgress}
        redeemPoints={redeemPoints}
        valueProgress={valueProgress}
        onRedeemReward={onRedeemReward}
        isLoading={isLoading}
        cropImage={cropImage}
      />
      <RewardInformation
        footer={footer}
        title={title}
        description={description}
        condition={condition}
      />
    </Container>
  );
};

RewardContainer.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  points: PropTypes.number,
  redeemPoints: PropTypes.number,
  maxProgress: PropTypes.number.isRequired,
  valueProgress: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  onRedeemReward: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

RewardContainer.defaultProps = {
  isLoading: false,
};

export default React.memo(RewardContainer);
