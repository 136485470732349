import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import {
  Button,
  Col,
  ErrorText,
  Form as FormElement,
  Input,
  Link,
  Row,
} from 'components/design-components';

const AddMembersForm = ({
  onValidate,
  onSave,
  errorText,
  disabledSubmit,
  cancelLink,
  isLoading,
}) => {
  return (
    <Form
      onSubmit={onSave}
      validate={onValidate}
      render={({ handleSubmit, pristine }) => (
        <FormElement onSubmit={handleSubmit}>
          <Input
            isTextarea
            rows={5}
            name="members"
            label="Enter multiple email addresses"
            helperText="Please seperate multiple address with commas."
          />
          <Row>
            <Col width={2} verticalCenter horizontalCenter>
              <ErrorText>{errorText}</ErrorText>
            </Col>
            <Col verticalCenter horizontalCenter>
              <Link to={cancelLink} underlined>
                Cancel
              </Link>
            </Col>
            <Col>
              <Button loading={isLoading} disabled={pristine || disabledSubmit} isSubmit fullwidth>
                Send Email Invitation
              </Button>
            </Col>
          </Row>
        </FormElement>
      )}
    />
  );
};

AddMembersForm.propTypes = {
  onValidate: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  cancelLink: PropTypes.string.isRequired,
  errorText: PropTypes.string,
};

AddMembersForm.defaultProps = {
  disabledSubmit: false,
  isLoading: false,
  errorText: '',
};

export default AddMembersForm;
