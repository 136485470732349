import React, { Component, Fragment } from 'react';
import getDashBoardInfo from 'services/graphQL/queries/user/dashBoardInfo.js';
import lodash from 'lodash';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import ActivityWithData from './components/ActivityWithData.jsx';
import DashboardHeaderWithData from './components/DashboardHeaderWithData.jsx';
import { errorMessage } from 'components/design-components/toast/ToastrMsg';
import { splitTimesToNonDays } from 'utils/time';
import moment from 'moment';
import { redirectToErrorPage } from 'componentsWithData/router/routeService';

export default class ActivityDashBoardWithData extends Component {
  state = {
    isLoading: true,
    dashBoardData: {},
    fetchError: '',
    redeemError: '',
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchDashBoardData();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchDashBoardData = loadingSetState => {
    getDashBoardInfo()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const dashBoardData = lodash.get(data, 'data.me');

        this.setState({
          dashBoardData,
          isLoading: false,
        });

        if (loadingSetState) {
          loadingSetState(false);
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }
        errorMessage('Error fetching data from the server');

        this.setState(
          {
            fetchError: error.message,
            isLoading: false,
          },
          () => {
            const { history, location } = this.props;
            redirectToErrorPage(history, location.pathname);
          }
        );
        if (loadingSetState) {
          loadingSetState(false);
        }
      });
  };

  render() {
    const { isLoading, dashBoardData } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const sessionHistoryFromQuery = lodash.get(dashBoardData, 'sessionHistory') || [];

    const modifiedSession = sessionHistoryFromQuery.map(session => ({
      start: moment(session.started * 1000).toDate(),
      end: moment(session.ended * 1000).toDate(),
      points: session.pointsEarned,
    }));

    const sessionHistory = splitTimesToNonDays(modifiedSession);

    return (
      <Fragment>
        <DashboardHeaderWithData dashBoardData={dashBoardData} sessionHistory={sessionHistory} />
        <ActivityWithData dashBoardData={dashBoardData} sessionHistory={sessionHistory} />
      </Fragment>
    );
  }
}
