import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'components/design-components/config.js';

const TooltipFooter = styled.span`
  font-size: ${theme.fontSize.xsmallNormal};
  letter-spacing: 2px;
`;

const TooltipWrapper = styled.div`
  z-index: 15;
  position: absolute;
  top: 0;
  box-shadow: 0 2px 46px -10px ${theme.colors.warm_shadow};
  border-radius: 8px;
  background-color: ${theme.colors.true_white};
  padding: 25px 35px;
  text-align: center;
  font-size: ${theme.fontSize.normal};
  &:after {
    content: '';
    position: absolute;
    left: ${props => !props.mobileStyles && '50%'};
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: ${theme.colors.true_white} transparent transparent transparent;
    top: ${props => props.mobileStyles ? '50%' : '100%'};
    right: ${props => props.mobileStyles && '-20px'};
    transform: ${props => props.mobileStyles  && 'translateY(-50%) rotate(-90deg)'};
  }
`;

const TooltipContainer = ({
  opened,
  containerElementRef,
  text,
  footer,
  tooltipStyle,
  centered,
  mobileStyles
}) => {
  let tooltipRef = useRef(null);
  useEffect(
    () => {
      const tooltipElement = tooltipRef.current;
      const containerElement = containerElementRef.current;
      if (opened) {
        const {
          top,
          left,
          width: containerWidth,
          height: containerHeight,
        } = containerElement.getBoundingClientRect();
        const { width, height } = tooltipElement.getBoundingClientRect();
        // 5 px to compensate for the arrow
        let topPosition = top - height - 5 + window.scrollY;
        const leftPosition = left + (containerWidth / 2 - width / 2);

        if (centered) {
          topPosition += Math.floor(containerHeight / 2);
        }
        // set to dom
        tooltipElement.style.top = topPosition + 'px';
        tooltipElement.style.left = leftPosition + 'px';
        if(mobileStyles) {
          tooltipElement.style.left = leftPosition - mobileStyles.left + 'px';
          tooltipElement.style.top = topPosition + mobileStyles.top + 'px';
        }
      }
    },
    [opened]
  );

  return (
    <TooltipWrapper style={tooltipStyle} ref={tooltipRef} mobileStyles={mobileStyles}>
      <div>{text}</div>
      {footer && <TooltipFooter>{footer}</TooltipFooter>}
    </TooltipWrapper>
  );
};

TooltipContainer.propTypes = {
  text: PropTypes.node.isRequired,
  containerElementRef: PropTypes.object,
  footer: PropTypes.node,
  tooltipStyle: PropTypes.object,
  opened: PropTypes.bool,
  centered: PropTypes.bool,
};

TooltipContainer.defaultProps = {
  opened: false,
  centered: false,
  text: '',
};

export default TooltipContainer;
