import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, theme } from 'components/design-components/config';
import { NavLink } from 'react-router-dom';

const NavItemContainer = styled.div`
  margin: 0 1px;
  height: 100%;
  ${props => props.spread && 'flex: 1'}
  ${media.phone`
    width: 100%;
  `};
`;

const CustomNavLink = styled(NavLink)`
  display: block;
  height: 100%;
  min-height: 40px;
  padding: 0 20px;
  transition: color 0.2s linear;
  text-decoration: none;
  color: ${theme.colors.dark_grey};
  &:hover {
    color: ${theme.colors.equell_teal};
    text-decoration: none;
  }
  &.active {
    color: ${theme.colors.equell_teal};
    border-bottom: 2px solid ${theme.colors.equell_teal};
  }
  ${media.phone`
     padding: 0 6px; 
     min-height: 32px;
     text-align: center;
     font-size: 14px;
     width: 100%;
     white-space: nowrap;
  `}
`;

const HeaderNav = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.dark_grey};
  font-size: 16px;
  ${media.phone`
    width: 100%;
    overflow-x: auto;
    padding-top: 15px;
    margin-top: -15px;
 `}
`;

const HeaderNavigation = ({ navItems, navItemsSpread }) => {
  if (!navItems.length) return null;
  return (
    <HeaderNav>
      {navItems.map(({ label, to }, index) => (
        <NavItemContainer spread={navItemsSpread} key={index}>
          <CustomNavLink to={to}>{label}</CustomNavLink>
        </NavItemContainer>
      ))}
    </HeaderNav>
  );
};

HeaderNavigation.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  navItemsSpread: PropTypes.bool,
};

HeaderNavigation.defaultProps = {
  navItems: [],
  navItemsSpread: false,
};

export default HeaderNavigation;
