import React from 'react';
import PropTypes from 'prop-types';
import { ClassicModal, ErrorText, PasswordInput } from 'components/design-components';
import { Form } from 'react-final-form';

const ChangePasswordModal = ({
  loading,
  opened,
  handleClose,
  onSave,
  onValidate,
  oldPasswordVisible,
  newPasswordVisible,
  onOldPasswordVisibleChange,
  onNewPasswordVisibleChange,
  errorText,
  passwordInstructions,
}) => {
  let formSubmit = null;

  function handleFormSubmit(fields) {
    onSave(fields);
  }
  function handleSave() {
    formSubmit();
  }
  return (
    <ClassicModal
      fullscreen
      title="Change Password?"
      subTitle="Please enter your old and new password"
      cancelText="Cancel"
      approveText="Update"
      loading={loading}
      opened={opened}
      onClose={handleClose}
      onApprove={handleSave}
      modalTitleFontSize={28}
    >
      <Form
        onSubmit={handleFormSubmit}
        validate={onValidate}
        render={({ handleSubmit }) => {
          formSubmit = handleSubmit;
          return (
            <form onSubmit={handleSubmit}>
              <PasswordInput
                name="oldPassword"
                label="Old password"
                passwordInstructions={[]}
                passwordVisible={oldPasswordVisible}
                onPasswordVisibleChange={onOldPasswordVisibleChange}
                error={!!errorText}
              />
              <PasswordInput
                name="newPassword"
                label="New password"
                passwordInstructions={passwordInstructions}
                passwordVisible={newPasswordVisible}
                onPasswordVisibleChange={onNewPasswordVisibleChange}
                error={!!errorText}
                alternateLabel="Password (8 or more characters)"
              />
            </form>
          );
        }}
      />
      <ErrorText>{errorText}</ErrorText>
    </ClassicModal>
  );
};

ChangePasswordModal.propTypes = {
  loading: PropTypes.bool,
  opened: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  oldPasswordVisible: PropTypes.bool,
  newPasswordVisible: PropTypes.bool,
  onOldPasswordVisibleChange: PropTypes.func.isRequired,
  onNewPasswordVisibleChange: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  passwordInstructions: PropTypes.arrayOf(PropTypes.string),
};

ChangePasswordModal.defaultProps = {
  loading: false,
  opened: false,
  passwordVisible: false,
  errorText: '',
  passwordInstructions: [],
};

export default React.memo(ChangePasswordModal);
