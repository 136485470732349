import React, { Component } from 'react';
import Devices from 'pages/my-account/Devices.jsx';
import getDeviceData from 'services/graphQL/queries/devicesData.js';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import moment from 'moment';
import lodash from 'lodash';
import defaultDevices from './enum/defaultDevices';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { replaceRouteParams } from 'componentsWithData/router/routeService.js';
import parameterEnum from 'componentsWithData/router/parameterEnums.js';
import { errorMessage } from 'components/design-components/toast/ToastrMsg';
import { redirectToErrorPage } from 'componentsWithData/router/routeService';

const typeDownloadProps = {
  DESKTOP: {
    getApp: {
      label: 'Get Chrome Extension',
      link: replaceRouteParams(routeEnums.DOWNLOAD_AND_INSTALL, {
        type: parameterEnum.downloadType.CHROME_EXTENSION,
      }),
    },
    desktopLink: replaceRouteParams(routeEnums.DOWNLOAD_AND_INSTALL, {
      type: parameterEnum.downloadType.DESKTOP,
    }),
  },
  MOBILE: {
    getApp: {
      label: 'Get Mobile app',
      link: replaceRouteParams(routeEnums.DOWNLOAD_AND_INSTALL, {
        type: parameterEnum.downloadType.MOBILE,
      }),
    },
  },
  TABLET: {
    getApp: {
      label: 'Get Mobile app',
      link: replaceRouteParams(routeEnums.DOWNLOAD_AND_INSTALL, {
        type: parameterEnum.downloadType.MOBILE,
      }),
    },
  },
};

export default class EditDeviceWithData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      deviceData: {},
    };
  }

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchData() {
    this.setState({
      isLoading: true,
    });

    getDeviceData()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const devices = lodash.get(data, 'data.me.devices');
        const completeDevices = this.addMissingDevices(devices || []);

        this.setState({
          isLoading: false,
          deviceData: { devices: completeDevices },
        });
      })
      .catch(err => {
        if (!this.__isComponentMounted) {
          return;
        }

        errorMessage(err.message || 'Error fetching data from the server');

        const { history, location } = this.props;
        redirectToErrorPage(history, location.pathname);
      });
  }

  getDownloadProps(type) {
    return typeDownloadProps[type];
  }

  formatDate(date) {
    const momentObject = moment(date);
    const formatedDate = momentObject.format('D MMMM YYYY');
    const formatedTime = momentObject.format('HH:mma');

    return `${formatedDate} at ${formatedTime}`;
  }

  addMissingDevices(devices) {
    defaultDevices.forEach(device => {
      const type = device.type;

      const existingDevice = devices.find(dev => dev.type === type);

      if (!existingDevice) {
        devices.push(device);
      }
    });

    return devices;
  }

  render() {
    const { isLoading, deviceData } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const currentDevices = deviceData.devices;

    const devicesProps = {
      devices: currentDevices.map(device => {
        //TODO check again the query to see if has changed
        var lastUpdated = new Date(0); // The 0 there is the key, which sets the date to the epoch
        const lastAccessed = lodash.get(device, 'lastAccessed');
        if(lastAccessed){
          lastUpdated.setUTCSeconds(lastAccessed);
          lastUpdated = this.formatDate(lastUpdated);
        }else
          lastUpdated = 'No recorded use of equell';

        return {
          name: device.name,
          type: device.type,
          lastUpdated
        };
      }),
      title: 'Devices',
      subTitle: [
        {
          id: 'sub1',
          text:
            'Devices that have enabled equell on your account in the last 28 days, or are currently signed in.',
        },
      ],
    };

    return <Devices {...devicesProps} />;
  }
}
