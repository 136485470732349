import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import LocationSearchInput from 'components/design-components/form/LocationSearch/LocationSearchInput';

const LocationSearchInputField = ({ handleChange, mobileStyles, ...rest }) => {
  return (
    <Field {...rest}>
      {({ input, meta }) => (
        <LocationSearchInput {...input} error={!!meta.error} setLocation={handleChange} mobileStyles={mobileStyles} />
      )}
    </Field>
  );
};

LocationSearchInputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default React.memo(LocationSearchInputField);
