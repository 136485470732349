import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BodyContainer } from 'components/design-components';
import { theme } from 'components/design-components/config';
import EditProfileTeams from './components/EditProfileTeams';
import ChangePasswordModal from './components/ChangePasswordModal';
import SocialShare from 'components/design-components/social-share/SocialShare';
import EditProfileMain from './components/EditProfileMain';

const BodyContainerWithTopLine = styled.div`
  border-top: 1px solid ${theme.colors.switch_off};
`;

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.file = null;
  }

  state = {
    oldPasswordVisible: false,
    newPasswordVisible: false,
    activeUpload: false,
  };

  componentDidMount() {
    const { imgSrc } = this.props;
    this.file = imgSrc;
  }

  onOldPasswordVisibleChange = () => {
    this.setState(prevState => ({
      oldPasswordVisible: !prevState.oldPasswordVisible,
    }));
  };
  onNewPasswordVisibleChange = () => {
    this.setState(prevState => ({
      newPasswordVisible: !prevState.newPasswordVisible,
    }));
  };

  uploadedFile = file => {
    const { onFileSelect } = this.props;

    if (onFileSelect) {
      onFileSelect(file);
    }

    this.setState({ activeUpload: true });
  };

  editProfileSubmit = (data, events) => {
    const { reset } = events;
    const { onUpdate } = this.props;
    const newData = Object.assign({}, data, { imgSrc: this.file });
    onUpdate(newData, { reset });
  };

  render() {
    const {
      title,
      fields,
      onValidate, 
      changePasswordLink,
      userEmail,
      imgSrc,
      disableSubmit,
      itemList,
      profileLoading,
      passwordLoading,
      onValidatePassword,
      passwordUpdateError,
      onUpdatePassword,
      opened,
      handleModalClose,
      handleModalOpen,
      fileSizeLimit,
      fileSizeError,
      isImageLoading,
    } = this.props;
    const { oldPasswordVisible, newPasswordVisible, activeUpload } = this.state;
    return (
      <Fragment>
        <BodyContainer title={title}>
          <EditProfileMain
            fields={fields}
            onValidate={onValidate}
            changePasswordLink={changePasswordLink}
            userEmail={userEmail}
            imgSrc={imgSrc}
            disableSubmit={disableSubmit}
            profileLoading={profileLoading}
            handleModalOpen={handleModalOpen}
            fileSizeLimit={fileSizeLimit}
            fileSizeError={fileSizeError}
            isImageLoading={isImageLoading}
            activeUpload={activeUpload}
            editProfileSubmit={this.editProfileSubmit}
            uploadedFile={this.uploadedFile}
          />
        </BodyContainer>
        <BodyContainer
          title="Share equell"
          noPadding
          customWrapper={e => <BodyContainerWithTopLine>{e}</BodyContainerWithTopLine>}
        >
          <SocialShare fields={fields} />
        </BodyContainer>
        {itemList.length > 0 && (
          <BodyContainer
            title="Teams"
            noPadding
            customWrapper={e => (
              <BodyContainerWithTopLine style={{ marginTop: '50px' }}>{e}</BodyContainerWithTopLine>
            )}
          >
            <EditProfileTeams itemList={itemList} />
          </BodyContainer>
        )}
        <ChangePasswordModal
          loading={passwordLoading}
          opened={opened}
          handleClose={handleModalClose}
          onSave={onUpdatePassword}
          onValidate={onValidatePassword}
          onOldPasswordVisibleChange={this.onOldPasswordVisibleChange}
          onNewPasswordVisibleChange={this.onNewPasswordVisibleChange}
          oldPasswordVisible={oldPasswordVisible}
          newPasswordVisible={newPasswordVisible}
          errorText={passwordUpdateError}
        />
      </Fragment>
    );
  }
}

EditProfile.propTypes = {
  title: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  changePasswordLink: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  isImageLoading: PropTypes.bool,
  onFileSelect: PropTypes.func,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      members: PropTypes.number.isRequired,
      joined: PropTypes.string.isRequired,
    })
  ),
  fields: PropTypes.object,
  disableSubmit: PropTypes.bool,
  opened: PropTypes.bool,
  loading: PropTypes.bool,
  onValidatePassword: PropTypes.func,
  onUpdatePassword: PropTypes.func.isRequired,
  passwordUpdateError: PropTypes.string,
  accept: PropTypes.string,
  passwordInstructions: PropTypes.arrayOf(PropTypes.string),
};

EditProfile.defaultProps = {
  imgSrc: '',
  itemList: [],
  loading: false,
  opened: false,
  disableSubmit: false,
  passwordUpdateError: '',
  accept: ' .jpg, .jpeg, .png',
  passwordInstructions: [],
};

export default React.memo(EditProfile);
