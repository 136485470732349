import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query Group($groupId: ID!) {
    group(groupId: $groupId) {
      id
      name
      s3Key
      groupProfiles {
        isAdmin
        givenName
        familyName
        profile {
          id
          email
          givenName
          familyName
          s3Key
          activity {
            availablePoints
          }
        }
      }
      groupInvites {
        id
        email
        accepted
        invitingProfile {
          givenName
          familyName
          s3Key
          activity {
            availablePoints
          }
        }
      }
    }
  }
`;

export default function getSingleGroup(groupId) {
  return executeQuery(query, { groupId });
}