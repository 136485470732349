import React from 'react';
import PropTypes from 'prop-types';
import { Align, HelperText, Link } from 'components/design-components';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';

const StyledHelperText = styled(HelperText)`
  font-weight: normal;
  color: ${theme.colors.grey};
`;

const Links = ({ termsLink, privacyLink }) => (
  <Align left style={{ marginTop: 26 }}>
    <StyledHelperText>
      By signing up, I agree to equell’s{' '}
      <Link highlighted to={termsLink} fontSize={14} underlined>
        terms of service
      </Link>{' '}
      and{' '}
      <Link highlighted to={privacyLink} fontSize={14} underlined>
        privacy policy
      </Link>
      .
    </StyledHelperText>
  </Align>
);
Links.propTypes = {
  termsLink: PropTypes.string.isRequired,
  privacyLink: PropTypes.string.isRequired,
};

export default Links;
