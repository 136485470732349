import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'components/design-components/config';
import {
  BodyContainer,
  DefaultTable,
  H5,
  Image,
  P,
  Table,
  TableLink,
} from 'components/design-components';

const TitleH5Mobile = styled(H5)`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;

const TitleH5Desktop = styled(H5)`
  display: block;

  ${media.tablet`
    display: none;
  `}
`;

const styles = {
  img: {
    width: 180,
  },
};

const RewardContent = ({ rewardTitle, rewardDescription }) => {
  function getMarkup(arg) {
    return { __html: arg };
  }

  function truncate(content, limit, after) {
    // Make sure an element and number of items to truncate is provided
    if (!content || !limit) return;

    let editedContent = content.trim();

    // Convert the content into an array of words
    // Remove any words above the limit
    editedContent = editedContent.split(' ').slice(0, limit);

    // Convert the array of words back into a string
    // If there's content to add after it, add it
    editedContent = editedContent.join(' ');
    if(content != editedContent)
      editedContent += (after ? after : '');

    return editedContent;
  }

  return (
    <Fragment>
      <TitleH5Desktop>{rewardTitle}</TitleH5Desktop>
      <P key={'124'} dangerouslySetInnerHTML={getMarkup(truncate(rewardDescription, 30, '...'))} />
    </Fragment>
  );
};

const tableRowLink = e => {
  function resendEmail() {
    e.resendEmail(e);
  }
  return <TableLink onClick={resendEmail}>Resend Email</TableLink>;
};

const listOfColumns = ['Reward', '', 'Date', 'Points', 'Status'];
const listOfInfo = [];

const RewardsHistoryTable = ({ rewards, title, subTitle }) => {
  const validTable = rewards.length > 0;
  return (
    <BodyContainer title={title} subTitle={subTitle}>
      {validTable && (
        <Table
          columns={listOfColumns}
          data={rewards}
          columnsProportions={[20, 20, 20]}
          renderRow={e => [
            <Fragment>
              <TitleH5Mobile>{e.rewardTitle}</TitleH5Mobile>
              <Image withShadow style={styles.img} src={e.imageUrl} />
            </Fragment>,
            <RewardContent rewardTitle={e.rewardTitle} rewardDescription={e.rewardDescription} />,
            e.date,
            e.points.toLocaleString(),
            e.status
          ]}
        />
      )}
      {!validTable && <DefaultTable listOfInfo={listOfInfo} listOfColumns={listOfColumns} />}
    </BodyContainer>
  );
};

RewardsHistoryTable.propTypes = {
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      rewardTitle: PropTypes.string.isRequired,
      rewardDescription: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      resendEmail: PropTypes.func.isRequired,
    })
  ),
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};

RewardsHistoryTable.defaultProps = {
  rewards: [],
};

export default React.memo(RewardsHistoryTable);
