import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/design-components/typography/Link';
import { media } from 'components/design-components/config';
import EquellLogo from 'public/logo/equell-logo.svg';
import SimpleLogo from 'public/logo/simple_logo.svg';
import { Image, useWindowSize } from 'components';

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  ${media.tablet`
    margin-left: 10px;
    ${props => !props.isAuth && `
      display: none;
    `}
  `}
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

const NavLogo = ({ to, isAuth }) => {
  const { outerWidth } = useWindowSize();
  const src = outerWidth > 992 ? EquellLogo : SimpleLogo;
  return (
    <LogoContainer isAuth={isAuth}>
      <LogoLink to={to}>{<Image src={src} alt="Logo" />}</LogoLink>
    </LogoContainer>
  );
};

NavLogo.propTypes = {
  to: PropTypes.string.isRequired,
};

export default NavLogo;
