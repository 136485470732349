import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H4, P } from 'components/design-components';

const Wrapper = styled.div`
  min-width: 231px;
  /* min-width: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:not(:last-of-type) {
    padding-right: 80px;
  }
`;

const FooterContainer = styled.div`
  margin-top: auto;
`;
const Description = styled(P)`
  text-align: center;
`;
const Header = styled(H4)`
  margin-top: 5px;
  text-align: center;
`;

const ItemContainer = ({ header, title, description, footer, onClickAward, ...props }) => {
  function onClick(e) {
    e.preventDefault();
    onClickAward(title);
  }
  return (
    <Wrapper {...props} onClick={onClick}>
      {header}
      {title && <Header>{title}</Header>}
      {description && <Description>{description}</Description>}
      <FooterContainer>{footer}</FooterContainer>
    </Wrapper>
  );
};

ItemContainer.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  footer: PropTypes.node,
};

ItemContainer.defaultProps = {
  header: null,
  title: '',
  description: '',
  footer: null,
};

export default ItemContainer;
