import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation CreateGroupInvites($input: CreateGroupInvitesInput!) {
    createGroupInvites(input: $input) {
      success
      errors
      groupInvites {
        email
      }
    }
  }
`;

export default function createGroupInvites({ groupId, emails }) {
  return executeMutation(mutation, { input: { groupId, emails } });
}
