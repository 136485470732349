import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'components/design-components';
import { theme } from 'components/design-components/config';
import { Info } from 'components/design-components/icons';

const Container = styled.div`
  height: 100%;
  display: block;
`;

const Text = styled.div`
  font-size: ${theme.fontSize.smallNormal};
`;

const StyledInfo = styled(Info)`
  font-size: 20px;
`;

const InfoTooltip = ({mobileStyles}) => { 
  return (
    <Tooltip
      text={
        <Text>
          Entering your city and country helps us to offer rewards relevant to where you live or
          enable certain equell services
        </Text>
      }
      containerStyle={{ height: '100%' }}
      tooltipStyle={{ width: '212px', padding: '15px' }}
      mobileStyles={mobileStyles}
    >
      <Container>
        <StyledInfo />
      </Container>
    </Tooltip>
  );
};

export default React.memo(InfoTooltip);
