import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation CreateTeamInvites($input: CreateTeamInvitesInput!) {
    createTeamInvites(input: $input) {
      success
      errors
      teamInvites {
        email
        sent
        read
      }
    }
  }
`;

export default function addTeamProfile(input) {
  return executeMutation(mutation, { input });
}
