import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query SingleReward($rewardId: ID!) {
    me {
      id
      activity {
        availablePoints
      }
    }
    reward(rewardId: $rewardId) {
      id
      name
      description
      points
      startDate
      endDate
      isArchived
      s3Key
      terms
      disclaimer
    }
  }
`;

export default function getSingleReward(rewardId) {
  return executeQuery(query, { rewardId });
}
