import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { theme } from 'components/design-components/config';

const markGlobal = css`
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
`;

const radioMark = css`
  ${markGlobal};
  width: ${props => `${props.sizeCheckMark - 10}px`};
  height: ${props => `${props.sizeCheckMark - 10}px`};
  border-radius: 50%;
  margin: ${props =>
    `-${(props.sizeCheckMark - 10) / 2}px 0 0 -${(props.sizeCheckMark - 10) / 2}px`};
  background: ${theme.colors.equell_teal};
`;

const checkMark = css`
  ${markGlobal};
  height: ${props => `${props.heightCheckMark}px`};
  width: ${props => `${props.widthCheckMark}px`};
  border-left: 2px solid;
  border-bottom: 2px solid;
  margin: ${props => `-${props.topCheckMark}px 0 0 -${props.leftCheckMark}px`};
  transform: rotate(-45deg);
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 6px;
  height: ${props => `${props.sizeCheckMark}px`};
  width: ${props => `${props.sizeCheckMark}px`};
  border-radius: ${props => (props.type === 'checkbox' ? `${props.borderRadius}px` : '50%')};
  border: ${props => `${props.borderSize}px `}solid
    ${props => (!props.disabled ? theme.colors.grey : theme.colors.light_grey)};
  color: ${theme.colors.true_white};
  background: ${theme.colors.true_white};

  &:after {
    ${props => (props.type === 'checkbox' ? checkMark : radioMark)}
  }
  &:hover {
    ${props => !props.disabled && theme.boxShadow};
  }
`;

const InputRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  display: none;
  &:checked ~ ${Checkmark}:after {
    display: block;
  }
  &:checked ~ ${Checkmark}:hover {
    box-shadow: none;
  }
  &:checked ~ ${Checkmark} {
    background-color: ${theme.colors.true_white};
    border: 2px solid ${theme.colors.equell_teal};
  }
`;

const InputCheck = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  display: none;
  &:checked ~ ${Checkmark}:after {
    display: inline-block;
  }
  &:checked ~ ${Checkmark}:hover {
    box-shadow: none;
  }
  &:checked ~ ${Checkmark} {
    background-color: ${theme.colors.equell_teal};
    border: 2px solid ${theme.colors.equell_teal};
  }
`;

const Container = styled.label`
  height: ${props => (props.heightCustom ? `${props.heightCustom}px` : 'fit-content')};
  width: 100%;
  position: relative;
  padding-left: ${props => `${props.sizeCustom + 13}px`};
  cursor: ${props => (!props.disabled ? 'pointer' : '')};
  margin: 0;
  font-size: ${props => `${props.fontSizeText}px`};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${props => (!props.disabled ? theme.colors.grey : theme.colors.light_grey)};
  display: flex;
  align-items: center;
`;

class MarkerButton extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      containerSize: null,
    };
  }

  componentDidMount() {
    if (this.containerRef) {
      const customHeight = this.containerRef.current.offsetHeight;
      const { widthMarker } = this.props;
      if (customHeight < this.props.widthMarker) {
        this.setState({
          containerSize: widthMarker,
        });
      }
    }
  }

  render() {
    const {
      widthMarker,
      label,
      fontSize,
      type,
      heightCheckMark,
      widthCheckMark,
      leftCheckMark,
      topCheckMark,
      disabled,
      borderRadius,
      borderSize,
      ...rest
    } = this.props;
    const { containerSize } = this.state;
    const Input = type === 'checkbox' ? InputCheck : InputRadio;
    return (
      <Container
        heightCustom={containerSize}
        sizeCustom={widthMarker}
        fontSizeText={fontSize}
        disabled={disabled}
        ref={this.containerRef}
      >
        <Input {...rest} disabled={disabled} />
        <Checkmark
          borderRadius={borderRadius}
          sizeCheckMark={widthMarker}
          type={type}
          heightCheckMark={heightCheckMark}
          widthCheckMark={widthCheckMark}
          leftCheckMark={leftCheckMark}
          topCheckMark={topCheckMark}
          disabled={disabled}
          borderSize={borderSize}
        />
        <span>{label}</span>
      </Container>
    );
  }
}

MarkerButton.propTypes = {
  widthMarker: PropTypes.number,
  label: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  heightCheckMark: PropTypes.number,
  widthCheckMark: PropTypes.number,
  leftCheckMark: PropTypes.number,
  topCheckMark: PropTypes.number,
  borderSize: PropTypes.number,
  borderRadius: PropTypes.number,
  disabled: PropTypes.bool,
};

MarkerButton.defaultProps = {
  widthMarker: 28,
  fontSize: 16,
  type: 'checkbox',
  heightCheckMark: 8,
  widthCheckMark: 15,
  leftCheckMark: 7,
  topCheckMark: 6,
  disabled: false,
  borderRadius: 8,
  borderSize: 2,
};

export default React.memo(MarkerButton);
