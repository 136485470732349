import React from 'react';
import Lottie from 'react-lottie';

class MeterAnimationLogic extends React.PureComponent {
  state = {
    segments: [0, 7.5],
  };

  componentDidMount() {
    this.calculateMeterHours();
  }

  calculateMeterHours = () => {
    const { hours, minutes } = this.props;
    let lastFrame = 49;

    if (hours === 0) {
      const perFrame = 8 / 60;
      if (minutes) {
        lastFrame = 7.5 + perFrame * minutes;
      } else {
        lastFrame = 3;
      }
    }

    if (1 <= hours && hours <= 7) {
      const perFrame = 4 / 60;
      lastFrame = 15.5 + 4 * (hours - 1) + perFrame * minutes;
      if (lastFrame >= 26 && hours < 4) {
        lastFrame = 26;
      }
    }

    if (7 < hours && hours < 8) {
      const perFrame = 9.5 / 60;
      lastFrame = 39.5 + perFrame * minutes;
    }

    this.setState({
      segments: [0, lastFrame],
    });
  };

  componentDidUpdate(prevProps) {
    const { minutes, hours } = this.props;
    const { minutes: prevMinutes, hours: prevHours } = prevProps;
    if (minutes !== prevMinutes || hours !== prevHours) {
      this.calculateMeterHours();
    }
  }

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: require('./meter.json'),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
        imagePreserveAspectRatio: 'xMidYMid meet',
        progressiveLoad: true,
        scaleMode: 'noScale',
      },
    };
    const { segments } = this.state;

    return <Lottie options={defaultOptions} width={360} segments={segments} />;
  }
}

export default MeterAnimationLogic;
