import awsSettings from 'services/auth/aws-settings.js';
import uuidv1 from 'uuid/v1';
import apiNameEnum from 'services/s3/enums/apiNameEnum.js';

import { API } from 'aws-amplify';

function uploadFile(base64Data, type) {
  return new Promise((resolve, reject) => {
    const uploadId = uuidv1();

    API.post(apiNameEnum.UploadAPI, `/image/${type}/${uploadId}`, { body: { image: base64Data } })
      .then(response => {
        if (response.success) {
          resolve(uploadId);
        } else {
          reject(new Error('Upload issue'));
        }
      })
      .catch(reject);
  });
}

export { uploadFile };
