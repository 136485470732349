import React from 'react';

const SvgAppleiOs = props => (
  <svg width="1em" height="1em" viewBox="0 0 15 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-1 0h18v18H-1z" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M10.76 0C8.813.677 7.296 1.353 7.512 4.286 9.46 3.835 11.192 2.706 10.76 0zM4.58 4.071C2.706 4.071 0 5.786 0 9c0 4.5 2.914 9 4.58 9 1.249 0 2.29-.857 3.122-.857.833 0 1.665.857 2.914.857 1.666 0 3.54-2.571 3.955-4.929-1.457-1.071-2.498-1.714-2.498-3.642 0-1.286 1.041-2.572 2.29-3.643-.624-.857-1.873-1.715-3.122-1.715-1.25 0-2.592 1.018-3.539 1.072-.833 0-1.873-1.072-3.122-1.072z"
      />
    </g>
  </svg>
);

export default SvgAppleiOs;
