import styled, { css } from 'styled-components';
import { theme } from '../../config';

export default styled.div`
  border-radius: 3.5px;
  background-color: ${theme.colors.faded_teal};
  height: 100%;
  ${props =>
    props.disabled && // &:before is just a way to hide stuff that is under the event (hides other events that are under it)
    css`
      background-color: rgba(238, 238, 238, 0.8);
      cursor: auto;
      &:before {
        content: ' ';
        position: absolute;
        top: 2px;
        left: 5px;
        right: 2px;
        height: 43px;
        background-color: #eeeeee;
      }
    `}
`;
