import React from 'react';
import PropTypes from 'prop-types';

import {
  MeterThumbnail1H,
  MeterThumbnail2H,
  MeterThumbnail3H,
  MeterThumbnail4H,
  MeterThumbnail5H,
  MeterThumbnail6H,
  MeterThumbnail7H,
  MeterThumbnail8H,
  MeterThumbnailEmpty,
  MeterThumbnailNull,
} from 'components/design-components/icons';

import moment from 'moment';
import { media } from 'components/design-components/config';
import styled, { css } from 'styled-components';

const images = {
  0: MeterThumbnailNull, // TODO works but should be different from the not used at all meter
  1: MeterThumbnail1H,
  2: MeterThumbnail2H,
  3: MeterThumbnail3H,
  4: MeterThumbnail4H,
  5: MeterThumbnail5H,
  6: MeterThumbnail6H,
  7: MeterThumbnail7H,
  8: MeterThumbnail8H,
  future: MeterThumbnailEmpty,
  notUsed: MeterThumbnailNull,
};

const StyledImage = styled.div`
  svg {
    font-size: 45px;
    ${media.tablet(css`
      font-size: 30px;
    `)}
  }
`;

const MeterImagesSelector = ({ timeInS, notUsed, isNotUsedPast }) => {
  let ImageComponent = null;
  if (notUsed) {
    ImageComponent = images['future'];
  } else if (isNotUsedPast) {
    ImageComponent = images['notUsed'];
  } else {
    // convert to hours
    const durationHours = Math.floor(moment.duration(timeInS, 'seconds').asHours());
    if (0 <= durationHours && durationHours <= 8) {
      ImageComponent = images[durationHours];
    }
    //TODO in case the user was active more than 8 hours select the 8 hours meter ?
    if (durationHours > 8) {
      ImageComponent = images[8];
    }

    if (
      0 < moment.duration(timeInS, 'seconds').asMinutes() &&
      moment.duration(timeInS, 'seconds').asMinutes() < 60
    ) {
      ImageComponent = images[1];
    }
  }
  return (
    <StyledImage>
      <ImageComponent />
    </StyledImage>
  );
};

MeterImagesSelector.propTypes = {
  timeInS: PropTypes.number.isRequired,
  notUsed: PropTypes.bool,
  isNotUsedPast: PropTypes.bool,
};

MeterImagesSelector.defaultProps = {
  notUsed: false,
  isNotUsedPast: false,
};

export default MeterImagesSelector;
