import React from 'react';
import PropTypes from 'prop-types';
import Links from './Links';
import {
  Button,
  ErrorText,
  H1,
  Input,
  Panel,
  PasswordInput,
} from 'components/design-components';
import { Form } from 'react-final-form';
import styled, { css } from 'styled-components';
import { media, theme } from 'components/design-components/config';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledPannel = styled(Panel)`
  margin-top: 65px;
  width: 560px;
  ${media.tablet(css`
    width: 100%;
    margin-top: 0;
    height: 100%;
  `)};
  ${media.phone(css`
    padding-right: 10px;
    padding-left: 10px;
  `)}
  h1 {
    margin: 0;
    font-weight: 400;
  };
  h3 {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.39px;
    font-weight: 400;
  };
`;

const StyledErrorText = styled(ErrorText)`
  text-align: left;
  color: ${theme.colors.grey};
  font-size: 14px;
`;

const InputWrapper = styled.div`
  margin-bottom: 25px;
`;

const SignIn = ({
  onSubmit,
  onValidate,
  disabledSubmit,
  errorText,
  onPasswordVisibleChange,
  passwordVisible,
  forgotPasswordLink,
  chromeExtension,
  mobileApp,
  onChangePassword,
  isLoading,
}) => {
  if(errorText == 'User does not exist.')
    errorText = 'Your email or password is not correct';

  return (
    <Container>
      <StyledPannel>
        <H1>equell</H1>
        <h3>Rewards for being offline</h3>
        <StyledErrorText>{errorText}</StyledErrorText>
        <Form
          onSubmit={onSubmit}
          validate={onValidate}
          render={({ handleSubmit, submitting, pristine }) => {
            return (
              <form onSubmit={handleSubmit}>
                <InputWrapper>
                  <Input name="email" type="email" label="Email address" error={!!errorText} />
                </InputWrapper>
                <InputWrapper>
                  <PasswordInput
                    id="password"
                    name="password"
                    label="Password"
                    passwordVisible={passwordVisible}
                    onPasswordVisibleChange={onPasswordVisibleChange}
                    passwordInstructions={[]}
                    onChangePassword={onChangePassword}
                    error={!!errorText}
                    alternateLabel="Password (8 or more characters)"
                  />
                </InputWrapper>
                <Button
                  loading={isLoading}
                  disabled={pristine || disabledSubmit}
                  isSubmit
                  fullwidth
                >
                  Sign in
                </Button>
              </form>
            );
          }}
        />
        <Links
          forgotPasswordLink={forgotPasswordLink}
          chromeExtension={chromeExtension}
          mobileApp={mobileApp}
        />
      </StyledPannel>
    </Container>
  );
};

SignIn.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  errorText: PropTypes.string,
  onPasswordVisibleChange: PropTypes.func.isRequired,
  passwordVisible: PropTypes.bool,
  disabledSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  ...Links.propTypes,
};

SignIn.defaultProps = {
  errorText: '',
  passwordVisible: false,
  disabledSubmit: false,
  isLoading: false,
};

export default SignIn;
