import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { theme } from 'components/design-components/config';
import styled from 'styled-components';
import { DeviceTypeIcon } from 'enums/DeviceTypeEnum';
import DeviceListDetails from './DeviceListDetails';

const DeviceList = styled.div`
  padding-top: 25px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  font-size: ${theme.fontSize.normal};
`;

const DeviceContainer = styled.div`
  height: 250px;
  width: 350px;
  background-color: ${theme.colors.true_white};
  ${theme.boxShadow};
  margin-bottom: 30px;
  &:not(:last-of-type) {
    margin-right: 6px;
  }
`;

const DeviceName = styled.div`
  padding: 0 25px;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.light_grey};
  font-weight: bold;
`;

const DeviceTextContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const DeviceIcon = styled.i`
  font-size: 25px;
  margin-right: 3px;
`;

const DevicesList = ({ devices }) => {
  return (
    <DeviceList>
      {devices.map(device => (
        <DeviceContainer key={uniqid()}>
          <DeviceName>
            <DeviceTextContainer>
              <DeviceIcon className={DeviceTypeIcon[device.type]} />
              {device.name}
            </DeviceTextContainer>
          </DeviceName>
          <DeviceListDetails device={device} />
        </DeviceContainer>
      ))}
    </DeviceList>
  );
};

DevicesList.propTypes = {
  devices: PropTypes.arrayOf(DeviceListDetails.propTypes.device).isRequired,
};

export default DevicesList;
