import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation RedeemReward($input: RedeemRewardInput!) {
    redeemReward(input: $input) {
      success
      errors
      rewardRedemption {
        id
        created
        updated
        pointsUsed
        status
      }
    }
  }
`;

export default function redeemReward(input) {
  return executeMutation(mutation, { input });
}
