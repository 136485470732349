const userStore = {
  userInfo: null,
  isUserLoggedIn: false,
  username: null,
};

function getUser() {
  return userStore.userInfo;
}

function getUsername() {
  return userStore.username;
}

function storeUser(userInfo) {
  userStore.userInfo = userInfo;
  userStore.isUserLoggedIn = true;
}

function storeUsername(username) {
  userStore.username = username;
}

function clearUser() {
  userStore.userInfo = null;
  userStore.isUserLoggedIn = false;
}

function isUserLoggedIn() {
  return userStore.isUserLoggedIn;
}

export { getUser, storeUser, storeUsername, clearUser, isUserLoggedIn, getUsername };
