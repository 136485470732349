import React, { useState, useEffect } from 'react';
import { DeleteInviteButton } from '../styled';

const DeleteInvite = ({ onDeleteInvite, field, isRemoving }) => {
  const [remove, onRemoving] = useState(isRemoving);

  const onDelete = (e) => {
    e.preventDefault();
    onDeleteInvite(field, remove);
    onRemoving(false);
  };

  useEffect(() => {
    onRemoving(isRemoving);

    return () => {
      onRemoving(false);
    };
  }, []);

  // console.log('isRemoving deleteInvite -> ', remove);

  return (
    <DeleteInviteButton loading={remove} onClick={onDelete}>
      <i className="mdi mdi-delete" />
    </DeleteInviteButton>
  );
};

export default DeleteInvite;
