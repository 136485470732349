import React, { Component, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { HeaderContent } from 'components/content';

export default class MyAccountWithData extends Component {
  render() {
    const { routes, location } = this.props;
    const pathName = location.pathname;
    const validRoutes = [
      routeEnums.EDIT_PROFILE,
      routeEnums.DEVICES,
      routeEnums.EMAIL_PREFERENCES,
      routeEnums.REWARD_HISTORY,
    ];

    if (location.pathname === routeEnums.MY_ACCOUNT) {
      return <Redirect to={routeEnums.EDIT_PROFILE} />;
    }

    if (!validRoutes.includes(pathName)) {
      return <Redirect to="/404" />;
    }

    const accountProps = {
      header: 'Account',
      navItems: [
        {
          label: 'Profile',
          to: routeEnums.EDIT_PROFILE,
        },
        {
          label: 'Devices',
          to: routeEnums.DEVICES,
        },
        {
          label: 'Notifications',
          to: routeEnums.EMAIL_PREFERENCES,
        },
        {
          label: 'Rewards History',
          to: routeEnums.REWARD_HISTORY,
        },
      ],
    };

    return (
      <Fragment>
        <HeaderContent {...accountProps} />
        <Switch>
          {routes.map(({ path, component: Component }) => {
            return (
              <Route
                key={path}
                path={path}
                routes={routes}
                render={props => <Component {...props} routes={routes} />}
              />
            );
          })}
        </Switch>
      </Fragment>
    );
  }
}
