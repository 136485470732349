import React, { Component } from 'react';
import RewardsInventory from 'pages/admin-dashboard/RewardsInventory.jsx';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import getRewardInventory from 'services/graphQL/queries/admin/reward/rewardInventoryData.js';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { replaceRouteParams } from 'componentsWithData/router/routeService.js';
import removeReward from 'services/graphQL/mutations/admin/reward/removeReward.js';
import removeAllRewards from 'services/graphQL/mutations/admin/reward/removeAllRewards.js';
import lodash from 'lodash';
import moment from 'moment';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';

export default class RewardInventoryWithData extends Component {
  state = {
    isLoading: true,
    fetchError: '',
    removeError: null,
    removeAllError: null,
    rewardsData: {},
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchData() {
    this.setState({
      isLoading: true,
    });

    getRewardInventory()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const team = lodash.get(data, 'data.me.teams.0');

        if (team) {
          this.teamId = team.id;
        }

        this.setState({
          isLoading: false,
          rewardsData: { availableRewards: team.availableRewards },
        });
      })
      .catch(err => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          isLoading: false,
          fetchError: err.message,
        });
      });
  }

  editReward = rewardId => {
    const { history } = this.props;

    history.push(replaceRouteParams(routeEnums.EDIT_REWARD, { rewardId }));
  };

  onRemoveReward = (rewardId, closeModal, setLoading) => {
    const teamId = this.teamId;
    setLoading(true);

    removeReward({
      rewardId,
      teamId,
    })
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        setLoading(true);

        const success = lodash.get(data, 'data.removeTeamReward.success');
        const errors = lodash.get(data, 'data.removeTeamReward.errors');

        closeModal();

        if (success) {
          successMessage('Reward removed');

          this.fetchData();
        } else {
          errorMessage('Reward not removed');

          this.setState({
            removeError: errors[0],
          });
        }
      })
      .catch(err => {
        if (!this.__isComponentMounted) {
          return;
        }

        errorMessage('Reward not removed');
        setLoading(true);

        closeModal();

        this.setState({
          removeError: err.message,
        });
      });
  };

  onDeleteAll = (closeModal, setLoading) => {
    const teamId = this.teamId;
    setLoading(true);

    removeAllRewards({ teamId })
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        setLoading(true);

        const success = lodash.get(data, 'data.removeAllTeamRewards.success');
        const errors = lodash.get(data, 'data.removeAllTeamRewards.errors');

        closeModal();

        if (success) {
          successMessage('Rewards removed');

          this.setState({
            rewardsData: { availableRewards: [] },
          });
        } else {
          errorMessage('Rewards not removed');

          this.setState({
            removeAllError: errors[0],
          });
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        errorMessage('Rewards not removed');
        setLoading(true);

        closeModal();

        this.setState({
          removeAllError: error.message,
        });
      });
  };

  render() {
    const { isLoading, rewardsData, fetchError } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (fetchError) {
      return <div>{fetchError}</div>;
    }

    const rewardArray = rewardsData.availableRewards || [];

    const rewardsProps = {
      rewards: rewardArray.map(reward => ({
        id: reward.id,
        imageUrl: getS3ImageUrl(s3BucketTypes.REWARD, reward.s3Key),
        rewardTitle: reward.name,
        rewardDescription: reward.description || '',
        points: reward.points || 0,
        startDate: moment(reward.startDate * 1000).toDate(),
        endDate: moment(reward.endDate * 1000).toDate(),
        onEdit: this.editReward,
        onDelete: this.onRemoveReward,
        isArchived: reward.isArchived,
      })),
      profileTier: rewardsData.tier,
      onDeleteAllRewards: this.onDeleteAll,
      addRewardLink: routeEnums.ADD_REWARD,
    };

    return <RewardsInventory {...rewardsProps} />;
  }
}
