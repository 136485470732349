import React from 'react';
import { Table, TableHeader } from '../styled';
import TableRow from './TableRow';

const labels = ['Name', 'Email Address', 'Points', 'Role' ];

const MembersTable = ({
  groupId,
  members,
  userId,
  adminMember,
  onRemoveProfile,
  onDeleteInvite,
  onUpdateGroupProfile,
  editMemberErrorText
}) => {
  return (
    <Table>
      <TableHeader>
        {
          labels.map((label, index) => (
            <span key={index}>{label}</span>
          ))
        }
      </TableHeader>
      {
        members.map((member, index) =>
          <TableRow
            key={index}
            groupId={groupId}
            member={member}
            userId={userId}
            adminMember={adminMember}
            onRemoveProfile={onRemoveProfile}
            onDeleteInvite={onDeleteInvite}
            onUpdateGroupProfile={onUpdateGroupProfile}
            editMemberErrorText={editMemberErrorText}
          />)
      }
    </Table>
    
  );
};

export default React.memo(MembersTable);
