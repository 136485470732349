import React from 'react';
import Achievements from './Achievements';
import ActivitySection from './components/ActivitySection';
import Calendar from './Calendar';
import Rewards from './Rewards';
import { MainContainer } from 'components/design-components';
import styled from 'styled-components';

const StyledMainContainer = styled(MainContainer)`
  margin-bottom: 50px;
`;

const ActivityDashBoard = ({
  achievements,
  rewards,
  rewardsPerLine,
  onRedeemReward,
  onClickDetails,
  activity,
  activityStartDate,
  onClickAward,
  profileTier,
}) => {
  return (
    <StyledMainContainer>
      <ActivitySection title="Awards">
        <Achievements achievements={achievements} onClickAward={onClickAward} />
      </ActivitySection>
      <ActivitySection title="Rewards">
        <Rewards
          profileTier={profileTier}
          rewards={rewards}
          rewardsPerLine={rewardsPerLine}
          onRedeemReward={onRedeemReward}
          onClickDetails={onClickDetails}
        />
      </ActivitySection>
      <ActivitySection title="Calendar">
        <Calendar activity={activity} activityStartDate={activityStartDate} />
      </ActivitySection>
    </StyledMainContainer>
  );
};

ActivityDashBoard.propTypes = {
  ...Achievements.propTypes,
  ...Rewards.propTypes,
  ...Calendar.propTypes,
};
ActivityDashBoard.defaultProps = {
  ...Rewards.defaultProps,
};

export default ActivityDashBoard;
