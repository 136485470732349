import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media, sizes } from '../../config';

const FlexGrid = styled.div`
  display: flex;
  ${props =>
    props.chouldCollapse &&
    media[props.collapse](css`
      display: block;
      & > div {
        text-align: center;
        padding: 0;
      }
    `)}
`;

FlexGrid.propTypes = {
  children: PropTypes.node.isRequired,
  collapse: PropTypes.oneOf(Object.keys(sizes)),
  shouldCollapse: PropTypes.bool,
};

FlexGrid.defaultProps = {
  collapse: 'tablet',
  chouldCollapse: true,
};

export default FlexGrid;
