import React, { useState } from 'react';
import styled from 'styled-components';
import CircularProgress from 'components/mouldifi-components/CircularProgress';

const Container = styled.div`
  height: 86vh;
  @media screen and (max-height: 700px) {
    height: 80vh;
  }
  position: relative;
  top: 20px;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden;
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
`;

const PrivacyPolicy = () => {
  const [loadingState, setLoading] = useState(true);
  const iframe = React.createRef();

  function finisLoading() {
    setLoading(false);
  }

  return (
    <Container>
      {loadingState && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      <StyledIframe
        ref={iframe}
        src="https://equell.com/privacy_.html"
        scrolling="no"
        allowfullscreen
        onLoad={finisLoading}
      />
    </Container>
  );
};

export default React.memo(PrivacyPolicy);
