import React, { Component } from 'react';
import CompleteReward from 'pages/admin-dashboard/CompleteRewards.jsx';
import updateReward from 'services/graphQL/mutations/admin/reward/updateReward.js';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import getSingleReward from 'services/graphQL/queries/rewards/singleReward.js';
import lodash from 'lodash';
import pointValueEnum from './enums/pointValueEnum.js';
import pointStringEnum from './enums/pointStringEnum.js';
import moment from 'moment';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { uploadFile } from 'services/s3/apiGatewayClient.js';

const fileSizeLimit = 200000;

export default class EditRewardWithData extends Component {
  state = {
    reward: {},
    isLoading: true,
    isSubmitting: false,
    disableSubmit: false,
    isImageLoading: false,
    imageKey: null,
    error: '',
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchReward();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchReward() {
    const { match } = this.props;
    const rewardId = match.params.rewardId;

    this.setState({
      isLoading: true,
    });

    getSingleReward(rewardId)
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const reward = lodash.get(data, 'data.reward');

        this.setState({
          reward: reward,
          imageKey: lodash.get(data, 'data.reward.s3Key'),
          isLoading: false,
        });
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          isLoading: false,
          error,
        });
      });
  }

  updateReward = ({ rewardTitle, rewardDescription, duration, pointValue, startDate }) => {
    const { history, match } = this.props;
    const { reward, imageKey } = this.state;
    const rewardId = match.params.rewardId;
    const points = pointValueEnum[pointValue];
    const startDateInt = moment(startDate).unix();
    const weekNumber = parseInt(duration.split('_')[0]);
    const endDate = moment(startDate).add(weekNumber, 'weeks');
    const endDateInt = endDate.unix();

    const rewardData = {
      rewardId,
      name: rewardTitle,
      description: rewardDescription,
      points,
      startDate: startDateInt,
      endDate: endDateInt,
      s3Key: imageKey || '',
    };

    this.setState({
      isSubmitting: true,
      reward: { ...reward, ...rewardData },
    });

    updateReward(rewardData)
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const success = lodash.get(data, 'data.updateTeamReward.success');
        const errors = lodash.get(data, 'data.updateTeamReward.errors');

        this.setState({
          isSubmitting: false,
        });
        if (success) {
          successMessage('Reward updated');

          history.push(routeEnums.REWARD_INVENTORY);
        } else {
          errorMessage('Reward not updated');

          this.setState({
            error: errors[0],
          });
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        errorMessage('Reward not updated');

        this.setState({
          isSubmitting: false,
          error: error.message,
        });
      });
  };

  getDurationOption(startDate, endDate) {
    const weeksNumber = Math.round(moment(endDate * 1000).diff(startDate * 1000, 'weeks'));

    return `${weeksNumber}_WEEKS`;
  }

  fileSizeError = () => {
    errorMessage('File must be max 200kB');
  };

  onFileSelect = (base64String, setFormEvent) => {
    this.setState({
      disableSubmit: true,
      isImageLoading: true,
    });

    uploadFile(base64String, s3BucketTypes.REWARD)
      .then(uploadId => {
        if (!this.__isComponentMounted) {
          return;
        }
        this.setState({
          disableSubmit: false,
          isImageLoading: false,
          imageKey: uploadId,
        });

        setFormEvent(uploadId);

        successMessage('File uploaded');
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          disableSubmit: false,
          isImageLoading: false,
        });
        errorMessage('Error uploading file');
      });
  };

  onImageRemove = () => {
    this.setState({ imageKey: '' });
  };

  render() {
    const {
      isLoading,
      error,
      isSubmitting,
      imageKey,
      disableSubmit,
      isImageLoading,
      reward,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    let imageUrl = '';

    if (imageKey) {
      imageUrl = getS3ImageUrl(s3BucketTypes.REWARD, imageKey);
    }

    const updateRewardProps = {
      title: 'Edit Reward',
      rewardsInventoryLink: routeEnums.REWARD_INVENTORY,
      onValidate: this.onValidate,
      cancelLink: routeEnums.REWARD_INVENTORY,
      onSave: this.updateReward,
      errorText: error,
      isLoading: isSubmitting,
      disabledSubmit: disableSubmit,
      titleMaxLength: 50,
      descriptionMaxLength: 100,
      fileSizeLimit: fileSizeLimit,
      fileSizeError: this.fileSizeError,
      imageUrl,
      onFileSelect: this.onFileSelect,
      onImageRemove: this.onImageRemove,
      isImageLoading: isImageLoading,
      fields: {
        rewardTitle: reward.name,
        rewardDescription: reward.description,
        duration: this.getDurationOption(reward.startDate, reward.endDate),
        pointValue: pointStringEnum[reward.points.toString()],
        startDate: moment(reward.startDate * 1000).toDate(),
        imageUrl: reward.s3Key,
      },
    };

    return <CompleteReward {...updateRewardProps} />;
  }
}
