import React from 'react';
import PropTypes from 'prop-types';
import Link from '../typography/Link';
import styled from 'styled-components';
const TheLink = styled(Link)`
  display: block;
  text-align: center;
  padding: 10px;
  cursor: pointer;
`;

const LinkButton = ({ onClick, children, ...props }) => {
  function handleClick(e) {
    e.preventDefault();
    onClick();
  }
  return (
    <TheLink {...props} onClick={handleClick} reactLink={false}>
      {children}
    </TheLink>
  );
};

LinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default LinkButton;
