import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Button, ImageUpload, Image } from 'components/design-components';
import { theme, media } from 'components/design-components/config';
import SimpleLogo from 'public/logo/icon_logo.svg';
import { IconCamera } from 'components/design-components/icons';
import Loader from 'components/mouldifi-components/CircularProgress';

const Container = styled.div`
  width: 250px;
  margin-right: 27px;
  flex-shrink: 0;
  margin-top: -30px;

  ${media.tablet`
    width: 450px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -40px;
  `}
`;

const DragContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 14px;
  position: relative;
  ${props => props.imgSrc && `
    background-image: url(${props.imgSrc});
    background-size: cover;
    background-position: center center;
    border-radius: 8px;
  `};
  ${props => props.isImageLoading && `
    background-color: ${theme.colors.switch_off};
    border-radius: 8px;
  `};

  ${media.tablet`
    width: 80px;
    height: 80px;
  `}

  > div {
    margin: 0;
    margin-left: -4px;
  }
`;

const InfoLabel = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
  display: inline-block;

  ${media.tablet`
    margin-bottom: 25px;
  `}
`;

const TextLabel = styled(InfoLabel)`
  ${media.tablet`
    display: none;
  `}
`;

const IconLogo = styled(Image)`
  width: 158px;
  height: 158px;

  ${media.tablet`
    width: 32px;
    height: 32px;
  `}
`;

const ImageUploadField = styled.input`
  display: none;
`;

const EditButton = styled(Button)`
  font-size: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 5px;
  background-color: ${theme.colors.equell_teal};

  ${media.tablet`width: auto;`}
  
  > i {
    color: ${theme.colors.true_white
  }
`

const Icon = styled.div`
  font-size: 58px;
  line-height: 46px;
  margin-bottom: 7px;
  margin-left: -10px;

  ${media.tablet`
    font-size: 38px;
    margin-left: -5px;
  `}

  svg {
    fill: currentColor;
    vertical-align: middle;
  }
`;

const UploadLabel = styled.span`
  ${media.tablet`
    display: none;
  `}
`;

class GroupImageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: null, 
    };
    this.myRef = React.createRef();
  }

  setFile = file => {
    const { uploadedFile } = this.props;
    
    uploadedFile(file);
    this.setState({
      imgSrc: file,
    });
  };

  handleImageChanged = e => {
    e.stopPropagation();
    const { uploadedFile, fileSizeLimit, fileSizeError } = this.props;
    const imageObj = e.target.files[0];
    if (fileSizeLimit && imageObj.size > fileSizeLimit) {
      fileSizeError();
      return;
    }
    const reader = new FileReader();

    reader.onload = res => {
      const b64string = res.target.result;

      uploadedFile(b64string);
    };
    reader.readAsDataURL(imageObj);
  };
    
  changeImg = (e) => {
    e.preventDefault();
    this.myRef.current.click();
  }

  render() {
    const accept = '.jpg,.jpeg,.png';
    const { isImageLoading, imgSrc } = this.props;

    return (
      <Container>
        <TextLabel>Group Image</TextLabel>
        <DragContainer imgSrc={imgSrc} isImageLoading={isImageLoading}>
          {
            isImageLoading ? <Loader /> :
            <Fragment>
              {
                !imgSrc &&
                <ImageUpload
                  name="imageUrl"
                  type="file"
                  getFile={this.setFile}
                  noLabel
                >
                  <Fragment>
                    <Icon><IconCamera /></Icon>
                    <UploadLabel>Drop a file here to upload</UploadLabel>
                  </Fragment>
                </ImageUpload>
              }
              {
                imgSrc &&
                <Fragment>
                  <IconLogo src={SimpleLogo}/>
                  <EditButton onClick={this.changeImg}>
                    <i className="mdi mdi-pencil" />
                  </EditButton>
                  <ImageUploadField
                    name="imageUrl"
                    type="file"
                    accept={accept}
                    onChange={this.handleImageChanged}
                    ref={this.myRef}
                  />
                </Fragment>
              }
            </Fragment>
          }
        </DragContainer>
        <InfoLabel>Images should be 350w and 226h and max. file size of 200 KB</InfoLabel>
      </Container>
    );
  }
}

export default GroupImageContainer;
