import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import FormGroup from './FormGroup';
import { theme } from '../config';
import { Field } from 'react-final-form';
import ErrorField from './ErrorField';
import { InputField, Label, TextareaField } from 'components/design-components/form/styled';

const HelperText = styled.div`
  margin-top: 4px;
`;
const InputCountLabel = styled.span`
  position: absolute;
  ${theme.fontFamily};
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  right: 21px;
  top: 24px;
  transition: ${theme.transition};
  color: ${theme.colors.grey};
  &.error {
    color: ${theme.colors.error_red};
  }
`;

const Input = ({
  type,
  isTextarea,
  label,
  maxLength,
  placeholder,
  children,
  helperText,
  inputClassName,
  rows,
  customOnChange,
  alternateLabel,
  error,
  disabled,
  ...props
}) => {
  function customInput(input, meta) {
    if (customOnChange) {
      const { onChange } = input;
      const mergedOnChange = e => {
        customOnChange(e);
        onChange(e);
      };
      const newInput = { ...input, onChange: mergedOnChange };
      return (
        <InputField
          {...newInput}
          error={error || !!meta.error}
          id={input.name}
          type={type}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled}
        />
      );
    }
    return (
      <InputField
        {...input}
        error={error || !!meta.error}
        id={input.name}
        type={type}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  }

  function renderLabel({ name, value }, active) {
    const newLabel = alternateLabel && (active || value) ? alternateLabel : label;
    return <Label htmlFor={name}>{newLabel}</Label>;
  }

  return (
    <Field {...props}>
      {({ input, meta }) => (
        <FormGroup>
          {children}
          {isTextarea ? (
            <TextareaField
              id={input.name}
              maxLength={maxLength}
              placeholder={placeholder}
              rows={rows}
              error={error || !!meta.error}
              {...input}
            />
          ) : (
            customInput(input, meta)
          )}
          {helperText && <HelperText>{helperText}</HelperText>}
          {label && renderLabel(input, meta.active)}
          {maxLength !== -1 && (
            <InputCountLabel
              className={classNames({
                error: input.value && input.value.length === maxLength,
              })}
            >
              {input.value.length} / {maxLength}
            </InputCountLabel>
          )}
          {meta.error && <ErrorField>{meta.error}</ErrorField>}
        </FormGroup>
      )}
    </Field>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  inputClassName: PropTypes.string,
  helperText: PropTypes.string,
  isTextarea: PropTypes.bool,
  error: PropTypes.bool,
  rows: PropTypes.number,
  customOnChange: PropTypes.func,
  alternateLabel: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  placeholder: ' ',
  children: null,
  name: '',
  maxLength: -1,
  inputClassName: '',
  helperText: '',
  isTextarea: false,
  rows: 4,
  error: false,
};
export default Input;
