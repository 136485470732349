import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'components/design-components/index';

const EventTooltip = ({ points, showTooltip, text }) => {
  let footer = null;
  if (points) {
    footer = points.toLocaleString() + ' POINTS';
  }
  return (
    <Tooltip
      centered
      enabled={showTooltip}
      text={text}
      footer={footer}
      containerStyle={{ height: '100%' }}
    >
      <div style={{ height: '100%', display: 'block' }} />
    </Tooltip>
  );
};

EventTooltip.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  points: PropTypes.number,
  showTooltip: PropTypes.bool,
};

EventTooltip.defaultProps = {
  points: 0,
  showTooltip: true,
};

export default EventTooltip;
