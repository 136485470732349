import React from 'react';

const SvgMeterThumbnail1H = props => (
  <svg viewBox="0 0 41.49 26.6" {...props} width="1em" height="1em">
    <defs>
      <linearGradient
        id="a"
        x1={9688.12}
        x2={9687.18}
        y1={39.83}
        y2={40.1}
        gradientTransform="matrix(.09 0 0 -1.11 -836.89 64.74)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#3fddb6" />
        <stop offset={1} stopColor="#1ab38e" />
      </linearGradient>
    </defs>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          fill="none"
          stroke="#f2e1d9"
          strokeLinecap="round"
          strokeWidth={12}
          d="M35.49 20.6A14.75 14.75 0 0 0 6 20.6"
        />
        <path
          fill="none"
          stroke="url(#a)"
          strokeLinecap="round"
          strokeWidth={8}
          d="M5.88 21L6 19.91"
        />
        <circle cx={6.42} cy={18.57} r={1.65} fill="#fdfcfb" />
      </g>
    </g>
  </svg>
);

export default SvgMeterThumbnail1H;
