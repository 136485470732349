import React, { Component } from 'react';
import Members from 'pages/admin-dashboard/Members.jsx';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import getTeamMembers from 'services/graphQL/queries/admin/members/teamMembers.js';
import removeAllProfiles from 'services/graphQL/mutations/admin/members/removeAllProfiles.js';
import removeTeamProfile from 'services/graphQL/mutations/admin/members/removeProfile.js';
import updateTeamProfile from 'services/graphQL/mutations/admin/members/updateTeamProfile.js';
import lodash from 'lodash';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import moment from 'moment';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';

export default class MembersWithData extends Component {
  state = {
    membersData: {},
    isLoading: true,
    fetchError: '',
    removeAllMembersErrorText: '',
    removeMemberErrorText: '',
    editMemberErrorText: '',
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchMembers();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchMembers(setLoading) {
    this.setState({
      isLoading: true,
    });

    getTeamMembers()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const team = lodash.get(data, 'data.me.teams.0');

        if (team) {
          this.teamId = team.id;
        }

        this.setState({
          membersData: {
            teamProfiles: team.teamProfiles,
          },
          isLoading: false,
        });

        if (setLoading) {
          setLoading(false);
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          fetchError: error.message,
          isLoading: false,
        });

        if (setLoading) {
          setLoading(false);
        }
      });
  }

  onEditSave = ({ firstName, lastName }, profileId, onClose, setLoading) => {
    const teamId = this.teamId;
    setLoading(true);

    updateTeamProfile({ teamId, profileId, givenName: firstName, familyName: lastName })
      .then(data => {
        onClose();
        setLoading(false);

        if (!this.__isComponentMounted) {
          return;
        }

        const success = lodash.get(data, 'data.updateTeamProfile.success');
        const errors = lodash.get(data, 'data.updateTeamProfile.errors');

        if (success) {
          successMessage('Member info changed!');

          this.fetchMembers();
        } else {
          errorMessage('Member info not changed!');

          this.setState({
            editMemberErrorText: errors[0],
          });
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        errorMessage('Member info not changed!');

        this.setState({
          editMemberErrorText: error.message,
        });

        setLoading(false);
        onClose();
      });
  };

  onRemoveAllMembers = (closeModal, setLoading) => {
    const teamId = this.teamId;
    setLoading(true);

    removeAllProfiles({ teamId })
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        setLoading(false);

        const success = lodash.get(data, 'data.removeAllTeamProfiles.success');
        const errors = lodash.get(data, 'data.removeAllTeamProfiles.errors');

        if (success) {
          successMessage('Removed all members!');

          this.fetchMembers();
          closeModal();
        } else {
          errorMessage('Members not removed!');

          this.setState({
            removeAllMembersErrorText: errors[0],
          });
        }
      })
      .catch(error => {
        errorMessage('Members not removed!');

        if (!this.__isComponentMounted) {
          return;
        }

        setLoading(false);

        this.setState({
          removeAllMembersErrorText: error.message,
        });
      });
  };

  onRemoveMember = (user, closeModal, setLoading) => {
    const teamId = this.teamId;
    setLoading(true);

    removeTeamProfile({ teamId, profileId: user.id })
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        setLoading(false);

        const success = lodash.get(data, 'data.removeTeamProfile.success');
        const errors = lodash.get(data, 'data.removeTeamProfile.errors');

        if (success) {
          successMessage('Member removed!');

          this.fetchMembers();
          closeModal();
        } else {
          errorMessage('Member not removed!');

          this.setState({
            removeMemberErrorText: errors[0],
          });
        }
      })
      .catch(error => {
        errorMessage('Member not removed!');

        if (!this.__isComponentMounted) {
          return;
        }

        setLoading(false);

        this.setState({
          removeMemberErrorText: error.message,
        });
      });
  };

  render() {
    const {
      membersData,
      fetchError,
      removeAllMembersErrorText,
      removeMemberErrorText,
      editMemberErrorText,
      isLoading,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (fetchError) {
      return <div>{fetchError}</div>;
    }

    const profiles = membersData.teamProfiles || [];

    const membersProps = {
      members: profiles.map(teamProfile => ({
        id: lodash.get(teamProfile, 'profile.id'),
        avatarUrl: getS3ImageUrl(s3BucketTypes.PROFILE, lodash.get(teamProfile, 'profile.s3Key')),
        firstName: lodash.get(teamProfile, 'profile.givenName'),
        lastName: lodash.get(teamProfile, 'profile.familyName'),
        email: lodash.get(teamProfile, 'profile.email'),
        points: lodash.get(teamProfile, 'profile.activity.availablePoints') || 0,
        creationDate: moment(teamProfile.joined * 1000).toDate(),
      })),
      onEditSave: this.onEditSave,
      addMembersLink: routeEnums.ADD_MEMBERS,
      onRemoveAllMembers: this.onRemoveAllMembers,
      onRemoveMember: this.onRemoveMember,
      onEditValidate: () => {}, //TODO Implement this once needed
      removeAllMembersErrorText,
      removeMemberErrorText,
      editMemberErrorText,
    };

    return <Members {...membersProps} />;
  }
}
