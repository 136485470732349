import React from 'react';
import PropTypes from 'prop-types';
import colors from '../styleConfig';
import styled from 'styled-components';
import Link from 'components/design-components/typography/Link';
import { media, theme } from 'components/design-components/config';
import { MainContainer } from 'components/design-components';

const BorderContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.gray500};
`;

const FooterSection = styled.div`
  font-size: ${theme.fontSize.smallNormal};
  display: flex;
  justify-content: space-between;
  ${media.tablet`
    &:not(:first-of-type) {
      padding-top: 20px;
    }
  `}
`;

const FooterContainer = styled.footer`
  min-height: 80px;
  font-size: ${theme.fontSize.normal};
  color: ${theme.colors.dark_grey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.tablet`
    flex-direction: column; 
    padding: 20px 0;
  `}
`;

const FooterSectionLinks = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

const FooterListItem = styled.li`
  display: inline-block;
  color: ${theme.colors.grey};
`;

const FooterSectionDownload = styled(FooterSection)`
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FooterDownloadListItem = styled(FooterListItem)`
  & i {
    font-size: 18px;
    fill: ${theme.colors.grey};
  }
  &&& a {
    color: ${theme.colors.dark_grey};
  }
  &&& a:hover {
    color: ${theme.colors.dark_grey};
  }

  &&& a:visited:not(.highlighted) {
    color: ${theme.colors.dark_grey};
  }
`;

// const EquellCoppyRight = styled.span`
//   display: flex;
//   color: ${theme.colors.grey};
//   padding-right: 50px;
// `;

const FooterLinks = ({ links, before }) => (
  <FooterSectionLinks>
    {before}
    {links.map(({ id, label, path, href }) => (
      <FooterListItem key={id}>
        <Link reactLink={!href} href={href} to={path} underlined fontSize={14}>
          {label}
        </Link>
      </FooterListItem>
    ))}
  </FooterSectionLinks>
);

const FooterDownloadLinks = ({ links }) => (
  <FooterSectionDownload>
    <FooterDownloadListItem>Download equell</FooterDownloadListItem>
    {links.map(({ id, icon, path }) => (
      <FooterDownloadListItem key={id}>
        <Link reactLink={false} href={path} rel="noopener noreferrer" target="_blank" fontSize={18}>
          {icon}
        </Link>
      </FooterDownloadListItem>
    ))}
  </FooterSectionDownload>
);

const Footer = ({ links, downloadLinks }) => (
  <BorderContainer>
    <MainContainer>
      <FooterContainer>
        <FooterSection>
          <FooterLinks links={links} before={<span>© 2019 equell</span>} />
        </FooterSection>
        <FooterSection>
          {downloadLinks && <FooterDownloadLinks links={downloadLinks} />}
        </FooterSection>
      </FooterContainer>
    </MainContainer>
  </BorderContainer>
);

Footer.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      href: PropTypes.string,
    })
  ).isRequired,

  downloadLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
};

export default Footer;
