import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation RemoveGroupProfile($input: RemoveGroupProfileInput!) {
    removeGroupProfile(input: $input) {
      success
      errors
    }
  }
`;

export default function removeGroupProfile({groupId, profileId}) {
  return executeMutation(mutation, { input: { groupId, profileId } });
}

