import React, { Component } from 'react';
import lodash from 'lodash';
import EditGroup from 'pages/groups/EditGroup';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';
import updateGroup from 'services/graphQL/mutations/groups/updateGroup';
import createGroupInvites from 'services/graphQL/mutations/groups/createGroupInvites';
import removeGroup from 'services/graphQL/mutations/groups/removeGroup';
import rejectGroup from 'services/graphQL/mutations/groups/rejectGroup';
import { uploadFile } from 'services/s3/apiGatewayClient.js';
import getSingleGroup from 'services/graphQL/queries/groups/getSingleGroup';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';

class EditGroupWithData extends Component {
  constructor() {
    super();
    this.state = {
      isSubmitting: false,
      isLoading: false,
      isRemoving: false,
      isImageLoading: false,
      group: {},
      groupInvites: [],
      groupProfiles: [],
      imageKey: null,
      disable: true,
      errorName: false,
      errorEmail: false,
      errorEmailText: '',
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fileSizeError = () => {
    errorMessage('File must be max 200kB');
  };

  fetchData = () => {
    const { match } = this.props;
    const groupId = match.params.groupId;

    this.setState({
      isLoading: true,
    });

    getSingleGroup(groupId)
      .then(data => {
        const groupData = lodash.get(data, 'data.group');
        const imageKey = lodash.get(data, 'data.group.s3Key');
        const groupInvites = lodash.get(data, 'data.group.groupInvites');
        const groupProfiles = lodash.get(data, 'data.group.groupProfiles');

        this.setState({
          isLoading: false,
          group: groupData,
          groupInvites,
          imageKey,
          groupProfiles
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
      });
  }

  goToGroups = () => {
    const { history } = this.props;

    history.push(routeEnums.GROUPS);
  }

  onUpdateGroup = ({ name='', email='', email1='' }) => {
    const { imageKey, groupInvites } = this.state;
    const { match } = this.props;
    const groupId = match.params.groupId;
    const groupData = {
      groupId,
      name,
      s3key: imageKey,
    };
    const emails = [email, email1].filter(e => !groupInvites.find(({ email }) => e === email)).join(',');

    if(name.trim() === '') {
      this.setState({
        isSubmitting: false,
        disable: false,
        errorName: true,
      });
      return;
    };

    if(email === email1 && (email.trim() || email1.trim() !== '')){
      this.setState({
        isSubmitting: false,
        disable: false,
        errorEmail: true,
      });
      return;
    };

    this.setState({
      isSubmitting: true,
    });
    updateGroup(groupData)
      .then(data => {
        const invitesData = {
          groupId,
          emails: emails.toLowerCase(),
        };
        createGroupInvites(invitesData)
          .then(data => {
            const success = lodash.get(data, 'data.createGroupInvites.success');
            const errors = lodash.get(data, 'data.createGroupInvites.errors[0]');

            if(errors) {
              this.setState({
                isSubmitting: false,
                errorEmailText: errors
              })
            };
            if(success) {
              successMessage('Saved!');
              this.goToGroups();
              this.setState({
                isSubmitting: false,
                disable: false,
              });
            }
          });
      })
      .catch(err => {
        this.setState({
          isSubmitting: false,
        });
      });
  }

  onGroupRemove = () => {
    const { match } = this.props;
    const groupId = match.params.groupId;
    
    removeGroup(groupId)
      .then(data => {
        this.goToGroups();
      })
      .catch(err => console.log(err));
  }

  onDeleteInvite = (email, loading) => {
    const { groupInvites } = this.state;
    const inviteId = groupInvites.find(invite => invite.email === email).id;
    const invites = groupInvites.filter(item => item.id !== inviteId);
    this.setState({
      isRemoving: true
    });
    rejectGroup({inviteId})
      .then(data => {
        const success = lodash.get(data, 'data.rejectGroupInvitation.success');
        
        if(success) {
          this.setState({
            groupInvites: invites,
            isRemoving: false,
          });
          successMessage('Invitation removed!');
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isRemoving: false
        });
      });
  }

  onFileSelect = base64String => {
    this.setState({
      isImageLoading: true,
      disable: true,
    });
    uploadFile(base64String, s3BucketTypes.GROUP)
      .then(uploadId => {
        this.setState({
          imageKey: uploadId,
          isImageLoading: false,
          disable: false,
        });
        successMessage('Image uploaded, successfully!');
      })
      .catch(error => {
        this.setState({
          isImageLoading: false,
          disable: false,
        })
        errorMessage('Error uploading file');
      });
  }

  onChange = e => {
    if(e.target.value.trim !== '') {
      this.setState({
        disable: false,
      });
    }
  }

  render() {
    const {
      disable,
      imageKey,
      group,
      groupInvites,
      isLoading,
      isRemoving,
      isImageLoading,
      isSubmitting,
      errorEmail,
      errorName,
      errorEmailText,
    } = this.state;
    const editGroupProps = {
      groupsLink: routeEnums.GROUPS,
      disable,
      isSubmitting,
      isImageLoading,
      isRemoving,
      errorEmail,
      errorName,
      errorEmailText,
      groupTitleMaxLength: 50,
      fields: {
        name: group.name || '',
        email: groupInvites[0] && groupInvites[0].email || '',
        email1: groupInvites[1] && groupInvites[1].email || '',
        sentEmail: groupInvites[0] && groupInvites[0].accepted,
        sentEmail1: groupInvites[1] && groupInvites[1].accepted,
      },
      imgSrc: imageKey && getS3ImageUrl(s3BucketTypes.GROUP, imageKey),
      removeGroup: this.onGroupRemove,
      onFileSelect: this.onFileSelect,
      fileSizeLimit: 204800,
      fileSizeError: this.fileSizeError,
      onUpdateGroup: this.onUpdateGroup,
      onDeleteInvite: this.onDeleteInvite,
      onChange: this.onChange,
    };
    
    if (isLoading) {
      return <Loader />;
    }

    return <EditGroup {...editGroupProps} />;
  }
}

export default EditGroupWithData;
