import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BodyContainer, Button, H2, LightText } from 'components/design-components';
import { Link } from 'react-router-dom';
import RewardsTable from './components/RewardsTable';

const RewardsInventory = ({ addRewardLink, onDeleteAllRewards, rewards, children }) => {
  const availableRewards = rewards.filter(e => !e.isArchived);
  const archivedRewards = rewards.filter(e => e.isArchived);
  return (
    <BodyContainer
      title={'Rewards Inventory'}
      titleCount={availableRewards.length || null}
      after={
        <Link to={addRewardLink}>
          <Button className="float-right">+ Add Rewards</Button>
        </Link>
      }
    >
      <RewardsTable
        onDeleteAllRewards={availableRewards.length ? onDeleteAllRewards : null}
        rewards={availableRewards}
      />
      {archivedRewards.length > 0 && (
        <Fragment>
          <H2 style={{ marginTop: 60 }}>
            Archived rewards <LightText inline>({archivedRewards.length})</LightText>
          </H2>
          <RewardsTable rewards={archivedRewards} />
        </Fragment>
      )}
      {children}
    </BodyContainer>
  );
};

RewardsInventory.propTypes = {
  rewards: RewardsTable.propTypes.rewards,
  addRewardLink: PropTypes.string.isRequired,
  onDeleteAllRewards: PropTypes.func.isRequired,
  children: PropTypes.node,
};
RewardsInventory.defaultProps = {
  children: null,
};
export default React.memo(RewardsInventory);
