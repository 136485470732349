import moment from 'moment';

const Timespans = {
  THISWEEK: 'This week',
  THISMONTH: 'This month',
};

// TODO find a better place for this ?
const TimespanTime = {
  [Timespans.THISMONTH]: {
    start: () => moment().toDate(),
    end: () =>
      moment()
        .subtract(1, 'months')
        .toDate(),
  },
  [Timespans.THISWEEK]: {
    start: () => moment().toDate(),
    end: () =>
      moment()
        .subtract(1, 'week')
        .toDate(),
  },
};
export { TimespanTime };
export default Timespans;
