import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RewardItem from 'components/design-components/rewards/RewardItem';

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const RewardsList = ({ rewards, onClickDetails, profileTier }) => {
  return (
    <Container>
      <ListContainer>
        {rewards.map(reward => (
          <RewardItem key={reward.id} {...reward} profileTier={profileTier} onClickDetails={onClickDetails} />
        ))}
      </ListContainer>
    </Container>
  );
};

RewardsList.propTypes = {
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.number,
      limit: PropTypes.number,
      maxProgress: PropTypes.number.isRequired,
      valueProgress: PropTypes.number.isRequired,
      imgSrc: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      listOfImg: PropTypes.arrayOf(PropTypes.string.isRequired),
    })
  ),
  onClickDetails: PropTypes.func.isRequired,
};

RewardsList.defaultProps = {
  rewards: [],
};

export default React.memo(RewardsList);
