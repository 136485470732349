import freshchatSettings from './freshchatSettings.js';

function initFreshchat(restoreId, externalId) {
  window.fcWidget.init({
    token: freshchatSettings.token,
    host: freshchatSettings.host,
    externalId,
    restoreId,
  });
}

function initFreshchatUser(id, firstName, email) {
  window.fcWidget.setExternalId(id);
  window.fcWidget.user.setFirstName(firstName);
  window.fcWidget.user.setEmail(email);
}

function onUserCreatedFreshchat(callback) {
  window.fcWidget.on('user:created', function(resp) {
    var status = resp && resp.status,
      data = resp && resp.data;
    if (status === 200) {
      if (data.restoreId) {
        callback(data.restoreId);
      }
    }
  });
}
export { initFreshchat, initFreshchatUser, onUserCreatedFreshchat };
