import styled from 'styled-components';
import { theme } from 'components/design-components/config';

export const ClockNumber = styled.span`
  font-size: 28px;
  color: ${theme.colors.dark_grey};
`;

export const ClockFormat = styled.span`
  font-size: ${theme.fontSize.xsmallNormal};
  color: ${theme.colors.dark_grey};
`;
