import styled, { css } from 'styled-components';
import { media, theme } from 'components/design-components/config';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

export const colorTransition = 'transition: color 0.2s linear';
export const centerContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const border = `1px solid ${theme.colors.switch_off};`;

export const NavLabel = styled.div`
  height: ${theme.navbarHeight}px;
  ${centerContent};
  cursor: pointer;
  ${colorTransition};
  color: ${theme.colors.grey};
  ${media.tablet`
    height: ${theme.navbarHeight / 2}px;
  `}
`;

export const StyledNavLink = styled(NavLink)`
  height: 100%;
  min-width: 160px;
  ${centerContent};
  cursor: pointer;
  text-decoration: none;
  &.active {
    border-top: 2px solid ${theme.colors.equell_teal};
    ${media.tablet`
      border-top: 0;
      border-bottom: 2px solid ${theme.colors.equell_teal};
    `}
  }
  &,
  & div {
    ${colorTransition};
  }
  &:hover,
  &:hover div {
    color: ${theme.colors.equell_teal};
    text-decoration: none;
  }
  ${media.tablet`
    width: 100%;
    height: ${theme.navbarHeight / 2}px;
    border-right: 0;
    border-bottom: ${border};
  `}
`;

export const DropdownItem = styled.div`
  cursor: pointer;
  ${props => (props.borderTop ? 'margin-top:10px' : 'margin-bottom:10px')};
  &:hover {
    color: ${theme.colors.equell_teal};
  }
  a {
    text-decoration: none;
    display: block;
    width: 100%;
  }
`;

export const BorderContainer = styled(DropdownItem)`
  width: 100%;
  color: ${theme.colors.grey};
  position: relative;
  ${p => (p.cursorShow ? 'cursor:pointer' : 'cursor: initial')}
  ${css`
    &:hover {
      color: ${p => (p.hoverShow ? theme.colors.equell_teal : theme.colors.grey)};
    }
  `}
  ${props => (props.borderTop ? 'padding-top:15px' : 'padding-bottom:15px')};
  ${props =>
    props.borderTop
      ? css`
          &:before {
            content: '';
            background: ${theme.colors.switch_off};
            position: absolute;
            height: 1px;
            bottom: 38px;
            left: 0;
            top: 0;
            width: 10%;
          }
        `
      : css`
          &:after {
            content: '';
            background: ${theme.colors.switch_off};
            position: absolute;
            top: 46px;
            height: 1px;
            left: 0;
            bottom: 0;
            width: 10%;
          }
        `};
`;

export const NavListContainer = styled.div`
  display: flex;
  align-items: center;
  a {
    color: ${theme.colors.grey};
  }
  svg {
    fill: ${theme.colors.grey};
  }
  ${props =>
    !props.navbarOpened &&
    media.tablet`
      display: none;
    `};
  ${props =>
    props.navbarOpened &&
    media.tablet`
    position: absolute;
    top: ${theme.navbarHeight}px;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: ${theme.colors.true_white};
  `};
`;

export const SideBarContainer = styled.div`
  position: fixed;
  width: 100%;
  background-color: ${props => props.opened ? 'rgba(4, 4, 15, 0.4)' : 'transparent'};
  visibility: ${props => props.opened ? 'visible' : 'hidden'};
  top: 0;
  left: 0;
  bottom: 0;
  transition: background-color 300ms ease-in;
`;

export const SideBarMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 20px 0 60px;
  background-color: #fff;
  width: 300px;
  height: 100%;
  transform: translateX(-300px);
  transform: ${props => props.opened && 'translateX(0)'};
  transition: transform 500ms ease;
  overflow-y: auto;
`;

export const SideBarMenuItem = styled.li`
  border-bottom: 1px #eaded8 solid;
  a {
    display: block;
    text-decoration: none;
    color: #737373;
    &:hover {
      span {
        color: #1ab38e;
      }
      svg {
        fill: #1ab38e;
      }
      path {
        fill: #1ab38e;
        &#shape {
          stroke: #1ab38e;
        }
      }
    }
  }
  svg {
    fill: #737373;
  }
  span {
    font-size: 16px;
    line-height: 16px;
    margin-left: 17px;
  }
`;

// Navbar styles
export const NavWrapper = styled.div`
  width: 100%;
  border-bottom: ${border};
  background-color: ${theme.colors.true_white};
  position: fixed;
  z-index: 15;
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: ${theme.navbarHeight}px;
  font-size: ${theme.fontSize.normal};
  ${media.tablet`
    max-height: 64px;
    min-height: 64px;
    ${props => !props.isAuth && `
      align-items: center;
    `}
  `}
`;

export const HamburgerContainer = styled.div`
  display: none;
  align-items: center;
  ${media.tablet`display: flex;`}
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-left: 15px;
  color: ${theme.colors.dark_grey};
  display: none;
  ${media.tablet`
    display: block;
  `}
`;

export const IconBack = styled.div`
  display: none;
  font-size: 40px;
  color: ${theme.colors.equell_teal};

  ${media.tablet`
    display: block;
  `}
`;

export const RightIcon = styled(Link)`
  font-size: 26px;
  line-height: 26px;
  display: none;
  color: ${theme.colors.equell_teal};
  margin-right: 15px;

  ${media.tablet`
    display: block;
  `}

  svg {
    display: block;
    fill: currentColor;
  }

  path {
    fill: currentColor;
    &#shape {
      stroke: currentColor;
    }
  }
`;
