import React, { useState } from 'react';
import { ClassicModal, ErrorText } from 'components/design-components';

const RemoveMemberModalContent = ({ user }) =>
  `${user.firstName} ${user.lastName} are you sure that you want to leave the group?`;

const LeaveMemberModal = ({ opened, onSave, firstName, lastName, onClose, errorText }) => {
  const [loading, setLoading] = useState(false);
  const user = {
    firstName,
    lastName
  };

  function handleSave() {
    onSave(setLoading);
  }

  return (
    <ClassicModal
      fullscreen
      loading={loading}
      title="Leave group"
      opened={opened}
      onClose={onClose}
      onApprove={handleSave}
    >
      <RemoveMemberModalContent user={user} />
      <ErrorText>{errorText}</ErrorText>
    </ClassicModal>
  );
};

export default React.memo(LeaveMemberModal);