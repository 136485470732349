import React from 'react';

const SvgMeterThumbnail5H = props => (
  <svg width="1em" height="1em" viewBox="0 0 43 27" {...props}>
    <defs>
      <linearGradient id="a" x1="100%" x2="5.911%" y1="84.089%" y2="57.129%">
        <stop offset="0%" stopColor="#3FDDB6" />
        <stop offset="100%" stopColor="#1AB38E" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#F2E1D9"
        strokeLinecap="round"
        strokeWidth={12}
        d="M36.358 20.312c0-7.848-6.6-14.21-14.743-14.21S6.87 12.464 6.87 20.312"
      />
      <g transform="translate(6.642 5)">
        <path
          stroke="url(#a)"
          strokeLinecap="round"
          strokeWidth={8}
          d="M19.319 1.65c-.7-.21-1.423-.372-2.164-.481a15.704 15.704 0 0 0-4.54-.01 15.201 15.201 0 0 0-6.223 2.359C4.22 4.954 2.47 6.928 1.353 9.237A13.543 13.543 0 0 0 0 15.143"
        />
        <ellipse cx={19.651} cy={1.607} fill="#FDFCFB" rx={1.651} ry={1.607} />
      </g>
    </g>
  </svg>
);

export default SvgMeterThumbnail5H;
