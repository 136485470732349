import React, { Component } from 'react';

import Footer from 'components/custom/Footer';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { AppleiOs, Chrome } from 'components/design-components/icons';
import { DOWNLOAD_CHROME, DOWNLOAD_MOBILE } from './lib/enums';

const footerLinks = [
  {
    id: 'link1',
    label: 'About',
    href: 'https://equell.com/',
  },
  {
    id: 'link2',
    path: routeEnums.PRIVACY_POLICY,
    label: 'Privacy',
  },
  {
    id: 'link3',
    path: routeEnums.TERMS_OF_SERVICE,
    label: 'Terms',
  },
];

const footerDownloadLinks = [
  // {
  //   id: 'id-1',
  //   icon: 'mdi mdi-apple-finder',
  //   path: replaceRouteParams(routeEnums.DOWNLOAD_AND_INSTALL, {
  //     type: parameterEnum.downloadType.DESKTOP,
  //   }),
  // },
  // {
  //   id: 'id-2',
  //   icon: 'mdi mdi-windows',
  //   path: replaceRouteParams(routeEnums.DOWNLOAD_AND_INSTALL, {
  //     type: parameterEnum.downloadType.DESKTOP,
  //   }),
  // },
  {
    id: 'id-3',
    icon: <AppleiOs />,
    path: DOWNLOAD_MOBILE,
  },
  // {
  //   id: 'id-4',
  //   icon: 'mdi mdi-android',
  //   path: replaceRouteParams(routeEnums.DOWNLOAD_AND_INSTALL, {
  //     type: parameterEnum.downloadType.MOBILE,
  //   }),
  // },
  //{
    //id: 'id-5',
    //icon: <Chrome />,
    //path: DOWNLOAD_CHROME,
  //},
];

export default class FooterWithData extends Component {
  render() {
    return <Footer links={footerLinks} downloadLinks={footerDownloadLinks} />;
  }
}
