import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query listOfRewards {
    me {
      id
      tier
      activity {
        availablePoints
      }
      availableRewards {
        id
        name
        description
        points
        s3Key
        rewardRedemptions {
          profile {
            s3Key
          }
        }
      }
    }
  }
`;

export default function getAvailableRewards() {
  return executeQuery(query, {});
}
