import { compareTwoDates, getLastOrFirstSetTime } from 'utils/time';

/**
 * Used in ActivityCalendar
 *
 * @param activity
 * @param activityStartDate
 * @returns {*}
 */
export const getActivityExtremities = (activity, activityStartDate) => {
  if (!activity.length) return [activityStartDate, new Date()];
  let firstDateUsed = getLastOrFirstSetTime(activity, true);
  // after getting the first date used from the activity array, check with the "real" date used provided by the db
  // if the real date is earlier switch to the real date
  if (activityStartDate && activityStartDate < firstDateUsed) {
    firstDateUsed = activityStartDate;
  }

  let lastDateUsed = getLastOrFirstSetTime(activity);
  if (compareTwoDates(lastDateUsed, new Date()) < 0) {
    lastDateUsed = new Date();
  }
  return [firstDateUsed, lastDateUsed];
};
