import styled from 'styled-components';
import { theme } from 'components/design-components/config';

export const HoverBg = styled.div`
  position: absolute;
  display: none;
  content: '';
  width: calc(100% + 30px);
  height: 100%;
  border-radius: 4px;
  background-color: ${theme.colors.switch_off};
  left: -15px;
`;
export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:hover > ${HoverBg} {
    display: block;
  }
`;

export const Container = styled.div`
  padding: 15px 0;
  position: relative;
  z-index: 1;
`;
