import Styled from 'styled-components';
import { theme } from '../config';

export default Styled.div`
  box-shadow: 0 0 46px -10px ${theme.colors.warm_shadow};
  background-color: ${theme.colors.true_white};
  padding: 70px;
  text-align: center;
  margin-top: ${props => (props.withMarginTop ? '77px' : 0)}
`;
