import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ClassicModal, ErrorText } from 'components/design-components';

const RemoveRewardModalContent = ({ isRemovingAll }) =>
  isRemovingAll
    ? `Remove all rewards from the inventory?
This action will delete all rewards available 
to your team.`
    : 'Remove reward from the inventory?';

const RemoveRewardModal = ({ opened, onSave, rewardId, onClose, removeAll, errorText }) => {
  const [loading, setLoading] = useState(false);
  function handleClose() {
    onClose();
    // setLoading(false);
  }
  function handleSave() {
    if (removeAll) {
      onSave(handleClose, setLoading);
    } else {
      onSave(rewardId, handleClose, setLoading);
    }
  }
  return (
    <ClassicModal
      fullscreen
      loading={loading}
      title={removeAll ? 'Remove all rewards' : 'Remove Reward'}
      opened={opened}
      onClose={handleClose}
      onApprove={handleSave}
    >
      <RemoveRewardModalContent isRemovingAll={removeAll} />
      <ErrorText>{errorText}</ErrorText>
    </ClassicModal>
  );
};

RemoveRewardModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  rewardId: PropTypes.string,
  removeAll: PropTypes.bool,
  errorText: PropTypes.string,
};

RemoveRewardModal.defaultProps = {
  removeAll: false,
  errorText: '',
  rewardId: '',
};

export default React.memo(RemoveRewardModal);
