import React from 'react';
import PropTypes from 'prop-types';
import { H2, Image, Link, P, Panel } from '../../components/design-components/';
import logo from 'public/logo/simple_logo.svg';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledPannel = styled(Panel)`
  width: 560px;
`;

const CheckYourEmail = ({ email, signInPath }) => (
  <Container>
    <StyledPannel>
      <Image src={logo} isAvatar style={{ width: 60, height: 60 }} />
      <H2
        style={{
          margin: '35px 0',
        }}
      >
        Check your email
      </H2>
      <P style={{ textAlign: 'left', fontSize: 18 }}>
        A message has been sent to <strong>{email}</strong>. <br />
        <br />
        If an equell account exists with this email address, you will receive reset instructions
        from us shortly. If you don&apos;t receive an email, please contact support@equell.com.
      </P>
      <Link style={{ marginTop: '44px', fontSize: 16 }} to={signInPath}>
        <i className="mdi mdi-arrow-left" /> Return to sign in
      </Link>
    </StyledPannel>
  </Container>
);

CheckYourEmail.propTypes = {
  email: PropTypes.string.isRequired,
  signInPath: PropTypes.string.isRequired,
};

export default CheckYourEmail;
