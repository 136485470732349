function replaceRouteParams(route, params) {
  let newRoute = route;
  const paramKeyPairs = Object.entries(params);
  paramKeyPairs.forEach(keyPair => {
    const key = keyPair[0];
    const value = keyPair[1];

    newRoute = newRoute.replace(`:${key}`, value);
  });

  return newRoute;
}

function redirectToErrorPage(history, prevLocation) {
  history.push({
    pathname: '/404',
    state: {
      prevLocation,
    },
  });
}

export { replaceRouteParams, redirectToErrorPage };
