import React from 'react';
import PropTypes from 'prop-types';
import { border, NavListContainer, StyledNavLink } from 'components/custom/nav-bar/components/styled';
import styled, { css } from 'styled-components';
import { media, theme } from 'components/design-components/config';

const StyledNavListContainer = styled(NavListContainer)`
  ${StyledNavLink} {
    border-right: ${border};
    &.active {
      border-top: 2px solid ${theme.colors.equell_teal};
      ${media.tablet(css`
        border-bottom: 0;
      `)}
    }

    ${media.tablet(css`
      min-width: 120px;
      height: 100%;
      border-bottom: 0;
    `)}
  }

  ${StyledNavLink}:first-child {
    border-left: ${border};
  }

  ${media.tablet(css`
    display: flex;
  `)};

  display: flex;
  flex-direction: row;
  position: relative;
`;

const AuthNavigation = ({ signUp, signIn, navbarOpened }) => {
  return (
    <StyledNavListContainer navbarOpened={navbarOpened}>
      <StyledNavLink to={signUp}>Sign Up</StyledNavLink>
      <StyledNavLink to={signIn}>Sign In</StyledNavLink>
    </StyledNavListContainer>
  );
};

AuthNavigation.propTypes = {
  signUp: PropTypes.string.isRequired,
  signIn: PropTypes.string.isRequired,
};

export default React.memo(AuthNavigation);
