import styled from 'styled-components';
import { theme } from '../config';

const ErrorText = styled.div`
  ${theme.fontFamily};
  color: ${theme.colors.error_red};
  opacity: 0.9;
  letter-spacing: 1px;
  margin-bottom: 3px;
`;

export default ErrorText;
