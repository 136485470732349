import React from 'react';

const SvgMeterThumbnail8H = props => (
  <svg width="1em" height="1em" viewBox="0 0 43 27" {...props}>
    <defs>
      <linearGradient id="a" x1="100%" x2="5.911%" y1="84.089%" y2="57.129%">
        <stop offset="0%" stopColor="#3FDDB6" />
        <stop offset="100%" stopColor="#1AB38E" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#F2E1D9"
        strokeLinecap="round"
        strokeWidth={12}
        d="M36.358 20.312c0-7.848-6.6-14.21-14.743-14.21S6.87 12.464 6.87 20.312"
      />
      <g transform="translate(6.642 6)">
        <path
          stroke="#3FDDB6"
          strokeLinecap="round"
          strokeWidth={8}
          d="M29.717 14.312c0-7.848-6.601-14.21-14.744-14.21S.229 6.464.229 14.312"
        />
        <path
          stroke="url(#a)"
          strokeLinecap="round"
          strokeWidth={8}
          d="M26.837 5.784C24.133 2.277 19.773 0 14.854 0 6.65 0 0 6.332 0 14.143"
        />
        <ellipse cx={29.651} cy={14.607} fill="#FDFCFB" rx={1.651} ry={1.607} />
      </g>
    </g>
  </svg>
);

export default SvgMeterThumbnail8H;
