import React from 'react';
import { BodyContainer } from 'components/design-components';
import { Form } from 'react-final-form';
import { Input } from 'components/design-components';
import GroupImageContainer from './components/GroupImageContainer';
import {
  StyledHeader,
  StyledLink,
  Container,
  StyledH1,
  StyledH4,
  StyledP,
  ButtonGroup,
  FormRow,
  FormContainer,
  FormWrap,
  CancelButton,
  CreateButton,
  ErrorText,
} from './styled';

const CreateGroup = ({
  groupsLink,
  goToGroups,
  disable,
  groupTitleMaxLength,
  onCreateGroup,
  onFileSelect,
  isImageLoading,
  isSubmitting,
  imgSrc,
  fileSizeLimit,
  fileSizeError,
  emailExists,
  errorName,
  errorEmailText,
  onChange
}) => {
  const uploadedFile = file => {
    if(onFileSelect) {
      onFileSelect(file);
    }
  };

  return (
    <BodyContainer>
      <Container>
        <StyledLink to={groupsLink}>← Back to Groups</StyledLink>
        <StyledHeader>
          <StyledH1>Create Group</StyledH1>
        </StyledHeader>
        <Form
          onSubmit={onCreateGroup}
          render={({ handleSubmit, pristine }) => (
            <FormRow>
              <FormWrap onSubmit={handleSubmit}>
                <GroupImageContainer
                  isImageLoading={isImageLoading}
                  uploadedFile={uploadedFile}
                  imgSrc={imgSrc}
                  fileSizeLimit={fileSizeLimit}
                  fileSizeError={fileSizeError}
                />
                <FormContainer>
                  <Input maxLength={groupTitleMaxLength} error={errorName} name="name" label="Group name" />
                  <StyledH4>Invite people to join your group</StyledH4>
                  {
                    errorEmailText &&
                    <ErrorText>{errorEmailText}</ErrorText>
                  }
                  <Input name="email" type="email" label="Add email address" error={emailExists} customOnChange={onChange} />
                  <Input name="email1" type="email" label="Add email address" error={emailExists} customOnChange={onChange} />
                  <StyledP>As a free member you can add up to 2 people to your group.</StyledP>
                </FormContainer>
                <ButtonGroup>
                  <CancelButton onClick={goToGroups}>Cancel</CancelButton>
                  <CreateButton loading={isSubmitting} disabled={pristine || disable} isSubmit>Create group</CreateButton>
                </ButtonGroup>
              </FormWrap>
            </FormRow>
          )}/>
      </Container>  
    </BodyContainer>
  );
};

export default CreateGroup;
