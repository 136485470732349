import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query myAccountData {
    me {
      id
      teams {
        id
        availableRewards {
          id
          name
          description
          points
          startDate
          endDate
          isArchived
          s3Key
        }
      }
    }
  }
`;

export default function getRewardInventory() {
  return executeQuery(query, {});
}
