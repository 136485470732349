import React, { Component } from 'react';
import CompleteReward from 'pages/admin-dashboard/CompleteRewards.jsx';
import createReward from 'services/graphQL/mutations/admin/reward/createReward.js';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import getTeamIds from 'services/graphQL/queries/admin/teams/teamIds.js';
import lodash from 'lodash';
import pointValueEnum from './enums/pointValueEnum.js';
import { uploadFile } from 'services/s3/apiGatewayClient.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import moment from 'moment';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';
import { logEvent } from 'services/analytics/gaService.js';

const fileSizeLimit = 200000;

export default class AddRewardWithData extends Component {
  state = {
    isLoading: true,
    isSubmitting: false,
    disableSubmit: false,
    error: null,
    isImageLoading: false,
    imageKey: null,
    initialData: {
      rewardTitle: '',
      rewardDescription: '',
      duration: '1_WEEKS',
      pointValue: 'LOW_VALUE',
      startDate: new Date(),
    },
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchTeam();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchTeam() {
    getTeamIds()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const team = lodash.get(data, 'data.me.teams.0');

        if (team) {
          this.teamId = team.id;
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          isLoading: false,
          fetchError: error.message,
        });
      });
  }

  onCreateSubmit = ({ rewardTitle, rewardDescription, duration, pointValue, startDate }) => {
    const { history } = this.props;
    const { imageKey } = this.state;
    const teamId = this.teamId;
    const points = pointValueEnum[pointValue];
    const startDateInt = moment(startDate).unix();
    const weekNumber = parseInt(duration.split('_')[0]);
    const endDate = moment(startDate).add(weekNumber, 'weeks');
    const endDateInt = endDate.unix();

    this.setState({
      isSubmitting: true,
      initialData: {
        rewardTitle,
        rewardDescription,
        duration,
        pointValue,
        startDate,
      },
    });

    const rewardData = {
      teamId,
      name: rewardTitle,
      description: rewardDescription,
      points,
      startDate: startDateInt,
      endDate: endDateInt,
    };

    if (imageKey) {
      rewardData.s3Key = imageKey;
    }

    logEvent({
      category: 'Reward',
      action: 'add attempt',
      label: rewardTitle,
    });

    createReward(rewardData)
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const success = lodash.get(data, 'data.createTeamReward.success');
        const errors = lodash.get(data, 'data.createTeamReward.errors');

        this.setState({
          isSubmitting: false,
        });
        if (success) {
          logEvent({
            category: 'Reward',
            action: 'add success',
            label: rewardTitle,
          });

          successMessage('Reward created');

          history.push(routeEnums.REWARD_INVENTORY);
        } else {
          logEvent({
            category: 'Reward',
            action: 'add fail',
            label: rewardTitle,
          });

          errorMessage('Reward not created');

          this.setState({
            isSubmitting: false,
            error: errors[0],
          });
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        logEvent({
          category: 'Reward',
          action: 'add fail',
          label: rewardTitle,
        });

        errorMessage('Reward not created');

        this.setState({
          isSubmitting: false,
          error,
        });
      });
  };
  onValidate = data => {
    //TODO Handle validation if necessary
  };

  fileSizeError = () => {
    errorMessage('File must be max 200kB');
  };

  onFileSelect = base64String => {
    this.setState({
      disableSubmit: true,
      isImageLoading: true,
    });

    uploadFile(base64String, s3BucketTypes.REWARD)
      .then(uploadId => {
        if (!this.__isComponentMounted) {
          return;
        }
        this.setState({
          disableSubmit: false,
          isImageLoading: false,
          imageKey: uploadId,
        });

        successMessage('File uploaded');
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          disableSubmit: false,
          isImageLoading: false,
        });
        errorMessage('Error uploading file');
      });
  };

  onImageRemove = () => {
    this.setState({ imageKey: null });
  };

  render() {
    const {
      isLoading,
      isSubmitting,
      disableSubmit,
      error,
      isImageLoading,
      initialData,
      imageKey,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    let imageUrl = '';

    if (imageKey) {
      imageUrl = getS3ImageUrl(s3BucketTypes.REWARD, imageKey);
    }

    const addRewardProps = {
      title: 'Add Reward',
      rewardsInventoryLink: routeEnums.REWARD_INVENTORY,
      onValidate: this.onValidate,
      cancelLink: routeEnums.REWARD_INVENTORY,
      onSave: this.onCreateSubmit,
      errorText: error ? error.message : '',
      disabledSubmit: disableSubmit,
      isLoading: isSubmitting,
      titleMaxLength: 50,
      descriptionMaxLength: 100,
      fields: initialData,
      imageUrl,
      fileSizeLimit: fileSizeLimit,
      fileSizeError: this.fileSizeError,
      onFileSelect: this.onFileSelect,
      onImageRemove: this.onImageRemove,
      isImageLoading: isImageLoading,
    };

    return <CompleteReward {...addRewardProps} />;
  }
}
