import React from 'react';
import { Container, HoverBg, Wrapper } from 'components/design-components/dropdown-list/styled';
import styled from 'styled-components';

const StyledHoverBg = styled(HoverBg)`
  width: calc(100% - 15px);
  left: 8px;
`;

const ResultItem = ({ label }) => {
  return (
    <Wrapper>
      <StyledHoverBg />
      <Container>{label}</Container>
    </Wrapper>
  );
};

ResultItem.propTypes = {};

export default React.memo(ResultItem);
