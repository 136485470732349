import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import NavDropdown from 'components/custom/nav-bar/components/NavDropdown';
import { BorderContainer, DropdownItem, NavLabel } from 'components/custom/nav-bar/components/styled';
import { Account } from 'components/design-components/icons';
import styled from 'styled-components';

const AccountLabel = styled(NavLabel)`
  display: flex;
  align-items: center;
`;

const SvgIcon = styled(Account)`
  display: block;
  margin-right: 5px;
  margin-bottom: 2px;
  path {
    transition: fill 0.3s linear;
  }
`;

const UserInfo = ({ userProfileRoute, onSignOut, userDropDown, routeLabel }) => (
  <NavDropdown
    route={userProfileRoute}
    routeLabel={
      <AccountLabel>
        <SvgIcon />
        {routeLabel}
      </AccountLabel>
    }
    exact
  >
    {userDropDown.map(item => (
      <DropdownItem key={uniqid()}>{item}</DropdownItem>
    ))}
    <BorderContainer cursorShow hoverShow borderTop onClick={onSignOut}>
      Sign out
    </BorderContainer>
  </NavDropdown>
);

UserInfo.propTypes = {
  onSignOut: PropTypes.func.isRequired,
  userProfileRoute: PropTypes.string.isRequired,
  userDropDown: PropTypes.array.isRequired,
  routeLabel: PropTypes.string.isRequired,
};

UserInfo.defaultProps = {
  userDropDown: [],
};

export default React.memo(UserInfo);
