import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MainContainer } from 'components/design-components';
import NavList from 'components/custom/nav-bar/components/NavList';
import Hamburger from './components/Hamburger';
import NavLogo from './components/NavLogo';
import AuthNavigation from 'components/custom/nav-bar/components/AuthNavigation';
import SideBar from './components/SideBar';
import { Groups } from 'components/design-components/icons';
import { NavWrapper, Navigation, HamburgerContainer, LeftSide, HeaderTitle, IconBack, RightIcon } from './components/styled';

const Navbar = ({
  logoRoute,
  showNavList,
  isAuth,
  signIn,
  signUp,
  headerTitle,
  backTo,
  historyBack,
  createGroup,
  createGroupRoute,
  ...rest
}) => {
  const [navbarOpened, setNavbarOpened] = useState(false);

  function handleToggleNavbar() {
    setNavbarOpened(!navbarOpened);
  }
  function openSideBar() {
    setNavbarOpened(true);
  }
  function closeSideBar() {
    setNavbarOpened(false);
  }

  return (
    <NavWrapper>
      <MainContainer>
        <Navigation showNavList={showNavList} isAuth={isAuth}>
          <LeftSide>
            <NavLogo to={logoRoute} isAuth={isAuth} />
            {!isAuth && !backTo && (
              <HamburgerContainer>
                <Hamburger opened={navbarOpened} onClick={openSideBar} />
              </HamburgerContainer>
            )}
            {
              backTo &&
              <IconBack onClick={historyBack}>
                <i className="mdi mdi-chevron-left"/>
              </IconBack>
            }
            {headerTitle && <HeaderTitle>{headerTitle}</HeaderTitle>}
          </LeftSide>
          {
            createGroup && <RightIcon to={createGroupRoute}><Groups /></RightIcon>
          }
          {showNavList && <NavList {...rest} />}
          {isAuth && <AuthNavigation signIn={signIn} signUp={signUp} />}
        </Navigation>
      </MainContainer>
      {
        !isAuth && navbarOpened &&
        <SideBar {...rest} opened={navbarOpened} closeSideBar={closeSideBar} onClick={handleToggleNavbar} />
      }
    </NavWrapper>
  );
};

Navbar.propTypes = {
  isEquelOn: PropTypes.bool,
  isAuth: PropTypes.bool,
  showNavList: PropTypes.bool,
  equelOptionsOffline: PropTypes.array,
  userDropDown: PropTypes.array,
  onSignOut: PropTypes.func,
  userProfileRoute: PropTypes.string,
  logoRoute: PropTypes.string,
  dashboardRoute: PropTypes.string,
  userRouteLabel: PropTypes.string,
  signIn: PropTypes.string,
  signUp: PropTypes.string,
};

Navbar.defaultProps = {
  showNavList: true,
  signIn: '',
  signUp: '',
};

export default Navbar;
