import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { Button } from 'components/design-components';
import { StyledContainer, DefaultIcon, IconLogo, LeftStyled, EditButton } from '../styled';
import { IconEdit } from 'components/design-components/icons';
import SimpleLogo from 'public/logo/icon_logo.svg';
import { media, theme } from 'components/design-components/config';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { getS3RewardImages } from 'services/s3/s3ImageUrl';
import joinGroup from 'services/graphQL/mutations/groups/joinGroup';
import rejectGroup from 'services/graphQL/mutations/groups/rejectGroup';
import lodash from 'lodash';

const StyledText = styled.div`
  padding: 0 15px;

  h3 {
    margin: 0 5px 0 0;
  }
  span {
    line-height: 20px;
  }
  p {
    margin: 0;

    ${media.tablet`
      font-size: 14px;
    `}
  }  
`;

const DenyButton = styled.a`
  font-weight: bold;
  text-decoration: underline;
  padding: 18px 20px;
  display: block;
  line-height: 16px;
  margin-bottom: 14px;
  flex: 1;

  i {
    display: none;
  }

  ${media.tablet`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 0;
    border: 1px ${theme.colors.dark_grey} solid;
    text-align: center;
    line-height: 34px;
    margin-bottom: 0;
    margin-right: 12px;
    font-size: 20px;

    i {
      display: block;
    }

    &:hover {
      background-color: #505050;
      color: ${theme.colors.true_white};
    }

    span {
      display: none
    }
  `}
`;

const AcceptButton = styled(Button)`
  flex: 1;
  i:not(.mdi-loading) {
    display: none;
  }

  ${media.tablet`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    font-size: 20px;

    i {
      display: block!important;
    }

    span {
      display: none
    }
  `}
`;

const TextRow = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 5px;
  flex-wrap: wrap;
  flex: 1;

  ${media.tablet`
    font-size: 14px;
    line-height: 22px;
  `}
`;

const InviteImage = styled(DefaultIcon)`
  background-image: ${props => props.imgSrc && `url(${props.imgSrc})`};
  background-size: cover;
  background-position: center center;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 270px;
  ${media.tablet`
    width: auto
  `}
`;

const InviteItem = ({
  myId,
  group={},
  invite,
  onEditGroup,
  invitingGroup,
  updateInviteStatus,
  rejectInvite,
  onViewGroup,
  myGroup
}) => {
  const { groupInvites=[] } = group;
  const pendingInvites = groupInvites.filter(invite => invite.accepted === null).length;
  const [loading, setLoading] = useState(false);
  const onAcceptInvite = () => {
    const joinData = {
      inviteUuid: invite.uuid
    };
    setLoading(true);
    joinGroup(joinData)
      .then(data => {
        const success = lodash.get(data, 'data.joinGroupFromUuid.success') || false;
        const groupData = lodash.get(data, 'data.joinGroupFromUuid.groupInvite.group');
        const groupIdInvite = lodash.get(data, 'data.joinGroupFromUuid.groupInvite.id');
        const acceptedInvite = lodash.get(data, 'data.joinGroupFromUuid.groupInvite.accepted');
        const inviteData = {
          id: groupIdInvite,
          accepted: acceptedInvite
        };

        if(success) {
          updateInviteStatus(groupData, inviteData);
          setLoading(false);
        }
      })
      .catch(err => console.log(err));
  };

  const onRejectInvite = () => {
    const rejectData = {
      inviteId: invite.id
    };

    rejectGroup(rejectData)
      .then(data => {
        const success = lodash.get(data, 'data.rejectGroupInvitation.success');
        const inviteId = lodash.get(data, 'data.rejectGroupInvitation.group.id');
        const groupId = {
          id: inviteId
        };
        if(success) {
          rejectInvite(groupId);
        }
      })
      .catch(err => console.log(err));
  };

  const editGroup = e => {
    e.stopPropagation();
    onEditGroup(group.id, true);
  };

  const viewGroup = () => {
    onViewGroup(group.id, true);
  };

  const imageUrl = getS3RewardImages(s3BucketTypes.GROUP, group.s3Key || invite && invite.group.s3Key);

  return (
    <StyledContainer onClick={onViewGroup && viewGroup} viewGroup={onViewGroup}>
      <LeftStyled>
        <InviteImage imgSrc={imageUrl}>
          <IconLogo src={SimpleLogo} />
        </InviteImage>
        <StyledText>
          <TextRow>
            {
              invite &&
              <Fragment>
                <h3>{invitingGroup.familyName} {invitingGroup.givenName}</h3>
                <span>invited you</span>
              </Fragment>
            }
          </TextRow>
          <p>{invite && 'to Group'} <b>{group.name || invite && invite.group.name}</b></p>
          { group.groupProfiles && <span>{group.groupProfiles.length + pendingInvites} members</span> }
        </StyledText>
      </LeftStyled>
      {
        group.groupProfiles && group.groupProfiles.map(profile => {
          return (
            <Fragment key={profile.profile.id}>
              {
                profile.profile.id === myId && profile.isAdmin &&
                <EditButton href="javascript:;" onClick={editGroup}>
                  <IconEdit />
                </EditButton>
              }
            </Fragment>
          );
        })
      }
      {
        invite &&
        <ButtonGroup>
          <DenyButton href="javascript:;" onClick={onRejectInvite}>
            <span>No thanks</span>
            <i className="mdi mdi-close" />
          </DenyButton>
          <AcceptButton loading={loading} onClick={onAcceptInvite}>
            <span>Accept</span>
            <i className="mdi mdi-check" />
          </AcceptButton>
        </ButtonGroup>
      }
    </StyledContainer>
  );
};

export default InviteItem;
