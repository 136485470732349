import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      success
      errors
      group {
        id
        name
        type
        code
        slug
        rules
      }
    }
  }
`;

export default function createGroup({name, s3Key}) {
  return executeMutation(mutation, { input: { name, s3Key } });
}
