import React from 'react';
import { ActivityCalendar } from 'components/design-components';
import { splitTimesToNonDays } from 'utils/time';
import moment from 'moment';

const Calendar = ({ activity, ...rest }) => {
  const newActivity = splitTimesToNonDays(activity).filter(({ start, end }) => {
    const minutes = moment(end).diff(moment(start), 'minutes');
    return minutes > 15;
  });
  return <ActivityCalendar activity={newActivity} {...rest} />;
};

Calendar.propTypes = ActivityCalendar.propTypes;

export default Calendar;
