import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation RemoveTeamProfile($input: RemoveTeamProfileInput!) {
    removeTeamProfile(input: $input) {
      success
      errors
    }
  }
`;

export default function removeTeamProfile(input) {
  return executeMutation(mutation, { input });
}
