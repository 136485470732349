import routeEnums from 'componentsWithData/router/routeEnums.js';
import routeTitle from 'componentsWithData/router/routeTitle.js';

export default function(currentLocation) {
  let title;
  let back;
  let createGroup;

  const getGroupId = currentLocation.split('/').pop();
  
  switch(currentLocation) {
  case routeEnums.HOME:
    title = routeTitle.activity;
    break;
  case routeEnums.EDIT_PROFILE:
    title = routeTitle.editProfile;
    break;
  case routeEnums.DEVICES:
    title = routeTitle.devices;
    break;
  case routeEnums.REWARD_HISTORY: 
    title = routeTitle.rewardHistory;
    break;
  case routeEnums.LIST_OF_REWARDS:
    title = routeTitle.rewards;
    break;
  case routeEnums.GROUPS:
    title = routeTitle.groups;
    createGroup = true;
    break;
  case routeEnums.EDIT_GROUP_ITEM + '/' + getGroupId:
    title = routeTitle.editGroup;
    back = true;
    break;
  case routeEnums.VIEW_GROUP_ITEM + '/' + getGroupId:
    back = true;
    break;
  case routeEnums.CREATE_GROUP:
    title = routeTitle.createGroup;
    back = true;
    break;
  case routeEnums.EMAIL_PREFERENCES:
    title = routeTitle.notifications;
    break;
  default:
    title = null;
    back = false;
    createGroup = false;
  }

  return { title, back, createGroup };
}