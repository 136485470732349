import React from 'react';
import { Link } from 'react-router-dom';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';

const StyledLink = styled(Link)`
  cursor: pointer;
  width: fit-content;
  &:hover {
    color: ${theme.colors.equell_teal};
    text-decoration: none;
  }
`;

export default function(withAdmin) {
  const links = [
    <StyledLink to={routeEnums.EDIT_PROFILE}>Profile</StyledLink>,
    <StyledLink to={routeEnums.DEVICES}>Devices</StyledLink>,
    <StyledLink to={routeEnums.EMAIL_PREFERENCES}>Notifications</StyledLink>,
    <StyledLink to={routeEnums.REWARD_HISTORY}>Reward History</StyledLink>,
  ];

  if (withAdmin) {
    links.push(<StyledLink to={routeEnums.ADMIN_MEMBERS}>Team Admin</StyledLink>);
  }
  return links;
}
