import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Image, H1, H4, P, Button } from 'components/design-components';
import { theme } from 'components/design-components/config';
import { media } from 'components/design-components/config';

export const StyledHeaderContent = styled.div`
  ${media.tablet`
    display: none;
  `}
`;

export const Text = styled(H1)`
  margin-bottom: 35px;
  margin-top: 50px;

  span {
    font-weight: 400;
  }

  ${media.tablet`
    font-size: 16px;
    padding-left: 15px;
    margin-top: 30px;
  `}
`;

export const StyledContainer = styled.div`
  background-color: ${theme.colors.true_white};
  border-bottom: 1px ${theme.colors.light_grey} solid;
  padding: 35px 45px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.viewGroup && `
    cursor: pointer;

    &:hover {
      background: #f3f3f3;
    }
  `}

  ${media.tablet`
    padding-right: 15px;
    padding-top: 15px;
  `}
`;

export const DefaultIcon = styled.div`
  width: 52px;
  height: 52px;
  background-color: #f2e1d9;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconLogo = styled(Image)`
  width: 32px;
  height: 32px;
`;

export const LeftStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const EditButton = styled.a`
  color: ${theme.colors.equell_teal};
  display: inline-block;
  padding: 5px 15px;
  font-size: 20px;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0;
    height: 1px;
  }

  &:hover:before {
    background-color: ${theme.colors.equell_teal};
  }
  
  svg {
    fill: currentColor;
    vertical-align: middle;
  }
`;

// Styled components Create/Edit group;
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.dark_grey}

  ${media.tablet`
    display: none;
  `}
`;

export const Container = styled.div`
  padding-top: 55px;
  padding-bottom: 95px;

  ${media.tablet`
    padding-top: 0;
  `}
`;

export const StyledH1 = styled(H1)`
  margin: 0;

  ${media.tablet`
    font-size: 20px;
  `}
`;

export const StyledH4 = styled(H4)`
  margin-top: 50px;
  margin-bottom: 15px;
  padding: 0 8px;

  ${media.tablet`
    padding: 0;
    font-size: 14px;
    margin-top: 25px;
    font-weight: 400;
    color: ${theme.colors.grey}
  `}
`;

export const StyledP = styled(P)`
  padding: 0 8px;
  font-size: 14px;
  line-height: 20px;

  ${media.tablet`
    margin-bottom: 70px;
    padding: 0;
  `}
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${media.tablet`
    justify-content: space-between;
    margin: 0 auto;
    width: 450px;
    max-width: 100%;
  `}
`;

export const FormContainer = styled.div`
  width: 450px;
  max-width: 100%;

  ${media.tablet`
    margin: 0 auto;
  `}
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.tablet`
    box-shadow: 0 0 46px -10px rgba(109, 50, 50, 0.24);
    background-color: ${theme.colors.true_white};
    padding-bottom: 37px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 50px;
  `}
`;

export const FormWrap = styled.form`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  ${media.tablet`flex-wrap: wrap;`}
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 95px;

  ${media.tablet`
    display: none;
  `}
`;

export const CancelButton = styled.div`
  padding: 16px 40px;
  line-height: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  
  ${media.tablet`
    flex: 1;
    padding: 16px 30px;
  `}
`;

export const CreateButton = styled(Button)`
  margin: 0;
  min-width: 170px;
  ${media.tablet`
    flex: 1;
    min-width: auto;
  `}
`;

export const DeleteInviteButton = styled(Button)`
  font-size: 24px;
  position: absolute;
  top: 50%;
  right: 0;
  padding: 5px 10px;
  cursor: pointer;
  transform: translateY(-50%);
  color: ${theme.colors.grey}
  border: 0;
  outline: 0;
  margin: 0;

  ${media.tablet`
    width: auto;
  `}

  &:hover {
    background-color: transparent!important;
    color: ${theme.colors.dark_grey}!important;
  }
`;

export const FormHelper = styled.div`
  width: 100%;
  position: relative;
`;

export const ErrorText = styled.div`
  color: #AE0015;
  font-size: 14px;
  margin-bottom: 10px;
`;

// Styled components group view
export const ViewGroupStyled = styled.div`
  ${media.phone`
    box-shadow: 0 0 46px -10px rgba(109, 50, 50, 0.24);
    background-color: #ffffff;
    padding-bottom: 50px;
    margin-top: 50px;
  `}
`;

export const GroupHeaderWrap = styled.div`
  width: 100%;
  padding-top: 40px;

  ${media.phone`
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px; 
  `}
`;

export const GroupImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 5px;

  ${media.phone`
    width: 80px;
    height: 80px;
  `}
`;

export const GroupInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  ${media.phone`
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: -40px;
  `}
`;

export const GroupTitle = styled.h1`
  font-size: 36px;
  line-height: 40px;
  color: ${theme.colors.dark_grey};
  margin: 0;

  ${media.phone`
    font-size: 20px;
    line-height: 30px;
    white-space: nowrap;
    max-width: calc(100% - 50px - 95px);
    text-overflow: ellipsis;
    overflow: hidden;
  `}
`;

// Styled Members Table
export const Table = styled.div`
  background: ${theme.colors.true_white};
`;

export const TableHeader = styled.div`
  padding: 12px 15px;
  display: flex;

  ${media.tablet`
    display: none;
  `}
  
  span {
    font-size: 14px;
    color: ${theme.colors.grey};
    padding-right: 10px;

    &:first-of-type {
      width: 30%;
    }
    &:nth-of-type(2) {
      width: 30%;
    }
    &:nth-of-type(3) {
      width: 15%;
    }
    &:nth-of-type(4) {
      width: 15%;
    }
  }
`;

export const TableRowStyled = styled.div`
  padding: 15px;
  border-bottom: 1px #f2e1d9 solid;
  display: flex;

  ${media.tablet`
    padding: 10px;
  `}
`;

export const TabelCell = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const MemberImg = styled.img`
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 50%;
  ${media.tablet`
    width: 45px;
    height: 45px;
  `}
`;

export const MemberIcon = styled.div`
  width: 52px;
  height: 52px;
  background-color: ${theme.colors.switch_off};
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  line-height: 56px;
  overflow: hidden;

  ${media.tablet`
    width: 45px;
    height: 45px;
    line-height: 51px;
    font-size: 20px;
  `}
`;

export const MemberInfo = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  padding-right: 10px;

  ${media.tablet`
    width: 55px;
  `}
`;

export const MemberName = styled.h3`
  font-size: 20px;
  line-height: 22px;
  color: ${theme.colors.dark_grey};
  padding-left: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${media.tablet`
    display: none;
  `}
`;

export const MemberEmail = styled(TabelCell)`
  width: 30%;

  ${media.tablet`
    width: calc(100% - 74px - 55px);
    flex-wrap: wrap;
    flex: 1;
  `}
`;

export const MemberPoints = styled(TabelCell)`
  width: 15%;

  ${media.tablet`
    display: none;
  `}
`;

export const MemberRole = styled(TabelCell)`
  width: 15%;
  text-transform: uppercase;
  
  ${props => props.hiddenDesktop && `
  display: none;
  `}
  ${media.tablet`
    font-size: 12px;
    width: 100%;
    ${props => props.hiddenDesktop && `
      display: block;
    `}
    ${props => props.hiddenMobile && `
      display: none;
    `}
  `}
`;

export const MemberAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10%;
  padding-right: 0;

  ${media.tablet`
    width: auto;
    ${props => props.hiddenMobile && `
      display: none
    `}
  `}
`;

export const RemoveMember = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${theme.colors.switch_off};
  text-align: center;
  line-height: 34px;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
`;

export const MemberFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  margin-bottom: 70px;

  ${media.tablet`
    flex-wrap: wrap;
  `}
`;

export const RemoveAllBtn = styled.div`
  ${props => props.hiddenDesktop && 'display:none'}
  ${media.tablet`
    text-align: center;
    width: 100%;
    margin-top: 50px;
    ${props => props.hiddenDesktop && 'display:block'}
  `}
`;

export const GroupInfoP = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 16px;
`;