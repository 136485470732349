import React from 'react';
import styled, { css } from 'styled-components';
import { media, theme } from 'components/design-components/config';
import Link from 'components/design-components/typography/Link';
import { EmailIconNotifcation, Facebook, Twitter } from 'components/design-components/icons';
import PropTypes from 'prop-types';

const Container = styled.div`
  min-height: 127px;
  max-width: 660px;
  border-radius: 4px;
  font-size: ${theme.fontSize.normal};
  line-height: 2;
  box-shadow: 0 0 10px 0 ${theme.colors.warm_shadow};
  background-color: ${theme.colors.true_white};
  outline: none;
  width: 100%;
  padding: 24px 30px 12px;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ShareLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.phone(css`
    display: block;
    margin: 0 auto;
  `)};
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 20px;
    margin-right: 10px;
  }
  ${media.phone(css`
    padding-bottom: 10px;
  `)};
`;

const SocialShare = ({ fields }) => {
  const { firstName } = fields;
  const subject = `${firstName} is inviting you to equell`;
  const body =
    `Hello%2c %0D%0A%0D%0A` +
    `I’ve been using equell and thought you might like it. It’s a simple way to  increase focus while being rewarded for doing so.%0D%0A` +
    'Get on the waitlist at https%3A%2F%2F.equell.com %0D%0A%0D%0A' +
    'If you do not wish to respond to this email%2c you can simply safely ignore or delete this email, safe in the knowledge nothing further will happen.';
  const IOSBody =
    'Hi, I’ve been using equell and thought you might like it. It’s a simple way to increase focus while being rewarded for doing so. Get on the waitlist at https://www.equell.com';
  return (
    <Container>
      <ShareLinksContainer>
        <LinkContainer>
          <EmailIconNotifcation style={{ fontSize: '30px' }} />
          <Link
            reactLink={false}
            underlined
            href={`mailto:?subject=${subject}&body=${IOSBody}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Share by Email
          </Link>
        </LinkContainer>
        <LinkContainer>
          <Facebook />
          <Link
            reactLink={false}
            underlined
            href="https://www.facebook.com/sharer/sharer.php?u=http://www.equell.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Share on Facebook
          </Link>
        </LinkContainer>
        <LinkContainer>
          <Twitter />
          <Link
            reactLink={false}
            underlined
            href="https://twitter.com/intent/tweet?text=Hi, I’ve been using equell and thought you might like it. It’s a simple way to increase focus while being rewarded for doing so. Get on the waitlist at https://www.equell.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Share on Twitter
          </Link>
        </LinkContainer>
      </ShareLinksContainer>
      <div>To thank you for inviting your friend to equell, you will receive an award badge. </div>
    </Container>
  );
};

SocialShare.propTypes = {
  fields: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(SocialShare);
