import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';

const Container = styled.div`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: ${theme.colors.grey};
`;

const Title = styled.div`
  font-size: ${theme.fontSize.xsmallNormal};
  text-align: center;
`;

const Description = styled.div`
  width: 210px;
  padding-top: 10px;
  font-size: 13px;
  text-align: justify;
`;

const BedtimeMode = ({ time }) => {
  return (
    <Container>
      {time}
      <Title>BEDTIME</Title>
      <Description>
        A good night's sleep is something we all value. During bedtime  11:00 pm - 5:00 am you can
        use  equell and collect badges but you  will not earn points.
      </Description>
    </Container>
  );
};

BedtimeMode.propTypes = {
  time: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default React.memo(BedtimeMode);
