import React from 'react';
import DevicesList from './components/devices/DevicesList';
import { BodyContainer } from 'components/design-components';
import PropTypes from 'prop-types';

const Devices = ({ devices, title, subTitle }) =>
  devices.length ? (
    <BodyContainer title={title} subTitle={subTitle}>
      <DevicesList devices={devices} />
    </BodyContainer>
  ) : (
    <BodyContainer title="No devices" centeredTitle />
  );

Devices.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  devices: DevicesList.propTypes.devices,
};

Devices.defaultProps = {
  devices: [],
};

export default React.memo(Devices);
