import React from 'react';

const SvgIconEdit = props => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
    <path
      fillRule="evenodd"
      d="M21.309 3.182L18.857.692a2.357 2.357 0 0 0-3.331 0L3.37 12.798a2.42 2.42 0 0 0-.566.927L.063 21.937l8.218-2.742c.345-.119.659-.312.92-.566L21.309 6.514a2.358 2.358 0 0 0 0-3.332zm-16.5 10.434l6.875-6.867 1.241 1.242-6.899 6.875-1.217-1.25zm7.951-7.982l1.25-1.242 3.598 3.599-1.242 1.249-3.606-3.606zm-8.753 9.444l2.923 2.915-4.376 1.453 1.453-4.368zm4.377 2.145l-1.25-1.25 6.875-6.867 1.242 1.242-6.867 6.875zM20.169 5.437l-1.477 1.477-3.575-3.638L16.594 1.8a.786.786 0 0 1 .558-.228.79.79 0 0 1 .558.228l2.49 2.499a.786.786 0 0 1 0 1.108l-.03.031z"
    />
  </svg>
);

export default SvgIconEdit;
