import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

const HelperDefaultRowContainer = ({ columnWidth, children, withMarginTop }) => {
  return (
    <div
      className="container-fluid"
      style={{ marginTop: typeof withMarginTop === 'number' ? withMarginTop : 100 }}
    >
      <div className="row">
        <div className={classname('offset-md-' + (12 - columnWidth) / 2, 'col-md-' + columnWidth)}>
          {children}
        </div>
      </div>
    </div>
  );
};

HelperDefaultRowContainer.propTypes = {
  children: PropTypes.node.isRequired,
  columnWidth: PropTypes.number.isRequired,
  withMarginTop: PropTypes.bool,
};
HelperDefaultRowContainer.defaultProps = {
  columnWidth: 6,
  withMarginTop: false,
};
export default HelperDefaultRowContainer;
