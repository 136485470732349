import React from 'react';

const SvgRightArrow = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h18v18H0z" />
      <path
        fill="#1AB38E"
        d="M12.883 9.351a.677.677 0 0 0 0-.76L10.32 5.25c-.194-.266-.621-.342-.893-.114a.646.646 0 0 0-.272.494c0 .114.04.266.117.38l1.786 2.355H5.66c-.35 0-.66.266-.66.645 0 .38.272.646.66.646h5.476L9.35 12.01c-.195.266-.156.684.155.874.272.19.699.152.893-.152l2.485-3.38z"
      />
    </g>
  </svg>
);

export default SvgRightArrow;
