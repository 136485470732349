import React from 'react';

const SvgSignout = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 17" {...props}>
    <path
      fillRule="evenodd"
      d="M9 0H7v10h2V0zm3.56 2.44l-1.45 1.45A5.969 5.969 0 0 1 14 9c0 3.31-2.69 6-6 6s-6-2.69-6-6c0-2.17 1.16-4.06 2.88-5.12L3.44 2.44A7.961 7.961 0 0 0 0 9c0 4.42 3.58 8 8 8s8-3.58 8-8c0-2.72-1.36-5.12-3.44-6.56z"
    />
  </svg>
);

export default SvgSignout;
