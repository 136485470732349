import {
  EmailIconNotifcation,
  ErrorIconNotifcation,
  QuestionIconNotifcation,
  TickIconNotification,
} from 'components/design-components/icons/notificationIcons';

const toastrType = {
  SUCCESS: 'success',
  ERROR: 'error',
  EMAIL: 'email',
  QUESTION: 'questions',
};

export default {
  [toastrType.SUCCESS]: TickIconNotification,
  [toastrType.ERROR]: ErrorIconNotifcation,
  [toastrType.EMAIL]: EmailIconNotifcation,
  [toastrType.QUESTION]: QuestionIconNotifcation,
};

export { toastrType };
