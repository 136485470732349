import React from 'react';
import { ErrorContainer } from '../../components/index';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Button, H2, H4, Image, Input, Link, Panel } from '../../components/design-components';
import logo from 'public/logo/simple_logo.svg';
import { theme } from 'components/design-components/config';
import styled from 'styled-components';

const InputContainer = styled.div`
  margin-bottom: 20px;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledPannel = styled(Panel)`
  width: 560px;
`;

const ResetPassword = ({
  onSubmit,
  errorText,
  signInLink,
  disabledSubmit,
  onValidate,
  isLoading,
}) => (
  <Container>
    <StyledPannel>
      <Image src={logo} isAvatar style={{ width: 60, height: 60 }} />
      <H2
        style={{
          margin: '35px 0',
        }}
      >
        Forgot your password?
      </H2>
      <H4 style={{ fontSize: 17, marginBottom: 35, color: theme.colors.grey, fontWeight: 'bold' }}>
        We will send you an email with a password reset link
      </H4>
      <Form
        onSubmit={onSubmit}
        validate={onValidate}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <Input name="email" type="email" label="Email address" />
            </InputContainer>
            <Button loading={isLoading} disabled={pristine || disabledSubmit} isSubmit fullwidth>
              Reset Password
            </Button>
          </form>
        )}
      />

      <ErrorContainer>{errorText}</ErrorContainer>
      <Link style={{ marginTop: '44px', fontSize: 16 }} to={signInLink}>
        <i className="mdi mdi-arrow-left" /> Return to Sign In
      </Link>
    </StyledPannel>
  </Container>
);

ResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  changedSuccessfully: PropTypes.bool,
  isLoading: PropTypes.bool,
  errorText: PropTypes.string,
  disabledSubmit: PropTypes.bool,
  signInLink: PropTypes.string,
};

ResetPassword.defaultProps = {
  errorText: '',
  disabledSubmit: false,
  changedSuccessfully: false,
  isLoading: false,
};

export default React.memo(ResetPassword);
