import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation JoinGroup($input: JoinGroupFromUuidInput!) {
    joinGroupFromUuid(input: $input) {
      success
      errors
      groupInvite {
        id
        accepted
        group {
          id
          name
          s3Key
          groupProfiles {
            isAdmin
            profile {
              id
            }
          }
        }
      }
    }
  }
`;

export default function joinGroup({inviteUuid}) {
  return executeMutation(mutation, { input: { inviteUuid } });
}
