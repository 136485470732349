import React, { Component } from 'react';
import Notification from 'pages/my-account/Notification.jsx';
import getEmailPreferences from 'services/graphQL/queries/userSettings/emailPreferencesData.js';
import updateNotifications from 'services/graphQL/mutations/userSettings/updateNotifications.js';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import lodash from 'lodash';
import uniqid from 'uniqid';
import { logEvent } from 'services/analytics/gaService.js';

const subTitle = [
  { id: uniqid(), text: 'Notification alerts will only appear on your device when it is in use.' },
  { id: uniqid(), text: 'And you won’t get alerts on your device if equell is enabled.' },
];

export default class EmailPreferencesWithData extends Component {
  state = {
    isLoading: true,
    isSaving: false,
    error: '',
    saveError: '',
    notificationsData: {},
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchData() {
    this.setState({
      isLoading: true,
    });

    getEmailPreferences()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const notifications = lodash.get(data, 'data.me.notificationPreferences');

        this.setState({
          isLoading: false,
          notificationsData: { notificationPreferences: notifications },
        });
      })
      .catch(err => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          error: err.message,
          isLoading: false,
        });
      });
  }

  cleanNotifications = notificationPreferences => {
    return notificationPreferences.map(({ name, value }) => ({
      id: this.extractRewardId(name),
      value,
    }));
  };

  getChangedNotification(data) {
    const { notificationsData } = this.state;

    return notificationsData.notificationPreferences.find(notification => {
      const foundNot = data.find(not => not.id === notification.id);

      return foundNot.value !== notification.value;
    });
  }

  changeStateNotifications(notificationToChange) {
    this.setState((state, props) => {
      const { notificationsData } = state;

      notificationsData.notificationPreferences.find(notification => {
        if (notification.id === notificationToChange.id) {
          notification.value = !notification.value;
        }
      });

      return { notificationsData };
    });
  }

  saveNotifications = data => {
    this.setState({ isSaving: true });

    const cleanNotifications = this.cleanNotifications(data);
    const updateData = {
      notificationPreferences: cleanNotifications,
    };

    const changedNotification = this.getChangedNotification(cleanNotifications);

    if (changedNotification) {
      this.changeStateNotifications(changedNotification);

      logEvent({
        category: 'Notification',
        action: changedNotification ? 'on' : 'off',
        label: changedNotification.name,
      });
    }

    updateNotifications(updateData)
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const success = lodash.get(data, 'data.updateProfile.success');
        const errors = lodash.get(data, 'data.updateProfile.errors');

        this.setState({ isSaving: false });

        if (success) {
          //TODO Maybe add notification
        } else {
          this.setState({
            saveError: errors[0],
          });
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          isSaving: false,
          saveError: error.message,
        });
      });
  };

  formatInputName(rewardId) {
    return `reward__${rewardId}`;
  }

  extractRewardId(name) {
    return name.split('reward__')[1];
  }

  render() {
    const { isLoading, isSaving, notificationsData, error } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return <div>{error}</div>;
    }

    const notificationPreferences = notificationsData.notificationPreferences || [];

    const notificationProps = {
      options: notificationPreferences.map(option => ({
        name: this.formatInputName(option.id),
        label: option.name,
        value: option.value,
      })),
      onSave: this.saveNotifications,
      title: 'Notifications',
      subTitle,
    };

    return <Notification {...notificationProps} />;
  }
}
