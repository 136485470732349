import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ToolbarIndicator from './ToolbarIndicator';
import styled from 'styled-components';

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const CustomToolbar = ({ lastDateUsed, firstDateUsed, ...toolbar }) => {
  // const [currentDate, setCurrentDate] = useState(Object.assign({}, toolbar.date))
  const [currentDate, setCurrentDate] = useState(toolbar.date);

  const toolbarSetDate = dateObj => {
    toolbar.date.setTime(dateObj.getTime());
    setCurrentDate(toolbar.date);
  };

  const goToBack = () => {
    const newDate = moment(toolbar.date)
      .subtract(1, 'week')
      .toDate();
    toolbarSetDate(newDate);
    toolbar.onNavigate('prev');
  };

  const goToNext = () => {
    const newDate = moment(toolbar.date)
      .add(1, 'week')
      .toDate();
    toolbarSetDate(newDate);
    toolbar.onNavigate('next');
  };

  return (
    <ToolbarWrapper>
      <ToolbarIndicator
        firstDateUsed={firstDateUsed}
        lastDateUsed={lastDateUsed}
        currentDate={currentDate}
        onClick={goToBack}
      />
      <ToolbarIndicator
        firstDateUsed={firstDateUsed}
        lastDateUsed={lastDateUsed}
        currentDate={currentDate}
        onClick={goToNext}
        forNextWeek
      />
    </ToolbarWrapper>
  );
};

CustomToolbar.propTypes = {
  lastDateUsed: PropTypes.instanceOf(Date).isRequired,
  firstDateUsed: PropTypes.instanceOf(Date).isRequired,
};

export default CustomToolbar;
