import React, { Component } from 'react';
import lodash from 'lodash';
import CreateGroup from 'pages/groups/CreateGroup';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import createGroup from 'services/graphQL/mutations/groups/createGroup';
import createGroupInvites from 'services/graphQL/mutations/groups/createGroupInvites';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';
import { uploadFile } from 'services/s3/apiGatewayClient.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';

class CreateGroupWithData extends Component {
  constructor() {
    super();
    this.state = {
      imageKey: null,
      isImageLoading: false,
      isSubmitting: false,
      disable: false,
      emailExists: false,
      errorName: false,
      errorEmailText: '',
    }
  }

  fileSizeError = () => {
    errorMessage('File must be max 200kB');
  };

  goToGroups = () => {
    const { history } = this.props;

    history.push(routeEnums.GROUPS);
  }

  onFileSelect = base64String => {
    this.setState({
      isImageLoading: true,
      disable: true,
    });
    uploadFile(base64String, s3BucketTypes.GROUP)
      .then(uploadId => {
        this.setState({
          imageKey: uploadId,
          isImageLoading: false,
          disable: false,
        });
        successMessage('Image uploaded, successfully!');
      })
      .catch(error => {
        this.setState({
          isImageLoading: false,
          disable: false,
        })
        errorMessage('Error uploading file');
      });
  }

  onCreateGroup = ({name='', email='', email1=''}) => {
    const { imageKey } = this.state;
    
    this.setState({
      isSubmitting: true,
      disable: true,
    });

    if(!imageKey) {
      errorMessage('Choose a file');
    }

    if(name.trim() === '') {
      this.setState({
        isSubmitting: false,
        disable: false,
        errorName: true,
      });
      return;
    }

    if(email === email1 && (email.trim() || email.trim() !== '')){
      this.setState({
        isSubmitting: false,
        disable: false,
        emailExists: true,
      });
      return;
    }

    const groupData = {
      name,
      s3Key: imageKey,
    }

    createGroup(groupData)
      .then(data => {
        const errors = lodash.get(data, 'data.createGroup.errors[0]');
        const groupId = lodash.get(data, 'data.createGroup.group.id');
        let emails = email || email1;
        if(email && email1) {
          emails = email + ',' + email1;
        }
        const invitesData = {
          groupId,
          emails: emails.toLowerCase(),
        };

        if(errors) {
          this.setState({
            errorEmailText: errors
          })
        }
        
        createGroupInvites(invitesData)
        .then(data => {
          const success = lodash.get(data, 'data.createGroupInvites.success');
          const errors = lodash.get(data, 'data.createGroupInvites.errors[0]');
          
          if(errors) {
            this.setState({
              isSubmitting: false,
              errorEmailText: errors,
            })
          };
          if(success) {
            this.goToGroups();
            this.setState({
              isSubmitting: false,
              disable: false,
            });
          };
        })
        .catch(err => {
          this.setState({
            isSubmitting: false,
            disable: false,
          });
        });
      })
      .catch(err => {
        this.setState({
          isSubmitting: false,
        });
      });
  }

  onChange = e => {
    if(e.target.value.trim !== '') {
      this.setState({
        disable: false,
      });
    }
  }

  render() {
    const { isImageLoading, imageKey, isSubmitting, disable, emailExists, errorEmailText, errorName, onChange } = this.state;
    const createGroupProps = {
      groupsLink: routeEnums.GROUPS,
      goToGroups: this.goToGroups,
      groupTitleMaxLength: 50,
      onCreateGroup: this.onCreateGroup,
      onFileSelect: this.onFileSelect,
      fileSizeLimit: 204800,
      fileSizeError: this.fileSizeError,
      imgSrc: imageKey && getS3ImageUrl(s3BucketTypes.GROUP, imageKey),
      onChange: this.onChange,
      isImageLoading,
      isSubmitting,
      disable,
      emailExists,
      errorName,
      errorEmailText
    };

    return <CreateGroup {...createGroupProps} />;
  }
}

export default CreateGroupWithData;
