import React from 'react';
import PropTypes from 'prop-types';
import RewardItem from 'components/design-components/rewards/RewardItem';

const RewardCard = ({
  rewardId,
  imgSrc,
  maxProgress,
  points,
  redeemPoints,
  title,
  valueProgress,
  onRedeemReward,
  isLoading,
  cropImage
}) => {
  return (
    <RewardItem
      id={rewardId}
      imgSrc={imgSrc}
      maxProgress={maxProgress}
      points={points}
      redeemPoints={redeemPoints}
      title={title}
      valueProgress={valueProgress}
      onClickDetails={onRedeemReward}
      rewardDetails={true}
      showAvatarList={false}
      loading={isLoading}
      cropImage={cropImage}
    />
  );
};

RewardCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  rewardId: PropTypes.string.isRequired,
  points: PropTypes.number,
  redeemPoints: PropTypes.number,
  maxProgress: PropTypes.number.isRequired,
  valueProgress: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onRedeemReward: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

RewardCard.defaultProps = {
  isLoading: false,
};

export default React.memo(RewardCard);
