import React, { Component } from 'react';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import ErrorPage from 'pages/404/ErrorPage';
import { logEvent } from '../../services/analytics/gaService';

export default class Error404 extends Component {
  componentDidMount() {
    logEvent({
      category: 'Error',
      action: 'view',
      label: 'Error Page',
    });
  }
  render() {
    const prevLocation = (this.props) ? ((this.props.location) ? this.props.location.state : null) : null;
    return <ErrorPage backToEquell={prevLocation || routeEnums.HOME} />;
  }
}
