import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TooltipCard from './TooltipCard';

const TooltipContainer = styled.div`
  position: relative;
`;

const Tooltip = ({ children, containerStyle, enabled, mobileStyles, ...props }) => {
  const [tooltipContainer] = useState(document.createElement('div'));
  const [open, setOpen] = useState(false);
  let containerElementRef = useRef(null);
  function handleOpen() {
    if (!enabled) return false;
    document.body.appendChild(tooltipContainer);
    setOpen(true);
  }
  function handleClose() {
    if (!enabled) return false;
    document.body.removeChild(tooltipContainer);
    setOpen(false);
  }

  return (
    <TooltipContainer
      ref={containerElementRef}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      style={containerStyle}
    >
      {ReactDOM.createPortal(
        <TooltipCard opened={open} containerElementRef={containerElementRef} mobileStyles={mobileStyles} {...props} />,
        tooltipContainer
      )}
      {children}
    </TooltipContainer>
  );
};

Tooltip.propTypes = {
  text: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  containerStyle: PropTypes.object,
  centered: PropTypes.bool,
  footer: PropTypes.node,
  tooltipStyle: PropTypes.object,
  enabled: PropTypes.bool,
};

Tooltip.defaultProps = {
  footer: '',
  containerStyle: {},
  tooltipStyle: {},
  centered: false,
  enabled: true,
};

export default Tooltip;
