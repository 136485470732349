import React from 'react';
import PropTypes from 'prop-types';
import { LeftArrow, RightArrow } from 'components/design-components/icons';
import styled from 'styled-components';
import { media, theme } from 'components/design-components/config';
import moment from 'moment';
import {
  compareTwoDates,
  getDatesBetweenDates,
  getLastOrFirstSetTime,
  sumIntoWeeksArray,
} from 'utils/time';
import { pipe } from 'utils/utils';
import uniqid from 'uniqid';
import DashboardHeaderMonthItem from './DashboardHeaderMonthItem';
import { useWindowSize } from 'components';

const HeaderNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  ${media.tablet`
      width:100%;
  `};
  color: ${theme.colors.dark_grey};
  font-size: 16px;
  margin-top: 30px;
`;

const ArrowDiv = styled.div`
  visibility: ${props => !props.show && 'hidden'};
  svg {
    cursor: pointer;
    font-size: 28px;
  }
`;

function createWeekArray(currentStartOfWeek, currentEndOfWeek, totalHoursArray) {
  const days = [];
  for (
    let day = moment(currentStartOfWeek), i = 0;
    day <= currentEndOfWeek;
    day = day.clone().add(1, 'd'), i++
  ) {
    days.push({ id: uniqid(), date: day.toDate(), hours: totalHoursArray[i] });
  }
  return days;
}

const DashboardHeaderMonthsList = ({
  activity: incomingActivity,
  clickBack,
  clickForward,
  currentWeekDate,
  userFirstInteraction,
  onClickDayMonth,
  currentDate,
}) => {
  function formatWeekArray() {
    if (!incomingActivity.length) {
      const currentStartOfWeek = moment(currentWeekDate).startOf('week');
      const currentEndOfWeek = moment(currentWeekDate).endOf('week');
      return {
        firstDateUsed: currentWeekDate,
        lastDateUsed: moment(),
        calendarDays: createWeekArray(currentStartOfWeek, currentEndOfWeek, Array(7).fill(0)),
        showBackButton: compareTwoDates(currentStartOfWeek, userFirstInteraction) > 0,
        showForwardButton: compareTwoDates(currentEndOfWeek, moment()) < 0,
      };
    }

    const firstDateUsedActivity = getLastOrFirstSetTime(incomingActivity, true);

    const currentStartOfWeek = moment(firstDateUsedActivity)
      .startOf('week')
      .toDate();
    const currentEndOfWeek = moment(firstDateUsedActivity)
      .endOf('week')
      .toDate();

    const totalHoursArray = pipe(
      getDatesBetweenDates,
      sumIntoWeeksArray
    )(incomingActivity, currentStartOfWeek, currentEndOfWeek);

    const calendarDays = createWeekArray(currentStartOfWeek, currentEndOfWeek, totalHoursArray);

    return {
      calendarDays,
      showBackButton: compareTwoDates(currentStartOfWeek, userFirstInteraction) > 0,
      showForwardButton: compareTwoDates(currentEndOfWeek, moment()) < 0,
    };
  }

  const { calendarDays, showBackButton, showForwardButton } = formatWeekArray();
  const { outerWidth } = useWindowSize();
  return (
    <HeaderNav>
      <ArrowDiv show={showBackButton} onClick={clickBack}>
        <LeftArrow />
      </ArrowDiv>
      {calendarDays.map(({ id, date, hours }) => (
        <DashboardHeaderMonthItem
          key={id}
          width={outerWidth}
          date={date}
          hours={hours}
          userFirstInteraction={userFirstInteraction}
          onClickDayMonth={onClickDayMonth}
          currentDate={currentDate}
        />
      ))}
      <ArrowDiv show={showForwardButton} onClick={clickForward}>
        <RightArrow />
      </ArrowDiv>
    </HeaderNav>
  );
};

DashboardHeaderMonthsList.propTypes = {
  activity: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
        .isRequired,
      end: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
        .isRequired,
      points: PropTypes.number.isRequired,
    })
  ),
  clickBack: PropTypes.func.isRequired,
  clickForward: PropTypes.func.isRequired,
  currentWeekDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
    .isRequired,
  userFirstInteraction: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
  ]).isRequired,
  currentDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)])
    .isRequired,
};

DashboardHeaderMonthsList.defaultProps = {
  activity: [],
};

export default React.memo(DashboardHeaderMonthsList);
