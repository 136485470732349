import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { ClassicModal, ErrorText, Input } from 'components/design-components/';

const EditTeamNameModal = ({
  opened,
  onSave,
  onValidate,
  teamName,
  onClose,
  loading,
  errorText,
}) => {
  let editFormSubmit = null;
  function handleClose() {
    onClose();
  }
  function handleSave() {
    editFormSubmit();
  }
  function handleFormSubmit(fields, events) {
    onSave(fields, events);
  }

  return (
    <ClassicModal
      fullscreen
      title="Edit Team Name"
      cancelText="Cancel"
      approveText="Done"
      loading={loading}
      opened={opened}
      onClose={handleClose}
      onApprove={handleSave}
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{ teamName }}
        validate={onValidate}
        render={({ handleSubmit }) => {
          editFormSubmit = handleSubmit;
          return (
            <form onSubmit={handleSubmit}>
              <Input name="teamName" label="Name" />
            </form>
          );
        }}
      />
      <ErrorText>{errorText}</ErrorText>
    </ClassicModal>
  );
};

EditTeamNameModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  teamName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  errorText: PropTypes.string,
};
EditTeamNameModal.defaultProps = {
  errorText: '',
  loading: false,
};

export default React.memo(EditTeamNameModal);
