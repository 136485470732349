import React from 'react';

const SvgInfo = props => (
  <svg width="1em" height="1em" viewBox="0 0 20 21" {...props}>
    <path
      fill="#EADED8"
      d="M10 20.347c5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10s-10 4.478-10 10c0 5.523 4.477 10 10 10zm-.25-13.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5zm-1.75 10v-.5h1v-7.5H8v-.5h3v8h1v.5H8z"
    />
  </svg>
);

export default SvgInfo;
