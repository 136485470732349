import React from 'react';
import PropTypes from 'prop-types';
import { ToggleIcons } from 'components/design-components/form/styled';
import SearchInput from 'components/design-components/form/LocationSearch/SearchInput';
import InfoTooltip from 'components/design-components/form/LocationSearch/InfoTooltip';
import styled from 'styled-components';

const StyledToggleIcons = styled(ToggleIcons)`
  z-index: 20;
`;

const LocationSearchInputToggle = ({ meta, error, name, mobileStyles, ...rest }) => {
  return (
    <SearchInput name={name} {...rest} error={error}>
      <StyledToggleIcons>
        <InfoTooltip mobileStyles={mobileStyles} />
      </StyledToggleIcons>
    </SearchInput>
  );
};

LocationSearchInputToggle.propTypes = {
  error: PropTypes.bool,
};

LocationSearchInputToggle.defaultProps = {
  error: false,
};

export default React.memo(LocationSearchInputToggle);
