import React from 'react';

const SvgAccount = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 14" {...props}>
    <path
      fillRule="evenodd"
      d="M13.758 9.139c-.293-.188-1.103-.444-2.698-.912a26.788 26.788 0 0 1-.889-.269c-.16-.058-.228-.108-.191-.334.034-.087.215-.326.348-.502.455-.603 1.143-1.516 1.143-2.512C11.471 2.62 9.914 1 8 1 6.086 1 4.529 2.62 4.529 4.61c0 .996.688 1.909 1.143 2.512.133.176.314.415.348.502.037.226-.03.276-.191.334-.116.042-.491.152-.89.27-1.594.467-2.404.723-2.697.911-1.071.69-1.222 2.902-1.242 3.338-.012.278.23.512.543.523h.023c.301 0 .552-.212.564-.483.064-1.45.5-2.387.772-2.562.243-.143 1.694-.569 2.392-.773.518-.152.818-.24.963-.293.674-.246.994-.767.878-1.431-.046-.263-.247-.529-.524-.896-.402-.533-.952-1.262-.952-1.952 0-1.412 1.072-2.605 2.341-2.605 1.269 0 2.341 1.193 2.341 2.605 0 .69-.55 1.42-.952 1.952-.277.367-.478.633-.524.896-.116.664.204 1.185.878 1.43.145.053.445.142.963.294.698.204 2.15.63 2.39.771.418.27.728 1.541.774 2.564.012.27.263.483.564.483h.023c.312-.011.555-.245.543-.523-.02-.436-.17-2.648-1.242-3.338"
    />
  </svg>
);

export default SvgAccount;
