import SignIn from 'componentsWithData/SignIn/SignInWithData';
import DevicesWithData from 'componentsWithData/MyAccount/DevicesWithData';
import SignUp from 'componentsWithData/SignUp/SignUpWithData';
import EmailConfirmationWithData from 'componentsWithData/SignUp/EmailConfirmationWithData.jsx';
import ForgotPassword from 'componentsWithData/ForgotPassword/ForgotPasswordWithData';
import CheckEmailWithData from 'componentsWithData/ForgotPassword/CheckEmailWithData';
import UpdatePasswordWithData from 'componentsWithData/ForgotPassword/UpdatePasswordWithData';
import DownloadAndInstallWithData from 'componentsWithData/DownloadApp/DownloadAndInstallWithData';
import MyAccountWithData from 'componentsWithData/MyAccount/MyAccountWithData';
import EditProfileWithData from 'componentsWithData/MyAccount/EditProfileWithData';
import EmailPreferencesWithData from 'componentsWithData/MyAccount/EmailPreferencesWithData';
import RewardHistoryWithData from 'componentsWithData/MyAccount/RewardHistoryWithData';
import AdminAccountWithData from 'componentsWithData/Admin/AdminAccountWithData.jsx';
import RewardInventoryWithData from 'componentsWithData/Admin/Rewards/RewardInventoryWithData.jsx';
import ClaimedRewardsWithData from 'componentsWithData/Admin/Rewards/ClaimedRewardsWithData.jsx';
import CreateTeamWithData from 'componentsWithData/Admin/Teams/CreateTeamWithData.jsx';
import AddRewardWithData from 'componentsWithData/Admin/Rewards/AddRewardWithData.jsx';
import EditRewardWithData from 'componentsWithData/Admin/Rewards/EditRewardWithData.jsx';
import MembersWithData from 'componentsWithData/Admin/Members/MembersWithData.jsx';
import AddMembersWithData from 'componentsWithData/Admin/Members/AddMembersWithData.jsx';
import TermsOfServiceWithData from 'componentsWithData/helpers/TermsOfServiceWithData.jsx';
import PrivacyPolicyWithData from 'componentsWithData/helpers/PrivacyPolicyWithData.jsx';
import AboutWithData from 'componentsWithData/helpers/AboutWithData.jsx';
import ActivityDashBoardWithData from 'componentsWithData/Dashboard/ActivityDashBoardWithData.jsx';
import RewardDetailsWithData from 'componentsWithData/Rewards/RewardDetailsWithData.jsx';
import RewardsList from 'componentsWithData/ListOfRewards/RewardListWithData.jsx';
import GroupsWithData from 'componentsWithData/Groups/GroupsWithData';
import CreateGroupWithData from 'componentsWithData/Groups/CreateGroupWithData';
import EditGroupWithData from 'componentsWithData/Groups/EditGroupWithData';
import ViewGroupWithData from 'componentsWithData/Groups/ViewGroupWithData';

import routeEnums from 'componentsWithData/router/routeEnums.js';

export default [
  {
    path: routeEnums.SIGN_IN,
    component: SignIn,
    requireUser: false,
    requireNoUser: true,
    withHeader: true,
    withFooter: true,
  },
  {
    path: routeEnums.SIGN_UP,
    component: SignUp,
    requireUser: false,
    requireNoUser: true,
    withHeader: true,
    withFooter: true,
  },
  {
    path: routeEnums.EMAIL_CONFIRMATION,
    component: EmailConfirmationWithData,
    requireUser: false,
    requireNoUser: true,
    withHeader: false,
    withFooter: true,
  },
  {
    path: routeEnums.CHECK_EMAIL,
    component: CheckEmailWithData,
    requireUser: false,
    requireNoUser: true,
    withHeader: false,
    withFooter: true,
  },
  {
    path: routeEnums.UPDATE_PASSWORD,
    component: UpdatePasswordWithData,
    requireUser: false,
    requireNoUser: true,
    withHeader: false,
    withFooter: true,
  },
  {
    path: routeEnums.FORGOT_PASSWORD,
    component: ForgotPassword,
    requireUser: false,
    requireNoUser: true,
    withHeader: false,
    withFooter: true,
  },
  {
    path: routeEnums.DOWNLOAD_AND_INSTALL,
    component: DownloadAndInstallWithData,
    requireUser: false,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
  },
  {
    path: routeEnums.MY_ACCOUNT,
    component: MyAccountWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    routes: [
      {
        path: routeEnums.EDIT_PROFILE,
        component: EditProfileWithData,
        routes: [],
      },
      {
        path: routeEnums.EMAIL_PREFERENCES,
        component: EmailPreferencesWithData,
        routes: [],
      },
      {
        path: routeEnums.REWARD_HISTORY,
        component: RewardHistoryWithData,
        routes: [],
      },
      {
        path: routeEnums.DEVICES,
        component: DevicesWithData,
        requireUser: true,
        requireNoUser: false,
        routes: [],
      },
    ],
  },
  {
    path: routeEnums.ADMIN_ACCOUNT,
    component: AdminAccountWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    routes: [
      {
        path: routeEnums.ADMIN_MEMBERS,
        component: MembersWithData,
        routes: [],
      },
      {
        path: routeEnums.ADD_MEMBERS,
        component: AddMembersWithData,
        routes: [],
      },
      {
        path: routeEnums.REWARD_INVENTORY,
        component: RewardInventoryWithData,
        routes: [],
      },
      {
        path: routeEnums.CLAIMED_REWARDS,
        component: ClaimedRewardsWithData,
        routes: [],
      },
      {
        path: routeEnums.CREATE_TEAM,
        component: CreateTeamWithData,
        routes: [],
      },
    ],
  },
  {
    path: routeEnums.ADD_REWARD,
    component: AddRewardWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    routes: [],
  },
  {
    path: routeEnums.EDIT_REWARD,
    component: EditRewardWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    routes: [],
  },
  {
    path: routeEnums.REWARD_DETAILS,
    component: RewardDetailsWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    routes: [],
  },
  {
    path: routeEnums.TERMS_OF_SERVICE,
    component: TermsOfServiceWithData,
    requireUser: false,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    noPaddingBody: true,
    routes: [],
  },
  {
    path: routeEnums.PRIVACY_POLICY,
    component: PrivacyPolicyWithData,
    requireUser: false,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    noPaddingBody: true,
    routes: [],
  },
  {
    path: routeEnums.ABOUT,
    component: AboutWithData,
    requireUser: false,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    routes: [],
  },
  {
    path: routeEnums.HOME,
    component: ActivityDashBoardWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    exactRoute: true,
    exact: true,
  },
  {
    path: routeEnums.LIST_OF_REWARDS,
    component: RewardsList,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
    routes: [],
  },
  {
    path: routeEnums.GROUPS,
    component: GroupsWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
  },
  {
    path: routeEnums.CREATE_GROUP,
    component: CreateGroupWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
  },
  {
    path: routeEnums.EDIT_GROUP,
    component: EditGroupWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
  },
  {
    path: routeEnums.VIEW_GROUP,
    component: ViewGroupWithData,
    requireUser: true,
    requireNoUser: false,
    withHeader: true,
    withFooter: true,
  },
];
