import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { isUserLoggedIn } from 'services/auth/userStore';
import routeEnums from 'componentsWithData/router/routeEnums.js';

function checkAuth(requireUser, requireNoUser) {
  if (requireUser) {
    return isUserLoggedIn();
  } else if (requireNoUser) {
    return !isUserLoggedIn();
  } else {
    return true;
  }
}

export default function AuthRoute({
  component: Component,
  render,
  requireUser,
  requireNoUser,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props =>
        checkAuth(requireUser, requireNoUser) ? (
          render(props)
        ) : (
          <Redirect
            to={{
              pathname: requireUser ? routeEnums.SIGN_IN : routeEnums.HOME,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
