import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { theme } from '../config';

const HelpButtonContainer = styled.div`
  ${props =>
    props.absolute &&
    css`
      position: fixed;
      right: 50px;
      bottom: 50px;
    `}
  z-index: 3;
  width: 68px;
  height: 68px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #9b9b9b;
  &:hover {
    background-color: #777676;
  }
`;

const HelpButtonIcon = styled.i`
  font-size: 2.8em;
  color: ${theme.colors.true_white};
`;

const HelpButton = ({ absolute, onClick }) => {
  return (
    <HelpButtonContainer absolute={absolute} onClick={onClick}>
      <HelpButtonIcon className="mdi mdi-chat" />
    </HelpButtonContainer>
  );
};

HelpButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  absolute: PropTypes.bool,
};

HelpButton.defaultProps = {
  absolute: false,
};

export default HelpButton;
