import React, { Component } from 'react';
import lodash from 'lodash';
import Groups from 'pages/groups/Groups';
import { replaceRouteParams } from 'componentsWithData/router/routeService.js';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import getGroups from 'services/graphQL/queries/groups/getGroups';
import { errorMessage } from 'components/design-components/toast/ToastrMsg';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';

class GroupsWithData extends Component {
  state = {
    myId: null,
    groupsList: [],
    invitesList: [],
    isLoading: true,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    getGroups()
      .then(data => {
        const myId = lodash.get(data, 'data.me.id') || '';
        const groupsList = lodash.get(data, 'data.me.groups') || [];
        const invitesList = lodash.get(data, 'data.me.groupInvites') || [];
        const pendingInvites = invitesList.filter(invite => invite.accepted === null);

        this.setState({
          myId,
          groupsList,
          invitesList: pendingInvites,
          isLoading: false,
        });
      })
      .catch(err => {
        errorMessage('Error fetching data from the server');
      });
  }

  updateInviteStatus = (group, acceptedGroup) => {
    const groups = this.state.groupsList.concat(group);
    const invites = this.state.invitesList;
    const acceptedInviteIdx = invites.findIndex((invite => invite.id === acceptedGroup.id));
    invites[acceptedInviteIdx].accepted = acceptedGroup.accepted;
    const newInvitesList = invites.filter(invite => invite.accepted === null);

    this.setState({
      groupsList: groups,
      invitesList: newInvitesList,
    });
  }

  onRejectInvite = (groupId) => {
    const invites = this.state.invitesList;

    invites.splice(invites.findIndex(item => item.id === groupId));
    this.setState({
      invitesList: invites,
    })
  }

  onEditGroup = groupId => {
    const { history } = this.props;

    history.push(replaceRouteParams(routeEnums.EDIT_GROUP, { groupId }));
  };

  onViewGroup = groupId => {
    const { history } = this.props;

    history.push(replaceRouteParams(routeEnums.VIEW_GROUP, { groupId }));
  }

  render() {
    const { myId, groupsList, invitesList, isLoading } = this.state;
    const groupsProps = {
      myId,
      groups: groupsList,
      invites: invitesList,
      createGroupLink: routeEnums.CREATE_GROUP,
      updateInviteStatus: this.updateInviteStatus,
      onRejectInvite: this.onRejectInvite,
      onViewGroup: this.onViewGroup,
    };
    
    if (isLoading) {
      return <Loader />;
    }

    return (
      <Groups onEditGroup={this.onEditGroup} {...groupsProps} />
    )
  }
}

export default GroupsWithData;
