import React from 'react';

const SvgMeterThumbnailNull = props => (
  <svg width="1em" height="1em" viewBox="0 0 43 27" {...props}>
    <path
      fill="none"
      stroke="#F2E1D9"
      strokeLinecap="round"
      strokeWidth={12}
      d="M36.358 20.683c0-8.062-6.6-14.598-14.743-14.598S6.87 12.621 6.87 20.683"
    />
  </svg>
);

export default SvgMeterThumbnailNull;
