import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2 } from 'components/design-components';

const ActivityWrapper = styled.div`
  margin-top: 70px;
`;
const ActivityTitle = styled(H2)`
  margin-bottom: 30px;
`;

const ActivitySection = ({ title, children }) => {
  return (
    <ActivityWrapper>
      <ActivityTitle>{title}</ActivityTitle>
      {children}
    </ActivityWrapper>
  );
};

ActivitySection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ActivitySection;
