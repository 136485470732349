import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { theme } from '../../config';
import { hasNextOrPreviousWeek } from 'utils/time';

const IndicatorWrapper = styled.div`
  ${theme.fontFamily};
  font-size: ${theme.fontSize.normal};
  cursor: pointer;
  ${theme.noSelect}
  color:${theme.colors.dark_grey}
`;

const IndicatorArrow = styled.i`
  color: ${theme.colors.equell_teal};
  margin: 0 10px;
`;

const formatNextFirstDayOfTheWeek = momentDate => momentDate.isoWeekday(1).format('MMMM D, YYYY');

const ToolbarIndicator = ({ lastDateUsed, firstDateUsed, currentDate, forNextWeek, onClick }) => {
  // console.log(...hasNextOrPreviousWeek(currentDate, firstDateUsed, lastDateUsed),
  //   moment(firstDateUsed).format('MMMM Do YYYY, h:mm:ss a'),
  //   moment(currentDate).format('MMMM Do YYYY, h:mm:ss a'),
  //   moment(lastDateUsed).format('MMMM Do YYYY, h:mm:ss a'), forNextWeek);
  const [canGoPrevious, canGoNext] = hasNextOrPreviousWeek(
    currentDate,
    firstDateUsed,
    lastDateUsed
  );
  if (!canGoNext && forNextWeek) return null;
  if (!canGoPrevious && !forNextWeek) return <div />; // div is for flexbox purposes

  return (
    <IndicatorWrapper onClick={onClick}>
      {!forNextWeek && <IndicatorArrow className="mdi mdi-arrow-left" />}
      Week of{' '}
      {forNextWeek
        ? formatNextFirstDayOfTheWeek(moment(currentDate).add(1, 'week'))
        : formatNextFirstDayOfTheWeek(moment(currentDate).subtract(1, 'week'))}
      {forNextWeek && <IndicatorArrow className="mdi mdi-arrow-right" />}
    </IndicatorWrapper>
  );
};

ToolbarIndicator.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  lastDateUsed: PropTypes.instanceOf(Date),
  firstDateUsed: PropTypes.instanceOf(Date),
  forNextWeek: PropTypes.bool,
};
ToolbarIndicator.defaultProps = {
  forNextWeek: false,
  lastDateUsed: null,
  firstDateUsed: null,
};
export default ToolbarIndicator;
