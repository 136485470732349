import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { theme } from 'components/design-components/config';

const HContainer = styled.div`
  cursor: pointer;

  & span {
    display: block;
    width: 33px;
    height: 4px;
    margin: 0 6px 5px;
    background: ${theme.colors.equell_teal};
    border-radius: 3px;
    transition: 0.3s;
`;

const Hamburger = ({ onClick }) => {
  return (
    <HContainer onClick={onClick}>
      <span />
      <span />
      <span />
    </HContainer>
  );
};

Hamburger.propTypes = {
  onClick: PropTypes.func,
};

export default Hamburger;
