import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DeleteButton, H4, Image, Table, TableLink } from 'components/design-components';
import RemoveMemberModal from './RemoveMemberModal';
import EditMemberModal from './EditMemberModal';
import { formatDateToStandard } from 'utils/utils';
import { Account } from 'components/design-components/icons';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';
import { errorMessage } from 'components/design-components/toast/ToastrMsg';

const styles = {
  img: {
    width: 68,
    height: 68,
  },
};

const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
`;

const DefaultImage = styled.div`
  position: relative;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: ${theme.colors.switch_off};
  svg {
    font-size: 38px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: ${theme.colors.grey};
  }
`;

const TableColumn = styled.div`
  color: ${theme.colors.dark_grey};
`;

const MembersEditLink = ({ user, onClick }) => {
  function handleClick() {
    onClick(user);
  }

  return <TableLink onClick={handleClick}>Edit</TableLink>;
};

const MemmbersDeleteButton = ({ user, onClick }) => {
  function handleClick() {
    onClick(user);
  }

  return <DeleteButton onClick={handleClick} />;
};

class MembersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedEditModal: false,
      openedRemoveAllModal: false,
      openedRemoveModal: false,
      onRemoveAllMembers: false,
      currentModalUser: {},
    };
  }

  closeEditModal = () => this.setState({ openedEditModal: false });
  closeRemoveModal = () => this.setState({ openedRemoveModal: false });
  openEditModal = user => this.setState({ openedEditModal: true, currentModalUser: user });
  openRemoveModal = user => {
    const { members } = this.props;
    if (members.length === 1) {
      errorMessage("Can't remove the last member from team");
      return;
    }
    this.setState({ openedRemoveModal: true, currentModalUser: user });
  };
  openRemoveAllModal = () => this.setState({ openedRemoveAllModal: true });
  closeRemoveAllModal = () => this.setState({ openedRemoveAllModal: false });

  renderMembers = e => {
    const avatar = e.avatarUrl ? (
      <Image isAvatar style={styles.img} src={e.avatarUrl} />
    ) : (
      <DefaultImage>
        <Account />
      </DefaultImage>
    );
    const { firstName } = e;
    const lastName = e.lastName || '';
    let userName = 'Guest';
    if (firstName || lastName) {
      userName = ` ${firstName} ${lastName}`;
    }

    return [
      <UserAvatar>
        {avatar}
        <H4 style={{ margin: 0, marginLeft: '15px' }}>{userName}</H4>
      </UserAvatar>,
      <TableColumn>{e.email}</TableColumn>,
      <TableColumn>{e.points.toLocaleString()}</TableColumn>,
      <TableColumn>
        {e.creationDate ? formatDateToStandard(e.creationDate) : 'Invited'}
      </TableColumn>,
      <MembersEditLink user={e} onClick={this.openEditModal} />,
      <MemmbersDeleteButton user={e} onClick={this.openRemoveModal} />,
    ];
  };

  render() {
    const {
      openedEditModal,
      openedRemoveModal,
      openedRemoveAllModal,
      currentModalUser,
    } = this.state;
    const {
      onRemoveMember,
      onEditSave,
      onEditValidate,
      onRemoveAllMembers,
      removeAllMembersErrorText,
      removeMemberErrorText,
      editMemberErrorText,
      members,
      tableColumns,
    } = this.props;

    return (
      <Fragment>
        <RemoveMemberModal
          errorText={removeAllMembersErrorText}
          onClose={this.closeRemoveAllModal}
          opened={openedRemoveAllModal}
          onSave={onRemoveAllMembers}
          removeAll
        />
        <RemoveMemberModal
          errorText={removeMemberErrorText}
          onClose={this.closeRemoveModal}
          opened={openedRemoveModal}
          onSave={onRemoveMember}
          user={currentModalUser}
        />
        <EditMemberModal
          errorText={editMemberErrorText}
          onClose={this.closeEditModal}
          opened={openedEditModal}
          onSave={onEditSave}
          user={currentModalUser}
          onValidate={onEditValidate}
        />
        <Table columns={tableColumns} data={members} renderRow={this.renderMembers} />
        {/*TODO this will be added in beta2*/}
        {/*{members.length > 0 && (*/}
        {/*  <LinkButton style={{ float: 'right' }} underlined onClick={this.openRemoveAllModal}>*/}
        {/*    Remove All Members*/}
        {/*  </LinkButton>*/}
        {/*)}*/}
      </Fragment>
    );
  }
}

MembersTable.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      avatarUrl: PropTypes.shape.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string,
      email: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      creationDate: PropTypes.instanceOf(Date),
    })
  ),
  onEditSave: PropTypes.func.isRequired,
  onRemoveAllMembers: PropTypes.func.isRequired,
  onRemoveMember: PropTypes.func.isRequired,
  onEditValidate: PropTypes.func,
  removeAllMembersErrorText: PropTypes.string,
  removeMemberErrorText: PropTypes.string,
  editMemberErrorText: PropTypes.string,
  tableColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MembersTable.defaultProps = {
  members: [],
};

export default MembersTable;
