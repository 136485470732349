import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import styled, { css } from 'styled-components';
import { H3 } from 'components/design-components';
import { media, theme } from '../config';

const TableRowHead = styled.tr`
  && > th {
    border: 0;
    font-weight: normal;
    text-align: left;
  }
  ${media.tablet(css`
    display: none;
  `)}
`;

const TD = styled.td`
  vertical-align: middle;
  font-size: ${theme.fontSize.normal};
  padding: 12px;
  border-top: 1px solid ${theme.colors.light_grey};
  
  ${media.tablet(css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &:before {
      content: attr(data-label);
      font-weight: bold;
    }

    &:first-of-type {
      border-top: 0;

      &:before {
        display: none;
      }
    }

    h5 {
      margin-bottom: 10px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
    }
  `)}
`;
const TR = styled.tr`
  &:last-of-type td {
    border-bottom: 1px solid ${theme.colors.light_grey};
  }

  ${media.tablet(css`
    margin-bottom: 50px;
    display: block;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 4px rgba(210, 198, 198, 0.34);

    &:last-of-type td {
      border-bottom: 0;
    }
  `)}
`;
const TH = styled.th`
  padding: 12px;
`;
const TableElement = styled.table`
  min-width: ${props => props.minWidth}px;
  border-collapse: collapse;
  margin-bottom: 16px;
  width: 100%;

  ${media.tablet(css`
    min-width: auto;
  `)}
`;
const TableContainer = styled.div`
  width: 100%;

  ${media.tablet(css`
    overflow: initial;
  `)}
`;

const TableRowsBody = ({ data, renderRow, columns }) =>
  data.map(row => (
    <TR key={uniqid()}>
      {renderRow(row).map((e, i) => (
        <TD data-label={columns[i]} key={uniqid()}>{e}</TD>
      ))}
    </TR>
  ));

const Table = ({
  columns,
  data,
  noDataText,
  renderRow,
  children,
  columnsProportions,
  minWidth,
}) => {
  if (!data.length) return <H3 className="text-center">{noDataText}</H3>;
  return (
    <TableContainer>
      <TableElement minWidth={minWidth}>
        <thead>
          <TableRowHead>
            {columns.map((e, i) => (
              <TH style={{ width: `${columnsProportions[i]}%` }} key={uniqid()}>
                {e}
              </TH>
            ))}
          </TableRowHead>
        </thead>
        <tbody>{children || TableRowsBody({ data, columns, renderRow })}</tbody>
      </TableElement>
    </TableContainer>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  columnsProportions: PropTypes.arrayOf(PropTypes.number),
  renderRow: PropTypes.func,
  noDataText: PropTypes.string,
  children: PropTypes.node,
  minWidth: PropTypes.number,
};
Table.defaultProps = {
  columns: [],
  data: [],
  columnsProportions: [],
  children: null,
  noDataText: 'No data',
  minWidth: 769,
};
export default Table;
