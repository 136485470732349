import getS3Settings from 'services/graphQL/queries/appSettings/s3Settings.js';
import lodash from 'lodash';

let settings = {};

function initS3Settings() {
  return new Promise((resolve, reject) => {
    getS3Settings()
      .then(data => {
        const s3Info = lodash.get(data, 'data.systemInformation');
        settings = Object.assign(settings, s3Info);
        resolve(s3Info);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export { initS3Settings };

export default settings;
