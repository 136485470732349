import React, { Component } from 'react';
import UpdatePassword from 'pages/forgot-passwrd/UpdatePassword';
import { resetPassword } from 'services/user/userService.js';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import passwordPolicies from 'componentsWithData/utils/passwordPolicies.js';
import { logEvent } from 'services/analytics/gaService.js';
import cognitoErrorEnum from 'services/aws-cognito/enums/cognitoErrorEnum.js';

export default class UpdatePasswordWithData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errorText: '',
      passwordVisible: false,
    };
  }

  onSubmit = ({ password }) => {
    const { history, match } = this.props;

    const email = window.atob(match.params.base64Email);
    const verificationCode = match.params.code;

    this.setState({
      isLoading: true,
    });

    resetPassword({ email, verificationCode, password })
      .then(result => {
        this.setState({
          isLoading: false,
        });

        logEvent({
          category: 'User',
          action: 'reset password success',
          label: 'User reset password success',
        });

        history.push(routeEnums.SIGN_IN);
      })
      .catch(error => {
        logEvent({
          category: 'User',
          action: 'reset password fail',
          label: 'User reset password fail',
        });

        this.setState({
          isLoading: false,
          errorText: this.getPasswordErrorMessage(error),
        });
      });
  };

  getPasswordErrorMessage(error) {
    if (error.code === cognitoErrorEnum.InvalidParameterException) {
      return 'New password is not valid';
    } else if (error.code === cognitoErrorEnum.InvalidPasswordException) {
      return 'Old password is not correct';
    } else if (error.code === cognitoErrorEnum.NotAuthorizedException) {
      return 'Old password is not correct';
    } else if (error.code === cognitoErrorEnum.LimitExceededException) {
      return "You've reached the retry limit";
    }

    return 'Error resetting password';
  }

  onPasswordVisibleChange = () => {
    this.setState((state, props) => ({
      passwordVisible: !state.passwordVisible,
    }));
  };

  render() {
    const { passwordVisible, errorText, isLoading } = this.state;

    const updateProps = {
      onSubmit: this.onSubmit,
      onPasswordVisibleChange: this.onPasswordVisibleChange,
      passwordVisible: passwordVisible,
      signInLink: routeEnums.SIGN_IN,
      errorText,
      isLoading,
      passwordInstructions: passwordPolicies,
    };

    return <UpdatePassword {...updateProps} />;
  }
}
