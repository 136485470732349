import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import HeaderDayIndicator from './HeaderDayIndicator';
import { compareTwoDates } from 'utils/time';

const HeaderDayIndicatorContainer = (
  totalHoursArray,
  lastDateUsed,
  firstDateUsed
) => reactBigCalendarProps => {
  const { date } = reactBigCalendarProps;
  // const currentStartOfWeek = moment(date).startOf('isoWeek');
  const notUsed = compareTwoDates(firstDateUsed, date) > 0 || compareTwoDates(moment(), date) < 0;
  const currentIndicatorWeekDateIndex = moment(date).day();
  return (
    <HeaderDayIndicator
      notUsed={notUsed}
      isNotUsedPast={false}
      totalTimeInS={totalHoursArray[currentIndicatorWeekDateIndex]}
      {...reactBigCalendarProps}
    />
  );
};

HeaderDayIndicatorContainer.propTypes = {
  totalHoursArray: PropTypes.arrayOf(PropTypes.number).isRequired,
  lastDateUsed: PropTypes.instanceOf(Date).isRequired,
  firstDateUsed: PropTypes.instanceOf(Date).isRequired,
};

export default HeaderDayIndicatorContainer;
