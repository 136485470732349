import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'components/design-components';
import { theme } from 'components/design-components/config';
import { ClockFormat, ClockNumber } from './styledComponents';

const ContainerNotch = styled.div`
  width: 256px;
  height: 256px;
  border: 1px transparent solid;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%) rotate(${props => props.rotateNotch}deg);
  border-radius: 50%;
  transition: transform 1000ms ease-out;
`;

const Triangle = styled.div`
  width: 20px; 
  height: 20px; 
  position: absolute;
  left: -15px;
  transform: translateY(-50%);
  top: 50%;
  cursor: pointer;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent; 
    border-right: 15px solid #f2e0d9;
  }
`;

const Title = styled.div`
  font-size: 10px;
  color: ${theme.colors.equell_teal};
`;

const StyledClockFormat = styled(ClockFormat)`
  margin: 5px 0;
  display: block;
`;

const Notch = ({ durationHours, displayMinutes, dayPoints }) => {
  let minutesDisplay = '' + displayMinutes;
  if (displayMinutes < 10) {
    minutesDisplay = '0' + displayMinutes;
  }

  let rotateNotch = '2';
  const previousTime = durationHours* 60 + displayMinutes;

  if(previousTime >= 480) {
    rotateNotch = '176';
  } else if(previousTime === 0) {
    rotateNotch = '2';
  } else {
    rotateNotch = previousTime* 176 / 480; 
  }

  return (
    <ContainerNotch rotateNotch={rotateNotch} >
      <Triangle>
        <Tooltip
          centered
          text={
            <div>
              <Title>PREVIOUS</Title>
              <StyledClockFormat>
                <ClockNumber>{durationHours}</ClockNumber>h{' '}
                <ClockNumber style={{ paddingLeft: '3px' }}>{minutesDisplay}</ClockNumber>m
              </StyledClockFormat>
            </div>
          }
          footer={dayPoints.toLocaleString() + ' POINTS'}
          containerStyle={{ height: '100%' }}
        >
          <div style={{ height: '100%', display: 'block' }} />
        </Tooltip>
      </Triangle>
    </ContainerNotch>
  );
};

Notch.propTypes = {};

export default React.memo(Notch);
