import React, { Component } from 'react';
import SignIn from 'pages/sign-in/SignIn';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { logInUser } from 'services/user/userService.js';
import { replaceRouteParams } from 'componentsWithData/router/routeService.js';
import parameterEnum from 'componentsWithData/router/parameterEnums.js';
import EventEmitter, { EventsEnum } from 'services/events/eventsService.js';
import { logEvent } from 'services/analytics/gaService.js';

export default class SignInWithData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordVisible: false,
      isSubmitting: false,
      errorMessage: '',
    };
  }

  onSubmit = ({ email, password }) => {
    const { history } = this.props;

    this.setState({
      isSubmitting: true,
    });

    email = email.toLowerCase();

    logInUser({ email, password })
      .then(userInfo => {
        this.setState({
          isSubmitting: false,
        });

        EventEmitter.emit(EventsEnum.FETCH_USER_DATA, true);

        logEvent({
          category: 'Sign In',
          action: 'success',
          label: 'Signin Success',
        });
        history.push(routeEnums.HOME);
      })
      .catch(error => {
        logEvent({
          category: 'Sign In',
          action: 'fail',
          label: 'Signin Fail',
        });

        this.setState({
          isSubmitting: false,
          errorMessage: error.message || 'Your email or password is not correct',
        });
      });
  };

  onPasswordVisibleChange = () => {
    this.setState((state, props) => ({
      passwordVisible: !state.passwordVisible,
    }));
  };

  render() {
    const { passwordVisible, isSubmitting, errorMessage } = this.state;

    const signInProps = {
      onSubmit: this.onSubmit,
      onPasswordVisibleChange: this.onPasswordVisibleChange,
      passwordVisible: passwordVisible,
      forgotPasswordLink: routeEnums.FORGOT_PASSWORD,
      mobileApp: 'http://appstore.equell.com/',
      //chromeExtension: 'https://equell.com/chrome.html',
      errorText: errorMessage,
      isLoading: isSubmitting,
    };

    return <SignIn {...signInProps} />;
  }
}
