import React, { Component } from 'react';
import lodash from 'lodash';
import { RewardsList } from 'pages/rewards';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import getAvailableRewards from 'services/graphQL/queries/rewards/availableRewards';
import { errorMessage } from 'components/design-components/toast/ToastrMsg';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import { logEvent } from 'services/analytics/gaService';
import { replaceRouteParams } from 'componentsWithData/router/routeService.js';
import { getS3ImageUrl } from '../../services/s3/s3ImageUrl';
import s3BucketTypes from '../../services/s3/enums/s3BucketTypes';

class RewardListWithData extends Component {
  state = {
    rewardsList: [],
    isLoading: true,
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchDashBoardData();
  }

  fetchDashBoardData = () => {
    getAvailableRewards()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const rewardsList = lodash.get(data, 'data.me') || [];

        this.setState({
          rewardsList,
          isLoading: false,
        });
      })
      .catch(err => {
        console.log(err);
        if (!this.__isComponentMounted) {
          return;
        }
        errorMessage('Error fetching data from the server');
        const { history } = this.props;
        history.push('/404');
      });
  };

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  onClickDetails = rewardId => {
    const { history } = this.props;

    history.push(replaceRouteParams(routeEnums.REWARD_DETAILS, { rewardId }));

    logEvent({
      category: 'Reward',
      action: 'click',
      label: 'Reward click',
    });
  };

  render() {
    const {
      rewardsList: { availableRewards, activity, tier },
      isLoading,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const modifiedRewardsList = availableRewards.map(reward => ({
      id: reward.id,
      points: reward.points,
      limit: 5, //User avatars limit
      maxProgress: reward.points,
      valueProgress: activity.availablePoints || 0,
      imgSrc: reward.s3Key,
      listOfImg: reward.rewardRedemptions.map(rewardRedemption =>
        getS3ImageUrl(s3BucketTypes.PROFILE, lodash.get(rewardRedemption, 'profile.s3Key'))
      ),
      title: reward.name,
    }));

    return (
      <RewardsList
        rewardsList={modifiedRewardsList}
        onClickDetails={this.onClickDetails}
        profileTier={tier}
        backTo={routeEnums.HOME}
        headerTitle="Rewards"
        backText="Back to dashboard"
      />
    );
  }
}

export default RewardListWithData;
