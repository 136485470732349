import React from 'react';

const SvgErrorIconNotifcation = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={15} cy={15} r={15} fill="#AE0015" />
      <path
        fill="#FFF"
        stroke="#FFF"
        d="M15.982 15.172l4.193-4.187a.577.577 0 0 0-.816-.816l-4.187 4.193-4.187-4.193a.577.577 0 1 0-.815.816l4.193 4.187-4.193 4.187a.574.574 0 0 0 0 .816.574.574 0 0 0 .815 0l4.187-4.193 4.187 4.193a.574.574 0 0 0 .816 0 .574.574 0 0 0 0-.816l-4.193-4.187z"
      />
    </g>
  </svg>
);

export default SvgErrorIconNotifcation;
