import React from 'react';
import PropTypes from 'prop-types';
import AbtHeader from './components/AbtHeader';
import RowSection from '../../components/custom/RowSection';
import uniqid from 'uniqid';
import AbtFooter from './components/AbtFooter';

const About = ({ header, sections, footer }) => {
  return (
    <div>
      <AbtHeader {...header} />
      <div className="container">
        {sections.map((section, i) => (
          <RowSection imageRight={i % 2 === 0} key={uniqid()} {...section}>
            {section.description}
          </RowSection>
        ))}
      </div>

      <AbtFooter {...footer} />
    </div>
  );
};

About.propTypes = {
  header: PropTypes.shape(AbtHeader.propTypes).isRequired,
  footer: PropTypes.shape(AbtFooter.propTypes).isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape(RowSection.propTypes)).isRequired,
};

export default About;
