import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/design-components';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';
import uniqid from 'uniqid';
import DeviceTypeEnum from 'enums/DeviceTypeEnum';

const LastUpdate = styled.div`
  font-size: ${theme.fontSize.smallNormal};
`;

const ListOfDevicesUsed = styled.div``;

const DeviceUsed = styled.div`
  padding-top: 10px;
  &&& a {
    cursor: pointer;
    color: ${theme.colors.equell_teal};
    &:visited:not(.highlighted) {
      color: ${theme.colors.equell_teal};
    }
  }
`;

const InnerContainer = styled.div`
  padding: 25px 25px 15px 25px;
  height: 185px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const DeviceListDetails = ({ device }) => {
  return (
    <InnerContainer>
      <LastUpdate>
        {device.lastUpdated
          ? `Last used equell: ${device.lastUpdated}`
          : 'No recorded use of equell.'}
      </LastUpdate>
      <ListOfDevicesUsed>
        {device.subDevices &&
          device.subDevices.map(subDevice => <DeviceUsed key={uniqid()}>{subDevice}</DeviceUsed>)}
        <DeviceUsed>
          {device.desktopLink && (
            <Link
              style={{ display: 'block' }}
              reactLink={false}
              highlighted
              href={device.desktopLink}
            >
              Desktop App
            </Link>
          )}
          {device.getApp && (
            <Link reactLink={false} highlighted href={device.getApp.link}>
              {device.getApp.label}
            </Link>
          )}
        </DeviceUsed>
      </ListOfDevicesUsed>
    </InnerContainer>
  );
};

DeviceListDetails.propTypes = {
  device: PropTypes.shape({
    lastUpdated: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(DeviceTypeEnum)),
    getApp: PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
    desktopLink: PropTypes.string,
    subDevices: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default DeviceListDetails;
