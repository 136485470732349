import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Link } from 'components/design-components';
import styled from 'styled-components';
import { media } from 'components/design-components/config';

const InputContainer = styled.div`
  padding-bottom: 11px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  ${media.tablet`display: block;`}
`;

const StyledButton = styled(Button)`
  min-width: 170px;
  ${media.tablet`width: 100%;`}
`;

const EmailContainer = styled(InputContainer)`
  padding-left: 20px;
`;

const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: ${props => `${props.fontSize}px`};
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  ${media.tablet`display: block; text-align: center;`}
`;

const ProfileFormContainer = ({
  userEmail,
  // changePasswordLink,
  submitting,
  pristine,
  disableSubmit,
  handleModalOpen,
}) => {
  return (
    <Fragment>
      <InputContainer>
        <Input name="firstName" label="First Name" />
      </InputContainer>
      <InputContainer>
        <Input name="lastName" label="Last Name" />
      </InputContainer>
      <EmailContainer>
        <InputContainer>
          <Paragraph fontSize={12}>Email</Paragraph>
          <Paragraph fontSize={16}>{userEmail}</Paragraph>
          <Paragraph fontSize={12}>You can’t change the email for this account. </Paragraph>
        </InputContainer>
        <ActionContainer>
          <StyledLink reactLink={false} underlined onClick={handleModalOpen}>
            Change password
          </StyledLink>
          <StyledButton loading={submitting} disabled={pristine || disableSubmit} isSubmit>
            Update
          </StyledButton>
        </ActionContainer>
      </EmailContainer>
    </Fragment>
  );
};

ProfileFormContainer.propTypes = {
  userEmail: PropTypes.string.isRequired,
  // changePasswordLink: PropTypes.string.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  disableSubmit: PropTypes.bool,
};

ProfileFormContainer.defaultProps = {
  submitting: false,
  pristine: false,
  disableSubmit: false,
};

export default React.memo(ProfileFormContainer);
