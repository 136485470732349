import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query myAccountData {
    me {
      id
      givenName
      familyName
      s3Key
      tier
      email
      created
    }
  }
`;

export default function getProfileData() {
  return executeQuery(query, {});
}
