import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query teamIds {
    me {
      id
      teams {
        id
      }
    }
  }
`;

export default function getTeamIds() {
  return executeQuery(query, {});
}
