import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, theme } from 'components/design-components/config';

const TeamList = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const TeamItem = styled.div`
  height: 200px;
  width: 350px;
  background-color: ${theme.colors.true_white};
  ${theme.boxShadow};
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 30px;
  ${media.phone`margin-right: 0`}
`;

const TeamItemName = styled.div`
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${theme.colors.light_grey};
`;

const TeamItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 30px 0 40px 20px;
`;

const EditProfileTeams = ({ itemList }) => {
  return (
    <TeamList>
      {itemList.map(item => (
        <TeamItem key={item.id}>
          <TeamItemName>{item.name}</TeamItemName>
          <TeamItemInfo>
            <span>{item.members} Members</span>
            <span>You joined the team: {item.joined}</span>
          </TeamItemInfo>
        </TeamItem>
      ))}
    </TeamList>
  );
};

EditProfileTeams.propTypes = {
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      members: PropTypes.number.isRequired,
      joined: PropTypes.string.isRequired,
    })
  ),
};

EditProfileTeams.defaultProps = {
  itemList: [],
};

export default React.memo(EditProfileTeams);
