import React from 'react';

const SvgPasswordShowIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 21 12" {...props}>
    <g fill="#EADED8" fillRule="evenodd">
      <path d="M10.5 10.286c-3.976 0-7.206-3.065-8.334-4.287C3.29 4.777 6.51 1.714 10.5 1.714c3.976 0 7.206 3.065 8.334 4.287-1.125 1.222-4.344 4.285-8.334 4.285m10.325-4.78C20.65 5.282 16.44 0 10.5 0 4.56 0 .35 5.282.175 5.506a.785.785 0 0 0 0 .988C.35 6.718 4.56 12 10.5 12c5.94 0 10.15-5.282 10.325-5.506a.785.785 0 0 0 0-.988" />
      <path d="M10.5 7.714c-1.053 0-1.91-.769-1.91-1.714s.857-1.714 1.91-1.714 1.91.77 1.91 1.714c0 .945-.857 1.714-1.91 1.714m0-5.143C8.394 2.571 6.682 4.11 6.682 6S8.394 9.429 10.5 9.429 14.318 7.89 14.318 6 12.606 2.571 10.5 2.571" />
    </g>
  </svg>
);

export default SvgPasswordShowIcon;
