import styled from 'styled-components';
import { theme } from '../config';

export default styled.div`
  position: absolute;
  color: ${theme.colors.error_red};
  bottom: 0;
  font-size: 12px;
  right: 8px;
`;
