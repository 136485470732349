import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation RemoveGroup($input: RemoveGroupInput!) {
    removeGroup(input: $input) {
      success
      errors
    }
  }
`;

export default function removeGroup(groupId) {
  return executeMutation(mutation, { input: { groupId } });
}
