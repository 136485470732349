import React from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import ActivityCalendarContainer from './ActivityCalendarContainer.jsx';
import moment from 'moment';
import Event from './components/Event';
import { getDatesBetweenDates, sumIntoWeeksArray } from 'utils/time';
import { pipe } from 'utils/utils.js';
import { getActivityExtremities } from './utils';
import HeaderDayIndicatorContainer from './components/RBCHeaderDayIndicatorContainer';
import ToolbarContainer from './components/RBCToolbarContainer';

/*
How calendar meter indicators work:
1. activity calendar is accepting an array of activity objects that have a start and end date, these dates can be provided
in an random way and can aso contain multiple different object that contain dates from a single day.
Because of this, the array of objects should be compressed in a structure like this:


Other notes:
- HeaderDayIndicatorContainer is a must-have because react-big-calendar is accepting a react non-rendered FUNCTION component and it passes itself the current date,
so in this way it's impossible to pass additional arguments so a special hoc is needed

and some other things I forgot to write at the proper moment...
*/

class ActivityCalendar extends React.Component {
  constructor(props) {
    super(props);
    const { activity: incomingActivity, activityStartDate } = props;
    const [firstDateUsed, lastDateUsed] = getActivityExtremities(
      incomingActivity,
      activityStartDate
    );

    this.firstDateUsed = firstDateUsed;
    this.lastDateUsed = lastDateUsed;

    this.state = {
      arrayWeekOfTotalSeconds: Array(7).fill(0),
      activity: [],
    };
  }

  componentDidMount = () => {
    this.handleCalendarWeekChange(new Date());
  };

  handleCalendarWeekChange = date => {
    const { onWeekChange } = this.props;
    const { activity } = this.props;
    // react big calendar is setting the day of the week the same as you are currently in, it just changes the week number
    // so we need to normalize it to always be the first day of the week
    const currentStartOfWeek = moment(date)
      .startOf('week')
      .toDate();
    const currentEndOfWeek = moment(date)
      .endOf('week')
      .toDate();

    // might be useful someday
    onWeekChange && onWeekChange(currentStartOfWeek);

    // filter all dates to get only current week's activity
    const arrayWeekOfTotalSeconds = pipe(
      getDatesBetweenDates,
      // remove disabled ones
      datesArray => datesArray.filter(({ disabled }) => !disabled),
      sumIntoWeeksArray
    )(activity, currentStartOfWeek, currentEndOfWeek); // [mondaySeconds, tuesdaySeconds,...]
    this.setState({ arrayWeekOfTotalSeconds });
  };

  render() {
    const { activity } = this.props;
    const { arrayWeekOfTotalSeconds } = this.state;

    return (
      <ActivityCalendarContainer>
        <BigCalendar
          events={activity}
          localizer={BigCalendar.momentLocalizer(moment)}
          defaultDate={new Date()}
          timeslots={1}
          step={120} // DO NOT MODIFY OR ELSE IT WILL BREAK
          defaultView={BigCalendar.Views.WEEK}
          views={[BigCalendar.Views.WEEK]}
          onNavigate={this.handleCalendarWeekChange}
          showMultiDayTimes={false}
          components={{
            event: Event,
            week: {
              header: HeaderDayIndicatorContainer(
                arrayWeekOfTotalSeconds,
                this.lastDateUsed,
                this.firstDateUsed
              ),
            },
            toolbar: ToolbarContainer(this.lastDateUsed, this.firstDateUsed),
          }}
          formats={{
            timeGutterFormat: 'hh:mm a',
          }}
        />
      </ActivityCalendarContainer>
    );
  }
}

ActivityCalendar.propTypes = {
  activity: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date).isRequired,
      points: PropTypes.number.isRequired,
    })
  ).isRequired,
  activityStartDate: PropTypes.instanceOf(Date),
  activityPadding: PropTypes.shape({
    hoursFromStart: PropTypes.number.isRequired,
    hoursFromEnd: PropTypes.number.isRequired,
  }),
  onWeekChange: PropTypes.func,
};

ActivityCalendar.defaultProps = {
  activity: [],
  onWeekChange: null,
  activityPadding: {
    hoursFromStart: 4,
    hoursFromEnd: 4,
  },
};

export default ActivityCalendar;
