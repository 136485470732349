import React, { Component, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { HeaderContent } from 'components/content';
import BodyContainer from 'components/design-components/wrappers/BodyContainer.jsx';
import EditTeamNameWithData from './components/EditTeamNameWithData.jsx';
import getTeamsInfo from 'services/graphQL/queries/admin/teams/getTeamsInfo.js';
import lodash from 'lodash';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';

export default class AdminAccountWithData extends Component {
  state = {
    teamNameModalOpen: false,
    loading: true,
    fetchError: '',
    teamData: null,
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  toggleTeamNameModal = () => {
    this.setState((state, props) => ({
      teamNameModalOpen: !state.teamNameModalOpen,
    }));
  };

  fetchData() {
    getTeamsInfo()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const team = lodash.get(data, 'data.me.teams.0');

        this.setState({
          loading: false,
          teamData: team,
        });
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          loading: false,
        });
      });
  }

  onTeamNameChanged = name => {
    this.setState((state, props) => ({
      teamData: Object.assign(state.teamData, { name }),
    }));
  };

  render() {
    const { teamNameModalOpen, loading, teamData } = this.state;
    const { routes, location } = this.props;
    const pathName = location.pathname;
    const validRoutes = [
      routeEnums.ADMIN_MEMBERS,
      routeEnums.REWARD_INVENTORY,
      routeEnums.CLAIMED_REWARDS,
      routeEnums.ADD_MEMBERS,
      routeEnums.CREATE_TEAM,
    ];

    if (location.pathname === routeEnums.ADMIN_ACCOUNT) {
      return <Redirect to={routeEnums.EDIT_PROFILE} />;
    }

    if (!validRoutes.includes(pathName)) {
      return <Redirect to="/404" />;
    }

    if (loading) {
      return <Loader />;
    }

    const accountProps = {
      header: teamData ? teamData.name : '',
      navItems: [
        {
          label: 'Members',
          to: routeEnums.ADMIN_MEMBERS,
        },
        {
          label: 'Rewards Inventory',
          to: routeEnums.REWARD_INVENTORY,
        },
        {
          label: 'Claimed Rewards',
          to: routeEnums.CLAIMED_REWARDS,
        },
      ],
      addons: {
        rightButton: {
          text: 'Edit Team Name',
          onClick: this.toggleTeamNameModal,
        },
      },
    };

    const editTeamNameProps = {
      modalOpened: teamNameModalOpen,
      onModalClose: this.toggleTeamNameModal,
      teamName: teamData ? teamData.name : '',
      teamId: teamData ? teamData.id : '',
      onTeamNameChanged: this.onTeamNameChanged,
    };

    return (
      <Fragment>
        <HeaderContent {...accountProps} />
        <BodyContainer>
          <Switch>
            {routes.map(({ path, component: Component }) => {
              return (
                <Route
                  key={path}
                  path={path}
                  routes={routes}
                  render={props => <Component {...props} routes={routes} />}
                />
              );
            })}
          </Switch>
          <EditTeamNameWithData {...editTeamNameProps} />
        </BodyContainer>
      </Fragment>
    );
  }
}
