import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { ClassicModal, ErrorText, Input } from 'components/design-components';

const EditMemberModal = ({ onChangeName, firstName, lastName, opened, onSave, onValidate, user, onClose, errorText }) => {
  const [loading, setLoading] = useState(false);
  let editFormSubmit = null;
  function handleClose() {
    setLoading(false);
    onClose();
  }
  function handleSave() {
    editFormSubmit();
  }
  function handleFormSubmit({firstName, lastName}) {
    onSave(user.id, firstName, lastName, handleClose, setLoading);
    onChangeName(firstName, lastName);
  }

  return (
    <ClassicModal
      fullscreen
      title="Edit member"
      cancelText="Cancel"
      approveText="Yes"
      loading={loading}
      opened={opened}
      onClose={handleClose}
      onApprove={handleSave}
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{ firstName, lastName }}
        validate={onValidate}
        render={({ handleSubmit }) => {
          editFormSubmit = handleSubmit;
          return (
            <form onSubmit={handleSubmit}>
              <Input name="firstName" label="First Name" />
              <Input name="lastName" label="Last Name" />
            </form>
          );
        }}
      />
      <ErrorText>{errorText}</ErrorText>
    </ClassicModal>
  );
};

export default EditMemberModal;
