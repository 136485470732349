const DeviceTypeEnum = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  OTHER: 'OTHER',
  TABLET: 'TABLET',
};

const DeviceTypeIcon = {
  [DeviceTypeEnum.MOBILE]: 'mdi mdi-cellphone-android',
  [DeviceTypeEnum.DESKTOP]: 'mdi mdi-laptop',
  [DeviceTypeEnum.TABLET]: 'mdi mdi-tablet-android',
};
export { DeviceTypeIcon };
export default DeviceTypeEnum;
