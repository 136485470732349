import React from 'react';

const SvgDashboard = props => (
  <svg width="1em" height="1em" viewBox="0 0 17 10" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M8.5 1.143c-4.063 0-7.347 3.2-7.347 7.143a.576.576 0 0 1-1.153 0C0 3.714 3.803 0 8.5 0c1.44 0 2.881.371 4.178 1.057.288.143.375.486.23.772-.144.285-.49.371-.777.228A7.774 7.774 0 0 0 8.5 1.143zM17 8.286a.576.576 0 0 1-1.153 0 6.62 6.62 0 0 0-.864-3.315c-.144-.285-.058-.628.23-.771.289-.143.634-.057.779.229C16.654 5.57 17 6.914 17 8.286zm-2.219-5l-4.84 4.343c.086.2.144.428.144.657 0 .943-.778 1.714-1.73 1.714-.95 0-1.728-.771-1.728-1.714S7.405 6.57 8.356 6.57c.288 0 .576.086.836.2l4.84-4.342c.23-.2.605-.2.807.028.202.257.202.6-.058.829z"
      />
    </g>
  </svg>
);

export default SvgDashboard;
