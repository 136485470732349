import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query teamsInfo {
    me {
      id
      teams {
        id
        name
        type
        code
        slug
      }
    }
  }
`;

export default function getTeamsInfo() {
  return executeQuery(query, {});
}
