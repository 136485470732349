import React from 'react';
import styled from 'styled-components';
import { Account } from 'components/design-components/icons';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { theme } from 'components/design-components/config';

const SidebarAccountStyled = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px #eaded8 solid;
`;

const Icon = styled.div`
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  background-color: #f2e1d9;
  border-radius: 50%;
  text-align: center;
  line-height: 34px;
  ${props => (props.imgSrc && `
    background: url(${props.imgSrc}) center center / cover;
  `)};

  svg {
    fill: currentColor;
  }
`;

const InfoWrapper = styled.div`
  padding-left: 10px;
  width: 100%;
  overflow: hidden;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 13px;
  color: ${theme.colors.dark_grey};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Email = styled.div`
  font-size: 13px;
  color: ${theme.colors.grey};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SidebarAccount = ({ personalData }) => {
  const { completeName, email, s3Key } = personalData;
  const imgSrc = s3Key ? getS3ImageUrl(s3BucketTypes.PROFILE, s3Key) : null;

  return (
    <SidebarAccountStyled>
      <Icon imgSrc={imgSrc}>
        {
          !imgSrc && <Account />
        } 
      </Icon>
      <InfoWrapper>
        <Name>{completeName}</Name>
        <Email>{email}</Email>
      </InfoWrapper>
    </SidebarAccountStyled>
  );
};

export default SidebarAccount;
