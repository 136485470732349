import React from 'react';

const SvgTickIconNotification = props => (
  <svg width="1em" height="1em" viewBox="0 0 29 29" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={14.5} cy={14.5} r={14.5} fill="#1AB38E" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M13 20l-5-4.798 1.41-1.354L13 17.284 20.59 10 22 11.363z"
      />
    </g>
  </svg>
);

export default SvgTickIconNotification;
