import { css } from 'styled-components';

export default css`
  border: 0;

  .rbc-row {
    border: 0;
    /* all day row */
    &:nth-child(2) {
      display: none;
    }
  }
  .rbc-header {
    border: 0;
    a {
      cursor: auto;
    }
    &.rbc-today {
      background-color: transparent;
    }
  }
`;
