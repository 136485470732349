import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'components/design-components/config';
import { Button } from 'components/design-components';
import { HeaderTitle } from 'components/content/styled';

const HeaderContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.phone`
    display: none;
  `}
`;

const HeaderVerticalSection = styled.div`
  flex: 1;
  display: flex;
  ${props => props.align && `justify-content: ${props.align}`}
`;

const HeaderContentTitle = ({ header, headerContent, addons }) => {
  let { rightButton, leftButton } = addons;
  if (rightButton) {
    rightButton = <Button onClick={rightButton.onClick}>{rightButton.text}</Button>;
  }
  if (leftButton) {
    leftButton = <Button onClick={leftButton.onClick}>{leftButton.text}</Button>;
  }
  return (
    <HeaderContainer>
      <HeaderVerticalSection align="flex-start">{leftButton}</HeaderVerticalSection>
      <HeaderVerticalSection>
        {header && <HeaderTitle>{header}</HeaderTitle>}
        {headerContent}
      </HeaderVerticalSection>
      <HeaderVerticalSection align="flex-end">{rightButton}</HeaderVerticalSection>
    </HeaderContainer>
  );
};

HeaderContentTitle.propTypes = {
  addons: PropTypes.shape({
    rightButton: PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  }),
  header: PropTypes.string,
  headerContent: PropTypes.node,
};
HeaderContentTitle.defaultProps = {
  addons: {},
};
export default HeaderContentTitle;
