import { createGlobalStyle } from 'styled-components';
import normalize from 'normalize.css';
import { theme } from 'components/design-components/config';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '@mdi/font/css/materialdesignicons.css';
import 'rc-drawer/assets/index.css';
import chatBubble from './chatBubble';
import circularLoader from './circularLoader';
import fontsFaces from './fonts';

export default createGlobalStyle`
  ${normalize};
  ${fontsFaces};
  html, body {
    margin: 0;
    padding: 0;
    background-color: ${theme.colors.warm_white}
    color: ${theme.colors.grey};
  }

  a {
    color: ${theme.colors.grey};
  }
  
  * {
    ${theme.fontFamily};
    box-sizing: border-box;
  }
  #root {
    min-height: 100vh;
  }
  
  ${chatBubble};
  ${circularLoader};
  img {
    vertical-align: middle;
  }
`;
