import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query teamMembers {
    me {
      id
      teams {
        id
        teamProfiles {
          joined
          profile {
            id
            givenName
            familyName
            email
            s3Key
            activity {
              availablePoints
            }
          }
        }
      }
    }
  }
`;

export default function getTeamMembers() {
  return executeQuery(query, {});
}
