import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const FlexGridCol = styled.div`
  flex: ${props => props.width};
  display: flex;
  padding: 0 7px;
  width: 100%;

  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
  ${props =>
    props.verticalCenter &&
    css`
      align-items: center;
    `}
  ${props =>
    props.horizontalCenter &&
    css`
      justify-content: center;
    `}
`;

FlexGridCol.propTypes = {
  width: PropTypes.number,
  children: PropTypes.node,
  verticalCenter: PropTypes.bool,
  horizontalCenter: PropTypes.bool,
};

FlexGridCol.defaultProps = {
  width: 1,
  verticalCenter: false,
  horizontalCenter: false,
};

export default FlexGridCol;
