import awsSettings from 'services/auth/aws-settings.js';
import Amplify, { Auth } from 'aws-amplify';
import apiNameEnum from 'services/s3/enums/apiNameEnum.js';

function initSettings() {
  Amplify.configure({
    Auth: {
      identityPoolId: awsSettings.IDENTITY_POOL_ID,
      region: awsSettings.REGION,
      userPoolId: awsSettings.USER_POOL_ID,
      userPoolWebClientId: awsSettings.CLIENT_ID,
      mandatorySignIn: false,
    },
    API: {
      aws_appsync_graphqlEndpoint: awsSettings.ENDPOINT,
      aws_appsync_region: awsSettings.REGION,
      aws_appsync_authenticationType: awsSettings.TYPE,
      endpoints: [
        {
          name: apiNameEnum.UploadAPI,
          endpoint: `https://${awsSettings.API_GATEWAY_ID}.execute-api.${
            awsSettings.REGION
          }.amazonaws.com/v1`,
        },
      ],
    },
  });
}

function sendForgotPasswordEmail({ email }) {
  return Auth.forgotPassword(email);
}

function resetForgottenPassword({ email, verificationCode, password }) {
  return Auth.forgotPasswordSubmit(email, verificationCode, password);
}

function signUpUser({ email, password, firstName, lastName, location }) {
  return Auth.signUp({
    username: email,
    password,
    attributes: {
      email: email,
      given_name: firstName,
      family_name: lastName,
      'custom:signup_url': window.location.href,
      'custom:google_maps_place_id': location,
    },
  });
}

function confirmUserRegistration({ email, code }) {
  return Auth.confirmSignUp(email, code, {
    forceAliasCreation: true,
  });
}

function getSessionUser() {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        user.getSession((error, session) => {
          resolve(session);
        });
      })
      .catch(reject);
  });
}

function authenticateUser({ email, password }) {
  return new Promise((resolve, reject) => {
    Auth.signIn({
      username: email,
      password,
    })
      .then(user => {
        user.getSession((err, session) => {
          if (!err) {
            resolve(session);
          } else {
            reject(err);
          }
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

function signUserOut() {
  return Auth.signOut();
}

function changeUserPassword({ oldPassword, newPassword }) {
  return Auth.currentAuthenticatedUser().then(user => {
    return Auth.changePassword(user, oldPassword, newPassword);
  });
}

export {
  initSettings,
  authenticateUser,
  signUpUser,
  sendForgotPasswordEmail,
  resetForgottenPassword,
  getSessionUser,
  signUserOut,
  confirmUserRegistration,
  changeUserPassword,
};
