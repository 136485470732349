import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components/design-components';
import styled from 'styled-components';
import { theme, media } from 'components/design-components/config';
import SimpleLogo from 'public/logo/icon_logo.svg';
import { StyledContainer, DefaultIcon, IconLogo, LeftStyled } from '../styled';

const StyledButton = styled(Button)`
  background-color: ${props => !props.groups.length && `${theme.colors.equell_teal}`};
  margin: 0;
  color: ${props => !props.groups.length && `${theme.colors.true_white}`};
`;

const StyledText = styled.div`
  padding-left: 15px;

  h3 {
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 0;
  }
  p {
    font-size: 16px;
    margin: 0;
  }

  ${media.tablet`
    padding-left: 0;
    margin-bottom: 15px;
    width: 100%;
  `}
`;

const Container = styled(StyledContainer)`
  ${media.tablet`
    flex-wrap: wrap;
    justify-content: center;
    ${props => props.groups.length && 'display: none'}
  `}
`;

const LeftCol = styled(LeftStyled)`
  ${media.tablet`
    width: 100%;
    text-align: center;
  `}
`;

const Icon = styled(DefaultIcon)`
  ${media.tablet`
    display: none;
  `}
`;

const CreateGroup = ({ createGroupLink, groups }) => {
  return (
    <Container groups={groups}> 
      <LeftCol>
        <Icon>
          <IconLogo src={SimpleLogo} />
        </Icon>
        <StyledText>
          <h3>Create a new group</h3>
          <p>Get the people you want to replace online with offline</p>
        </StyledText>
      </LeftCol>
      <Link to={createGroupLink}>
        <StyledButton groups={groups}>Create group</StyledButton>
      </Link>
    </Container>
  );
};

export default CreateGroup;
