import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      success
      errors
    }
  }
`;

export default function updateGroup({ groupId, s3Key, name }) {
  return executeMutation(mutation, { input: { groupId, s3Key, name } });
}
