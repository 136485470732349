import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import DragScroll from 'react-dragscroll';
import ItemContainer from './ItemContainer';
import { AchievementsImage, ListOfAvatars } from 'components/design-components';
import { media, theme } from 'components/design-components/config';

const AchievementsWrapper = styled.div`
  ${theme.noSelect};
  & > div {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    ${media.tablet(css`
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
    `)};
  }
`;

const AchievementsList = ({ achievements, onClickAward }) => (
  <AchievementsWrapper>
    <DragScroll width="100%">
      {achievements.map((e, index) => {
        const footer = e.usersCompletedAvatars > 0 && (
          <ListOfAvatars listOfImg={e.usersCompletedAvatars} limit={3} />
        );
        return (
          <ItemContainer
            header={
              <AchievementsImage index={(index % 3) + 1} src={e.imageUrl} inactive={e.isDisabled} />
            }
            title={e.title}
            description={e.description}
            footer={footer}
            key={e.id}
            onClickAward={onClickAward}
          />
        );
      })}
    </DragScroll>
  </AchievementsWrapper>
);

AchievementsList.propTypes = {
  achievements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      usersCompleted: PropTypes.arrayOf(PropTypes.string),
      isDisabled: PropTypes.bool,
    })
  ),
  onClickAward: PropTypes.func.isRequired,
};

export default AchievementsList;
