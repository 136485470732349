import styled from 'styled-components';
import { media, theme } from 'components/design-components/config';
import { H1, MainContainer } from 'components/design-components';

export const HeaderContainerStyled = styled.div`
  width: 100%;
  box-shadow: 0 10px 20px -10px ${theme.colors.warm_shadow};
  background-color: ${theme.colors.true_white};

  ${media.phone`
    ${props => props.mobileGroupView && `
      box-shadow: none;
      background-color: transparent
    `}
  `}
`;

export const StyledMainContainer = styled(MainContainer)`
  min-height: 240px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  ${media.phone`
    min-height: auto;
  `}
`;

export const HeaderTitle = styled(H1)`
  text-align: center;
  margin: auto;
  width: 100%;
  color: ${theme.colors.dark_grey};
  ${media.phone`
    display: none;
    font-size: ${theme.fontSize.extraLarge};
  `}
`;
