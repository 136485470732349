export default function fetchSettings() {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.AWS_CONFIG_JSON}`, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
