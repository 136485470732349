import React from 'react';
import PropTypes from 'prop-types';
import ProfileImageContainer from './ProfileImageContainer';
import { Form } from 'react-final-form';
import { Form as FormComponent } from 'components/design-components';
import ProfileFormContainer from './ProfileFormContainer';
import styled from 'styled-components';
import { media, theme } from 'components/design-components/config';

const EditProfileContainer = styled.div`
  padding: 38px 0;
  display: flex;
  font-size: ${theme.fontSize.normal};
  ${media.tablet`display: block;`}
`;

const FormContainer = styled.div`
  width: 450px;
  padding-left: 20px;
  padding-top: 20px;
  ${media.tablet`width: 100%;padding: 20px 0 0;`}
`;

const EditProfileMain = ({
  imgSrc,
  uploadedFile,
  fileSizeLimit,
  fileSizeError,
  isImageLoading,
  fields,
  editProfileSubmit,
  onValidate,
  activeUpload,
  profileLoading,
  changePasswordLink,
  userEmail,
  disableSubmit,
  handleModalOpen,
}) => {
  return (
    <EditProfileContainer>
      <ProfileImageContainer
        imgSrc={imgSrc}
        uploadedFile={uploadedFile}
        fileSizeLimit={fileSizeLimit}
        fileSizeError={fileSizeError}
        isLoading={isImageLoading}
      />
      <FormContainer>
        <Form
          initialValues={fields}
          onSubmit={editProfileSubmit}
          validate={onValidate}
          render={({ handleSubmit, pristine }) => {
            let pris = pristine;
            if (activeUpload) {
              pris = false;
            }
            return (
              <FormComponent onSubmit={handleSubmit}>
                <ProfileFormContainer
                  submitting={profileLoading}
                  pristine={pris}
                  changePasswordLink={changePasswordLink}
                  userEmail={userEmail}
                  disableSubmit={disableSubmit}
                  handleModalOpen={handleModalOpen}
                />
              </FormComponent>
            );
          }}
        />
      </FormContainer>
    </EditProfileContainer>
  );
};

EditProfileMain.propTypes = {
  imgSrc: PropTypes.string,
  uploadedFile: PropTypes.func.isRequired,
  fileSizeLimit: PropTypes.number,
  fileSizeError: PropTypes.func,
  isImageLoading: PropTypes.bool,
  fields: PropTypes.object,
  editProfileSubmit: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  activeUpload: PropTypes.bool,
  profileLoading: PropTypes.bool,
  changePasswordLink: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  disableSubmit: PropTypes.bool,
  handleModalOpen: PropTypes.func.isRequired,
};

export default React.memo(EditProfileMain);
