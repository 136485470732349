import styled, { css } from 'styled-components';
import { theme } from 'components/design-components/config';

export const MainContainer = styled.div`
  max-width: 1150px;
  margin: 0 auto;
  width: 98%;
`;

export const MainApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const BodyContainer = styled.div`
  flex-grow: 1;
  min-height: 100%;
  ${props =>
    !props.noPadding &&
    css`
      padding: ${theme.navbarHeight}px 0 30px 0;
    `};
`;
