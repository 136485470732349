import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from './Toolbar';

const ToolbarContainer = (lastDateUsed, firstDateUsed) => reactBigCalendarProps => (
  <Toolbar lastDateUsed={lastDateUsed} firstDateUsed={firstDateUsed} {...reactBigCalendarProps} />
);

ToolbarContainer.propTypes = {
  lastDateUsed: PropTypes.instanceOf(Date).isRequired,
  firstDateUsed: PropTypes.instanceOf(Date).isRequired,
};

export default ToolbarContainer;
