import React from 'react';

const SvgEmailIconNotifcation = props => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={15} cy={15} r={15} fill="#1AB38E" />
      <path
        fill="#1AB38E"
        stroke="#FFF"
        d="M21 9H9c-.825 0-1.492.675-1.492 1.5l-.008 9c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5v-9c0-.825-.675-1.5-1.5-1.5h0z"
      />
      <path fill="#FFF" d="M21 12l-6 3.75L9 12v-1.5l6 3.75 6-3.75z" />
      <path d="M6 6h18v18H6z" />
    </g>
  </svg>
);

export default SvgEmailIconNotifcation;
