import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { H2, MainContainer, RewardsList } from 'components';
import Header from './Header';

const Rewards = ({ rewardsList, onClickDetails, headerTitle, backTo, backText, profileTier }) => {
  return (
    <Fragment>
      <Header header={headerTitle} backTo={backTo} backText={backText} />
      <MainContainer>
        {rewardsList.length > 0 ? (
          <RewardsList rewards={rewardsList} profileTier={profileTier} onClickDetails={onClickDetails} />
        ) : (
          <H2 textCenter>No rewards available</H2>
        )}
      </MainContainer>
    </Fragment>
  );
};

Rewards.propTypes = {
  rewardsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      points: PropTypes.number,
      limit: PropTypes.number,
      maxProgress: PropTypes.number.isRequired,
      valueProgress: PropTypes.number.isRequired,
      imgSrc: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      listOfImg: PropTypes.arrayOf(PropTypes.string.isRequired),
    })
  ),
  onClickDetails: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  backTo: PropTypes.string.isRequired,
  backText: PropTypes.string.isRequired,
  profileTier: PropTypes.string.isRequired,
};
Rewards.defaultProps = {
  rewardsList: [],
};

export default React.memo(Rewards);
