import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'components/design-components';
import { media, theme } from 'components/design-components/config';
import MeterAnimation from './MeterAnimation';
import moment from 'moment';
import { DATE_ENUMS } from 'enums/DateEnums';
import { compareTwoDates } from 'utils/time';

const PointsInfoContainer = styled.div`
  white-space: nowrap;
  font-size: ${theme.fontSize.normal};
`;

const Container = styled.div`
  height: 260px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  padding-bottom: 40px;
  ${media.tablet(css`
    flex-direction: column-reverse;
    width: 100%;
  `)};
`;

const PointsInfo = styled.div`
  color: ${theme.colors.dark_grey};
`;
const Points = styled.span`
  font-weight: bold;
  font-size: ${theme.fontSize.extraLarge};
  color: ${theme.colors.dark_grey};
`;

const Meter = ({
  totalPoints,
  redeemRewardsLink,
  onChangeDayBack,
  onChangeDayForward,
  singleDayActivity,
  previousDayActivity,
  currentDateFromProps,
  userFirstInteraction,
}) => {
  function formatCurrentDate(dayActivity) {
    if (!dayActivity.length) {
      return {
        daySeconds: 0,
        dayPoints: 0,
        currentDaySession: currentDateFromProps,
      };
    }
    const totalArray = dayActivity.reduce((acc, { start, end, points }) => {
      const { points: prevPoints = 0, duration = 0 } = acc;
      const currentIntervalDuration = moment(end).diff(moment(start), 'seconds');
      acc.points = prevPoints + points;
      acc.duration = duration + currentIntervalDuration;
      acc.currentDay = start;
      return acc;
    }, {});

    const { points, duration, currentDay } = totalArray;
    return {
      daySeconds: duration,
      dayPoints: points,
      currentDaySession: currentDay,
    };
  }

  function displayDayFormat(currentDay) {
    if (compareTwoDates(moment(), currentDay) === 0) return DATE_ENUMS.TODAY;

    if (compareTwoDates(currentDay, moment()) === -1) {
      return DATE_ENUMS.YESTERDAY;
    }
    return moment(currentDay).format('ddd, MMM D');
  }

  const { daySeconds, dayPoints, currentDaySession } = formatCurrentDate(singleDayActivity);
  const { dayPoints: prevDayPoints, daySeconds: previousDaySeconds } = formatCurrentDate(
    previousDayActivity
  );
  const showBack = compareTwoDates(userFirstInteraction, currentDaySession) < 0;

  const showForwardArrow =
    compareTwoDates(moment(currentDaySession).endOf('week'), currentDaySession) !== 0;
  const displayDay = displayDayFormat(currentDaySession);
  return (
    <Container>
      <MeterAnimation
        changeDayBack={onChangeDayBack}
        changeDayForward={onChangeDayForward}
        dayPoints={dayPoints}
        prevDayPoints={prevDayPoints}
        daySeconds={daySeconds}
        previousDaySeconds={previousDaySeconds}
        displayDay={displayDay}
        showBackArrow={showBack}
        showForwardArrow={showForwardArrow}
      />
      <PointsInfoContainer>
        <PointsInfo>
          <Points>{totalPoints.toLocaleString()}</Points> current points
        </PointsInfo>
        <Link to={redeemRewardsLink} arrowRight reactLink highlighted>
          Redeem Rewards
        </Link>
      </PointsInfoContainer>
    </Container>
  );
};

Meter.propTypes = {
  totalPoints: PropTypes.number,
  redeemRewardsLink: PropTypes.string.isRequired,
  onChangeDayBack: PropTypes.func.isRequired,
  onChangeDayForward: PropTypes.func.isRequired,
  singleDayActivity: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date).isRequired,
      points: PropTypes.number.isRequired,
    })
  ),
  previousDayActivity: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date).isRequired,
      points: PropTypes.number.isRequired,
    })
  ),
  currentDateFromProps: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
  ]).isRequired,
  userFirstInteraction: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
  ]).isRequired,
};

Meter.defaultProps = {
  totalPoints: 0,
  singleDayActivity: [],
  previousDayActivity: [],
};

export default React.memo(Meter);
