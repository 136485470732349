import React, { Component } from 'react';
import createTeam from 'services/graphQL/mutations/admin/teams/createTeam.js';
import removeTeam from 'services/graphQL/mutations/admin/teams/removeTeam.js';
import getTeamsInfo from 'services/graphQL/queries/admin/teams/getTeamsInfo.js';
import lodash from 'lodash';
import uniqid from 'uniqid';
import moment from 'moment';

export default class CreateTeamWithData extends Component {
  state = {
    teams: [],
    fetchError: '',
    createError: '',
    removeError: '',
    isCreating: false,
  };

  //Refs
  teamNameRef = React.createRef();

  componentDidMount() {
    this.fetchTeam();
  }

  onCreateTeam = e => {
    e.preventDefault();

    const teamName = this.teamNameRef.current.value;

    if (!teamName) {
      return;
    }

    this.setState({
      isCreating: true,
    });

    const data = {
      name: teamName,
      type: 'DEPARTMENT',
      code: uniqid() + moment().toISOString(),
    };

    createTeam(data)
      .then(data => {
        this.setState({
          isCreating: false,
        });

        const success = lodash.get(data, 'data.createTeam.success');
        const errors = lodash.get(data, 'data.createTeam.errors') || ['Server error'];

        if (success) {
          this.fetchTeam();
        } else {
          this.setState({
            createError: errors[0],
          });
        }
      })
      .catch(error => {
        this.setState({
          createError: error.message,
          isCreating: false,
        });
      });
  };

  fetchTeam = () => {
    getTeamsInfo()
      .then(data => {
        const teams = lodash.get(data, 'data.me.teams');

        this.setState({ teams });
      })
      .catch(error => {
        this.setState({
          fetchError: error.message,
        });
      });
  };

  removeTeam(teamId) {
    removeTeam({ teamId })
      .then(data => {
        const success = lodash.get(data, 'data.removeTeam.success');
        const errors = lodash.get(data, 'data.removeTeam.errors') || ['Server error'];

        if (success) {
          this.fetchTeam();
        } else {
          this.setState({
            removeError: errors[0],
          });
        }
      })
      .catch(error => {
        this.setState({
          removeError: error.message,
        });
      });
  }

  render() {
    const { teams, fetchError, createError, isCreating, removeError } = this.state;

    return (
      <div>
        <div>
          {fetchError ? (
            <div>{fetchError}</div>
          ) : (
            <ul>
              {teams.map(team => (
                <li key={team.id}>
                  <div>
                    <span style={{ marginRight: 30 }}>{team.name}</span>
                    <strong
                      onClick={() => {
                        this.removeTeam(team.id);
                      }}
                    >
                      X
                    </strong>
                  </div>
                </li>
              ))}
              {removeError ? <div>{removeError}</div> : null}
            </ul>
          )}
        </div>
        <div>
          <form onSubmit={this.onCreateTeam}>
            <input type="text" ref={this.teamNameRef} />
            <button disabled={isCreating} type="submit">
              Create team
            </button>
            {createError ? <div>{createError}</div> : null}
          </form>
        </div>
      </div>
    );
  }
}
