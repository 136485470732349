import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ErrorText,
  H1,
  Image,
  Input,
  LocationSearchInputField,
  Panel,
  PasswordInput,
} from 'components/design-components';
import { Form } from 'react-final-form';
import Links from './Links';
import SimpleLogo from 'public/logo/simple_logo.svg';
import styled, { css } from 'styled-components';
import { media, theme } from 'components/design-components/config';

const Container = styled.div`
  display: flex;
  justify-content: center; 
  width: 100%;
`;

const StyledPanel = styled(Panel)` 
  margin-top: 65px;
  width: 660px;
  padding: 70px 106px;
  ${media.tablet(css`
    width: 100%;
    margin-top: 0;
    height: 100%;
    padding: 70px;
  `)};
  ${media.phone(css`
    padding-right: 10px;
    padding-left: 10px;
  `)};
  h1 {
    margin: 0;
    font-weight: 400;
  };
  h3 {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.39px;
    font-weight: 400;
  };
`;

const InputWrapper = styled.div`
  margin-bottom: 21px;
`;

const LastField = styled.div`
  margin-bottom: 40px;
`;

const StyledErrorText = styled(ErrorText)`
  text-align: left;
  color: ${theme.colors.grey};
  font-size: 14px;
`;

const SignUp = ({
  onSubmit,
  onValidate,
  errorText,
  passwordVisible,
  onPasswordVisibleChange,
  passwordInstructions,
  disabledSubmit,
  privacyLink,
  termsLink,
  isLoading,
  initialValues,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function onSetWindowWidth() {
    setWindowWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', onSetWindowWidth);
  }, window.removeEventListener('resize', onSetWindowWidth));

  let mobileStyles;
  if(windowWidth < 769) {
    mobileStyles = {
      left: 129,
      top: 69
    };
  }

  return (
    <Container>
      <StyledPanel>
        <H1>equell</H1>
        <h3>Rewards for being offline</h3>
        <StyledErrorText>{errorText}</StyledErrorText>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={onValidate}
          mutators={{
            setLocation: (args, state, utils) => {
              utils.changeValue(state, 'location', () => args[0]);
            },
          }}
          render={({ form: { mutators }, handleSubmit, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <InputWrapper>
                <Input
                  name="firstName"
                  type="text"
                  label="First name"
                  required
                  error={!!errorText}
                />
              </InputWrapper>
              {/*<InputWrapper>*/}
              {/*<Input name="lastName" type="text" label="Last name" required error={!!errorText} />*/}
              {/*</InputWrapper>*/}
              <InputWrapper>
                <Input name="email" label="Email address" required error={!!errorText} />
              </InputWrapper>
              <InputWrapper>
                <PasswordInput
                  id="password"
                  name="password"
                  label="Password"
                  passwordVisible={passwordVisible}
                  onPasswordVisibleChange={onPasswordVisibleChange}
                  error={!!errorText}
                  alternateLabel="Password (8 or more characters)"
                />
              </InputWrapper>
              <LastField>
                <LocationSearchInputField mobileStyles={mobileStyles} name="location" handleChange={mutators.setLocation} />
              </LastField>
              <Button loading={isLoading} disabled={pristine || disabledSubmit} isSubmit fullwidth>
                Sign Up
              </Button>
            </form>
          )}
        />
        <Links privacyLink={privacyLink} termsLink={termsLink} />
      </StyledPanel>
    </Container>
  );
};

SignUp.propTypes = {
  initialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  errorText: PropTypes.string,
  passwordVisible: PropTypes.bool,
  onPasswordVisibleChange: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  passwordInstructions: PropTypes.arrayOf(PropTypes.string),
  ...Links.propTypes,
};

SignUp.defaultProps = {
  initialValues: {},
  errorText: '',
  passwordVisible: false,
  onPasswordVisibleChange: '',
  disabledSubmit: false,
  isLoading: false,
  passwordInstructions: [],
};

export default SignUp;
