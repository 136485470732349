import React from 'react';
import { ErrorContainer } from '../../components/index';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import {
  Button,
  H2,
  H4,
  Image,
  Link,
  Panel,
  PasswordInput,
} from '../../components/design-components/';
import { theme } from 'components/design-components/config';
import logo from 'public/logo/simple_logo.svg';
import styled from 'styled-components';

const InputContainer = styled.div`
  margin-bottom: 20px;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledPannel = styled(Panel)`
  width: 560px;
`;
const UpdatePassword = ({
  onSubmit,
  disabledSubmit,
  signInLink,
  passwordVisible,
  onPasswordVisibleChange,
  changedSuccessfully,
  errorText,
  onValidate,
  isLoading,
  passwordInstructions,
}) => (
  <Container>
    <StyledPannel>
      <Image src={logo} isAvatar style={{ width: 60, height: 60 }} />
      <H2
        style={{
          margin: '35px 0',
        }}
      >
        {changedSuccessfully ? 'Password updated' : 'Update password ?'}
      </H2>
      <H4 style={{ fontSize: 18, marginBottom: 35, color: theme.colors.grey, fontWeight: 'bold' }}>
        {changedSuccessfully
          ? 'Your password has been successfully updated'
          : 'Please enter your new password'}
      </H4>
      <Form
        onSubmit={onSubmit}
        validate={onValidate}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <PasswordInput
                name="password"
                label="Password"
                passwordInstructions={passwordInstructions}
                passwordVisible={passwordVisible}
                onPasswordVisibleChange={onPasswordVisibleChange}
              />
            </InputContainer>
            <ErrorContainer>{errorText}</ErrorContainer>
            <Button loading={isLoading} disabled={pristine || disabledSubmit} isSubmit fullwidth>
              Update Password
            </Button>
          </form>
        )}
      />
      <Link style={{ marginTop: '44px', fontSize: 16 }} to={signInLink}>
        <i className="mdi mdi-arrow-left" /> Return to Sign in
      </Link>
    </StyledPannel>
  </Container>
);

UpdatePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  passwordVisible: PropTypes.bool,
  onPasswordVisibleChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  signInLink: PropTypes.string.isRequired,
  disabledSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  errorText: PropTypes.string,
  changedSuccessfully: PropTypes.bool,
  passwordInstructions: PropTypes.arrayOf(PropTypes.string),
};

UpdatePassword.defaultProps = {
  passwordVisible: false,
  errorText: '',
  disabledSubmit: false,
  changedSuccessfully: false,
  isLoading: false,
  onValidate: null,
  passwordInstructions: [],
};

export default UpdatePassword;
