import React, { Fragment } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import App from './componentsWithData/App';
import GlobalStyles from './styles';
import { hot } from 'react-hot-loader/root';
import { setConfig } from 'react-hot-loader';
import { detect } from 'detect-browser';
import { logEvent } from 'services/analytics/gaService';

setConfig({
  pureSFC: true,
  pureRender: true,
});

class MainApp extends React.Component {
  componentDidMount() {
    const browser = detect();
    let browserType = `not a supported browser`;
    if (browser && browser.name) {
      browserType = `Browser name:${browser.name}, version:${browser.version}, os:${browser.os}`;
    }
    logEvent({
      category: 'Browser',
      action: 'view',
      label: browserType,
    });
  }

  render() {
    return (
      <Fragment>
        <GlobalStyles />
        <BrowserRouter>
          <Switch>
            <App />
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
}

export default hot(MainApp);
