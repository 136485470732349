import DeviceTypeEnum from 'enums/DeviceTypeEnum';

export default [
  {
    id: 'device1',
    type: DeviceTypeEnum.DESKTOP,
    name: 'Computer',
    lastAccessed: null,
  },
  {
    id: 'device2',
    type: DeviceTypeEnum.MOBILE,
    name: 'Phone',
    lastAccessed: null,
  },
  {
    id: 'device3',
    type: DeviceTypeEnum.TABLET,
    name: 'Tablet',
    lastAccessed: null,
  },
];
