import React, { useState } from 'react';
import lodash from 'lodash';
import { BodyContainer } from 'components/design-components';
import { GroupInfoP, RemoveAllBtn, MemberFooter } from './styled';
import MembersTable from './components/MembersTable';
import { HeaderContent } from 'components/content';
import { IconEdit } from 'components/design-components/icons';
import RemoveMemberModal from './components/RemoveMemberModal';
import LeaveMemberModal from './components/LeaveMemberModal';
import {
  StyledLink,
  GroupHeaderWrap,
  GroupImage,
  GroupInfo,
  GroupTitle,
  Text,
  EditButton,
  ViewGroupStyled
} from './styled';

const ViewGroup = ({
  onRemoveProfile,
  onRemoveAllMembers,
  onDeleteInvite,
  onEditGroup,
  onUpdateGroupProfile,
  userId,
  groupsLink,
  group,
  groupProfiles,
  groupInvites,
  imgSrc,
  editMemberErrorText,
}) => {
  const [openedRemoveModal, setOpenedRemoveModal] = useState(false);
  const [openedLeaveModal, setLeaveModal] = useState(false);
  const pendingInvites = groupInvites.filter(invite => invite.accepted === null);
  const members = groupProfiles.concat(pendingInvites);
  const membersCount = members.length;
  const owner = groupProfiles.find(profile => profile.profile.id === userId && profile.isAdmin);
  const member = groupProfiles.find(profile => profile.profile.id === userId && !profile.isAdmin);
  const memberId = lodash.get(member, 'profile.id');
  const editGroup = () => {
    onEditGroup(group.id, true);
  };
  const removeProfile = (setLoading) => {
    const leave = true;
    onRemoveProfile(group.id, memberId, closeLeaveModal, setLoading, leave);
  };
  const removeAllMembers = () => {
    setOpenedRemoveModal(true);
  };
  const closeRemoveModal = () => {
    setOpenedRemoveModal(false);
  };
  const leaveModal = () => {
    setLeaveModal(true);
  };
  const closeLeaveModal = () => {
    setLeaveModal(false);
  };

  return (
    <ViewGroupStyled>
      <HeaderContent mobileGroupView noTitle>
        <GroupHeaderWrap>
          <StyledLink to={groupsLink}>← Back to Groups</StyledLink>
          <GroupInfo>
            <GroupImage src={imgSrc} alt="" />
            <GroupTitle>{group.name}</GroupTitle>
            {
              owner && <EditButton href="javascript:;" onClick={editGroup}><IconEdit /></EditButton>
            }
          </GroupInfo>
        </GroupHeaderWrap>
      </HeaderContent>
      <BodyContainer>
        <Text>Members <span>({membersCount})</span></Text>
        <MembersTable
          groupId={group.id}
          userId={userId}
          members={members}
          adminMember={owner}
          onRemoveProfile={onRemoveProfile}
          onDeleteInvite={onDeleteInvite}
          onUpdateGroupProfile={onUpdateGroupProfile}
          editMemberErrorText={editMemberErrorText}
        />
        {
          owner &&
          <MemberFooter>
            <GroupInfoP>As a free member you can add up to 2 people to your group.</GroupInfoP>
            {
              membersCount > 1 &&
              <RemoveAllBtn>
                <a href="javascript:;" onClick={removeAllMembers}>Remove All Members</a>
              </RemoveAllBtn>
            }
          </MemberFooter>
        }
        {
          member &&
          <RemoveAllBtn hiddenDesktop>
            <a href="javascript:;" onClick={leaveModal}>Leave Group</a>
          </RemoveAllBtn>
        }
        <LeaveMemberModal
          onClose={closeLeaveModal}
          opened={openedLeaveModal}
          onSave={removeProfile}
          firstName={member && member.profile.givenName || ''}
          lastName={member && member.profile.familyName || ''}
        />
        <RemoveMemberModal
          onClose={closeRemoveModal}
          opened={openedRemoveModal}
          onSave={onRemoveAllMembers}
          removeAll
        />
      </BodyContainer>
    </ViewGroupStyled>
  );
};

export default React.memo(ViewGroup);
