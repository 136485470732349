import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query FullName {
    me {
      id
      givenName
      familyName
      created
      tier
      activity {
        availablePoints
      }
      availableBadges {
        id
        title
        description
        slug
        enabled
        earnedBadges {
          profile {
            s3Key
          }
        }
      }
      earnedBadges {
        badge {
          id
          title
          description
          number
          slug
          enabled
        }
      }
      sessionHistory {
        started
        ended
        pointsEarned
        isBedtime
      }
      availableRewards {
        id
        name
        description
        points
        s3Key
        rewardRedemptions {
          profile {
            s3Key
          }
        }
      }
    }
  }
`;

export default function getDashBoardInfo() {
  return executeQuery(query, {});
}
