import React, { Component } from 'react';
import RewardDetails from 'pages/reward-details/RewardDetails';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import getSingleReward from 'services/graphQL/queries/rewards/singleReward.js';
import redeemReward from 'services/graphQL/mutations/user/redeemReward.js';
import lodash from 'lodash';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';
import { logEvent } from 'services/analytics/gaService.js';
import { getS3RewardImages } from 'services/s3/s3ImageUrl';

const pointsTresholds = [100, 90, 75, 50, 10];

export default class RewardDetailsWithData extends Component {
  state = {
    isLoading: true,
    isRedeeming: false,
    reward: null,
    myPoints: null,
    fetchError: '',
    redeemError: '',
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchReward();
  }

  componentWillUnmount() {
    const { myPoints, reward } = this.state;

    if (reward && myPoints >= reward.points) {
      logEvent({
        category: 'Reward',
        action: 'ignore',
        label: 'Reward ignore',
        value: reward.id,
      });
    }

    this.__isComponentMounted = false;
  }

  getPointsTreshold(rewardPoints, myPoints) {
    const percentage = (myPoints / rewardPoints) * 100;

    return pointsTresholds.find(treshold => percentage >= treshold);
  }

  fetchReward() {
    const { match } = this.props;
    const rewardId = match.params.rewardId;

    this.setState({
      isLoading: true,
    });

    getSingleReward(rewardId)
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const reward = lodash.get(data, 'data.reward');
        const myPoints = lodash.get(data, 'data.me.activity.availablePoints') || 0;
        const pointsPercentage = this.getPointsTreshold(reward.points, myPoints);

        if (pointsPercentage) {
          logEvent({
            category: 'Reward',
            action: 'progress',
            label: `${pointsPercentage}%`,
            value: reward.id,
          });
        }

        this.setState({
          reward,
          myPoints,
          isLoading: false,
        });
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          isLoading: false,
          fetchError: error.message,
        });
      });
  }

  onRewardRedeem = () => {
    const { match, history } = this.props;
    const rewardId = match.params.rewardId;

    this.setState({ isRedeeming: true });

    redeemReward({ rewardId })
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        logEvent({
          category: 'Reward',
          action: 'redeem',
          label: 'Reward redeem',
          value: rewardId,
        });

        const success = lodash.get(data, 'data.redeemReward.success');
        const errors = lodash.get(data, 'data.redeemReward.errors');

        if (success) {
          successMessage('Reward redeemed');

          logEvent({
            category: 'Reward',
            action: 'redeem success',
            label: 'Reward redeem success',
            value: rewardId,
          });

          this.setState({
            isRedeeming: false,
          });

          history.push(routeEnums.HOME);
        } else {
          errorMessage('Reward not redeemed');

          logEvent({
            category: 'Reward',
            action: 'redeem fail',
            label: 'Reward redeem fail',
            value: rewardId,
          });

          this.setState({
            redeemError: errors[0],
            isRedeeming: false,
          });
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        errorMessage('Reward not redeemed');

        this.setState({
          redeemError: error.message,
          isRedeeming: false,
        });
      });
  };

  render() {
    const { isLoading, isRedeeming, myPoints, reward, fetchError } = this.state;
    const { match } = this.props;
    const { fromDashboard } = match.params;

    if (isLoading) {
      return <Loader />;
    }

    if (fetchError) {
      return <div>{fetchError}</div>;
    }

    const rewardProps = {
      rewardId: reward.id,
      title: reward.name,
      description: reward.description,
      imageUrl: getS3RewardImages(s3BucketTypes.REWARD, reward.s3Key),
      conditions: reward.terms,
      footer: reward.disclaimer,
      pricePoints: reward.points,
      currentPoints: myPoints,
      isLoading: isRedeeming,
      dashboardLink: routeEnums.LIST_OF_REWARDS,
      onRedeem: this.onRewardRedeem,
      backFrom: fromDashboard ? 'Back to Activity' : 'Back to Redeem Rewards',
    };

    return <RewardDetails {...rewardProps} />;
  }
}
