import React from 'react';
import styled from 'styled-components';
import { HeaderContainerStyled, HeaderTitle, Link, StyledMainContainer } from 'components';
import { media } from 'components/design-components/config';

const Container = styled(HeaderContainerStyled)`
  justify-content: normal;
  flex-direction: row;
`;

const InnerContainer = styled(StyledMainContainer)`
  align-items: normal;
  justify-content: normal;
`;

const BackLink = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 17px;

  ${media.phone`
    margin-top: 17px;
  `}
`;

const StyledHeaderTitle = styled(HeaderTitle)`
  margin: 0;
`;

const Header = ({ header, backTo, backText }) => {
  return (
    <Container>
      <InnerContainer>
        <BackLink>
          <Link to={backTo} arrowLeft>
            {backText}
          </Link>
        </BackLink>
        <StyledHeaderTitle>{header}</StyledHeaderTitle>
      </InnerContainer>
    </Container>
  );
};

Header.propTypes = {};

Header.defaultProps = {
  header: 'test',
};

export default React.memo(Header);
