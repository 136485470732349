import React from 'react';

const SvgSimpleArrow = props => (
  <svg viewBox="0 0 18 18" {...props} width="1em" height="1em">
    <g fill="none">
      <path d="M0 0h18v18H0V0z" />
      <path stroke="#737373" strokeLinecap="round" strokeWidth={1.5} d="M6 7.5l3 3 3-3" />
    </g>
  </svg>
);

export default SvgSimpleArrow;