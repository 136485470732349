import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// TODO set gradient
const BackgroundGradient = (startColor, endColor) => `
  data:image/svg+xml,
  <svg xmlns='http://www.w3.org/2000/svg'>
    <linearGradient xmlns='http://www.w3.org/2000/svg' id='a' x1='50%' x2='50%' y1='-6.063%' y2='104.848%'>
      <stop offset='0%' stop-color='#CEE7DF'/>
      <stop offset='100%' stop-color='#93D9C3'/>
    </linearGradient>
  </svg>#a
`;

const AchievementsWrapper = styled.div`
  position: relative;
  width: 130px;
  height: 120px;
`;

const IconWrapper = styled.div`
  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #737373;
    width: 86px;
    height: 87px;
    ${props => props.disabled && 'fill: #ffffff;'}
  }
`;

const BackgroundWrapper = styled.div`
  & > svg {
    position: absolute;
    fill: #eaded8;
    width: 100%;
    height: 100%;
    ${props => props.enabled && 'fill: #93d9c3;'}
  }
`;
// this component is not used
const AchievementsIconContainer = ({ icon, background, disabled, enabled }) => (
  <AchievementsWrapper>
    <BackgroundWrapper enabled={!disabled}>{background}</BackgroundWrapper>
    <IconWrapper disabled={disabled}>{icon}</IconWrapper>
  </AchievementsWrapper>
);

AchievementsIconContainer.propTypes = {
  icon: PropTypes.node,
  background: PropTypes.node,
  disabled: PropTypes.bool,
  enabled: PropTypes.bool,
};

AchievementsIconContainer.defaultProps = {
  icon: null,
  background: null,
  disabled: false,
  enabled: true,
};
export default AchievementsIconContainer;
