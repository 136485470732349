import React from 'react';
import PropTypes from 'prop-types';

const AbtFooter = ({ title, description }) => {
  return (
    <div className="jumbotron text-center bg-dark text-white">
      <div className="container">
        <h1>
          <strong>{title}</strong>
        </h1>
        <p>{description}</p>
      </div>
    </div>
  );
};

AbtFooter.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default AbtFooter;
