import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BodyContainer, MarkerButton } from 'components/design-components';
import _ from 'lodash';
import { theme } from 'components/design-components/config';

const FieldGroup = styled.div`
  padding-top: 30px;
`;

const NotifyMe = styled.div`
  padding-top: 15px;
  font-size: ${theme.fontSize.smallNormal};
`;

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultOpt: {},
      submitData: {},
      turnOff: false,
    };
    this.delayedCallback = _.throttle(this.delayChange, 300);
  }

  turnOffAll = () => {
    const { onSave } = this.props;
    const { turnOff } = this.state;
    if (turnOff) {
      return;
    }
    this.setState(prevState => {
      const { submitData } = prevState;
      const newObject = Object.assign({}, submitData);
      const saveData = _.map(newObject, val => {
        val.value = false;
        return val;
      });
      onSave(saveData);
      return {
        submitData: newObject,
        turnOff: true,
      };
    });
  };

  delayChange = name => {
    const { onSave } = this.props;
    this.setState(prevState => {
      const { submitData } = prevState;
      const data = submitData[name];
      data.value = !data.value;
      const newData = Object.assign({}, submitData, { [name]: data });
      onSave(Object.values(newData));
      return {
        submitData: newData,
        turnOff: this.isAllFalse(newData),
      };
    });
  };

  onClick = e => {
    e.persist();
    this.delayedCallback(e.target.name);
  };

  componentDidMount() {
    const { options } = this.props;
    const submitData = options.reduce((acc, value) => {
      acc[value.name] = value;
      return acc;
    }, {});

    this.setState({
      submitData,
      turnOff: this.isAllFalse(submitData),
    });
  }

  isAllFalse(data) {
    let allFalse = true;

    Object.entries(data).forEach(objectPair => {
      const objectValue = objectPair[1];

      if (objectValue.value) {
        allFalse = false;
      }
    });

    return allFalse;
  }

  render() {
    const { title, subTitle } = this.props;
    const { submitData, turnOff } = this.state;
    return (
      <BodyContainer title={title} subTitle={subTitle}>
        <NotifyMe>Notify me about:</NotifyMe>
        {_.map(submitData, e => (
          <FieldGroup key={e.name}>
            <MarkerButton label={e.label} checked={e.value} name={e.name} onChange={this.onClick} />
          </FieldGroup>
        ))}
        <FieldGroup>
          <MarkerButton
            label="Turn off all notifications"
            checked={turnOff}
            onChange={this.turnOffAll}
          />
        </FieldGroup>
      </BodyContainer>
    );
  }
}

Notification.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};

Notification.defaultProps = {
  initialValues: {},
};
export default Notification;
