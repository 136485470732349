import React, { Component } from 'react';
import ActivityDashBoard from 'pages/activity-dashboard/ActivityDashBoard';
import moment from 'moment';
import lodash from 'lodash';
import { replaceRouteParams } from 'componentsWithData/router/routeService.js';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { getS3ImageUrl } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';
import { withRouter } from 'react-router';
import { logEvent } from 'services/analytics/gaService.js';
import settings from 'services/auth/aws-settings';
import { getS3RewardImages } from 'services/s3/s3ImageUrl';

function getBadgeImage(key, disabled) {
  return `https://9qilccoxi3.execute-api.us-east-1.amazonaws.com/dev/${key}?isDisabled=${disabled}`;
}

class ActivityDashBoardWithData extends Component {
  onRewardDetailsClick = (rewardId, preview) => {
    const { history, dashBoardData } = this.props;

    history.push(replaceRouteParams(routeEnums.REWARD_DETAILS, { rewardId, fromDashboard: true }));

    logEvent({
      category: 'Reward',
      action: 'click',
      label: preview
        ? `Reward preview click, RewardID:${rewardId}, UserID:${dashBoardData.id}`
        : `Reward redeem, RewardID:${rewardId}, UserID:${dashBoardData.id}`,
    });
  };

  componentDidMount() {
    const { dashBoardData } = this.props;
    const rewards = lodash.get(dashBoardData, 'availableRewards') || [];

    logEvent({
      category: 'Rewards',
      action: 'view',
      label: 'Rewards number',
      value: rewards.length,
    });
  }

  onClickAward(title) {
    const { dashBoardData={} } = this.props;
    if(dashBoardData) {
      logEvent({
        category: 'Awards',
        action: 'click',
        label: `Award badge detail, Badge Title:${title}, UserID:${dashBoardData.id}`,
      });
    }
  }

  render() {
    const { dashBoardData, sessionHistory } = this.props;

    let earnedBadges = lodash.get(dashBoardData, 'earnedBadges') || [];
    earnedBadges = earnedBadges.map(earnedBadge => earnedBadge.badge);

    const availableBadges = lodash.get(dashBoardData, 'availableBadges') || [];
    availableBadges.forEach(badge => (badge.enabled = false));

    const totalBadges = earnedBadges.concat(availableBadges);
    const filteredBadgesBySlug = totalBadges.filter(badge => badge.slug) || [];
    const rewards = lodash.get(dashBoardData, 'availableRewards') || [];

    const dashBoardProps = {
      achievements: filteredBadgesBySlug.map(badge => ({
        id: badge.id,
        title: badge.title,
        description: badge.description || '',
        imageUrl: getBadgeImage(badge.slug, !badge.enabled),
        // return empty array, give data for image src from earnedBadges
        // usersCompleted: badge.earnedBadges.map(earnedBadge =>
        //   getS3ImageUrl(s3BucketTypes.PROFILE, lodash.get(earnedBadge, 'profile.s3Key'))
        // ),
        isDisabled: !badge.enabled,
      })),
      activity: sessionHistory,
      rewards: rewards.slice(0, 6).map(reward => ({
        id: reward.id,
        points: reward.points,
        limit: 5, //User avatars limit
        maxProgress: reward.points,
        valueProgress: lodash.get(dashBoardData, 'activity.availablePoints') || 0,
        imgSrc: getS3RewardImages(s3BucketTypes.REWARD, reward.s3Key),
        listOfImg: reward.rewardRedemptions.map(rewardRedemption =>
          getS3ImageUrl(s3BucketTypes.PROFILE, lodash.get(rewardRedemption, 'profile.s3Key'))
        ),
        title: reward.name,
      })),
      onClickDetails: this.onRewardDetailsClick,
      activityStartDate: moment(dashBoardData.created * 1000).toDate(),
      onClickAward: this.onClickAward,
      profileTier: dashBoardData.tier,
    };

    return <ActivityDashBoard {...dashBoardProps} />;
  }
}

export default withRouter(ActivityDashBoardWithData);
