import React, { Component } from 'react';
import RewardsHistoryTable from 'pages/my-account/RewardsHistoryTable.jsx';
import getRewardHistory from 'services/graphQL/queries/userSettings/reward/rewardHistoryData.js';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import moment from 'moment';
import lodash from 'lodash';
import DeliveryStatusEnum from 'enums/DeliveryStatusEnum.js';
import uniqid from 'uniqid';
import { getS3RewardImages } from 'services/s3/s3ImageUrl.js';
import s3BucketTypes from 'services/s3/enums/s3BucketTypes.js';

const subTitle = [
  {
    id: uniqid(),
    text: "You'll generally receive a confirmation email within 24 hours of redeeming.",
  },
];

export default class RewardHistoryWithData extends Component {
  state = {
    isLoading: true,
    error: null,
    rewardsData: {},
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchData() {
    this.setState({
      isLoading: true,
    });

    getRewardHistory()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const rewardRedemptions = lodash.get(data, 'data.me.rewardRedemptions');

        this.setState({
          isLoading: false,
          rewardsData: { rewardRedemptions },
        });
      })
      .catch(err => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          error: err.message,
          isLoading: false,
        });
      });
  }

  render() {
    const { isLoading, error, rewardsData } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return <div>{error}</div>;
    }

    const rewardArray = rewardsData.rewardRedemptions || [];

    const rewardsProps = {
      title: 'Reward history',
      subTitle,
      rewards: rewardArray.map(record => ({
        id: record.id,
        imageUrl: getS3RewardImages(s3BucketTypes.REWARD, lodash.get(record, 'reward.s3Key')),
        resendEmail: id => {}, //TODO Implement this when you get more info
        rewardTitle: lodash.get(record, 'reward.name'),
        rewardDescription: lodash.get(record, 'reward.description'),
        status: DeliveryStatusEnum[record.status],
        points: record.pointsUsed,
        date: moment(record.created * 1000).format('MMM D, YYYY'),
      })),
    };

    return <RewardsHistoryTable {...rewardsProps} />;
  }
}
