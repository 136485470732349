import React, { Component } from 'react';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { confirmRegistration, logOutUser } from 'services/user/userService.js';
import { Redirect } from 'react-router';
import { auto } from 'async';

export default class EmailConfirmationWithData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isConfirmed: false,
      error: null,
    };
  }

  componentDidMount() {
    logOutUser();
    this.confirmUser();
  }

  confirmUser() {
    const { match } = this.props;

    const email = window.atob(match.params.base64Email);
    const code = match.params.code;

    confirmRegistration({ email, code })
      .then(() => {
        this.setState({
          isLoading: false,
        });

        setTimeout(() => {
          this.setState({
            isConfirmed: true,
          });
        }, 5000);
      })
      .catch(error => {
        this.setState({
          isLoading: false,
          error,
        });
      });
  }

  render() {
    const { isLoading, isConfirmed, error } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (isConfirmed) {
      return (
        <RedirectNative />
      );
    }

    if (error) {
      return <h4 style={{textAlign: 'center', padding: '10%', margin: auto}}>{error.message}</h4>;
    }

    return <h4 style={{textAlign: 'center', padding: '10%', margin: auto}}>Email confirmed. You will shortly be redirected to login screen.</h4>;
  }
}

class RedirectNative extends React.Component {
  componentDidMount = () => {
    document.location = 'equell://';
    setTimeout( function(){
      document.location = routeEnums.SIGN_IN;
    }, 300);
  };

  render = () => {
       return (
        <Redirect
          to={{
            pathname: routeEnums.SIGN_IN,
            state: {},
          }}
        />
       );
  }
}