import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, ErrorText, LinkButton, Modal } from 'components/design-components';

const ClassicModalFooter = ({ loading, onCancel, onApprove, cancelText, approveText }) => (
  <Fragment>
    <LinkButton underlined onClick={onCancel}>
      {cancelText}
    </LinkButton>
    <Button loading={loading} noMargin onClick={onApprove}>
      {approveText}
    </Button>
  </Fragment>
);

const ClassicModal = ({
  opened,
  onClose,
  cancelText,
  approveText,
  onApproveParam,
  onApprove,
  title,
  errorText,
  children,
  loading,
  fullscreen,
  subTitle,
  modalTitleFontSize,
}) => {
  function handleSave() {
    if (onApproveParam) {
      onApprove(onApproveParam, onClose);
    } else {
      onApprove(onClose);
    }
  }

  return (
    <Modal
      fullscreen={fullscreen}
      title={title}
      opened={opened}
      onClose={onClose}
      subTitle={subTitle}
      footer={
        <ClassicModalFooter
          loading={loading}
          onCancel={onClose}
          onApprove={handleSave}
          cancelText={cancelText}
          approveText={approveText}
        />
      }
      modalTitleFontSize={modalTitleFontSize}
    >
      <div style={{ textAlign: 'center' }}>{children}</div>
      <ErrorText>{errorText}</ErrorText>
    </Modal>
  );
};

ClassicModal.propTypes = {
  onApprove: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  cancelText: PropTypes.string,
  approveText: PropTypes.string,
  errorText: PropTypes.string,
  onApproveParam: PropTypes.any,
  loading: PropTypes.bool,
  fullscreen: PropTypes.bool,
  modalTitleFontSize: PropTypes.number,
};

ClassicModal.defaultProps = {
  cancelText: 'Cancel',
  approveText: 'Yes',
  errorText: '',
  loading: false,
  fullscreen: false,
  modalTitleFontSize: 20,
};

export default React.memo(ClassicModal);
