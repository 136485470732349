import React, { Component } from 'react';
import PrivacyPolicy from 'pages/legal/PrivacyPolicy.jsx';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { logEvent } from 'services/analytics/gaService';

export default class PrivacyPolicyWithData extends Component {
  componentDidMount() {
    logEvent({
      category: 'Legal',
      action: 'view',
      label: 'Privacy and Policy',
    });
  }

  render() {
    return <PrivacyPolicy backToEql={routeEnums.HOME} />;
  }
}
