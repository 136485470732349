import React from 'react';

const SvgPasswordHideIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 21 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#EADED8">
        <path d="M10.5 13.286c-3.976 0-7.206-3.065-8.334-4.287C3.29 7.777 6.51 4.714 10.5 4.714c3.976 0 7.206 3.065 8.334 4.287-1.125 1.222-4.344 4.285-8.334 4.285m10.325-4.78C20.65 8.282 16.44 3 10.5 3 4.56 3 .35 8.282.175 8.506a.785.785 0 0 0 0 .988C.35 9.718 4.56 15 10.5 15c5.94 0 10.15-5.282 10.325-5.506a.785.785 0 0 0 0-.988" />
        <path d="M10.5 10.714c-1.053 0-1.91-.769-1.91-1.714s.857-1.714 1.91-1.714 1.91.77 1.91 1.714c0 .945-.857 1.714-1.91 1.714m0-5.143C8.394 5.571 6.682 7.11 6.682 9s1.712 3.429 3.818 3.429S14.318 10.89 14.318 9 12.606 5.571 10.5 5.571" />
      </g>
      <path stroke="#EADED8" strokeLinecap="square" strokeWidth={2} d="M3 16.5l15.5-15" />
    </g>
  </svg>
);

export default SvgPasswordHideIcon;
