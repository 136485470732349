import styled, { css } from 'styled-components';
import { theme } from '../config';

const calculateMobileSize = initialSizePx => {
  return css`
    font-size: calc(${initialSizePx - 3}px + 0.4vw);
  `;
};

const headerStyles = ({ fontSizePx, lineHeight, letterSpacing, fontWeight }) => css`
  ${theme.fontFamily};
  font-size: ${fontSizePx}px;
  font-weight: ${fontWeight || 'normal'};
  line-height: ${lineHeight};
  letter-spacing: ${letterSpacing || 'normal'};
  color: ${theme.colors.dark_grey};
  ${props =>
    props.textCenter &&
    css`
      text-align: center;
    `}
`;

let H1 = styled.h1(...headerStyles({ fontSizePx: 36, lineHeight: 1.33, fontWeight: 'bold' }));
let H2 = styled.h2(...headerStyles({ fontSizePx: 28, lineHeight: 1.43, fontWeight: 'bold' }));
let H3 = styled.h3(...headerStyles({ fontSizePx: 24, lineHeight: 1.5, fontWeight: 'bold' }));
let H4 = styled.h4(...headerStyles({ fontSizePx: 20, lineHeight: 1.6, fontWeight: 'bold' }));
let H5 = styled.h5(...headerStyles({ fontSizePx: 14, lineHeight: 1.71, fontWeight: 'bold' }));
let H6 = styled.h6(...headerStyles({ fontSizePx: 12, lineHeight: 1.67, letterSpacing: '5px' }));

export { H1, H2, H3, H4, H5, H6, calculateMobileSize };
