import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation CreateReward($input: CreateTeamRewardInput!) {
    createTeamReward(input: $input) {
      success
      errors
      reward {
        id
        name
        description
        points
      }
    }
  }
`;

export default function createReward(input) {
  return executeMutation(mutation, { input });
}
