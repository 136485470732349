import React, { Component } from 'react';
import { Redirect } from 'react-router';
import ResetPassword from 'pages/forgot-passwrd/ResetPassword';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { forgotPassword } from 'services/user/userService.js';
import { emailMessage, errorMessage } from 'components/design-components/toast/ToastrMsg';
import { logEvent } from 'services/analytics/gaService.js';

export default class ForgotPasswordWithData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errorMessage: '',
      emailSent: false,
    };
  }

  onSubmit({ email }) {
    this.setState({
      isLoading: true,
    });

    email = email.toLowerCase();

    forgotPassword({ email })
      .then(result => {
        emailMessage('Email sent');

        logEvent({
          category: 'User',
          action: 'forgot send',
          label: 'Forgot password send',
        });

        this.setState({
          isLoading: false,
          emailSent: true,
          email: email,
        });
      })
      .catch(error => {
        errorMessage('Error sending email');

        this.setState({
          isLoading: false,
          errorMessage: error.message || 'Email send error',
        });
      });
  }

  render() {
    const { isLoading, errorMessage, emailSent, email } = this.state;

    if (emailSent) {
      return (
        <Redirect
          to={{
            pathname: routeEnums.CHECK_EMAIL,
            state: {
              email,
              signInPath: routeEnums.SIGN_IN,
            },
          }}
        />
      );
    }

    return (
      <ResetPassword
        onSubmit={data => {
          this.onSubmit(data);
        }}
        signInLink={routeEnums.SIGN_IN}
        errorText={errorMessage}
        isLoading={isLoading}
      />
    );
  }
}
