import React from 'react';
import PropTypes from 'prop-types';
import EquelStatus from 'components/custom/nav-bar/components/EquelStatus';
import styled from 'styled-components';
import {
  border,
  NavListContainer,
  StyledNavLink,
} from 'components/custom/nav-bar/components/styled';
import { media, theme } from 'components/design-components/config';
import { Dashboard, Groups, Rewards } from 'components/design-components/icons';
import UserInfo from 'components/custom/nav-bar/components/UserInfo';
import routeEnums from 'componentsWithData/router/routeEnums.js';

const ModifiedNavLink = styled(StyledNavLink)`
  border-right: ${border};
  &:hover svg {
    fill: ${theme.colors.equell_teal};
    path {
      fill: #1ab38e;
      &#shape {
        stroke: #1ab38e;
      }
    }
  }
  ${media.phone`
    border-right: 0;
  `};
  ${media.desktop`min-width: 130px; font-size: 14px;`}
`;

const Icon = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;

  path:nth-child(2) {
    transition: fill 0.3s linear;
  }
`;

const GroupsIcon = styled(Icon)`
  font-size: 20px;
`;

const NavList = ({
  isEquelOn,
  equelOptionsOffline,
  dashboardRoute,
  userDropDown,
  userProfileRoute,
  onSignOut,
  userRouteLabel,
  navbarOpened,
}) => {
  return (
    <NavListContainer navbarOpened={navbarOpened}>
      <EquelStatus isEquelOn={isEquelOn} equelOptionsOffline={equelOptionsOffline} />
      <ModifiedNavLink to={dashboardRoute} exact>
        <Icon>
          <Dashboard />
        </Icon>
        Activity
      </ModifiedNavLink>
      <ModifiedNavLink to={routeEnums.LIST_OF_REWARDS}>
        <Icon>
          <Rewards />
        </Icon>
        Rewards
      </ModifiedNavLink>
      <ModifiedNavLink to={routeEnums.GROUPS}>
        <GroupsIcon>
          <Groups />
        </GroupsIcon>
        Groups
      </ModifiedNavLink>
      <UserInfo
        userDropDown={userDropDown}
        userProfileRoute={userProfileRoute}
        onSignOut={onSignOut}
        routeLabel={userRouteLabel}
      />
    </NavListContainer>
  );
};

NavList.propTypes = {
  isEquelOn: PropTypes.bool,
  equelOptionsOffline: PropTypes.array.isRequired,
  userDropDown: PropTypes.array.isRequired,
  onSignOut: PropTypes.func,
  userProfileRoute: PropTypes.string.isRequired,
  dashboardRoute: PropTypes.string.isRequired,
  userRouteLabel: PropTypes.string.isRequired,
  navbarOpened: PropTypes.bool,
};

NavList.defaultProps = {
  equelOptionsOffline: [],
  userDropDown: [],
  isEquelOn: false,
  navbarOpened: false,
};

export default React.memo(NavList);
