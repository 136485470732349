import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      success
      errors
      profile {
        givenName
        familyName
      }
    }
  }
`;

export default function updateProfile(input) {
  return executeMutation(mutation, { input });
}
