import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { ClassicModal, ErrorText, Input } from 'components/design-components';

const EditMemberModal = ({ opened, onSave, onValidate, user, onClose, errorText }) => {
  const [loading, setLoading] = useState(false);

  let editFormSubmit = null;
  function handleClose() {
    setLoading(false);
    onClose();
  }
  function handleSave() {
    editFormSubmit();
  }
  function handleFormSubmit(fields, events) {
    onSave(fields, user.id, handleClose, setLoading, events);
  }

  return (
    <ClassicModal
      fullscreen
      title="Edit member"
      cancelText="Cancel"
      approveText="Yes"
      loading={loading}
      opened={opened}
      onClose={handleClose}
      onApprove={handleSave}
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{ firstName: user.firstName, lastName: user.lastName }}
        validate={onValidate}
        render={({ handleSubmit }) => {
          editFormSubmit = handleSubmit;
          return (
            <form onSubmit={handleSubmit}>
              <Input name="firstName" label="First Name" />
              <Input name="lastName" label="Last Name" />
            </form>
          );
        }}
      />
      <ErrorText>{errorText}</ErrorText>
    </ClassicModal>
  );
};

EditMemberModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  errorText: PropTypes.string,
};
EditMemberModal.defaultProps = {
  errorText: '',
};

export default React.memo(EditMemberModal);
