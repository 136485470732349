import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query myAccountData {
    me {
      id
      notificationPreferences {
        id
        name
        value
      }
    }
  }
`;

export default function getEmailPreferences() {
  return executeQuery(query, {});
}
