import React from 'react';
import PropTypes from 'prop-types';
import DatePickerModified from './DatePickerModified';
import { MarkerButton } from 'components/design-components/index';
import styled from 'styled-components';

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CHECK_SINGLE = 'single';
const CHECK_MULTIPLE = 'multiple';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    let multiple = false;
    let single = true;

    if (props.day && props.day.from) {
      multiple = true;
      single = false;
    }

    this.state = {
      multiple,
      single,
    };
  }

  handleChange = e => {
    this.setState({
      multiple: e.target.value === CHECK_MULTIPLE,
      single: e.target.value === CHECK_SINGLE,
    });
  };

  renderSelectContainer = () => {
    const { single, multiple } = this.state;
    const { singleDay, rangeDates } = this.props;
    if (!(singleDay && rangeDates)) return null;
    return (
      <SelectContainer>
        <MarkerButton
          label={'Multiple Select'}
          name={'radio'}
          type="radio"
          value={CHECK_MULTIPLE}
          onChange={this.handleChange}
          checked={multiple}
        />
        <MarkerButton
          label={'Select One'}
          name={'radio'}
          type="radio"
          value={CHECK_SINGLE}
          onChange={this.handleChange}
          checked={single}
        />
      </SelectContainer>
    );
  };

  render() {
    const { multiple } = this.state;
    const { singleDay, rangeDates, day } = this.props;
    return (
      <div>
        {this.renderSelectContainer()}
        <DatePickerModified
          singleDay={singleDay}
          rangeDates={rangeDates}
          multiple={multiple}
          day={day}
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  singleDay: PropTypes.func.isRequired,
  rangeDates: PropTypes.func,
  day: PropTypes.object,
};

export default DatePicker;
