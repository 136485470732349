import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';

const Container = styled.div`
  margin-left: -17px;
  position: relative;
  border: 5px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  user-select: none;
`;

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  background-color: ${theme.colors.switch_off};
  ${props => props.url && `background-image: url(${props.url})`};
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Avatar = ({ url, children }) => {
  return (
    <Container>
      <Image url={url}>{children}</Image>
    </Container>
  );
};

Avatar.propTypes = {
  url: PropTypes.string,
  children: PropTypes.node,
};

export default React.memo(Avatar);
