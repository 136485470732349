import styled from 'styled-components';
import { theme } from '../config';

export default styled.p`
  ${theme.borderRadius};
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
`;
