import moment from 'moment';

export const formatToOrdinal = number => moment(`1000-10-${number}`).format('Do');

/**
 * Convert the date to a standardized form
 * used by tables and activity calendar
 * @param  {Date} date
 */
export const formatDateToStandard = date => moment(date).format('MMM D, YYYY');

export const pipe = (...fns) => fns.reduce((f, g) => (...args) => g(f(...args)));
