import React from 'react';
import PropTypes from 'prop-types';
import { compareTwoDates } from 'utils/time';
import { theme } from 'components/design-components/config';
import { HeaderDayIndicator } from 'components/design-components';
import moment from 'moment';
import styled from 'styled-components';

const NavItemContainer = styled.div`
  margin: 0 1px;
  height: 100%;
  cursor: pointer;
`;

const DashboardHeaderMonthItem = ({
  userFirstInteraction,
  date,
  hours,
  width,
  onClickDayMonth,
  currentDate,
}) => {
  function clickDay() {
    onClickDayMonth(date);
  }

  const notUsed =
    compareTwoDates(userFirstInteraction, date) > 0 || compareTwoDates(moment(), date) < 0;
  const padding = width < 640 ? '2px' : '5px 20px';
  let style = { marginBottom: '40px', padding };
  if (compareTwoDates(currentDate, date) === 0) {
    style = Object.assign(style, {
      borderRadius: '4px',
      boxShadow: `0 -9px 48px -13px ${theme.colors.warm_shadow}`,
    });
  }

  return (
    <NavItemContainer onClick={clickDay}>
      <HeaderDayIndicator
        notUsed={notUsed}
        isNotUsedPast={false} // TODO implement if the image for not use is changed
        totalTimeInS={hours}
        date={date}
        style={style}
      />
    </NavItemContainer>
  );
};

DashboardHeaderMonthItem.propTypes = {
  userFirstInteraction: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
  ]).isRequired,
  date: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date)]).isRequired,
  hours: PropTypes.number,
};

DashboardHeaderMonthItem.defaultProps = {
  hours: 0,
};

export default React.memo(DashboardHeaderMonthItem);
