import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/design-components';
import { formatDateToStandard } from 'utils/utils';
import DefaultTable from 'components/design-components/default-table/DefaultTable';
import uniqid from 'uniqid';

const listOfInfo = [
  {
    id: uniqid(),
    info: 'No rewards have been claimed by this team.',
  },
  {
    id: uniqid(),
    info: 'Members who do redeem points for rewards will be displayed below.',
  },
];

const listOfColumns = ['Member Name', 'Date', 'Reward Title', 'Points', 'Authorize', 'Status'];

const ClaimedRewardsTable = ({ rewards }) => {
  if (!rewards.length) {
    return <DefaultTable listOfInfo={listOfInfo} listOfColumns={listOfColumns} />;
  }
  return (
    <Table
      columns={listOfColumns}
      columnsProportions={[10, 10, 40]}
      data={rewards}
      renderRow={e => [
        e.userName,
        formatDateToStandard(e.date),
        e.title,
        e.points.toLocaleString(),
        e.authorize ? 'Authorized' : 'Unauthorized',
        e.status,
      ]}
    />
  );
};

ClaimedRewardsTable.propTypes = {
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      userName: PropTypes.string.isRequired,
      date: PropTypes.instanceOf(Date).isRequired,
      title: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired,
      authorize: PropTypes.bool.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ClaimedRewardsTable;
