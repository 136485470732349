import { css } from 'styled-components';
import { theme } from '../../config';

export default css`
  overflow: visible;
  border: 0;
  margin-top: 30px;

  * {
    border-left: 0;
  }
  .rbc-event {
    background-color: transparent;
    padding: 0;
    border: 0;
    z-index: 0;
    .rbc-event-label {
      display: none;
    }
    .rbc-event-content {
      margin: 1px 4px 0;
    }
  }

  .rbc-time-gutter {
    &.rbc-time-column {
      width: 90px;
    }
    .rbc-timeslot-group {
      border: 0;
      opacity: 0;
      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(7),
      &:nth-child(10) {
        opacity: 1;
        span {
          color: ${theme.colors.dark_grey};
        }
      }
      &:nth-child(7) .rbc-label span {
        color: transparent;
        position: absolute;
        &::after {
          content: 'Noon';
          position: absolute;
          top: 0;
          left: 0;
          color: ${theme.colors.dark_grey};
          font-weight: normal;
        }
      }

      &:nth-child(12) {
        opacity: 1;
        span {
          color: transparent;
        }
        &::after {
          content: '11:59 pm';
          color: ${theme.colors.dark_grey};
          margin: 0 0 -8px 4px;
        }
      }
      .rbc-time-slot {
        &.rbc-label {
          margin: -8px 0 0 0;
        }
      }
    }
  }
  .rbc-day-slot {
    &.rbc-time-column {
      border-bottom: 1px solid ${theme.colors.light_grey};
    }
    .rbc-timeslot-group {
      border-top: 1px solid ${theme.colors.light_grey};
      border-bottom: 0;
    }
    .rbc-time-slot {
      border: 0;
    }
    &.rbc-today {
      background-color: transparent;
    }
  }
  .rbc-current-time-indicator {
    display: none !important;
  }
`;
