import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import settings from 'services/auth/aws-settings';

const ImageBackground = styled.div`
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Image = styled.img.attrs({
  alt: 'Achievement icon',
})`
  width: 90px;
`;

const AchievementsImage = ({ index, src, inactive }) => {
  return (
    <ImageBackground index={index} inactive={inactive} badgeUrl={settings.BADGE_URL}>
      <Image src={src} inactive={inactive} />
    </ImageBackground>
  );
};

AchievementsImage.propTypes = {
  index: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  AchievementsImage: PropTypes.bool,
};

AchievementsImage.defaultProps = {
  AchievementsImage: false,
};

export default React.memo(AchievementsImage);
