import React, { Component } from 'react';
import DashboardHeader from 'pages/activity-dashboard/DashboardHeader';
import moment from 'moment';
import lodash from 'lodash';
import { logEvent } from 'services/analytics/gaService.js';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { compareTwoDates } from 'utils/time';

export default class DashboardHeaderWithData extends Component {
  state = {
    currentDay: new Date(),
    currentWeek: new Date(),
  };

  componentDidUpdate() {
    const { sessionHistory } = this.props;
    const { currentDay } = this.state;
    const daySession = this.getCurrentDaySession(sessionHistory);
    let minuteSum = 0;

    daySession.forEach(session => {
      minuteSum += moment(session.started).diff(session.ended, 'minutes');
    });

    const hourValue = Math.floor(minuteSum / 60);
    if (hourValue === 0) {
      logEvent({
        category: 'Meteor',
        action: 'has value',
        label: '0 hrs',
        value: currentDay.getTime(),
      });
    } else if (hourValue >= 4 && hourValue < 8) {
      logEvent({
        category: 'Meteor',
        action: 'has value',
        label: '4 hrs',
        value: currentDay.getTime(),
      });
    } else if (hourValue >= 8) {
      logEvent({
        category: 'Meteor',
        action: 'has value',
        label: '8 hrs',
        value: currentDay.getTime(),
      });
    }
  }

  getCurrentWeekSession(session) {
    const { currentWeek } = this.state;
    let sessions = session.filter(session => moment(session.start).isSame(currentWeek, 'week'));
        sessions = sessions.filter(session => session.points > 0);

    return sessions;
  }

    getCurrentDaySession(session) {
    const { currentDay } = this.state;

    let sessions = session.filter(session => moment(session.start).isSame(moment(currentDay), 'day'));
        sessions = sessions.filter(session => session.points > 0);

    return  sessions;
  }

  getPreviousDaySession(session) {
    const { currentDay } = this.state;
    const previousDay = moment(currentDay).subtract(1, 'day');
    let sessions = session.filter(session => moment(session.start).isSame(moment(previousDay), 'day'));
        sessions = sessions.filter(session => session.points > 0);

    return sessions;
  }

  changeWeekForward = () => {
    this.setState((state, props) => ({
      currentWeek: moment(state.currentWeek)
        .add(1, 'weeks')
        .toDate(),
    }));
  };

  changeWeekBack = () => {
    this.setState((state, props) => ({
      currentWeek: moment(state.currentWeek)
        .subtract(1, 'weeks')
        .toDate(),
    }));
  };

  changeDayForward = () => {
    this.setState((state, props) => ({
      currentDay: moment(state.currentDay)
        .add(1, 'days')
        .toDate(),
    }));
  };

  changeDayBack = () => {
    this.setState((state, props) => ({
      currentDay: moment(state.currentDay)
        .subtract(1, 'days')
        .toDate(),
    }));
  };

  onClickDayMonth = date => {
    this.setState((state, props) => {
      const currentDay = state.currentDay;
      const difference = compareTwoDates(date, currentDay);
      if (difference < 0) {
        return {
          currentDay: moment(currentDay)
            .subtract(difference * -1, 'days')
            .toDate(),
        };
      }

      if (difference > 0) {
        return {
          currentDay: moment(currentDay)
            .add(difference, 'days')
            .toDate(),
        };
      }
    });
  };

  render() {
    const { dashBoardData, sessionHistory } = this.props;
    const { currentDay, currentWeek } = this.state;

    const weekSession = this.getCurrentWeekSession(sessionHistory);
    const daySession = this.getCurrentDaySession(sessionHistory);
    const previousDaySession = this.getPreviousDaySession(sessionHistory);

    const headerProps = {
      activity: weekSession,
      singleDayActivity: daySession,
      previousDayActivity: previousDaySession,
      userFirstInteraction: moment(lodash.get(dashBoardData, 'created') * 1000).toDate(),
      redeemRewardsLink: routeEnums.LIST_OF_REWARDS,
      totalPoints: lodash.get(dashBoardData, 'activity.availablePoints') || 0,
      onChangeWeekBack: this.changeWeekBack,
      onChangeWeekForward: this.changeWeekForward,
      onChangeDayBack: this.changeDayBack,
      onChangeDayForward: this.changeDayForward,
      onClickDayMonth: this.onClickDayMonth,
      currentDate: currentDay,
      currentWeekDate: currentWeek,
    };

    return <DashboardHeader {...headerProps} />;
  }
}
