import styled from 'styled-components';
import { theme } from '../config';

export default styled.div`
  ${theme.fontFamily};
  font-size: 14px;
  font-weight: bold;
  line-height: 2;
  color: ${theme.colors.light_grey};
`;
