import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H1 } from 'components/design-components';
import { theme } from 'components/design-components/config';

const Container = styled.div`
  padding-left: 60px;
`;

const Title = styled(H1)`
  margin-top: 0;
`;

const Description = styled.div`
  color: ${theme.colors.dark_grey};
  padding-bottom: 40px;
`;

const ConditionOfUse = styled.div`
  color: ${theme.colors.dark_grey};
  font-size: ${theme.fontSize.smallNormal};
  font-weight: bold;
  padding-bottom: 20px;
`;

const Condition = styled.div`
  font-size: ${theme.fontSize.smallNormal};
  padding-bottom: 70px;
`;

const TermsOfSponsor = styled.div`
  font-size: ${theme.fontSize.xsmallNormal};
`;

const RewardInformation = ({ title, description, condition, footer }) => {
  function getMarkup(arg) {
    return { __html: arg };
  }
  return (
    <Container>
      <Title>{title}</Title>
      <Description key={'124'} dangerouslySetInnerHTML={getMarkup(description)} />
      {
        condition && (condition !== undefined) &&
        <Fragment>
          <ConditionOfUse>Condition of Use</ConditionOfUse>
          <Condition>{condition}</Condition>
        </Fragment>
      }
      {
        footer && <TermsOfSponsor dangerouslySetInnerHTML={getMarkup(footer)}/>
      }
    </Container>
  );
};

RewardInformation.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
};

export default RewardInformation;
