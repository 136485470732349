import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H4, Image } from 'components/design-components';
import { media, theme } from 'components/design-components/config';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 356px;
  border-radius: 4px;
  margin: 0 4px 4px 0;
  background-color: ${theme.colors.true_white};
  ${theme.boxShadow};
  font-size: ${theme.fontSize.normal};
  ${media.tablet`margin: 10px auto`}
  ${media.phone`width: 98%;`}
`;
const CardTitle = styled(H4)`
  margin-bottom: 20px;
`;
const CardImage = styled(Image)`
  box-shadow: 0 2px 46px 0 ${theme.colors.warm_shadow};
  margin: 0;
  width: auto;
`;
const CardBody = styled.div`
  padding: 30px;
`;
const CardFooter = styled.div`
  margin-top: 50px;
`;

const Card = ({ title, imageUrl, imageAlt, footer, children }) => {
  return (
    <CardContainer>
      {imageUrl ? <CardImage src={imageUrl} alt={imageAlt} /> : null}
      <CardBody>
        <CardTitle>{title}</CardTitle>
        {children} {footer && <CardFooter>{footer}</CardFooter>}
      </CardBody>
    </CardContainer>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  footer: PropTypes.node,
};

Card.defaultProps = {
  title: null,
  imageUrl: '',
  imageAlt: '',
  footer: null,
};

export default Card;
