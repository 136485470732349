import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, theme } from 'components/design-components/config';
import { border, NavLabel, StyledNavLink } from 'components/custom/nav-bar/components/styled';
import { SimpleArrow } from 'components/design-components/icons';

const NavDropdownContent = styled.div`
  position: absolute;
  background-color: ${theme.colors.true_white};
  min-width: 160px;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  ${media.tablet`
    position: relative;
    height: 0;
    box-shadow: none;
  `}
`;

const HeaderArrow = styled(SimpleArrow)`
  font-size: 20px;
  transition: transform 0.4s linear;
  display: inline-block;
`;

const NavItem = styled.div`
  position: relative;
  min-width: 180px;
  border-right: ${border};
  display: inline-block;
  color: red;

  ${theme.noSelect};
  &:hover {
    & svg {
      fill: ${theme.colors.equell_teal};
    }
    & ${NavLabel} {
      color: ${theme.colors.equell_teal};
    }
    & ${HeaderArrow} {
      path:nth-child(2) {
        stroke: ${theme.colors.equell_teal};
      }
      transform: rotate(180deg);
    }

    & ${NavDropdownContent} {
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
  ${media.desktop`min-width: 130px; font-size: 14px;`}
  ${media.tablet`
    width: 100%;
    border-right: 0;
    border-bottom: ${border};
  `}
`;

const DropdownContent = styled.div`
  padding: 30px;
  min-width: 230px;
  ${media.tablet`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

const NavLink = styled.div`
  ${media.tablet`
    pointer-events: none;
 `}
`;

const Label = ({ label }) => (
  <NavLabel>
    {label} <HeaderArrow />
  </NavLabel>
);

const NavLinkLabel = ({ label, route }) => (
  <NavLink>
    <StyledNavLink to={route}>
      <Label label={label} />
    </StyledNavLink>
  </NavLink>
);

const NavDropdown = ({ children, label, route, routeLabel }) => (
  <NavItem>
    {label && <Label label={label} />}
    {route && <NavLinkLabel route={route} label={routeLabel}/>}
    <NavDropdownContent>
      <DropdownContent>{children}</DropdownContent>
    </NavDropdownContent>
  </NavItem>
);

NavDropdown.propTypes = {
  isOn: PropTypes.bool,
  children: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  route: PropTypes.string,
  routeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
NavDropdown.defaultProps = {
  equelOptionsOffline: [],
  isOn: false,
};

export default React.memo(NavDropdown);
