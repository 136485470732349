import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateTeamProfile($input: UpdateTeamProfileInput!) {
    updateTeamProfile(input: $input) {
      success
      errors
    }
  }
`;

export default function updateTeamProfile(input) {
  return executeMutation(mutation, { input });
}
