import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';
import { theme } from '../config';

const StyledLinkContainer = styled.span`
  &&& > a {
    color: ${theme.colors.grey};
    text-decoration: none;
    font-size: ${props => props.fontSize && `${props.fontSize}px`};
    &:hover {
      color: ${theme.colors.equell_teal};
    }
    &:visited:not(.highlighted) {
      color: ${theme.colors.light_grey};
    }
    ${props =>
      props.highlighted &&
      css`
        color: ${theme.colors.equell_teal};
      `}
    ${props =>
      props.underlined &&
      css`
        text-decoration: underline;
      `}
       ${props =>
         props.block &&
         css`
           display: block;
         `}
  }
`;

const Link = ({
  children,
  highlighted,
  underlined,
  reactLink,
  arrowRight,
  arrowLeft,
  block,
  fontSize,
  ...props
}) => {
  const theChildren = (
    <Fragment>
      {arrowLeft && <i style={{ marginRight: 4 }} className="mdi mdi-arrow-left" />}
      {children}
      {arrowRight && <i style={{ marginLeft: 4 }} className="mdi mdi-arrow-right" />}
    </Fragment>
  );
  return (
    <StyledLinkContainer
      highlighted={highlighted}
      underlined={underlined}
      block={block}
      fontSize={fontSize}
    >
      {reactLink ? (
        <ReactLink {...props}>{theChildren}</ReactLink>
      ) : (
        <a {...props}>{theChildren}</a>
      )}
    </StyledLinkContainer>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  highlighted: PropTypes.bool,
  reactLink: PropTypes.bool,
  underlined: PropTypes.bool,
  arrowRight: PropTypes.bool,
  arrowLeft: PropTypes.bool,
  fontSize: PropTypes.number,
  block: PropTypes.bool,
};

Link.defaultProps = {
  reactLink: true,
  highlighted: false,
  arrowLeft: false,
  arrowRight: false,
  underlined: false,
  block: false,
  fontSize: 16,
};

export default Link;
