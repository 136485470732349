import React from 'react';
import PropTypes from 'prop-types';
import { MainContainer } from 'components/design-components';
import BodyContainerContent from 'components/design-components/wrappers/BodyContainerContent';

const BodyContainer = ({
  title,
  centeredTitle,
  titleCount,
  subTitle,
  children,
  after,
  customWrapper,
  noPadding,
}) => {
  const body = (
    <BodyContainerContent
      title={title}
      centeredTitle={centeredTitle}
      titleCount={titleCount}
      subTitle={subTitle}
      after={after}
      noPadding={noPadding}
    >
      {children}
    </BodyContainerContent>
  );

  const content = customWrapper ? customWrapper(body) : body;

  return <MainContainer>{content}</MainContainer>;
};

BodyContainer.propTypes = {
  title: PropTypes.string,
  titleCount: PropTypes.number,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  ]),
  children: PropTypes.node,
  after: PropTypes.node,
  customWrapper: PropTypes.func,
  noPadding: PropTypes.bool,
  centeredTitle: PropTypes.bool,
};
BodyContainer.defaultProps = {
  title: null,
  titleCount: null,
  subTitle: null,
  children: null,
  after: null,
  customWrapper: null,
  noPadding: false,
  centeredTitle: false,
};
export default React.memo(BodyContainer);
