import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query listOfGroups {
    me {
      id
      groups {
        id
        name
        s3Key
        groupInvites {
          accepted
        }
        groupProfiles {
          profile {
            id
          }
          isAdmin
        }
      }
      groupInvites {
        id
        uuid
        accepted
        group {
          s3Key
          name
        }
        invitingProfile {
          id
          givenName
          familyName
        }
      }
    }
  }
`;

export default function getGroups() {
  return executeQuery(query, {});
}
