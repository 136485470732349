import React, { Component } from 'react';
import EditTeamNameModal from 'pages/admin-dashboard/components/EditTeamNameModal.jsx';
import updateTeam from 'services/graphQL/mutations/admin/teams/updateTeam.js';
import lodash from 'lodash';
import { errorMessage, successMessage } from 'components/design-components/toast/ToastrMsg';

const teamNameDuplicateError = 'duplicate key value violates unique constraint "teams_name_idx"';

export default class EditTeamNameWithData extends Component {
  state = {
    isSubmitting: false,
    submitError: '',
  };

  onSubmit = ({ teamName }) => {
    this.setState({
      isSubmitting: true,
    });

    const { teamId } = this.props;

    updateTeam({ teamId, name: teamName })
      .then(data => {
        const success = lodash.get(data, 'data.updateTeam.success');
        const errors = lodash.get(data, 'data.updateTeam.errors') || ['Server error!'];
        const name = lodash.get(data, 'data.updateTeam.team.name') || teamName;

        if (success) {
          successMessage('Team name changed!');

          if (this.props.onTeamNameChanged) {
            this.props.onTeamNameChanged(name);
          }
          if (this.props.onModalClose) {
            this.props.onModalClose();
          }

          this.setState({
            isSubmitting: false,
          });
        } else {
          let notificationError = 'Team name not changed!';

          if (errors[0] === teamNameDuplicateError) {
            notificationError = 'Team name already exists';
          }

          errorMessage(notificationError);

          this.setState({
            isSubmitting: false,
            submitError: errorMessage,
          });
        }
      })
      .catch(error => {
        errorMessage('Team name not changed!');

        this.setState({
          isSubmitting: false,
          submitError: error.message,
        });
      });
  };

  render() {
    const { modalOpened, onModalClose, teamName, teamId } = this.props;
    const { isSubmitting, submitError } = this.state;

    const editTeamNameProps = {
      teamName: teamName,
      teamId: teamId,
      loading: isSubmitting,
      opened: modalOpened,
      onSave: this.onSubmit,
      onValidate: () => {}, //TODO Implement when necessary
      onClose: onModalClose,
    };

    return <EditTeamNameModal {...editTeamNameProps} />;
  }
}
