import React, { Component } from 'react';
import TermsOfService from 'pages/legal/TermsOfService.jsx';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { logEvent } from 'services/analytics/gaService';

export default class TermsOfServiceWithData extends Component {
  componentDidMount() {
    logEvent({
      category: 'Legal',
      action: 'view',
      label: 'Terms of Use',
    });
  }

  render() {
    return <TermsOfService backToEql={routeEnums.HOME} />;
  }
}
