import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  DefaultTable,
  DeleteButton,
  H5,
  Image,
  LinkButton,
  P,
  Table,
  TableLink,
} from 'components/design-components';
import { formatDateToStandard } from 'utils/utils';
import RemoveRewardModal from './RemoveRewardModal';

const styles = {
  img: {
    width: 170,
    maxHeight: 96,
  },
};

const RewardDeleteButton = ({ reward, onClick }) => {
  function handleDelete() {
    onClick(reward);
  }

  return <DeleteButton onClick={handleDelete} />;
};

const RewardContent = ({ rewardTitle, rewardDescription }) => (
  <Fragment>
    <H5>{rewardTitle}</H5>
    <P>{rewardDescription}</P>
  </Fragment>
);

RewardContent.propTypes = {
  rewardTitle: PropTypes.string.isRequired,
  rewardDescription: PropTypes.string.isRequired,
};

const listOfInfo = [];

const listOfColumns = ['Reward', '', 'Points', 'Dates', '', ''];

class RewardsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedDeleteModal: false,
      openedDeleteAllModal: false,
      currentModalReward: {},
    };
  }
  openDeleteRewardModal = reward =>
    this.setState({ openedDeleteModal: true, currentModalReward: reward });
  closeDeleteRewardModal = () => this.setState({ openedDeleteModal: false });
  closeDeleteAllRewardsModal = () => this.setState({ openedDeleteAllModal: false });
  approveDeleteRewardModal = () =>
    this.state.currentModalReward.onDelete(this.closeDeleteRewardModal);
  openDeleteAllRewardsModal = () => this.setState({ openedDeleteAllModal: true });
  approveDeleteAllRewardsModal = () => this.props.onDeleteAllRewards(this.closeDeleteRewardModal);
  render() {
    const {
      rewards,
      onDeleteAllRewards,
      deleteRewardErrorText,
      deleteAllRewardsErrorText,
    } = this.props;
    const { openedDeleteModal, openedDeleteAllModal, currentModalReward } = this.state;
    if (!rewards.length) {
      return <DefaultTable listOfInfo={listOfInfo} listOfColumns={listOfColumns} />;
    }
    return (
      <Fragment>
        <RemoveRewardModal
          errorText={deleteRewardErrorText}
          onClose={this.closeDeleteRewardModal}
          opened={openedDeleteModal}
          onSave={currentModalReward.onDelete}
          rewardId={currentModalReward.id}
        />
        <RemoveRewardModal
          removeAll
          errorText={deleteAllRewardsErrorText}
          onClose={this.closeDeleteAllRewardsModal}
          opened={openedDeleteAllModal}
          onSave={onDeleteAllRewards}
          rewardId={currentModalReward.id}
        />
        <Table
          columns={listOfColumns}
          data={rewards}
          renderRow={e => [
            <Image withShadow style={styles.img} src={e.imageUrl} />,
            <RewardContent rewardTitle={e.rewardTitle} rewardDescription={e.rewardDescription} />,
            e.points.toLocaleString(),
            <Fragment>
              {formatDateToStandard(e.startDate)}
              {' - '}
              {formatDateToStandard(e.endDate)}
            </Fragment>,
            <TableLink onClick={e.onEdit} id={e.id}>
              Edit
            </TableLink>,
            <RewardDeleteButton reward={e} onClick={this.openDeleteRewardModal} />,
          ]}
        />
        {onDeleteAllRewards && (
          <LinkButton
            style={{ float: 'right' }}
            onClick={this.openDeleteAllRewardsModal}
            underlined
          >
            Delete all rewards
          </LinkButton>
        )}
      </Fragment>
    );
  }
}

RewardsTable.propTypes = {
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      ...RewardContent.propTypes,
      points: PropTypes.number.isRequired,
      startDate: PropTypes.instanceOf(Date).isRequired,
      endDate: PropTypes.instanceOf(Date).isRequired,
      onEdit: PropTypes.func.isRequired,
      onDelete: PropTypes.func.isRequired,
      isArchived: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onDeleteAllRewards: PropTypes.func,
};

export default RewardsTable;
