import React, { useState } from 'react';
import { ClassicModal, ErrorText } from 'components/design-components';

const RemoveMemberModalContent = ({ isRemovingAll, user }) =>
  isRemovingAll
    ? ` Remove all members from the team? This action will delete all 
    current and pending members from your team except for you`
    : `Remove ${user.firstName} ${user.lastName} from the team?`;

const RemoveMemberModal = ({ opened, onSave, firstName, lastName, onClose, removeAll, errorText }) => {
  const [loading, setLoading] = useState(false);
  const user = {
    firstName,
    lastName
  };
  function handleClose() {
    onClose();
    // setLoading(false);
  }

  function handleSave() {
    if (removeAll) {
      onSave(onClose, setLoading);
    } else {
      onSave(setLoading);
    }
  }

  return (
    <ClassicModal
      fullscreen
      loading={loading}
      title="Remove member"
      opened={opened}
      onClose={handleClose}
      onApprove={handleSave}
    >
      <RemoveMemberModalContent isRemovingAll={removeAll} user={user} />
      <ErrorText>{errorText}</ErrorText>
    </ClassicModal>
  );
};

export default React.memo(RemoveMemberModal);
