import React, { Fragment } from 'react';
import InviteItem from './InviteItem';

const InvitesList = ({invites, updateInviteStatus, onRejectInvite}) => {
  return (
    <Fragment>
      {
        invites.map(invite => (
          <InviteItem
            key={invite.id}
            invite={invite}
            invitingGroup={invite.invitingProfile}
            updateInviteStatus={updateInviteStatus}
            rejectInvite={onRejectInvite}
          />
        ))
      }
    </Fragment>
  );
};

export default InvitesList;
