import { executeQuery } from '../clientService.js';
import gql from 'graphql-tag';

//TODO update query to
// me {
//       id
//       devices {
//         uuid
//         name
//         type
//         activity {
//           isActive
//         }
//         sessionHistory {
//           end
//         }
//       }
//     }

const query = gql`
  query myDeviceData {
    me {
      id
      devices {
        uuid
        name
        type
        inUse
        lastAccessed
      }
    }
  }
`;

export default function getDeviceData() {
  return executeQuery(query, {});
}
