import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query myAccountData {
    me {
      id
      rewardRedemptions {
        id
        reward {
          id
          name
          description
          points
          s3Key
        }
        created
        updated
        pointsUsed
        status
      }
    }
  }
`;

export default function getRewardHistory() {
  return executeQuery(query, {});
}
