import fetchSettings from './settingsFetch.js';

let settings = {
  onFetch() {},
};

function onAwsSettings(callback) {
  settings.onFetch = callback;
}

fetchSettings()
  .then(data => {
    settings = Object.assign(settings, data);
    settings.onFetch();
  })
  .catch(error => {
    //TODO Manage credentials error
  });

export default settings;

export { onAwsSettings };
