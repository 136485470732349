import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';

const Container = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;

const Progress = styled.progress`
  -webkit-appearance: none;
  appearance: none;

  width: 100%;
  height: 4px;

  &::-webkit-progress-bar {
    background-color: ${theme.colors.switch_off};
    border-radius: 25px;
  }
  &::-webkit-progress-value {
    background-color: ${theme.colors.equell_teal};
    border-radius: 25px;
  }
`;

const Points = styled.div`
  padding-top: 10px;
  font-size: ${theme.fontSize.xsmallNormal};
`;

const ProgressBar = ({ max, value, points }) => (
  <Container>
    <Progress max={max} value={value} />
    {points ? <Points>{`${points.toLocaleString()} points to redeem this reward`}</Points> : null}
  </Container>
);

ProgressBar.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  points: PropTypes.number,
};

ProgressBar.defaultProps = {
  points: 0,
};

export default React.memo(ProgressBar);
