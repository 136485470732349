import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { H1, LightText } from 'components/design-components';
import styled, { css } from 'styled-components';
import Paragraph from 'components/design-components/typography/Paragraph';
import { media, theme } from 'components/design-components/config';

const TitleContainer = styled.div`
  ${props =>
    !props.noPadding &&
    css`
      padding-top: 70px;
    `};
  ${props => !props.noPadding && media.tablet`padding-top: 30px;`};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0;
  width: 100%;
  font-weight: normal;
  font-size: ${theme.fontSize.smallNormal};
  &:first-child {
    margin-bottom: 0;
  }
`;

const BodySubtitleContainer = styled.div`
  padding: 10px 0;
`;

const StyledLightText = styled(LightText)`
  font-weight: 200;
`;

const BodySubtitle = ({ content }) => {
  if (!content) return null;
  return (
    <BodySubtitleContainer>
      {Array.isArray(content)
        ? content.map(e => <StyledParagraph key={e.id}>{e.text}</StyledParagraph>)
        : content}
    </BodySubtitleContainer>
  );
};

const BodyContainerContent = ({
  noPadding,
  after,
  title,
  centeredTitle,
  titleCount,
  subTitle,
  children,
}) => {
  return (
    <Fragment>
      {title && (
        <TitleContainer noPadding={noPadding}>
          <H1 textCenter={centeredTitle}>
            {title}
            {titleCount !== null && (
              <Fragment>
                {' '}
                <StyledLightText inline>({titleCount})</StyledLightText>
              </Fragment>
            )}
          </H1>
          {after}
        </TitleContainer>
      )}
      <BodySubtitle content={subTitle} />
      {children}
    </Fragment>
  );
};

BodyContainerContent.propTypes = {
  title: PropTypes.string,
  titleCount: PropTypes.number,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  ]),
  children: PropTypes.node,
  after: PropTypes.node,
  noPadding: PropTypes.bool,
  centeredTitle: PropTypes.bool,
};

export default React.memo(BodyContainerContent);
