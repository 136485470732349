import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageResponsive from '../ImageResponsive';

const styles = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
};

const propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  imageRight: PropTypes.bool,
};

const RowSection = ({ title, children, imageUrl, imageAlt, imageRight }) => {
  const imageContainer = (
    <div style={styles.flexCenter} className="col-md-6 p-5">
      <ImageResponsive src={imageUrl} alt={imageAlt} />
    </div>
  );
  const contentContainer = (
    <div style={styles.flexCenter} className="col-md-6">
      <h1>
        <strong>{title}</strong>
      </h1>
      <p>{children}</p>
    </div>
  );
  return (
    <div className="row">
      {imageRight ? (
        <Fragment>
          {contentContainer}
          {imageContainer}
        </Fragment>
      ) : (
        <Fragment>
          {imageContainer}
          {contentContainer}
        </Fragment>
      )}
    </div>
  );
};

RowSection.propTypes = propTypes;

export default RowSection;
