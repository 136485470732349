import React, { Component } from 'react'; 
import Navbar from 'components/custom/nav-bar/Navbar.jsx';
import getUserNavItems from './lib/UserNavItems.jsx';
import OfflineNavItems from './lib/OfflineNavItems.jsx';
import { isUserLoggedIn } from 'services/auth/userStore';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import { Redirect } from 'react-router-dom';
import { logOutUser } from 'services/user/userService.js';
import { logEvent } from 'services/analytics/gaService';
import getHeaderTitle from 'componentsWithData/router/getHeaderTitle'; 

export default class NavbarWithData extends Component {
  state = {
    isLoggedOut: false, 
  };

  logOut = () => {
    const { userId } = this.props;
    logOutUser();
    logEvent({
      category: 'Sign out',
      action: 'click',
      label: `UserID:${userId}`,
    });
    this.setState({ isLoggedOut: true });
  };

  historyBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { isLoggedOut } = this.state;
    const { fullName, isActive, location, personalData } = this.props;
    const currentLocation = location.pathname;
    const headerTitle = getHeaderTitle(currentLocation);

    if (isLoggedOut) {
      return (
        <Redirect
          to={{
            pathname: routeEnums.SIGN_IN,
            state: {},
          }}
        />
      );
    }
    
    const currentPath = location.pathname;
    let userActiveRoute = routeEnums.MY_ACCOUNT;

    // if (currentPath.includes(routeEnums.ADMIN_ACCOUNT)) {
    //   userActiveRoute = routeEnums.ADMIN_ACCOUNT;
    // }

    const navProps = {
      isEquelOn: isActive, //TODO Fetch equall status from query
      equelOptionsOffline: OfflineNavItems,
      userDropDown: getUserNavItems(),
      onSignOut: this.logOut,
      userProfileRoute: userActiveRoute,
      logoRoute: routeEnums.HOME,
      dashboardRoute: routeEnums.HOME,
      createGroupRoute: routeEnums.CREATE_GROUP,
      userRouteLabel: fullName,
      showNavList: isUserLoggedIn(),
      isAuth: [routeEnums.SIGN_IN, routeEnums.SIGN_UP].indexOf(currentPath) >= 0,
      signIn: routeEnums.SIGN_IN,
      signUp: routeEnums.SIGN_UP,
      headerTitle: headerTitle.title,
      backTo: headerTitle.back,
      createGroup: headerTitle.createGroup,
      historyBack: this.historyBack,
      personalData,
    };

    return <Navbar {...navProps} />;
  }
}
