import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../config';
import moment from 'moment';
import MeterImagesSelector from './MeterImagesSelector';
import { media } from 'components/design-components/config';

const HeaderNumber = styled.div`
  font-size: ${theme.fontSize.xlarge};
  ${media.tablet`
    font-size: ${theme.fontSize.smallNormal};
  `};
  color: ${theme.colors.dark_grey};
  text-align: center;
  font-weight: bold;
`;

const HeaderWeekday = styled.div`
  font-size: ${theme.fontSize.xsmallNormal};
  ${media.tablet`
    font-size: 8px;
  `};
  color: ${theme.colors.dark_grey};
  letter-spacing: 4.8px;
  text-align: center;
  margin-right: -0.5em;
  font-weight: normal;
`;

const HeaderProgress = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const HeaderDayIndicator = ({ date, totalTimeInS, notUsed, isNotUsedPast, ...props }) => {
  const day = moment(date).format('D');
  const label = moment(date)
    .format('ddd')
    .toUpperCase();

  return (
    <div {...props}>
      <HeaderNumber>{day}</HeaderNumber>
      <HeaderWeekday>{label}</HeaderWeekday>
      <HeaderProgress>
        <MeterImagesSelector
          notUsed={notUsed}
          isNotUsedPast={isNotUsedPast}
          timeInS={totalTimeInS}
        />
      </HeaderProgress>
    </div>
  );
};

HeaderDayIndicator.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  totalTimeInS: PropTypes.number,
  notUsed: PropTypes.bool,
  isNotUsedPast: PropTypes.bool,
};

HeaderDayIndicator.defaultProps = {
  notUsed: false,
  isNotUsedPast: false,
};

export default HeaderDayIndicator;
