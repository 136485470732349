import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SideBarContainer, SideBarMenu, SideBarMenuItem } from './styled';
import SidebarAccount from './SidebarAccount';
import { Dashboard, Account, Groups, Help, Rewards, Signout } from 'components/design-components/icons';
import routeEnums from 'componentsWithData/router/routeEnums.js';

const SideBarHeader = styled(SideBarMenuItem)`
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
`;

const SideBarHeaderText = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin-left: 17px; 
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 15px;
`;

const Icon = styled.div`
  width: 20px;
  text-align: center;
  display: flex;

  svg {
    flex: 1;
  }
`;

const IconClose = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;

  span {
    height: 2px;
    background: #1AB38E;
    display: block;
    left: -2px;
    width: 22px;
    top: 9px;
    position: absolute;
    margin: 0;

    &:nth-of-type(1) {
      transform: rotate(45deg);
    }
    &:nth-of-type(2) {
      transform: rotate(-45deg)
    }    
  }
`;

const SideBar = ({personalData, onSignOut, opened, closeSideBar}) => {
  const items = [
    { name: 'My profile', icon: <Account />, to: routeEnums.EDIT_PROFILE },
    { name: 'Activity', icon: <Dashboard />, to: routeEnums.HOME },
    { name: 'Rewards', icon: <Rewards />, to: routeEnums.LIST_OF_REWARDS },
    { name: 'Groups', icon: <Icon><Groups /></Icon>, to: routeEnums.GROUPS },
    {
      name: 'Help', icon: <Help />,
      onClick: () => { window.fcWidget.open(); window.fcWidget.show(); onCloseSideBar(); }
    },
  ];
  const [active, setActive] = useState(false);
  const sideBar = useRef(null);

  function handleClick(e) {
    if(sideBar.current && sideBar.current.contains(e.target)) {
      return;
    }
    onCloseSideBar();
  }
  function onCloseSideBar() {
    closeSideBar();
    document.getElementById('body').removeAttribute('style');
  }
  useEffect(() => {
    document.getElementById('body').setAttribute('style', 'overflow: hidden');
    setTimeout(() => {
      setActive(true);
    }, 1);
    document.addEventListener('click', handleClick);
    return () => {
      document.getElementById('body').removeAttribute('style');
      document.removeEventListener('click', handleClick);
    };
  },[]);
  
  return (
    <SideBarContainer opened={opened}>
      <SideBarMenu opened={active} ref={sideBar}>
        <SideBarHeader>
          <IconClose onClick={closeSideBar}>
            <span></span>
            <span></span>
          </IconClose>
          <SideBarHeaderText>equell</SideBarHeaderText>
        </SideBarHeader>
        <SidebarAccount personalData={personalData} />
        {
          items.map((item, index) => (
            <SideBarMenuItem key={index}>
              {
                item.to ? (
                  <Link to={item.to} onClick={onCloseSideBar}>
                    <ItemContent>
                      {item.icon}
                      <span>{item.name}</span>
                    </ItemContent>
                  </Link>
                ) : (
                  <a href="javascript:;" onClick={item.onClick}>
                    <ItemContent>
                      {item.icon}
                      <span>{item.name}</span>
                    </ItemContent>
                  </a>
                )
              }
            </SideBarMenuItem>
          ))
        }
        <SideBarMenuItem>
          <a href="javascript:;" onClick={onSignOut}>
            <ItemContent>
              <Signout />
              <span>Sign out</span>
            </ItemContent>
          </a>
        </SideBarMenuItem>
      </SideBarMenu>
    </SideBarContainer>
  );
};

export default SideBar;
