import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      success
      errors
      team {
        name
      }
    }
  }
`;

export default function updateTeam(input) {
  return executeMutation(mutation, { input });
}
