import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, H4, Image } from 'components/design-components';
import ListOfAvatars from 'components/design-components/avatar/ListOfAvatars';
import ProgressBar from 'components/design-components/progressBar/ProgressBar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 10px;
  ${props =>
    props.rewardDetails &&
    css`
      margin: 0;
      padding: 0;
      width: 350px;
      height: auto;
      background-color: white;
      box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    `}
  ${props =>
    props.cropImage &&
    css`
      flex-shrink: 0
    `}
`;

const InfoContainer = styled.div`
  flex-grow: 1;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.rewardDetails &&
    css`
      padding-bottom: 45px;
    `}
`;

const StyledH4 = styled(H4)`
  width: 200px;
  cursor: pointer;
`;

const Points = styled.div`
  font-size: 16px;
  padding: 10px;
`;

const ProgressBarContainer = styled.div`
  width: 200px;
`;

const ImageContainer = styled.div`
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  min-width: 170px;
  margin: 0;
`;

const StyledImage = styled(Image)`
  margin: 0;
    
  ${props =>
    !props.cropImage &&
    css`
    object-fit: cover;
    width: 350px;
    height: 219px;
  `}
`;

const RewardItem = ({
  id,
  points,
  imgSrc,
  title,
  listOfImg,
  limit,
  maxProgress,
  valueProgress,
  redeemPoints,
  onClickDetails,
  profileTier,
  showAvatarList,
  rewardDetails,
  loading,
  cropImage
}) => {
  function handleClickDetails() {
    onClickDetails(id);
  }

  function previewReward() {
    onClickDetails(id, true);
  }

  showAvatarList = showAvatarList && (profileTier != 'free');

  return (
    <Container rewardDetails={rewardDetails} cropImage={cropImage}>
      <ImageContainer onClick={previewReward} cropImage={cropImage}>
        <StyledImage src={imgSrc} cropImage={cropImage} withShadow />
      </ImageContainer>
      <InfoContainer rewardDetails={rewardDetails}>
        <StyledH4 onClick={previewReward}>{title}</StyledH4>
        <Points>{`${maxProgress.toLocaleString()} points`}</Points>
        {showAvatarList && <ListOfAvatars listOfImg={listOfImg} limit={limit} />}
        <ProgressBarContainer>
          {maxProgress <= valueProgress ? (
            <StyledButton loading={loading} onClick={handleClickDetails}>
              redeem reward
            </StyledButton>
          ) : (
            <ProgressBar max={maxProgress} value={valueProgress} points={redeemPoints} />
          )}
        </ProgressBarContainer>
      </InfoContainer>
    </Container>
  );
};

RewardItem.propTypes = {
  id: PropTypes.string.isRequired,
  showAvatarList: PropTypes.bool,
  rewardDetails: PropTypes.bool,
  points: PropTypes.number,
  limit: PropTypes.number,
  redeemPoints: PropTypes.number,
  maxProgress: PropTypes.number.isRequired,
  valueProgress: PropTypes.number.isRequired,
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  listOfImg: PropTypes.arrayOf(PropTypes.string.isRequired),
  onClickDetails: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
RewardItem.defaultProps = {
  points: 0,
  limit: 0,
  redeemPoints: 0,
  showAvatarList: true,
  rewardDetails: false,
  listOfImg: [],
  loading: false,
};

export default React.memo(RewardItem);
