import React, { Fragment } from 'react';
import InviteItem from './InviteItem';

const MyGroups = ({myId, groups, createGroupLink, onEditGroup, onViewGroup}) => {
  return (
    <Fragment> 
      {
        groups.map(group => (
          <InviteItem
            myId={myId}
            onEditGroup={onEditGroup}
            onViewGroup={onViewGroup}
            createGroupLink={createGroupLink}
            group={group}
            key={group.id}
          />
        ))
      }
    </Fragment>
  );
};

export default MyGroups;
