import React, { Component } from 'react';
import AddMembers from 'pages/admin-dashboard/AddMembers.jsx';
import routeEnums from 'componentsWithData/router/routeEnums.js';
import addTeamProfile from 'services/graphQL/mutations/admin/members/addTeamProfile.js';
import getTeamIds from 'services/graphQL/queries/admin/teams/teamIds.js';
import lodash from 'lodash';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import { emailMessage, errorMessage } from 'components/design-components/toast/ToastrMsg';
import { logEvent } from 'services/analytics/gaService.js';

export default class AddMembersWithData extends Component {
  state = {
    isLoading: true,
    fetchError: null,
    validationError: '',
    submitError: '',
    isSubmitting: false,
    disableSubmit: false,
  };

  componentDidMount() {
    this.__isComponentMounted = true;
    this.fetchTeam();
  }

  componentWillUnmount() {
    this.__isComponentMounted = false;
  }

  fetchTeam() {
    getTeamIds()
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const team = lodash.get(data, 'data.me.teams.0');

        if (team) {
          this.teamId = team.id;
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(error => {
        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          isLoading: false,
          fetchError: error.message,
        });
      });
  }

  onSend = ({ members }) => {
    const { history } = this.props;
    const teamId = this.teamId;

    this.setState({
      isSubmitting: true,
      submitError: '',
    });

    addTeamProfile({ teamId, emails: members })
      .then(data => {
        if (!this.__isComponentMounted) {
          return;
        }

        const success = lodash.get(data, 'data.createTeamInvites.success');
        const errors = lodash.get(data, 'data.createTeamInvites.errors');

        this.setState({
          isSubmitting: false,
        });

        if (success) {
          emailMessage('Member added');

          logEvent({
            category: 'Users',
            action: 'invited',
            label: 'Users invited',
            value: members.split(',').length,
          });

          history.push(routeEnums.ADMIN_MEMBERS);
        } else {
          errorMessage('Member not added');

          this.setState({
            submitError: errors[0],
          });
        }
      })
      .catch(error => {
        errorMessage('Member not added');

        if (!this.__isComponentMounted) {
          return;
        }

        this.setState({
          isSubmitting: true,
          submitError: error.message,
        });
      });
  };

  onValidate = data => {
    const members = data.members || '';
    const emailList = members ? members.split(',').map(e => e.trim()) : [];
    let valid = true;

    emailList.forEach(email => {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!emailRegex.test(email)) {
        valid = false;
      }
    });

    if (!valid) {
      this.setState({
        disableSubmit: true,
        validationError: 'Emails not valid!',
      });
    } else {
      this.setState({
        disableSubmit: false,
        validationError: '',
      });
    }
  };

  render() {
    const { validationError, submitError, isSubmitting, disableSubmit, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const errorText = `${validationError}\n${submitError}`;

    const membersProps = {
      membersLink: routeEnums.ADMIN_MEMBERS,
      onValidate: this.onValidate,
      onSave: this.onSend,
      disabledSubmit: disableSubmit,
      isLoading: isSubmitting,
      cancelLink: routeEnums.ADMIN_MEMBERS,
      errorText: validationError || submitError ? errorText : null,
    };

    return <AddMembers {...membersProps} />;
  }
}
