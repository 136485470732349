import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query FullName {
    me {
      id
      givenName
      familyName
      email
      tier
      freshchatRestoreId
      s3Key
      activity {
        isActive
      }
    }
  }
`;

export default function getFullName() {
  return executeQuery(query, {});
}
