import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';
import { Button, ImageUpload } from 'components/design-components';
import Loader from 'components/mouldifi-components/CircularProgress';

const ProfileImage = styled.div`
  width: 355px;
  position: relative;
  top: -35px;
`;

const ImageLimits = styled.div`
  float: right;
  font-size: 0.8em;
  color: lightgrey;
  padding-top: 6px;
`;

const ImageUploadField = styled.input`
  display: none;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
  font-size: ${theme.fontSize.smallNormal};
`;

const UploadContainer = styled.div`
  height: 200px;
  background-color: ${theme.colors.faded_teal};
  border-radius: 8px;
`;

const InnerContainer = styled.div`
  padding: 12px;
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  border-color: white;
  color: white;
`;

const ImageContainer = styled.div`
  width: 154px;
  height: 154px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 5px solid white;
  margin-right: 15px;
  align-self: center;
`;

const ImageComponent = styled.img`
  object-fit: cover;
  border-radius: 50%;
  height:154px;
  width:154px;
`;

const DragAndDropContainer = styled.div`
  min-width: 355px;
  height: 200px;
`;

//TODO need update for upload image
class ProfileImageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: null,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { imgSrc } = this.props;
    this.setState({
      imgSrc,
    });
  }

  setFile = file => {
    const { uploadedFile } = this.props;
    uploadedFile(file);
    this.setState({
      imgSrc: file,
    });
  };

  handleImageChanged = e => {
    const imageObj = e.target.files[0];
    const { fileSizeLimit, fileSizeError } = this.props;
    if (fileSizeLimit && imageObj.size > fileSizeLimit) {
      fileSizeError();
      return;
    }
    const reader = new FileReader();
    const { uploadedFile } = this.props;

    reader.onload = res => {
      const b64string = res.target.result;
      // this.setState({ imgSrc: b64string });
      uploadedFile(b64string);
      // tricks react-final-form to believe that it's a normal event when doing e.target.value
    };
    reader.readAsDataURL(imageObj);
  };

  clickButton = () => {
    this.myRef.current.click();
  };

  render() {
    const { accept, fileSizeLimit, fileSizeError, isLoading, imgSrc } = this.props;
    if (imgSrc) {
      return (
        <ProfileImage>
          <Paragraph>Profile Image <ImageLimits>(maximum 200kB)</ImageLimits></Paragraph>
          <UploadContainer>
            <InnerContainer>
              {isLoading ? (
                <Loader />
              ) : (
                <Fragment>
                  <ImageComponent src={imgSrc} />
                  <StyledButton onClick={this.clickButton}>Change</StyledButton>
                  <ImageUploadField
                    name="imageUrl"
                    type="file"
                    accept={accept}
                    onChange={this.handleImageChanged}
                    ref={this.myRef}
                  />
                </Fragment>
              )}
            </InnerContainer>
          </UploadContainer>
        </ProfileImage>
      );
    }
    return (
      <DragAndDropContainer>
        {isLoading ? (
          <ProfileImage>
            <Paragraph>Profile Image</Paragraph>
            <UploadContainer>
              <Loader />
            </UploadContainer>
          </ProfileImage>
        ) : (
          <ImageUpload
            name="imageUrl"
            label="Profile Image"
            getFile={this.setFile}
            bgColor={theme.colors.faded_teal}
            fileSizeLimit={fileSizeLimit}
            fileSizeError={fileSizeError}
          />
        )}
      </DragAndDropContainer>
    );
  }
}

ProfileImageContainer.propTypes = {
  fileSizeLimit: PropTypes.number,
  fileSizeError: PropTypes.func,
  imgSrc: PropTypes.string,
  accept: PropTypes.string,
  uploadedFile: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ProfileImageContainer.defaultProps = {
  fileSizeLimit: null,
  imgSrc: '',
  accept: '.jpg,.jpeg,.png',
};

export default React.memo(ProfileImageContainer);
