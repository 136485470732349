import React from 'react';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';
import { ToastContainer } from 'react-toastify';

const StyledToastr = styled.div`
  && .Toastify__toast-container {
    max-width: 250px;
    top: 8em;
  }
  && .Toastify__toast {
    ${theme.boxShadow};
    border-radius: 4px;
  }
`;

const ContainerToastr = () => {
  return (
    <StyledToastr>
      <ToastContainer closeButton={false} autoClose={2500} hideProgressBar={true} />
    </StyledToastr>
  );
};

export default React.memo(ContainerToastr);
