import React from 'react';
import PropTypes from 'prop-types';
import EventCard from '../styled/EventCard';
import EventTooltip from './EventTooltip';
import { TimeText } from 'components/design-components/index';
import moment from 'moment';
import BedtimeMode from 'components/design-components/activity-calendar/components/BedtimeMode';

const Event = ({ event: { disabled, start, end, points } }) => {
  const minutes = moment(end).diff(moment(start), 'minutes');
  const defaultText = <TimeText>{moment.duration(minutes, 'minutes')}</TimeText>;
  const text = disabled ? <BedtimeMode time={defaultText} /> : defaultText;

  return (
    <EventCard title="" disabled={disabled}>
      <EventTooltip start={start} end={end} points={points} text={text} />
    </EventCard>
  );
};

Event.propTypes = {
  event: PropTypes.shape({
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
    points: PropTypes.number,
    disabled: PropTypes.bool,
  }),
};

export default Event;
