export default {
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  EMAIL_CONFIRMATION: '/confirm-email/:code/:base64Email',
  CHECK_EMAIL: '/check-email',
  UPDATE_PASSWORD: '/password-reset/:code/:base64Email',
  FORGOT_PASSWORD: '/forgot-password',
  DOWNLOAD_AND_INSTALL: '/download-and-install/:type',
  MY_ACCOUNT: '/my-account',
  EDIT_PROFILE: '/my-account/edit-profile',
  EMAIL_PREFERENCES: '/my-account/email-preferences',
  REWARD_HISTORY: '/my-account/reward-history',
  DEVICES: '/my-account/devices',
  ADMIN_ACCOUNT: '/admin',
  REWARD_INVENTORY: '/admin/reward-inventory',
  CLAIMED_REWARDS: '/admin/claimed-rewards',
  ADD_REWARD: '/admin-add-reward',
  EDIT_REWARD: '/admin-edit-reward/:rewardId',
  CREATE_TEAM: '/admin/create-team',
  ADMIN_MEMBERS: '/admin/members',
  ADD_MEMBERS: '/admin/add-members',
  REWARD_DETAILS: '/reward-details/:rewardId',
  TERMS_OF_SERVICE: '/terms-of-service',
  PRIVACY_POLICY: '/privacy-policy',
  ABOUT: '/about',
  HOME: '/',
  LIST_OF_REWARDS: '/list-of-rewards',
  GROUPS: '/groups',
  CREATE_GROUP: '/create-group',
  EDIT_GROUP: '/edit-group/:groupId',
  VIEW_GROUP: '/group/:groupId',
  VIEW_GROUP_ITEM: '/group',
  EDIT_GROUP_ITEM: '/edit-group',
};
