import React from 'react';
import PropTypes from 'prop-types';
import { BodyContainer, Link, Row, Col } from 'components/design-components';
import AddMembersForm from './components/AddMembersForm';

const AddMembers = ({ membersLink, ...props }) => (
  <BodyContainer
    title="Add members to equell"
    before={
      <Link to={membersLink} arrowLeft>
        Back to Members
      </Link>
    }
  >
    <Row>
      <Col width={1} />
      <Col width={10}>
        <AddMembersForm {...props} />
      </Col>
      <Col width={1} />
    </Row>
  </BodyContainer>
);

AddMembers.propTypes = {
  membersLink: PropTypes.string.isRequired,
  ...AddMembersForm.propTypes,
};
AddMembers.defaultProps = {};

export default React.memo(AddMembers);
