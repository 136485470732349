import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateReward($input: UpdateTeamRewardInput!) {
    updateTeamReward(input: $input) {
      success
      errors
      reward {
        id
        name
        description
        points
      }
    }
  }
`;

export default function updateReward(input) {
  return executeMutation(mutation, { input });
}
