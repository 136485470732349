import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/design-components/index';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';

const StyledP = styled.p`
  margin: 0;
  padding-top: 5px;
`;

const NoRewardsContainer = styled.div`
  padding-top: 30px;
  font-size: ${theme.fontSize.smallNormal};
`;

const InfoContainer = styled.div`
  margin-bottom: 30px;
`;

const DefaultTable = ({ listOfInfo, listOfColumns }) => {
  const renderRow = new Array(listOfColumns.length).fill('-');
  return (
    <NoRewardsContainer>
      {listOfInfo.length > 0 && (
        <InfoContainer>
          {listOfInfo.map(({ id, info }) => (
            <StyledP key={id}>{info}</StyledP>
          ))}
        </InfoContainer>
      )}

      <Table
        columns={listOfColumns}
        columnsProportions={[20, 10, 20]}
        data={[{}, {}]}
        renderRow={() => renderRow}
      />
    </NoRewardsContainer>
  );
};

DefaultTable.propTypes = {
  listOfInfo: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
    })
  ),
  listOfColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DefaultTable.defaultProps = {
  listOfInfo: [],
  listOfColumns: [],
};

export default React.memo(DefaultTable);
