import { clearUser, storeUser } from 'services/auth/userStore';
import {
  authenticateUser,
  changeUserPassword,
  confirmUserRegistration,
  getSessionUser,
  resetForgottenPassword,
  sendForgotPasswordEmail,
  signUpUser,
  signUserOut,
} from 'services/aws-cognito/cognito-service';
import { initS3Settings } from 'services/s3/s3Settings.js';

function identifyLoggedInUser(session) {
  const userInfo = {};
  return new Promise((resolve, reject) => {
    const jwtToken = session.getIdToken().getJwtToken();

    Object.assign(userInfo, { jwtToken });
    storeUser(userInfo);

    initS3Settings()
      .then(data => {
        resolve(userInfo);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function checkSessionUser() {
  return new Promise((resolve, reject) => {
    getSessionUser()
      .then(session => {
        return identifyLoggedInUser(session);
      })
      .then(userInfo => {
        resolve(userInfo);
      })
      .catch(err => {
        reject(err);
      });
  });
}

function logInUser({ email, password }) {
  return new Promise((resolve, reject) => {
    authenticateUser({ email, password })
      .then(session => {
        return identifyLoggedInUser(session);
      })
      .then(userInfo => {
        resolve(userInfo);
      })
      .catch(reject);
  });
}

function logOutUser() {
  signUserOut();
  clearUser();
}

function registerUser({ email, password, firstName, lastName, location }) {
  return signUpUser({ email, password, firstName, lastName, location });
}

function forgotPassword({ email }) {
  return sendForgotPasswordEmail({ email });
}

function resetPassword({ email, verificationCode, password }) {
  return resetForgottenPassword({ email, verificationCode, password });
}

function confirmRegistration({ email, code }) {
  return confirmUserRegistration({ email, code });
}

function changePassword({ oldPassword, newPassword }) {
  return changeUserPassword({ oldPassword, newPassword });
}

export {
  logInUser,
  registerUser,
  forgotPassword,
  resetPassword,
  checkSessionUser,
  logOutUser,
  confirmRegistration,
  changePassword,
};
