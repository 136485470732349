import React from 'react';

const SvgChrome = props => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h18v18H0z" />
      <path
        fill="#737373"
        fillRule="nonzero"
        d="M5.201 6.662L3.567 1.825A8.96 8.96 0 0 1 9 0c3.35 0 6.272 1.83 7.822 4.545h-7.61a4.457 4.457 0 0 0-4.01 2.117zM13.461 9a4.454 4.454 0 0 1-1.886 3.64l-5.649 4.821a9 9 0 0 0 11.61-11.323H12.42A4.441 4.441 0 0 1 13.46 9zm-9.108 7.709l3.885-3.315a4.464 4.464 0 0 1-3.572-3.34l-2.38-7.046A8.965 8.965 0 0 0 0 9a8.995 8.995 0 0 0 4.353 7.709zM9 11.788a2.788 2.788 0 1 0 0-5.576 2.788 2.788 0 0 0 0 5.576z"
      />
    </g>
  </svg>
);

export default SvgChrome;
