import React from 'react';

const SvgIconCamera = props => (
  <svg width="1em" height="1em" viewBox="0 0 62 57" {...props}>
    <path
      fillRule="evenodd"
      d="M8.25 8.25V0h5.5v8.25H22v5.5h-8.25V22h-5.5v-8.25H0v-5.5h8.25zm7.85 16.083v-8.166h8.1V8h18.9l4.941 5.444H56.6c2.97 0 5.4 2.45 5.4 5.445v32.667C62 54.55 59.57 57 56.6 57H13.4C10.43 57 8 54.55 8 51.556V24.333h8.1zM35.5 49C42.952 49 49 42.952 49 35.5S42.952 22 35.5 22 22 28.048 22 35.5 28.048 49 35.5 49zM27 35.5a8.49 8.49 0 0 0 8.5 8.5 8.49 8.49 0 0 0 8.5-8.5 8.49 8.49 0 0 0-8.5-8.5 8.49 8.49 0 0 0-8.5 8.5z"
    />
  </svg>
);

export default SvgIconCamera;
