import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  text1: {
    textTransform: 'uppercase',
    letterSpacing: 8,
    fontWeight: 'bold',
  },
};

const Header = ({ text1, text2, text3 }) => (
  <div className="jumbotron text-center m-0">
    <h4 style={styles.text1}>{text1}</h4>
    <h1>{text2}</h1>
    <h3>{text3}</h3>
  </div>
);

Header.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
};
export default Header;
