import React from 'react';
import PropTypes from 'prop-types';
import HeaderContentTitle from './HeaderContentTitle';
import HeaderNavigation from 'components/content/HeaderNavigation';
import { HeaderContainerStyled, StyledMainContainer } from 'components/content/styled';

const HeaderContent = ({ mobileGroupView, noTitle, children, navItems, headerVisible, navItemsSpread, ...props }) => {
  if (!headerVisible) return null;
  return (
    <HeaderContainerStyled mobileGroupView>
      <StyledMainContainer>
        {children}
        { !noTitle && <HeaderContentTitle {...props} /> }
        <HeaderNavigation navItems={navItems} navItemsSpread={navItemsSpread} />
      </StyledMainContainer>
    </HeaderContainerStyled>
  );
};

HeaderContent.propTypes = {
  children: PropTypes.node,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    })
  ),
  navItemsSpread: PropTypes.bool,
  ...HeaderContentTitle.propTypes,
};

HeaderContent.defaultProps = {
  navItems: [],
  headerVisible: true,
  navItemsSpread: false,
};

export default HeaderContent;
