import { executeQuery } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const query = gql`
  query s3Settings {
    systemInformation {
      imagesS3Bucket
      region
    }
  }
`;

export default function getS3Settings() {
  return executeQuery(query, {});
}
