import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateGroupProfile($input: UpdateGroupProfileInput!) {
    updateGroupProfile(input: $input) {
      success
      errors
    }
  }
`;

export default function updateGroupProfile({ groupId, profileId, givenName, familyName }) {
  return executeMutation(mutation, { input: { groupId, profileId, givenName, familyName } });
}
