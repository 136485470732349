import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ClassicModal, ErrorText } from 'components/design-components';

const RemoveMemberModalContent = ({ isRemovingAll, user }) =>
  isRemovingAll
    ? ` Remove all members from the team? This action will delete all 
    current and pending members from your team except for you`
    : `Remove ${user.firstName} ${user.lastName} from the team?`;

const RemoveMemberModal = ({ opened, onSave, user, onClose, removeAll, errorText }) => {
  const [loading, setLoading] = useState(false);

  function handleClose() {
    onClose();
    // setLoading(false);
  }

  function handleSave() {
    if (removeAll) {
      onSave(handleClose, setLoading);
    } else {
      onSave(user, handleClose, setLoading);
    }
  }

  return (
    <ClassicModal
      fullscreen
      loading={loading}
      title="Remove member"
      opened={opened}
      onClose={handleClose}
      onApprove={handleSave}
    >
      <RemoveMemberModalContent isRemovingAll={removeAll} user={user} />
      <ErrorText>{errorText}</ErrorText>
    </ClassicModal>
  );
};

RemoveMemberModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  user: PropTypes.object,
  removeAll: PropTypes.bool,
  errorText: PropTypes.string,
};

RemoveMemberModal.defaultProps = {
  removeAll: false,
  errorText: '',
  user: {},
};

export default React.memo(RemoveMemberModal);
