import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentCSS from './styled/ContentCSS';
import HeaderCSS from './styled/HeaderCSS';
import ToolbarCSS from './styled/ToolbarCSS';

const Wrapper = styled.div`
  height: 700px;
  .rbc-calendar {
    .rbc-toolbar {
      ${ToolbarCSS}
    }
    .rbc-time-view {
      border: 0;
      .rbc-time-header {
        ${HeaderCSS}
      }
      .rbc-time-content {
        ${ContentCSS}
      }
    }
  }
`;

const ActivityCalendarContainer = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

ActivityCalendarContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActivityCalendarContainer;
