import 'react-big-calendar/lib/css/react-big-calendar.css';
import { css } from 'styled-components';
import {
  NunitoBlack,
  NunitoBold,
  NunitoExtraBold,
  NunitoExtraLight,
  NunitoLight,
  NunitoRegular,
  NunitoSemiBold,
} from './availableFonts';

export default css`
  @font-face {
    font-family: 'NunitoCustom';
    src: url(${NunitoBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoCustom';
    src: url(${NunitoBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoCustom';
    src: url(${NunitoExtraBold}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoCustom';
    src: url(${NunitoExtraLight}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoCustom';
    src: url(${NunitoLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoCustom';
    src: url(${NunitoSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'NunitoCustom';
    src: url(${NunitoRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;
