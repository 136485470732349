import { css } from 'styled-components';

export const theme = {
  colors: {
    true_white: '#ffffff',
    equell_teal: '#1AB38E',
    dark_grey: '#505050',
    warm_white: '#fdfcfb',
    faded_teal: '#CEE7DF',
    grey: '#737373',
    warm_fill: '#FDFCFB',
    warm_shadow: 'rgba(109, 50, 50, 0.25)',
    light_grey: '#C1C1C1',
    error_red: '#AE0015',
    switch_off: '#eaded8',
    dark: '#333333',
  },
  transition: '.3s ease',
  boxShadow: 'box-shadow: 0 0 10px 0 rgba(109, 50, 50, 0.24);',
  borderRadius: 'border-radius: 31.8px',
  noSelect: css`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  `,
  noEvents: css`
    pointer-events: none;
  `,
  fontFamily: css`
    font-family: NunitoCustom, Arial, sans-serif;
  `,
  fontSize: {
    xlarge: '20px',
    large: '18px',
    normal: '16px',
    smallNormal: '14px',
    xsmallNormal: '12px',
    extraLarge: '24px',
  },
  navbarHeight: 79,
};

export const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
};

// https://www.styled-components.com/docs/advanced/#media-templates
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
