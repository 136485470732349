import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'components/design-components/config.js';
import moment from 'moment';
import uniqid from 'uniqid';

const TimeNumber = styled.span`
  font-size: 28px;
`;
const TimeLabel = styled.span`
  font-size: ${theme.fontSize.xsmallNormal};
  margin-left: 1px;
`;
const TimeSection = styled.span`
  margin-right: 4px;
`;
const TimeText = ({ children, timePeriods }) =>
  timePeriods.reduce((arr, e) => {
    const number = children.get(e);
    if (!number) return arr;
    const label = e[0];
    arr.push(
      <TimeSection key={uniqid()}>
        <TimeNumber>{number}</TimeNumber>
        <TimeLabel>{label}</TimeLabel>
      </TimeSection>
    );
    return arr;
  }, []);

TimeText.propTypes = {
  children: PropTypes.object.isRequired,
  timePeriods: PropTypes.array,
};
TimeText.defaultProps = {
  timePeriods: ['hours', 'minutes', 'seconds'],
};

export default TimeText;
