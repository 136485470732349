import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import uniqid from 'uniqid';
import styled from 'styled-components';
import { theme } from '../config';
import MarkerButton from 'components/design-components/form/MarkerButton';
import { ToggleIcons } from 'components/design-components/form/styled';
import { PasswordHideIcon, PasswordShowIcon } from 'components/design-components/icons';

const TogleMarkedPassword = styled.div`
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 45%;
  color: ${theme.colors.grey};
  width: 70px;
`;

const StyledToggleIcons = styled(ToggleIcons)`
  svg {
    font-size: 20px;
    fill: ${theme.colors.equell_teal};
  }
`;

const InstructionsList = styled.ul`
  list-style-type: none;
  ${theme.fontFamily};
  color: ${theme.colors.grey};
  padding: 0;
  margin: 0 0 10px;
  text-align: left;
`;

const EyeToggle = ({ onPasswordVisibleChange, passwordVisible }) => (
  <StyledToggleIcons onClick={onPasswordVisibleChange}>
    {passwordVisible ? <PasswordShowIcon /> : <PasswordHideIcon />}
  </StyledToggleIcons>
);

const RadioToggle = ({ onPasswordVisibleChange, passwordVisible }) => (
  <TogleMarkedPassword>
    <MarkerButton
      label={passwordVisible ? 'Hide' : 'Show'}
      onChange={onPasswordVisibleChange}
      value={passwordVisible}
      widthMarker={18}
      borderRadius={4}
      fontSize={12}
      borderSize={1}
    />
  </TogleMarkedPassword>
);

class PasswordInput extends React.Component {
  state = {
    showToggle: false,
  };

  onChange = e => {
    const { showToggle } = this.state;
    if (e.target.value && !showToggle) {
      this.setState({
        showToggle: true,
      });
    }
    if (!e.target.value) {
      this.setState({
        showToggle: false,
      });
    }
  };

  render() {
    const {
      passwordVisible,
      onPasswordVisibleChange,
      passwordInstructions,
      error,
      ...rest
    } = this.props;
    const { showToggle } = this.state;
    return (
      <Fragment>
        <Input
          {...rest}
          customOnChange={this.onChange}
          type={passwordVisible ? 'text' : 'password'}
          inputClassName="with-icon"
          error={error}
        >
          {showToggle && (
            <EyeToggle
              onPasswordVisibleChange={onPasswordVisibleChange}
              passwordVisible={passwordVisible}
            />
          )}
        </Input>
        <InstructionsList>
          {passwordInstructions.map(e => (
            <li key={uniqid()}>{e}</li>
          ))}
        </InstructionsList>
      </Fragment>
    );
  }
}

PasswordInput.propTypes = {
  ...Input.propTypes,
  passwordVisible: PropTypes.bool,
  error: PropTypes.bool,
  onPasswordVisibleChange: PropTypes.func,
  passwordInstructions: PropTypes.arrayOf(PropTypes.string),
};

PasswordInput.defaultProps = {
  passwordInstructions: [],
  error: false,
};

export default PasswordInput;
