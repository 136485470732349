import React from 'react';

const SvgMeterThumbnail6H = props => (
  <svg viewBox="0 0 41.49 26.21" {...props} width="1em" height="1em">
    <defs>
      <linearGradient
        id="a"
        x1={-903.4}
        x2={-904.34}
        y1={104.95}
        y2={105.22}
        gradientTransform="matrix(26.84 0 0 -14.14 24278.23 1500.33)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#3fddb6" />
        <stop offset={1} stopColor="#1ab38e" />
      </linearGradient>
    </defs>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          fill="none"
          stroke="#f2e1d9"
          strokeLinecap="round"
          strokeWidth={12}
          d="M35.49 20.21A14.48 14.48 0 0 0 20.75 6 14.48 14.48 0 0 0 6 20.21"
        />
        <path
          fill="none"
          stroke="#3fddb6"
          strokeLinecap="round"
          strokeWidth={8}
          d="M32.62 11.79a14.56 14.56 0 0 0-3.17-3A15.07 15.07 0 0 0 20.74 6a14.69 14.69 0 0 0-14.2 10.37A13.86 13.86 0 0 0 6 20.21"
        />
        <path
          fill="none"
          stroke="url(#a)"
          strokeLinecap="round"
          strokeWidth={8}
          d="M32.61 11.68a15.07 15.07 0 0 0-12-5.78C12.42 5.9 5.77 12.23 5.77 20"
        />
        <ellipse cx={33.42} cy={12.5} fill="#fdfcfb" rx={1.65} ry={1.61} />
      </g>
    </g>
  </svg>
);

export default SvgMeterThumbnail6H;
