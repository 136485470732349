import React from 'react';
import { BodyContainer } from 'components/design-components';
import { Form } from 'react-final-form';
import { Input } from 'components/design-components';
import GroupImageContainer from './components/GroupImageContainer';
import DeleteInvite from './components/DeleteInvite';
import {
  StyledHeader,
  StyledLink,
  Container,
  StyledH1,
  StyledH4,
  StyledP,
  ButtonGroup,
  FormRow,
  FormWrap,
  FormContainer,
  CancelButton,
  CreateButton,
  FormHelper,
  ErrorText,
} from './styled';

const EditGroup = ({
  groupsLink,
  disable,
  isSubmitting,
  groupTitleMaxLength,
  fields,
  isImageLoading,
  isRemoving,
  imgSrc,
  fileSizeLimit,
  fileSizeError,
  removeGroup,
  onFileSelect,
  onUpdateGroup,
  onDeleteInvite,
  onChange,
  errorEmail,
  errorName,
  errorEmailText,
}) => {
  const uploadedFile = file => {
    if(onFileSelect) {
      onFileSelect(file);
    }
  };

  return (
    <BodyContainer>
      <Container>
        <StyledLink to={groupsLink}>← Back to Groups</StyledLink>
        <StyledHeader>
          <StyledH1>Edit Group</StyledH1>
        </StyledHeader>
        <Form
          initialValues={fields}
          onSubmit={onUpdateGroup}
          render={({ handleSubmit, pristine }) => (
            <FormRow>
              <FormWrap onSubmit={handleSubmit}>
                <GroupImageContainer
                  isImageLoading={isImageLoading}
                  imgSrc={imgSrc}
                  uploadedFile={uploadedFile}
                  fileSizeLimit={fileSizeLimit}
                  fileSizeError={fileSizeError}
                />
                <FormContainer>
                  <Input
                    maxLength={groupTitleMaxLength}
                    name="name"
                    label="Group name"
                    customOnChange={onChange}
                    error={errorName}
                  />
                  <StyledH4>Invite people to join your group</StyledH4>
                  {
                    errorEmailText &&
                    <ErrorText>{errorEmailText}</ErrorText>
                  }
                  <FormHelper>
                    <Input
                      name="email"
                      disabled={fields.email}
                      type="email"
                      label="Add email address"
                      customOnChange={onChange}
                      error={errorEmail}
                    />
                    {
                      !fields.sentEmail && (fields.email.trim() !== '') &&
                      <DeleteInvite
                        field={fields && fields.email}
                        onDeleteInvite={onDeleteInvite}
                        isRemoving={isRemoving}
                      />
                    }
                  </FormHelper>
                  <FormHelper>
                    <Input
                      name="email1"
                      disabled={fields.email1}
                      type="email"
                      label="Add email address"
                      customOnChange={onChange}
                      error={errorEmail}
                    />
                    {
                      !fields.sentEmail1 && (fields.email1.trim() !== '') &&
                      <DeleteInvite
                        field={fields && fields.email1}
                        onDeleteInvite={onDeleteInvite}
                        isRemoving={isRemoving}
                      />
                    }
                  </FormHelper>
                  <StyledP>As a free member you can add up to 2 people to your group.</StyledP>
                </FormContainer>
                <ButtonGroup>
                  <CancelButton onClick={removeGroup}>Delete group</CancelButton>
                  <CreateButton loading={isSubmitting} disabled={disable} isSubmit>Save</CreateButton>
                </ButtonGroup>
              </FormWrap>
            </FormRow>
          )}/>
      </Container>  
    </BodyContainer>
  );
};

export default EditGroup;
