import React from 'react';
import PropTypes from 'prop-types';
import DropDown from './DropDown';
import ErrorField from './ErrorField';
import FormGroup from './FormGroup';
import { Field } from 'react-final-form';
import ListItem from '../dropdown-list/Item';
import uniqid from 'uniqid';

const DropdownInput = ({ name, label, items }) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormGroup>
          <DropDown label={label} value={items[input.value]}>
            {Object.values(items).map((e, i) => {
              function handleChange() {
                input.onChange({ target: { value: Object.keys(items)[i] } });
              }
              return <ListItem key={uniqid()} label={e} index={i} onClick={handleChange} />;
            })}
          </DropDown>
          {meta.error && <ErrorField>{meta.error}</ErrorField>}
        </FormGroup>
      )}
    </Field>
  );
};

DropdownInput.propTypes = {
  items: PropTypes.object.isRequired, // { key: value } type
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};
DropdownInput.defaultProps = {
  label: '',
};
export default React.memo(DropdownInput);
