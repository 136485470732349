import { executeMutation } from 'services/graphQL/clientService.js';
import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateNotifications($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      success
      errors
      profile {
        notificationPreferences {
          id
          name
          value
        }
      }
    }
  }
`;

export default function updateNotifications(input) {
  return executeMutation(mutation, { input });
}
