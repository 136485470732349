import React from 'react';
import PropTypes from 'prop-types';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';

const WEEKDAYS_SHORT = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const DatePickerContainer = styled.div`
  .DayPicker {
    width: 100%;
  }

  .DayPicker-Month {
    width: 100%;
  }
  .DayPicker-Day {
    outline: none;
  }
  .DayPicker-Day--disabled {
    pointer-events: none;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--outside):not(.DayPicker-Day--end):not(:first-of-type):not(:last-of-type) {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50%;
      right: 0;
      width: 200%;
      right: 0;
      z-index: -1;
      background-color: ${theme.colors.switch_off};
    }
  }
  .Range
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${theme.colors.switch_off};
    color: ${theme.colors.dark_grey};
    border-radius: 0;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${theme.colors.switch_off};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: ${theme.colors.equell_teal};
    color: ${theme.colors.true_white};

    &:hover {
      background-color: ${theme.colors.equell_teal};
    }
  }

`;

class DatePickerModified extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    const { day, multiple } = this.props;
    if (day && !multiple) {
      this.setState({
        selectedDays: day,
      });
    }
    if (day && day.from && day.to) {
      this.setState({
        from: day.from,
        to: day.to,
        enteredTo: day.to, // Keep track of the last day for mouseEnter.
      });
    }
  }

  getInitialState() {
    return {
      selectedDays: null,
      from: null,
      to: null,
      enteredTo: null, // Keep track of the last day for mouseEnter.
    };
  }

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  handleClickMultiple(day) {
    const { rangeDates } = this.props;
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      rangeDates({ from: null, to: null });
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      this.setState(
        {
          to: day,
          enteredTo: day,
        },
        () => {
          const { from, to } = this.state;
          rangeDates({ from, to });
        }
      );
    }
  }

  handleDayMouseEnter = day => {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  };

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  handleDayClick = (day, { selected, outside }) => {
    if (outside) return;
    const { multiple } = this.props;

    if (multiple) {
      this.handleClickMultiple(day);
    } else {
      this.handleSingleClick(day, selected);
    }
  };

  handleSingleClick = (day, selected) => {
    const { singleDay } = this.props;
    let selectedDays = null;
    if (!selected) {
      selectedDays = day;
    }
    this.setState({
      selectedDays,
    });
    singleDay(selectedDays || null);
  };

  render() {
    const { multiple } = this.props;

    if (multiple) {
      const { from, enteredTo } = this.state;
      const modifiers = { start: from, end: enteredTo };
      const disabledDays = { before: this.state.from };
      const selectedDaysRange = [from, { from, to: enteredTo }];
      return (
        <DatePickerContainer>
          <DayPicker
            className="Range"
            modifiers={modifiers}
            selectedDays={selectedDaysRange}
            disabledDays={disabledDays}
            fromMonth={from}
            month={from}
            showOutsideDays
            onDayClick={this.handleDayClick}
            onDayMouseEnter={this.handleDayMouseEnter}
            weekdaysShort={WEEKDAYS_SHORT}
          />
        </DatePickerContainer>
      );
    }

    const { selectedDays } = this.state;
    return (
      <DatePickerContainer>
        <DayPicker
          selectedDays={selectedDays}
          showOutsideDays
          onDayClick={this.handleDayClick}
          onDayMouseEnter={this.handleDayMouseEnter}
          weekdaysShort={WEEKDAYS_SHORT}
        />
      </DatePickerContainer>
    );
  }
}

DatePickerModified.propTypes = {
  multiple: PropTypes.bool,
  singleDay: PropTypes.func.isRequired,
  rangeDates: PropTypes.func.isRequired,
  day: PropTypes.object,
};

export default DatePickerModified;
