import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media, theme } from '../config';

const ButtonElement = styled.button`
  cursor:pointer;
  border-radius: 4px;
  ${theme.fontFamily};
  font-size: ${theme.fontSize.normal};
  text-align: center;
  background-color: transparent;
  padding: 15px 30px;
  border: 1px solid ${props => props.mainColor};
  color: ${props => props.mainColor};
  ${props => (!props.noMargin ? 'margin: 2px 2px 14px;' : '')}
  ${theme.noSelect}
  :hover:not(.inactive):not([disabled]) {
    background-color: ${props => props.mainColor};
    color: ${theme.colors.true_white};
  }
  &.inactive,
  &:disabled {
    border-color: ${theme.colors.light_grey};
    color: ${theme.colors.light_grey};
    cursor: auto;
  }
  ${props => props.fullwidth && 'width: 100%'}
  ${props => props.loading && theme.noEvents}
  ${media.phone`width: 100%`};
`;
const Button = ({ loading, children, ...props }) => (
  <ButtonElement loading={loading} {...props}>
    {loading ? <i className="mdi mdi-loading mdi-spin" /> : children}
  </ButtonElement>
);
Button.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  noMargin: PropTypes.bool,
  mainColor: PropTypes.string,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  fullWidth: false,
  noMargin: false,
  loading: false,
  mainColor: theme.colors.equell_teal,
};

export default Button;
