import React from 'react';
import PropTypes from 'prop-types';
import { Link, MainContainer } from 'components/design-components';

import styled from 'styled-components';
import RewardContainer from './components/RewardContainer';

const Container = styled.div`
  padding-top: 79px;
`;

const RewardDetails = ({
  dashboardLink,
  title,
  rewardId,
  description,
  footer,
  imageUrl,
  onRedeem,
  pricePoints,
  currentPoints,
  conditions,
  isLoading,
  backFrom,
}) => {
  return (
    <MainContainer>
      <Container>
        <Link to={dashboardLink} arrowLeft>
          {backFrom}
        </Link>
        <RewardContainer
          rewardId={rewardId}
          title={title}
          imgSrc={imageUrl}
          points={currentPoints}
          valueProgress={currentPoints}
          maxProgress={pricePoints}
          redeemPoints={pricePoints - currentPoints}
          onRedeemReward={onRedeem}
          description={description}
          condition={conditions}
          footer={footer}
          isLoading={isLoading}
          cropImage
        />
      </Container>
    </MainContainer>
  );
};

RewardDetails.propTypes = {
  dashboardLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onRedeem: PropTypes.func.isRequired,
  conditions: PropTypes.string.isRequired,
  backFrom: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  pricePoints: PropTypes.number.isRequired,
  currentPoints: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};

export default React.memo(RewardDetails);
