import React from 'react';
import PropTypes from 'prop-types';
import { BodyContainer, Link } from 'components/design-components';
import RewardForm from './components/RewardForm';
import styled from 'styled-components';
import { media } from 'components/design-components/config';

const WrapperContainer = styled.div`
  padding: 100px 0 50px 0;
  ${media.tablet`padding: 10px 0 5px 0`}
`;

const CompleteRewards = ({ rewardsInventoryLink, title, ...formProps }) => {
  return (
    <BodyContainer
      title={title}
      before={
        <Link to={rewardsInventoryLink} arrowLeft>
          Back to Inventory
        </Link>
      }
    >
      <WrapperContainer>
        <RewardForm cancelLink={rewardsInventoryLink} {...formProps} />
      </WrapperContainer>
    </BodyContainer>
  );
};

CompleteRewards.propTypes = {
  rewardsInventoryLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onValidate: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  disabledSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  titleMaxLength: PropTypes.number,
  descriptionMaxLength: PropTypes.number,
  fileSizeLimit: PropTypes.number,
  fileSizeError: PropTypes.func,
  onFileSelect: PropTypes.func,
  isImageLoading: PropTypes.bool,
};

CompleteRewards.defaultProps = {
  fileSizeLimit: null,
};

export default React.memo(CompleteRewards);
