import styled from 'styled-components';
import PropTypes from 'prop-types';

const LightText = styled.div`
  font-weight: ${props => props.weight};
  ${props => props.inline && 'display: inline-block'}
`;

LightText.propTypes = {
  children: PropTypes.node.isRequired,
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inline: PropTypes.bool,
};

LightText.defaultProps = {
  weight: 400,
  inline: false,
};

export default LightText;
