import React, { Component } from 'react';
import DownloadAndInstall from 'pages/download-and-install/DownloadAndInstall';
import DownloadDesktopImage from 'public/download_desktop.png';
import DownloadChromeImage from 'public/download_chrome.png';
import DownloadMobileImage from 'public/download_mobile.png';

const downloadContent = {
  desktop: {
    title: 'Download equell for Mac or Windows ',
    description: 'Digital balance and access to equell on your computer.',
    imageUrl: DownloadDesktopImage,
    downloadLinks: [
      {
        title: 'Download Mac',
        description: 'macOS Sierra 10.12 or later',
        route: '#', //TODO Add route when get more info
      },
      {
        title: 'Download Windows',
        description: 'Windows 8 or later',
        secondary: true,
        route: '#', //TODO Add route when get more info
      },
    ],
  },
  mobile: {
    title: 'Download equell for iOS or Android',
    description: 'Digital balance and access to equell on your phone or tablet.',
    imageUrl: DownloadMobileImage,
    downloadLinks: [
      {
        title: 'Download iOS',
        route: '#', //TODO Add route when get more info
      },
      {
        title: 'Download Android',
        secondary: true,
        route: '#', //TODO Add route when get more info
      },
    ],
  },
  'chrome-extension': {
    title: 'Add equell extension to Chrome ',
    description: 'Digital balance and access to equell on your browser.',
    imageUrl: DownloadChromeImage,
    downloadLinks: [
      {
        title: 'Add to Chrome',
        route: '#', //TODO Add route when get more info
      },
    ],
  },
};

export default class SignInWithData extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match } = this.props;
    const downloadType = match.params.type;

    return <DownloadAndInstall {...downloadContent[downloadType]} />;
  }
}
