import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from 'components/design-components/config';

const Switch = styled.label`
  position: relative;
  display: inline-block;
  ${props => props.width && `width:${props.width}px`}
  ${props => props.height && `height:${props.height}px`}
  ${props => props.disabled && 'pointer-events: none;'}

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const SliderContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props =>
    !props.disabled ? theme.colors.switch_off : theme.colors.light_grey};
  -webkit-transition: 0.8s;
  transition: 0.8s;
  border-radius: 31.8px;
  display: flex;
  align-items: center;
  ${theme.noSelect}
`;

const Slider = styled.span`
  position: absolute;
  content: '';
  height: ${props => `${props.ballSize}px`}
  width: ${props => `${props.ballSize}px`}
  right: 9px;
  background-color: ${theme.colors.true_white};
  -webkit-transition: 0.8s;
  transition: 0.8s;
  border-radius: 50%;
  z-index: 1;

  &:hover {
    ${theme.boxShadow}
  }
`;

const SliderText = styled.span`
  position: absolute;
  font-size: ${props => `${props.fontSize}px`};
  ${props => (props.right ? `right:${props.fontSize}px` : `left:${props.fontSize}px`)};
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  &:checked + ${SliderContainer} {
    background-color: ${theme.colors.equell_teal};
  }
  &:checked + ${SliderContainer} > ${Slider} {
    -webkit-transform: translateX(${props => `-${props.size - props.ballSize - 18}px`});
    -ms-transform: translateX(${props => `-${props.size - props.ballSize - 18}px`});
    transform: translateX(${props => `-${props.size - props.ballSize - 18}px`});
  }
`;

const SwitchButton = props => {
  const { width, onClick, checked, height, fontSize, disabled, onChange, ...rest } = props;
  const ballSize = height - 17;
  return (
    <Switch width={width} height={height} disabled={disabled}>
      <Checkbox checked={checked} size={width} ballSize={ballSize} onChange={onChange} {...rest} />
      <SliderContainer disabled={disabled} onClick={onClick}>
        {!checked && (
          <SliderText left fontSize={fontSize}>
            off
          </SliderText>
        )}
        <Slider ballSize={ballSize} />
        {checked && (
          <SliderText right fontSize={fontSize}>
            on
          </SliderText>
        )}
      </SliderContainer>
    </Switch>
  );
};

SwitchButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

SwitchButton.defaultProps = {
  width: 116,
  height: 52,
  fontSize: 16,
  disabled: false,
  checked: false,
};

export default React.memo(SwitchButton);
