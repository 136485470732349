import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import AppLocale from '../lngProvider';
import lodash from 'lodash';
import { BodyContainer, MainApp } from 'components/design-components/styleComponents';
import NavbarWithData from 'componentsWithData/utilComponents/NavbarWithData.jsx';
import FooterWithData from 'componentsWithData/utilComponents/FooterWithData.jsx';
import Error404 from 'componentsWithData/Errors/Error404.jsx';

import AuthRoute from './router/AuthRoute';
import routes from './router/routes.js';
import Loader from 'componentsWithData/utilComponents/LoaderContainer';
import getFullName from 'services/graphQL/queries/user/getFullName.js';

import { checkSessionUser } from 'services/user/userService.js';
import { initSettings } from 'services/aws-cognito/cognito-service.js';
import { onAwsSettings } from 'services/auth/aws-settings.js';
import { initFreshchat, initFreshchatUser } from 'services/freshchat/freshchatService.js';
import EventEmitter, { EventsEnum } from 'services/events/eventsService.js';
import ContainerToastr from 'components/design-components/toast/ContainerToastr';

import { initializeGaClient, logPageView } from 'services/analytics/gaService.js';
import { onUserCreatedFreshchat } from '../services/freshchat/freshchatService';
import updateProfile from '../services/graphQL/mutations/userSettings/updateProfile';

class App extends Component {
  state = {
    isLoading: true,
    fullName: 'User',
    isActive: false,
    userId: '',
    email: '',
    completeName: '',
    s3Key: null,
  };

  componentDidMount() {
    const { history } = this.props;
    initializeGaClient();

    onAwsSettings(() => {
      initSettings();
      checkSessionUser()
        .then(userInfo => {
          this.fetchUserData(false);
        })
        .catch(() => {
          this.setState({
            isLoading: false,
          });
        });
    });

    history.listen(this.onRouteChange);

    EventEmitter.on(EventsEnum.FETCH_USER_DATA, this.fetchUserData);
  }

  onRouteChange = (location, action) => {
    logPageView(location.pathname);
  };

  componentWillUnmount() {
    EventEmitter.off(EventsEnum.FETCH_USER_DATA, this.fetchUserData);
  }

  fetchUserData = handleLoading => {
    if (handleLoading) {
      this.setState({ isLoading: true });
    }

    getFullName()
      .then(data => {
        const userId = lodash.get(data, 'data.me.id');
        const firstName = lodash.get(data, 'data.me.givenName') || 'User';
        const familyName = lodash.get(data, 'data.me.familyName') || '';
        const completeName = `${firstName} ${familyName}`.trim();
        const isActive = lodash.get(data, 'data.me.activity.isActive');
        const fullName = `${firstName}`;
        const email = lodash.get(data, 'data.me.email');
        const s3Key = lodash.get(data, 'data.me.s3Key');
        const tier = lodash.get(data, 'data.me.tier');
        const freshchatRestoreId = lodash.get(data, 'data.me.freshchatRestoreId');

        initFreshchat(freshchatRestoreId, userId);
        initFreshchatUser(userId, firstName, email);
        onUserCreatedFreshchat(restoreId => {
          updateProfile({ freshchatRestoreId: restoreId });
        });
        this.setState({
          isLoading: false,
          fullName,
          completeName,
          email,
          s3Key,
          isActive,
          userId,
        });
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { isLoading, fullName, completeName, email, s3Key, isActive, userId } = this.state;
    const personalData = {
      completeName, email, s3Key
    };
    const currentAppLocale = AppLocale['en'];

    if (isLoading) {
      return <Loader />;
    }

    return (
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <MainApp>
          <Switch>
            {routes.map(
              ({
                path,
                component: Component,
                requireUser,
                requireNoUser,
                routes = [],
                withHeader,
                withFooter,
                exactRoute,
                noPaddingBody
              }) => {
                if (requireUser || requireNoUser) {
                  return (
                    <AuthRoute
                      key={path}
                      path={path}
                      requireUser={requireUser}
                      requireNoUser={requireNoUser}
                      routes={routes}
                      exact={exactRoute}
                      render={props => (
                        <Fragment>
                          {withHeader && (
                            <NavbarWithData
                              fullName={fullName}
                              isActive={isActive}
                              location={props.location}
                              history={props.history}
                              userId={userId}
                              personalData={personalData}
                            />
                          )}
                          <BodyContainer noPadding={noPaddingBody}>
                            <Component userId={userId} routes={routes} {...props} />
                          </BodyContainer>
                          {withFooter && <FooterWithData />}
                        </Fragment>
                      )}
                    />
                  );
                } else {
                  return (
                    <Route
                      key={path}
                      path={path}
                      routes={routes}
                      render={props => (
                        <Fragment>
                          {withHeader && (
                            <NavbarWithData
                              fullName={fullName}
                              isActive={isActive}
                              location={props.location}
                              userId={userId}
                            />
                          )}
                          <BodyContainer noPadding={noPaddingBody}>
                            <Component routes={routes} {...props} />
                          </BodyContainer>
                          {withFooter && <FooterWithData />}
                        </Fragment>
                      )}
                    />
                  );
                }
              }
            )}
            <Route
              render={props => (
                <Fragment>
                  <NavbarWithData
                    fullName={fullName}
                    isActive={isActive}
                    location={props.location}
                    userId={userId}
                  />
                  <BodyContainer>
                    <Error404 {...props} />
                  </BodyContainer>
                  <FooterWithData />
                </Fragment>
              )}
            />
          </Switch>
          <ContainerToastr />
        </MainApp>
      </IntlProvider>
    );
  }
}

export default withRouter(App);
