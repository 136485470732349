import React from 'react';
import PropTypes from 'prop-types';
import { Container, HoverBg, Wrapper } from 'components/design-components/dropdown-list/styled';

const Item = ({ index, onClick, label }) => {
  function handleClick() {
    onClick(index);
  }
  return (
    <Wrapper>
      <HoverBg />
      <Container onClick={handleClick}>{label}</Container>
    </Wrapper>
  );
};

Item.propTypes = {
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default React.memo(Item);
