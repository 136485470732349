import moment from 'moment';
import React from 'react';

export const splitTimesToNonDays = datesArray =>
  datesArray.reduce(
    (newdatesArray, { start, end, ...someOtherIncomingPropsThatShouldBePreserved }) => {
      let mStart = moment(start);
      const mEnd = moment(end);
      // diff doesn't work
      if (mStart.dayOfYear() !== mEnd.dayOfYear()) {
        for (let i = 0; i <= Math.abs(mStart.dayOfYear() - mEnd.dayOfYear()); i++) {
          newdatesArray.push({
            start: mStart.toDate(),
            end: mStart
              .endOf('day')
              .subtract(1, 'seconds')
              .toDate(),
            ...someOtherIncomingPropsThatShouldBePreserved,
          });
          mStart = mStart.add(1, 'days').startOf('day');
        }
        // last part that is on the same day as mEnd
        newdatesArray.push({
          start: mStart.toDate(),
          end: mEnd.toDate(),
          ...someOtherIncomingPropsThatShouldBePreserved,
        });
      } else {
        newdatesArray.push({ start, end, ...someOtherIncomingPropsThatShouldBePreserved });
      }
      return newdatesArray;
    },
    []
  );

/**
 * Returns the difference in milliseconds of an array of start and end dates [{start, end}, ....]
 *
 * @param  {array} datesArray - Array of start and end dates
 * @param  {array} [convertTo="milliseconds"]  convertTo - Will convert the calculated diffs into the specified time format
 * @returns {number}
 */
export const sumTimesDifferences = (datesArray, convertTo = 'milliseconds') =>
  datesArray.reduce((sum, { start, end }) => sum + moment(end).diff(moment(start), convertTo), 0);

/**
 * Returns the last or the first date that has the end set
 *
 * @param  {array} datesArray - Array of start and end dates
 * @param {bool} isFirst - If true the function will return the first set time
 * @returns {number}
 */
export const getLastOrFirstSetTime = (datesArray, isFirst = false) =>
  datesArray.reduce((comp, { start, end }) => {
    if (!isFirst && end > comp) return end;
    if (isFirst && comp > start) return start;
    return comp;
  }, new Date(datesArray[0].start));

/**
 * Filters all the dates in the datesArray to match in between the startDate and endDate
 *
 * @param datesArray
 * @param startDate
 * @param endDate
 */
export const getDatesBetweenDates = (datesArray, startDate, endDate) =>
  datesArray.filter(({ start }) => startDate <= start && start <= endDate);

/**
 * creates an array that contains 7 entries with total sum of the activity
 * @param datesArray
 * @param unit
 * @returns {array}
 */

export const sumIntoWeeksArray = (datesArray, unit = 'seconds') =>
  datesArray.reduce((acc, { start, end }) => {
    const currentIntervalDuration = moment(end).diff(moment(start), unit);
    const currentDayIndexOfTheCurrentWeek = moment(start).day();
    acc[currentDayIndexOfTheCurrentWeek] =
      acc[currentDayIndexOfTheCurrentWeek] + currentIntervalDuration;
    return acc;
  }, Array(7).fill(0));

/**
 * Returns a 2-tuple like so: [canNavigatePrevious, canNavigateForward] where canNavigatePrevious, canNavigateForward are booleans
 * @param currentDate
 * @param firstDateUsed
 * @param lastDateUsed
 * @returns {array}
 */

export const hasNextOrPreviousWeek = (currentDate, firstDateUsed, lastDateUsed) => {
  let canNavigatePrevious = true;
  let canNavigateForward = true;

  const nextStartOfWeek = moment(currentDate)
    .add(1, 'week')
    .startOf('week')
    .toDate();
  if (nextStartOfWeek > lastDateUsed) canNavigateForward = false;

  const currentStartOfWeek = moment(currentDate)
    .startOf('week')
    .toDate();
  if (firstDateUsed > currentStartOfWeek) canNavigatePrevious = false;

  // check if the current date is in a week that doesn't have any activity
  if (nextStartOfWeek < new Date()) canNavigateForward = true;
  return [canNavigatePrevious, canNavigateForward];
};

/**
 * Returns the difference of two dates in days
 * @param firstDate
 * @param secondDate
 * @returns Number
 */

export const compareTwoDates = (firstDate, secondDate) =>
  moment(firstDate)
    .startOf('day')
    .diff(moment(secondDate).startOf('day'), 'days');

/**
 * Does something that I don't know how to explain
 * see https://www.probabilitycourse.com/chapter1/1_2_2_set_operations.php
 *
 * Example1:
 * datesObject = [..........]
 * hammer      =      [.........]
 *               |    |
 * it returns what is in datesObject without the intersection
 *               [....]
 *
 * Example2:
 * datesObject =      [..........]
 * hammer      = [.........]
 *                          |    |
 * it returns what is in datesObject without the intersection
 *                          [....]
 *
 * Example3:
 * datesObject =              [..........]
 * hammer      = [.........]
 * or
 *  * datesObject =  [..........]
 * hammer         =                 [.........]
 * it returns datesObject
 * =======================================
 * DOES NOT WORK IF THE HAMMER IS TOTALLY INSIDE THE DATESOBJECT (actually it works but it should return the same datesObject)
 *
 * @param datesObject Must take { start, end }
 * @param hammer Must take { start, end }
 */
export const subtractTimeInterval = (datesObject, hammer) => {
  const { start, end } = datesObject;
  const { start: hammerStart, end: hammerEnd } = hammer;
  // console.log(datesObject, hammer, start <= hammerStart, end <= hammerEnd, end >= hammerStart);
  if (start <= hammerStart && end <= hammerEnd && end >= hammerStart)
    return {
      ...datesObject,
      start,
      end: hammerStart,
    }; // see example 1
  if (hammerStart <= start && hammerEnd <= end && start <= hammerEnd)
    return { ...datesObject, start: hammerEnd, end }; // see example 2
  if (hammerEnd <= start || end <= hammerStart) return datesObject;
  return datesObject;
};
