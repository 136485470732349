import React, { Component } from 'react';
import CheckYourEmail from 'pages/forgot-passwrd/CheckYourEmail';

export default class ForgotPasswordWithData extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { email, signInPath } = this.props.location.state;

    return <CheckYourEmail email={email} signInPath={signInPath} />;
  }
}
