import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import { PointValueEnum, RewardDurationEnum } from 'enums';
import RewardFormContent from './RewardFormContent';

const RewardFormContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const RewardForm = ({
  onValidate,
  fields,
  onSave,
  disabledSubmit,
  titleMaxLength,
  descriptionMaxLength,
  cancelLink,
  errorText,
  isLoading,
  fileSizeLimit,
  fileSizeError,
  onFileSelect,
  isImageLoading,
  imageUrl,
  onImageRemove,
}) => {
  return (
    <RewardFormContainer>
      <Form
        initialValues={fields}
        onSubmit={onSave}
        validate={onValidate}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <RewardFormContent
              titleMaxLength={titleMaxLength}
              descriptionMaxLength={descriptionMaxLength}
              errorText={errorText}
              cancelLink={cancelLink}
              submitting={submitting}
              pristine={pristine}
              disabledSubmit={disabledSubmit}
              isLoading={isLoading}
              fileSizeLimit={fileSizeLimit}
              fileSizeError={fileSizeError}
              onFileSelect={onFileSelect}
              isImageLoading={isImageLoading}
              imageUrl={imageUrl}
              onImageRemove={onImageRemove}
            />
          </form>
        )}
      />
    </RewardFormContainer>
  );
};

RewardForm.propTypes = {
  onValidate: PropTypes.func,
  cancelLink: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  disabledSubmit: PropTypes.bool,
  titleMaxLength: PropTypes.number,
  descriptionMaxLength: PropTypes.number,
  isLoading: PropTypes.bool,
  fields: PropTypes.shape({
    rewardTitle: PropTypes.string.isRequired,
    rewardDescription: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    duration: PropTypes.oneOf(Object.keys(RewardDurationEnum)).isRequired,
    pointValue: PropTypes.oneOf(Object.keys(PointValueEnum)).isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
  }),
  fileSizeLimit: PropTypes.number,
  fileSizeError: PropTypes.func,
  onFileSelect: PropTypes.func,
  onImageRemove: PropTypes.func,
  isImageLoading: PropTypes.bool,
};

RewardForm.defaultProps = {
  fields: null,
  fileSizeLimit: null,
  disabledSubmit: false,
  titleMaxLength: 50,
  descriptionMaxLength: 100,
  errorText: '',
};

export default React.memo(RewardForm);
