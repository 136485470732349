import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqid from 'uniqid';
import styled from 'styled-components';
import FormGroup from './FormGroup';
import { theme } from '../config';
import ErrorField from './ErrorField';
import Image from '../content/Image';
import Button from './Button';
import Loader from 'components/mouldifi-components/CircularProgress';

const TrueLabel = styled.label`
  ${theme.fontFamily};
  font-size: 14px;
  position: absolute;
  top: -22px;
  left: 4px;
`;
const LabelWrapper = styled.label`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  border-radius: 8px;
  margin: 4px;
  font-size: 16px;
  cursor: pointer;
  &.no-image {
    justify-content: center;
    align-items: center;
    background-color: ${props => props.bgColor};
  }
`;
const UploadIcon = styled.i`
  font-size: 40px;
`;
const ImageUploadField = styled.input`
  display: none;
`;
const FormGroupImage = styled(FormGroup)`
  height: 100%;
  position: relative;
`;

const UploadedImage = styled(Image)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  border-color: transparent;
`;
const CancelButton = styled(Button)`
  &&& {
    background-color: ${theme.colors.switch_off};
    border-color: ${theme.colors.switch_off};
    margin: 4px;
    padding: 8px 12px;
    color: ${theme.colors.dark_grey};

    &:hover {
      background-color: ${theme.colors.equell_teal};
      color: ${theme.colors.true_white};
    }
  }
`;
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      b64string: '',
      draggedOver: false,
    };
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragExit = this.handleDragExit.bind(this);
    this.handleImageChanged = this.handleImageChanged.bind(this);
    this.handleCancelImage = this.handleCancelImage.bind(this);
    this.fireImageChanged = this.fireImageChanged.bind(this);
  }
  handleDrop(formChangeEvent) {
    return e => {
      e.stopPropagation();
      e.preventDefault();
      this.fireImageChanged(formChangeEvent, e.dataTransfer.files[0]);
    };
  }
  handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ draggedOver: true });
  }
  handleDragExit() {
    this.setState({ draggedOver: false });
  }
  handleCancelImage(formChangeEvent) {
    return e => {
      e.stopPropagation();
      e.preventDefault();

      this.setState({ b64string: '' });
      formChangeEvent({ target: { value: '' } });
    };
  }
  handleImageRemove = e => {
    e.preventDefault();

    const { onImageRemove, input } = this.props;

    if (onImageRemove) {
      onImageRemove();
    }
  };
  handleImageChanged(formChangeEvent) {
    return e => {
      this.fireImageChanged(formChangeEvent, e.target.files[0]);
    };
  }
  fireImageChanged(formChangeEvent, imageObj) {
    const { getFile, fileSizeLimit, fileSizeError, onFileSelect } = this.props;
    if (fileSizeLimit && imageObj.size > fileSizeLimit) {
      fileSizeError();
      return;
    }
    const reader = new FileReader();

    reader.onload = e => {
      const b64string = e.target.result;
      // this.setState({ b64string });
      // tricks react-final-form to believe that it's a normal event when doing e.target.value
      if (getFile) {
        getFile(b64string);
      }
      // formChangeEvent({ target: { value: b64string } });
      if (onFileSelect) {
        onFileSelect(b64string, setFormEvent);
      }
    };
    reader.readAsDataURL(imageObj);

    function setFormEvent(imageId) {
      formChangeEvent({ target: { value: imageId } });
    }
  }
  render() {
    const { b64string, draggedOver } = this.state;
    const { id, label, accept, input, meta, bgColor, isLoading, imageUrl, noLabel, children } = this.props;
    const image = imageUrl;
    const defaultIcon = children ? <Fragment>{children}</Fragment> : <UploadIcon className="mdi mdi-plus-circle-outline" />

    const icons = draggedOver ? <UploadIcon className="mdi mdi-cloud-upload" /> : defaultIcon;

    return (
      <FormGroupImage>
        <TrueLabel>{label}</TrueLabel>
        {!isLoading && image && <UploadedImage src={image} />}
        <LabelWrapper
          htmlFor={id}
          onDrop={this.handleDrop(input.onChange)}
          onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragExit}
          className={classNames({ 'no-image': !image || isLoading })}
          bgColor={bgColor}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <Fragment>
              {!image && icons}
              {image ? (
                <CancelButton onClick={this.handleImageRemove}>Remove</CancelButton>
              ) : (
                !noLabel && <span>Drop a File Here to Upload</span>
              )}
            </Fragment>
          )}
        </LabelWrapper>

        <ImageUploadField
          value={''}
          id={id}
          type="file"
          onChange={this.handleImageChanged(input.onChange)}
          accept={accept}
        />
        {meta.error && <ErrorField>{meta.error}</ErrorField>}
      </FormGroupImage>
    );
  }
}

ImageUpload.propTypes = {
  fileSizeLimit: PropTypes.number,
  fileSizeError: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  accept: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  bgColor: PropTypes.string,
  isLoading: PropTypes.bool,
  imageUrl: PropTypes.string,
  onImageRemove: PropTypes.func,
};

ImageUpload.defaultProps = {
  label: null,
  fileSizeLimit: null,
  id: uniqid(),
  accept: ' .jpg, .jpeg, .png',
  input: {},
  meta: {},
  bgColor: theme.colors.switch_off,
};
export default ImageUpload;
