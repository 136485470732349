import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../config';

const DeleteButtonContainer = styled.span`
  border-radius: 100%;
  width: 36px;
  height: 36px;
  background-color: ${theme.colors.switch_off};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 4px;
  &:hover {
    background-color: ${theme.colors.light_grey};
    color: white;
  }
  & > i {
    font-size: 16px;
  }
`;

const DeleteButton = ({ onClick, id }) => {
  function handleClick() {
    onClick(id);
  }
  return (
    <DeleteButtonContainer onClick={handleClick}>
      <i className="mdi mdi-close" />
    </DeleteButtonContainer>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DeleteButton;
