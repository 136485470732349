import React, {Fragment} from 'react';
import { BodyContainer } from 'components/design-components';
import { Text, StyledHeaderContent } from './styled';
import { HeaderContent } from 'components/content';
import CreateItem from './components/CreateItem';
import InvitesList from './components/InvitesList';
import MyGroups from './components/MyGroups';

const Groups = ({ myId, invites, createGroupLink, groups, onViewGroup, onEditGroup, updateInviteStatus, onRejectInvite }) => {
  const headerProps = {
    header: 'Groups',
  };

  return (
    <Fragment>
      <StyledHeaderContent>
        <HeaderContent {...headerProps} />
      </StyledHeaderContent>
      <BodyContainer>
        <Text>Your groups <span>({groups.length})</span></Text>
        <CreateItem createGroupLink={createGroupLink} groups={groups} />
        <MyGroups
          myId={myId}
          groups={groups}
          createGroupLink={createGroupLink}
          onEditGroup={onEditGroup}
          onViewGroup={onViewGroup}
        />
      </BodyContainer>
      <BodyContainer>
        <Text>Invites <span>({invites.length})</span></Text>
        <InvitesList invites={invites} updateInviteStatus={updateInviteStatus} onRejectInvite={onRejectInvite} />
      </BodyContainer>
    </Fragment>
  );
};

export default Groups;
