import { css } from 'styled-components';
import { media } from 'components/design-components/config';

export default css`
  .fc-widget-small {
    bottom: 100px !important;
    right: 100px !important;
    ${media.phone`
      transform: scale(0.82);
      bottom: 0 !important;
      right: 0 !important;
    `};
  }
  .equel-chat {
    ${media.tablet`
      display: none;
    `};
  }
  
  .fc-widget-open .equel-chat {
    display: block;
  }
`;
