import React from 'react';
import PropTypes from 'prop-types';
import { BodyContainer, Link } from 'components/design-components';
import ErrorImage from 'public/Errorillustration.png';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

const ImageContainer = styled.div`
  padding: 80px 0 40px 0;
`;

const Title = styled.div`
  font-size: 36px;
`;

const InfoAndSupport = styled.div`
  font-size: ${theme.fontSize.xlarge};
`;

const ContactEquell = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const ErrorPage = ({ backToEquell }) => {
  function clickForSupport() {
    if (window.fcWidget.isOpen() !== true) {
      window.fcWidget.open();
    }
  }
  return (
    <BodyContainer
      before={
        <Link to={backToEquell} arrowLeft>
          Back to equell
        </Link>
      }
    >
      <Container>
        <Title>Did someone pull the plug?</Title>
        <ImageContainer>
          <img src={ErrorImage} alt="Error illustration" />
        </ImageContainer>
        <InfoAndSupport>We’re not quite sure what went wrong.</InfoAndSupport>
        <InfoAndSupport>
          You can go back, or{' '}
          <ContactEquell onClick={clickForSupport}>contact equell support</ContactEquell>
        </InfoAndSupport>
      </Container>
    </BodyContainer>
  );
};

ErrorPage.propTypes = {
  backToEquell: PropTypes.string.isRequired,
};

export default React.memo(ErrorPage);
