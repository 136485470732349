import React from 'react';
import PropTypes from 'prop-types';
import Link from '../typography/Link';

const TableLink = ({ onClick, id, children }) => {
  function handleClick(e) {
    e.preventDefault();
    onClick(id);
  }
  return (
    <Link
      style={{ textDecoration: 'underline', fontWeight: 'bold', fontSize: 16 }}
      onClick={handleClick}
      href="#"
      reactLink={false}
    >
      {children}
    </Link>
  );
};

TableLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
};

export default TableLink;
