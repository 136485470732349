import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import enums, { toastrType } from 'enums/ToastEnums';

const Icon = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 30px;
  }
  margin-right: 10px;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

function runToastr(type, message) {
  const IconSvg = enums[type];
  toast(({ closeToast }) => (
    <MessageContainer onClick={closeToast}>
      <Icon>
        <IconSvg />
      </Icon>
      {message}
    </MessageContainer>
  ));
}

const successMessage = function(message) {
  runToastr(toastrType.SUCCESS, message);
};
const errorMessage = function(message) {
  runToastr(toastrType.ERROR, message);
};
const emailMessage = function(message) {
  runToastr(toastrType.EMAIL, message);
};
const questionMessage = function(message) {
  runToastr(toastrType.QUESTION, message);
};

export { successMessage, errorMessage, emailMessage, questionMessage };
