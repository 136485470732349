import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import NavDropdown from './NavDropdown';
import styled from 'styled-components';
import { theme } from 'components/design-components/config';
import { BorderContainer, DropdownItem } from 'components/custom/nav-bar/components/styled';

const IsOnEquel = styled.i.attrs(props => ({
  className: props.isOn && 'mdi mdi-check',
}))`
  font-size: 15px;
  color: ${theme.colors.equell_teal};
  margin-right: 5px;
`;

const EquelStatus = ({ isEquelOn, equelOptionsOffline }) => (
  <NavDropdown 
    label={
      <Fragment>
        <IsOnEquel isOn={isEquelOn} />
        equell is {isEquelOn ? 'on' : 'off'}
      </Fragment>
    }
    isOn={isEquelOn}
  >
    <BorderContainer style={{ fontSize: '14px' }}>
      Turn on equell using these apps and extensions
    </BorderContainer>
    {equelOptionsOffline.map(item => (
      <DropdownItem key={uniqid()} borderTop={!isEquelOn}>
        {item}
      </DropdownItem>
    ))}
  </NavDropdown>
);

EquelStatus.propTypes = {
  isEquelOn: PropTypes.bool,
  equelOptionsOffline: PropTypes.array.isRequired,
};

EquelStatus.defaultProps = {
  isEquelOn: false,
  equelOptionsOffline: [],
};

export default React.memo(EquelStatus);
