import React from 'react';
import ClaimedRewardsTable from './components/ClaimedRewardsTable';
import { BodyContainer } from 'components/design-components';

const ClaimedRewards = ({ rewards }) => {
  return (
    <BodyContainer title="Claimed Rewards" titleCount={rewards.length}>
      <ClaimedRewardsTable nodataText="No claimed rewards" rewards={rewards} />
    </BodyContainer>
  );
};
ClaimedRewards.propTypes = {
  rewards: ClaimedRewardsTable.propTypes.rewards,
};
ClaimedRewards.defaultProps = {
  rewards: [],
};
export default ClaimedRewards;
