import React from 'react';

const SvgGroups = props => (
  <svg width="1em" height="1em" viewBox="0 0 26 17" {...props}>
    <path
      fillRule="evenodd"
      d="M14.069 9.79c-.53-.153-1.029-.3-1.182-.354-.214-.078-.304-.144-.255-.442.046-.115.286-.43.462-.663.606-.797 1.52-2.002 1.52-3.317 0-2.63-2.07-4.77-4.614-4.77-2.545 0-4.615 2.14-4.615 4.77 0 1.315.915 2.52 1.52 3.317.177.232.417.548.463.663.049.298-.041.364-.255.441a33.27 33.27 0 0 1-1.181.356c-2.12.618-3.198.955-3.587 1.204-1.424.911-1.625 3.833-1.65 4.41-.017.366.306.675.72.69h.03c.402 0 .735-.28.75-.638.087-1.915.666-3.153 1.027-3.383.323-.19 2.253-.752 3.18-1.022.689-.2 1.088-.318 1.28-.387.897-.325 1.322-1.013 1.168-1.89-.061-.347-.328-.698-.697-1.184-.534-.703-1.265-1.666-1.265-2.577 0-1.865 1.425-3.441 3.112-3.441s3.112 1.576 3.112 3.44c0 .912-.731 1.875-1.265 2.578-.37.486-.636.837-.697 1.184-.154.877.271 1.565 1.168 1.89.192.07.591.186 1.28.387.927.27 2.857.832 3.176 1.019.557.356.968 2.035 1.03 3.387.016.357.35.637.75.637h.03c.415-.015.738-.324.722-.69-.026-.577-.227-3.499-1.651-4.41-.39-.248-1.466-.586-3.586-1.204zM22 2v6m3-3h-6"
    />
    <path d="M22,2 L22,8" id="shape" stroke="#737373" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M25,5 L19,5" id="shape" stroke="#737373" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SvgGroups;
